import React from "react";
import { TableRow } from ".";
import { Table } from "components/shared/table";
import { IRawIngredientItem } from "pages/rawIngredient/types";

interface IProps {
    rawIngredients?: IRawIngredientItem[];
}

export const RawIngredientsTable: React.FC<IProps> = ({ rawIngredients }) => {
    return (
        <Table
            withHash={true}
            headNames={["Raw Ingredient Code", "Raw Ingredient Name", "Status"]}
        >
            <>
                {rawIngredients?.map((rawIngredient) => (
                    <TableRow
                        rawIngredient={rawIngredient}
                        key={rawIngredient.id}
                    />
                ))}
            </>
        </Table>
    );
};
