import React, { useCallback, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUploadContaminant, useRemoveContaminant } from "./queries";
import { useContaminantStore } from "./store";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    toastTexts,
    routerKeys,
    queryKeys,
} from "common/constants";
import { Header } from "./components";
import { TabsBox } from "components/tabsBox";
import { ToastContainer, toast } from "react-toastify";
import { LoadingToast } from "components/shared/loadingToast";
import { useQueryClient } from "@tanstack/react-query";
import { useKeycloak } from "@react-keycloak/web";

export const ContaminantProfilePage = () => {
    const { id = "" } = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { keycloak } = useKeycloak();

    const { setIsOwner, isOwner, setKey } = useContaminantStore();
    const {
        data: contaminant,
        isLoading,
        isError,
        refetch,
    } = useUploadContaminant(id);

    useEffect(() => {
        if (id) {
            const queryKey = [queryKeys.contaminants, id];
            setKey(queryKey);
        }
    }, [id]);

    const isUserOwner = useMemo(() => {
        return keycloak.tokenParsed?.email === contaminant?.created_by;
    }, [keycloak.tokenParsed?.email, contaminant?.created_by]);

    useEffect(() => {
        //setIsOwner(isUserOwner);
        setIsOwner(true);

        return () => {
            setIsOwner(false);
        };
    }, [isUserOwner, setIsOwner]);

    const { mutate: removeContaminantMutate } = useRemoveContaminant();

    const removeContaminant = useCallback(() => {
        removeContaminantMutate(
            { id },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: [queryKeys.contaminants],
                    });
                    navigate(routerKeys.contaminants);
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [id]);

    const onRemove = useCallback(() => {
        if (isOwner) {
            return removeContaminant;
        }
    }, [removeContaminant, isOwner]);

    if (isLoading) {
        return <LoadingToast />;
    }

    if (!contaminant || isError) {
        if (isError) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        } else {
            toast.info(toastTexts.dataLack, defaultToastOptions);
        }

        return <ToastContainer />;
    }

    return (
        <>
            <Header refetch={refetch} />
            <TabsBox
                tabs={[]}
                onSelectTab={() => {}}
                selectedTab={""}
                onRemove={onRemove()}
            />
            <ToastContainer />
        </>
    );
};
