import React, { FC } from "react";
import { Link } from "react-router-dom";

export const ToxTreeInfo: FC = () => (
    <tr>
        <td colSpan={2} className="p-3 text-sm text-gray-500">
            <span>
                <Link
                    to="https://toxtree.sourceforge.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-secondary-info hover:text-blue-600 hover:underline"
                >
                    ToxTree
                </Link>{" "}
                is a tool used for predicting the toxicity of chemicals.
            </span>
            <br />
            <span>
                To understand what a Cramer Class is and how to use ToxTree for
                determining it, refer to the following guide:{" "}
                <Link
                    to="https://www.chemsafetypro.com/Topics/CRA/How_to_Use_Toxtree_to_Determine_Cramer_Class_and_Estimate_Toxic_Hazard.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-secondary-info hover:text-blue-600 hover:underline"
                >
                    Cramer Class Guide
                </Link>
                .
            </span>
        </td>
    </tr>
);
