import React from "react";
import { CheckboxLabel } from "../checkboxLabel";

interface IProps {
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
}

export const YesNoCheckboxGroup: React.FC<IProps> = ({
    name,
    value,
    onChange,
    disabled,
}) => (
    <>
        <CheckboxLabel
            name={name}
            value="yes"
            isChecked={value === "yes"}
            onChange={onChange}
            label="YES"
            disabled={disabled}
        />
        <CheckboxLabel
            name={name}
            value="no"
            isChecked={value === "no"}
            onChange={onChange}
            label="NO"
            disabled={disabled}
        />
        <CheckboxLabel
            name={name}
            value="datamissing"
            isChecked={value === "datamissing"}
            onChange={onChange}
            label="NOT AVAILABLE"
            disabled={disabled}
        />
    </>
);
