import { PageTemplate } from "components/page-template";
import React, { useEffect, useMemo, useState } from "react";
import {
    defaultErrorToastOptions,
    routerKeys,
    subIngredientContent,
    toastTexts,
} from "common/constants";
import { LoadingToast } from "components/shared/loadingToast";
import { CreateINCIForm, Filters } from "./components";
import { useGetTableSubIngredients } from "./queries";
import { ToastContainer, toast } from "react-toastify";
import { ModalTemplate } from "components/shared/modalTemplate";
import { ButtonBlock } from "components/shared/button-block/ButtonBlock";
import {
    DataTable,
    FilterBlock,
    IconLink,
    StatusView,
} from "components/shared";
import { ColumnDef } from "@tanstack/react-table";
import { TableSubIngredient } from "./libs/types";
import { useFilter, useModal, useURLStateSync } from "common/hooks";
import { modifyDate } from "common/utils";

export const SubIngredients = () => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const {
        filters,
        pagination,
        sorting,
        setFilters,
        setPagination,
        setSorting,
        getParamsString,
    } = useURLStateSync();

    const {
        data: subIngredients,
        isLoading,
        isError,
        refetch,
    } = useGetTableSubIngredients({
        queryParams: getParamsString(),
        sorting,
    });

    useEffect(() => {
        if (isError) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        }
    }, [isError]);

    const {
        modalRef: createSubIngredientRef,
        openModal,
        closeModal,
    } = useModal({
        onClose: () => {
            refetch();
        },
    });

    const {
        handleUpdateFilters,
        handleUpdateSearch,
        handleFilterVisible,
        handleRemoveFilter,
    } = useFilter({
        setFilters,
        setIsFilterVisible,
    });

    const columns = useMemo<ColumnDef<TableSubIngredient>[]>(
        () => [
            {
                accessorKey: "sicode",
                header: () => <span className="max-w-[100px]">SI Code</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[110px]"
                        to={routerKeys.subIngredient}
                        id={row.original.id}
                    >
                        <span
                            className="truncate ... w-[100px]"
                            title={row.getValue("sicode")}
                        >
                            {row.getValue("sicode")}
                        </span>
                    </IconLink>
                ),
            },
            {
                accessorKey: "name",
                header: () => <span>Name</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[200px] lg:w-[300px] xl:w-[400px] 2xl:w-[500px]"
                        to={routerKeys.subIngredient}
                        id={row.original.id}
                    >
                        <p
                            className="truncate ... w-[200px] lg:w-[300px] xl:w-[400px] 2xl:w-[500px] cursor-pointer"
                            title={row.getValue("name")}
                        >
                            {row.getValue("name")}
                        </p>
                    </IconLink>
                ),
            },
            {
                accessorKey: "function",
                header: () => <span>Functions</span>,
                cell: ({ row }) =>
                    (row.getValue("function") as string)
                        ?.split(",")
                        ?.map((item) => (
                            <p
                                className="truncate ... w-[220px]"
                                title={item}
                                key={item}
                            >
                                {item}
                            </p>
                        )),
            },
            {
                accessorKey: "status",
                header: () => <span>Status</span>,
                cell: ({ row }) => (
                    <StatusView
                        title={row.original.status || ""}
                        active={row.original.status === "Active"}
                    />
                ),
            },
            {
                accessorKey: "date_created",
                header: () => <span>Date Created</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_created"))}</span>
                ),
            },
            {
                accessorKey: "date_modified",
                header: () => <span>Date Modified</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_modified"))}</span>
                ),
            },
            {
                id: "action",
                accessorKey: "action",
                enableSorting: false,
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.subIngredient}
                        id={row.original.id}
                    />
                ),
            },
        ],
        [],
    );

    return (
        <PageTemplate
            title={subIngredientContent.title}
            subtitle={subIngredientContent.subTitle}
            searchValue={filters.query?.value || ""}
            searchPlaceholder={subIngredientContent.placeholder}
            onChange={handleUpdateSearch}
            buttonBlock={
                <ButtonBlock
                    title={subIngredientContent.title}
                    createFn={openModal}
                />
            }
        >
            <div className="md:flex lg:flex py-4">
                <ModalTemplate
                    text="Create INCI"
                    onClose={closeModal}
                    modalRef={createSubIngredientRef}
                    className="max-w-[600px]"
                >
                    <CreateINCIForm handleClose={closeModal} isLink />
                </ModalTemplate>
                <div className="w-full mx-10">
                    {/* Filter button and filters status */}
                    <FilterBlock
                        isFilterVisible={isFilterVisible}
                        onFilterVisible={handleFilterVisible}
                        onRemoveFilter={handleRemoveFilter}
                        filters={filters}
                    />

                    <DataTable
                        data={subIngredients?.data || []}
                        columns={columns}
                        pagination={pagination}
                        setPagination={setPagination}
                        totalCount={subIngredients?.count || 0}
                        isFilterVisible={isFilterVisible}
                        sorting={sorting}
                        setSorting={setSorting}
                    >
                        {/* Custom filters */}
                        <Filters
                            onUpdateFilters={handleUpdateFilters}
                            filters={filters}
                        />
                    </DataTable>

                    {isLoading ? <LoadingToast /> : <ToastContainer />}
                </div>
            </div>
        </PageTemplate>
    );
};
