export const getInitialFormulaObject = (formula) => ({
    fda_class: {
        value: formula?.fda_product_classification || "fda",
    },
    regulatory_body: {
        value: formula?.regulatory_body || "",
    },
    rinseoff_leaveon: {
        value: formula?.rinseoff_leaveon || "",
    },
    flammability_code: {
        value: formula?.flammability_code || "",
    },
    physical_state: {
        value: formula?.physical_state || "",
    },
    initial_ph_min: {
        value: formula?.initial_ph_min || "",
    },
    initial_ph_max: {
        value: formula?.initial_ph_max || "",
    },
    emulsion: { value: formula?.emulsion || "not_applicable" },
    product_type: {
        value: formula?.product_type || "",
    },
    status: {
        value: formula?.status || "",
    },
});
