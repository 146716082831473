import classNames from "classnames";
import React, { memo } from "react";

interface IProps {
    text: string;
    className?: string;
    id?: string;
    tooltipMessage?: string | null;
}

export const TableTitleCeil: React.FC<IProps> = memo(
    ({ text, className, id, tooltipMessage }) => (
        <div
            className={classNames(
                "flex flex-wrap items-center text-xs md:text-base lg:text-base pl-3 py-3 break-words",
                className,
            )}
        >
            <b>{text}</b>
            {id && tooltipMessage && (
                <span
                    className="flex items-center justify-center w-5 h-5 p-2 ml-2 rounded-full bg-blue-100 transition-all cursor-pointer"
                    id={id}
                >
                    ?
                </span>
            )}
        </div>
    ),
);
