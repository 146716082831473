import React, { FC } from "react";
import classNames from "classnames";
import { type MoleculeDataType } from "pages/subIngredient/subIngredientProfile/libs";
import { normalizeString } from "common/utils";

interface IProps {
    dataStatus?: "idle" | "pending" | "success" | "error";
    model: MoleculeDataType;
    onSubmit?: () => void;
    errors?: Record<string, string>;
}

export const DataStatusButton: FC<IProps> = ({
    dataStatus,
    model,
    onSubmit,
    errors = {},
}) => {
    if (dataStatus === "idle") return null;

    return (
        <div className="flex flex-col items-center space-y-4 mb-3">
            <button
                type="submit"
                className={classNames(
                    "w-full text-lg flex justify-center items-center px-3 py-[calc(0.375em-1px)] text-center whitespace-nowrap rounded-lg",
                    {
                        "bg-blue-400 text-white hover:bg-blue-500 cursor-pointer":
                            dataStatus === "success",
                        "bg-gray-300 text-gray-500 cursor-not-allowed":
                            dataStatus !== "success",
                        "bg-red-300 text-white cursor-not-allowed":
                            dataStatus === "error" ||
                            Object.values(errors).length > 0,
                    },
                )}
                disabled={!model?.smiles || dataStatus !== "success"}
                onClick={onSubmit}
            >
                {dataStatus === "pending" && "Loading data..."}
                {dataStatus === "error" && "Error loading data"}
                {dataStatus === "success" && "Make a prediction"}
            </button>

            {Object.values(errors).length > 0 && dataStatus === "success" && (
                <>
                    <p className="text-sm text-gray-500">
                        Please fix the errors before making a prediction.
                    </p>
                    <ul className="text-sm text-red-500">
                        {Object.entries(errors)?.map(([prop, error], index) => (
                            <li className="text-sm mb-1" key={index}>
                                <span className="font-bold">
                                    {normalizeString(prop)}
                                </span>
                                : {error}
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {dataStatus === "pending" && (
                <p className="text-sm text-gray-500">
                    Data is loading, please wait...
                </p>
            )}
            {dataStatus === "error" && (
                <p className="text-sm text-red-500">
                    There was an error loading data. Please try again.
                </p>
            )}
        </div>
    );
};
