import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Status } from "pages/dashboard/components/Status";
import formulaIcon from "assets/images/icons/green-epruvete.svg";
import editIcon from "assets/images/icons/edit.svg";
import { FormulaStatus } from "common/data";
import { TableEntity } from "pages/dashboard/libs/types";
import classNames from "classnames";
import { Entity } from "pages/dashboard/libs/enums";
import {
    useFullSavedFormulas,
    useGetSavedFormulas,
    useGetUserProfile,
    useSaveFormulaMutation,
} from "pages/profile/libs";
import { useFormik } from "formik";
import { SaveButton } from "components/shared/save-button/SaveButton";
import { toast } from "react-toastify";
import {
    backendKeys,
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    toastTexts,
} from "common/constants";
import { buildLink } from "common/utils";

interface IProps {
    entities?: TableEntity[];
    entityType?: keyof typeof Entity;
    entity?: typeof Entity;
}

export const FormulasTable: FC<IProps> = ({
    entityType = "formulas",
    entities = [],
    entity = Entity,
}) => {
    const { mutate: saveFormula } = useSaveFormulaMutation();
    const { data: userInfo } = useGetUserProfile();
    const { data: savedFormulas, refetch: refetchSavedFormulas } =
        useGetSavedFormulas();
    const { refetch: refetchFullFormulas } = useFullSavedFormulas();

    const formik = useFormik({
        initialValues: {
            userId: userInfo?.id || "",
            formulaId: 0,
        },

        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            saveFormula(values, {
                onSuccess: () => {
                    toast.update(toastId, {
                        ...defaultSuccessToastUpdate,
                        render: toastTexts.success,
                    });

                    if (refetchFullFormulas) {
                        refetchFullFormulas();
                    }

                    if (refetchSavedFormulas) {
                        refetchSavedFormulas();
                    }
                },
                onError: () => {
                    toast.update(toastId, defaultErrorToastUpdate);
                },
            });
        },
    });

    const handleSave = (formulaId: number) => {
        formik.setValues({ userId: userInfo?.id || "", formulaId });
        formik.submitForm();
        refetchFullFormulas();
        refetchSavedFormulas();
    };

    const headers = entity[entityType]?.headers || [];

    return (
        <div className="rounded-md bg-white overflow-x-auto">
            <div className="hidden md:grid grid-cols-12 py-1 border-b-2 border-gray-200">
                {headers.map((header, index) => (
                    <div
                        key={`${header}-${index}`}
                        className={classNames(
                            "px-2",
                            {
                                "col-span-2":
                                    index === 0 ||
                                    (index > 1 && index < headers.length - 2),
                            },
                            { "col-span-4": index === 1 },
                            {
                                "col-span-1 text-right":
                                    index === headers.length - 2,
                            },
                            {
                                "col-span-1 text-right":
                                    index === headers.length - 1,
                            },
                        )}
                    >
                        {header}
                    </div>
                ))}
            </div>

            {entities.length > 0 ? (
                entities.map(
                    ({
                        id,
                        code,
                        description,
                        created_by,
                        status,
                        created,
                        funcParameter,
                    }) => (
                        <div
                            className="grid grid-cols-12 border-b-2 py-2 border-gray-200"
                            key={id}
                        >
                            <div className="col-span-2 flex items-center pl-3">
                                <img
                                    src={formulaIcon}
                                    alt="Chemical flask"
                                    className="h-5 pr-2"
                                />
                                {code}
                            </div>
                            <div className="col-span-4 flex items-center text-gray-600 px-2">
                                {description}
                            </div>
                            <div className="col-span-2 flex items-center">
                                <Status
                                    status={
                                        status as keyof typeof FormulaStatus
                                    }
                                />
                            </div>
                            {/* Conditionally render based on data */}
                            {funcParameter && (
                                <div className="col-span-2 flex items-center px-2">
                                    {funcParameter}
                                </div>
                            )}
                            <div className="col-span-2 flex items-center px-2">
                                {created_by}
                            </div>
                            {/* Conditionally render based on data */}
                            {created && (
                                <div className="col-span-2 flex items-center px-2">
                                    {created}
                                </div>
                            )}

                            <SaveButton
                                className="col-span-1 justify-end flex items-center px-2"
                                id={id}
                                onAction={handleSave}
                                items={savedFormulas || []}
                                attribute="formulaId"
                                isDisabled
                            />

                            <div className="col-span-1 flex items-center justify-end pr-3">
                                <Link
                                    to={buildLink(backendKeys.formulas, id)}
                                    className="text-secondary-info"
                                >
                                    <img src={editIcon} alt="Edit icon" />
                                </Link>
                            </div>
                        </div>
                    ),
                )
            ) : (
                <div className="text-center py-4 text-gray-500">
                    No data available
                </div>
            )}
        </div>
    );
};
