import React, { FC } from "react";
import classNames from "classnames";

interface IProps {
    active?: boolean;
    title?: string;
    className?: string;
}

export const StatusView: FC<IProps> = ({ className, active, title }) => (
    <div className={className}>
        {title && (
            <span
                className={classNames(
                    "inline-block px-3 py-1 min-w-10 rounded-full text-sm",

                    active ? "bg-green-200" : "bg-red-200",
                )}
            >
                {title}
            </span>
        )}
    </div>
);
