import React, { FC } from "react";

interface IProps {
    width?: number;
    height?: number;
    className?: string;
    color?: string;
}

export const ArrowTop: FC<IProps> = ({
    width = 24,
    height = 24,
    className = "",
    color = "inherit",
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={`${height}`}
        viewBox="0 -960 960 960"
        width={`${width}`}
        className={className}
    >
        <path
            d="M440-160v-487L216-423l-56-57 320-320 320 320-56 57-224-224v487h-80Z"
            fill={color}
        />
    </svg>
);
