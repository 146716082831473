const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
};

export const modifyDate = (
    date: string | number,
    options: Intl.DateTimeFormatOptions = dateOptions,
) => new Date(date).toLocaleDateString("en-US", options);
