import React, { useCallback, useState, memo, useEffect, FC } from "react";
import { DropdownWrapper, CheckboxGroup, Details } from "components/shared";
import { useUploadProductTypes } from "../../queries";
import { IFilter } from "common/types";
import { useHandleUpdateFilters } from "common/hooks";

interface IBoxes {
    productType: boolean;
    animalDerivedIngredients: boolean;
    containGluten: boolean;
    claims: boolean;
}

interface IProps {
    onUpdateFilters: (
        key: string,
        { value, label }: IFilter[keyof IFilter],
    ) => void;
    filters: IFilter;
}

export const Filters: FC<IProps> = memo(({ onUpdateFilters, filters }) => {
    const [isOpen, setIsOpen] = useState<IBoxes>({
        productType: false,
        animalDerivedIngredients: false,
        containGluten: false,
        claims: false,
    });

    const handleToggleVisibility = useCallback((name: string) => {
        setIsOpen((curr) => ({
            ...curr,
            [name]: !curr[name as keyof IBoxes],
        }));
    }, []);

    const handleToggleProductType = useCallback(() => {
        handleToggleVisibility("productType");
    }, [handleToggleVisibility]);

    const handleUpdateFilters = useHandleUpdateFilters({
        onUpdateFilters,
        filters,
    });

    const { data: uploadProductTypes = [], refetch } = useUploadProductTypes();

    useEffect(() => {
        refetch();
    }, [refetch]);

    return (
        <form>
            <div className="w-full bg-white max-w-lg px-4 my-2 rounded-md">
                <Details
                    isOpen={isOpen.productType}
                    text="Product type"
                    onOpen={handleToggleProductType}
                >
                    <DropdownWrapper
                        name="product_type"
                        value={filters?.product_type?.value ?? ""}
                        onChange={handleUpdateFilters({
                            key: "product_type",
                            label: "Product type",
                        })}
                        options={uploadProductTypes}
                    />
                </Details>

                <Details
                    isOpen={isOpen.animalDerivedIngredients}
                    text="Contains animal derived ingredients"
                    onOpen={() =>
                        handleToggleVisibility("animalDerivedIngredients")
                    }
                >
                    <CheckboxGroup
                        value="yes"
                        label="Yes"
                        onChange={handleUpdateFilters({
                            key: "animal_derived",
                            label: "Contains animal derived ingredients",
                        })}
                        isChecked={filters?.animal_derived?.value === "yes"}
                    />
                    <CheckboxGroup
                        value="no"
                        label="No"
                        onChange={handleUpdateFilters({
                            key: "animal_derived",
                            label: "Contains animal derived ingredients",
                        })}
                        isChecked={filters?.animal_derived?.value === "no"}
                    />
                    <CheckboxGroup
                        value="datamissing"
                        label="Data not available"
                        onChange={handleUpdateFilters({
                            key: "animal_derived",
                            label: "Contains animal derived ingredients",
                        })}
                        isChecked={
                            filters?.animal_derived?.value === "datamissing"
                        }
                    />
                </Details>

                <Details
                    isOpen={isOpen.containGluten}
                    text="Contain Gluten"
                    onOpen={() => handleToggleVisibility("containGluten")}
                >
                    <CheckboxGroup
                        value="yes"
                        label="Yes"
                        onChange={handleUpdateFilters({
                            key: "gluten",
                            label: "Contain gluten",
                        })}
                        isChecked={filters?.gluten?.value === "yes"}
                    />
                    <CheckboxGroup
                        value="no"
                        label="No"
                        onChange={handleUpdateFilters({
                            key: "gluten",
                            label: "Contain gluten",
                        })}
                        isChecked={filters?.gluten?.value === "no"}
                    />
                    <CheckboxGroup
                        value="datamissing"
                        label="Data not available"
                        onChange={handleUpdateFilters({
                            key: "gluten",
                            label: "Contain gluten",
                        })}
                        isChecked={filters?.gluten?.value === "datamissing"}
                    />
                </Details>

                <Details
                    isOpen={isOpen.claims}
                    text="Claims"
                    onOpen={() => handleToggleVisibility("claims")}
                >
                    <CheckboxGroup
                        value="yes"
                        label="Cruelty free"
                        onChange={handleUpdateFilters({
                            key: "cfi_acceptable",
                            label: "Cruelty free",
                        })}
                        isChecked={filters?.cfi_acceptable?.value === "yes"}
                    />
                    <CheckboxGroup
                        value="yes"
                        label="Vegan"
                        onChange={handleUpdateFilters({
                            key: "vegan",
                            label: "Vegan",
                        })}
                        isChecked={filters?.vegan?.value === "yes"}
                    />
                </Details>
            </div>
        </form>
    );
});
