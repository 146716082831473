import React from "react";

interface IProps {
    width?: number;
    height?: number;
    color?: string;
}

export const FolderIcon: React.FC<IProps> = ({
    width = 23,
    height = 19,
    color = "#7B61FF",
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 23 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.8354 18.5105H1.16456C0.5214 18.5105 0 17.9891 0 17.3459V4.96952C0 4.32637 0.5214 3.80497 1.16456 3.80497H13.6698C14.1481 3.80497 14.5777 3.51254 14.7532 3.06756L15.3196 1.63096C15.495 1.18598 15.9247 0.893555 16.4029 0.893555H21.8353C22.4785 0.893555 22.9999 1.41495 22.9999 2.05811V17.3459C23 17.9891 22.4786 18.5105 21.8354 18.5105Z"
            fill={color}
        />
    </svg>
);
