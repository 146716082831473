import { PlusIcon } from "assets/icons";
import { FolderIcon } from "assets/icons/Folder";
import { modifyDate } from "common/utils/modifyDate";
import React from "react";

interface IProps {
    name: string;
    author: string;
    date: string;
}

export const DetailRow: React.FC<IProps> = ({ name, author, date }) => (
    <div className="grid grid-cols-4 text-sm py-3 border-b-[1px]">
        <div className="flex justify-start gap-2">
            <FolderIcon />
            <p>{name}</p>
        </div>
        <div className="flex justify-center">
            <p>{author}</p>
        </div>
        <div className="flex justify-center">
            <p>{modifyDate(date)}</p>
        </div>
        <div className="flex justify-end">
            <PlusIcon />
        </div>
    </div>
);
