import React, { Dispatch, FC, SetStateAction, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputWrapper, Button } from "components/shared";
import {
    useCreateToxicologicalReferenceValues,
    useGetToxicReferenceOptions,
} from "pages/subIngredient/subIngredientProfile/queries";
import { IEnvironmentData } from "pages/subIngredient/subIngredientProfile/libs/types";
import { getErrorMessage } from "common/utils";
import { AxiosError } from "axios";

interface IProps {
    handleClose: () => void;
    setData: Dispatch<SetStateAction<IEnvironmentData>>;
}

export const AddToxicityReferenceForm: FC<IProps> = memo(
    ({ handleClose, setData }) => {
        const { mutate } = useCreateToxicologicalReferenceValues();
        const { refetch } = useGetToxicReferenceOptions();

        const formik = useFormik({
            initialValues: {
                dep_name: "",
                description: "",
            },
            validationSchema: Yup.object({
                dep_name: Yup.string().required("Name is required"),
                description: Yup.string(),
            }),
            onSubmit: (values) => {
                mutate(values, {
                    onSuccess: (response) => {
                        setData((curr) => ({
                            ...curr,
                            toxicity_reference_select: response.name,
                        }));
                        handleClose();
                        refetch();
                    },
                    onError: (err) => {
                        const errMsg = getErrorMessage(err as AxiosError);

                        formik.setErrors({
                            dep_name: (errMsg as string) || "",
                        });
                    },
                });
            },
        });

        const {
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            resetForm,
        } = formik;

        return (
            <form className="w-full" onSubmit={handleSubmit}>
                <div className="flex flex-col gap-4">
                    <InputWrapper
                        isError={Boolean(errors.dep_name && touched.dep_name)}
                        error={errors.dep_name}
                        label="Name"
                    >
                        <input
                            type="text"
                            name="dep_name"
                            className="w-full input input-bordered"
                            value={values.dep_name}
                            onChange={handleChange}
                        />
                    </InputWrapper>

                    <InputWrapper
                        isError={Boolean(
                            errors.description && touched.description,
                        )}
                        error={errors.description}
                        label="Description"
                    >
                        <textarea
                            className="textarea textarea-bordered w-full flex-grow"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                        />
                    </InputWrapper>
                    <div className="flex items-center justify-end gap-2 mt-3">
                        <Button text="Clear" onClick={resetForm} />
                        <Button text="Create" type="submit" />
                    </div>
                </div>
            </form>
        );
    },
);
