import React, { ChangeEvent, FC, useCallback, useMemo } from "react";
import { ToxTreeInfo } from "./ToxTreeInfo";
import { TableRow } from "./TableRow";
import { IBasicData } from "pages/subIngredient/subIngredientProfile/libs/types";
import {
    useUploadEnvironmentalClass,
    useCrammerClassOptions,
    useGetFunctionOptions,
} from "pages/subIngredient/subIngredientProfile/queries";
import { regexes as regexp } from "common/constants";

interface IProps {
    data: IBasicData;
    smiles?: string | null;
    onChangeData: (
        e: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >,
    ) => void;
    onChangeMultiSelect?: (selected: string[], name: string) => void;
}

export const LeftTable: FC<IProps> = ({
    data,
    smiles,
    onChangeData,
    onChangeMultiSelect,
}) => {
    const { data: environmentalClassOptions } = useUploadEnvironmentalClass();
    const { data: functionOptions } = useGetFunctionOptions();
    const { data: crammerClassOptions } = useCrammerClassOptions();

    const handleCASChange = useCallback(
        (
            e:
                | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                | ChangeEvent<HTMLSelectElement>,
        ) => {
            if (regexp.casNum.test(e.target.value) || !e.target.value) {
                onChangeData(e);
            }
        },
        [onChangeData],
    );

    const multiSelectValues = useMemo(() => {
        return data?.function?.split(",") || [];
    }, [data.function]);

    return (
        <div className="w-1/2 md:w-1/2 lg:w-1/2 md:mr-5">
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg mb-2">
                <tbody>
                    <TableRow
                        header="CAS #"
                        name="cas_number"
                        value={data?.cas_number}
                        onChange={handleCASChange}
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="Environmental Class"
                        name="environmental_class"
                        value={data?.environmental_class}
                        onChange={onChangeData}
                        options={environmentalClassOptions}
                        type="dropdown"
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="Cramer Class"
                        name="cramer_class"
                        value={data?.cramer_class}
                        onChange={onChangeData}
                        options={crammerClassOptions}
                        type="dropdown"
                        lastModifiedBy={data?.last_modified_by}
                    />

                    <ToxTreeInfo />
                    {!smiles && (
                        <TableRow
                            header="SMILES"
                            name="unique_smiles"
                            value={data?.unique_smiles}
                            onChange={onChangeData}
                            lastModifiedBy={data?.last_modified_by}
                        />
                    )}
                    <TableRow
                        name="unique_smiles"
                        header="SMILES"
                        content={smiles}
                        type="text"
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="InChI"
                        content={data?.inchi}
                        type="text"
                    />
                    <TableRow
                        header="Skin Sensitizer"
                        name="skin_sensitizer"
                        onChange={onChangeData}
                        value={data?.skin_sensitizer}
                        comment={data?.skin_sensitizer_comment}
                        type="radio"
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="Food Use"
                        name="food_use"
                        onChange={onChangeData}
                        value={data?.food_use}
                        comment={data?.food_use_comment}
                        type="radio"
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="Ingredient Function"
                        name="function"
                        values={multiSelectValues}
                        onChangeMultiSelect={onChangeMultiSelect}
                        options={functionOptions}
                        type="multi_select"
                    />
                    <TableRow
                        header="Contains Gluten"
                        name="gluten"
                        onChange={onChangeData}
                        value={data.gluten}
                        comment={data?.gluten_comment}
                        type="radio"
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="Colorant"
                        name="colorant"
                        onChange={onChangeData}
                        value={data.colorant}
                        comment={data?.colorant_comment}
                        type="radio"
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="Preservatives"
                        name="preservatives"
                        value={data?.preservatives}
                        onChange={onChangeData}
                        lastModifiedBy={data?.last_modified_by}
                    />
                </tbody>
            </table>
        </div>
    );
};
