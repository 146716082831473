import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

interface IProps {
    confidenceClass?: string;
    confidenceValue: string;
    confidenceText: string;
    withoutMaxWidth?: boolean;
}

export const ConfidenceInner: FC<IProps> = ({
    confidenceClass = "",
    confidenceValue,
    confidenceText,
    // withoutMaxWidth = false,
}) => (
    <div className={classNames(confidenceClass, "max-w-none")}>
        <div className="prediction-confidence__bar-container print:hidden">
            <div className="prediction-confidence__tick prediction-confidence__tick--down">
                <FontAwesomeIcon icon={faCaretDown} color="gray" />
            </div>
            <div className="prediction-confidence__tick prediction-confidence__tick--up">
                <FontAwesomeIcon icon={faCaretUp} color="gray" />
            </div>
            <div className="prediction-confidence__bar">
                <div className="prediction-confidence__bar-unit"></div>
                <div className="prediction-confidence__bar-unit"></div>
                <div className="prediction-confidence__bar-unit"></div>
            </div>
        </div>
        <div className="flex flex-col items-center mt-2 primary-color">
            <span className="text-sm font-medium">Prediction confidence:</span>
            <span
                className={classNames(
                    "tooltip text-center text-sm ml-2",
                    "is-tooltip-top",
                )}
                data-tooltip={confidenceValue}
            >
                {confidenceText}
            </span>
        </div>
    </div>
);
