import React, { FC, useCallback, useRef, useState } from "react";
import {
    DocumentType,
    DocumentTypeT,
} from "pages/rawIngredient/rawIngredientProfile/libs";
import { DropdownItem } from "./DropdownItem";

interface IProps {
    isOwner: boolean;
    uploadLabel: string;
    btnTitle?: string;
    handleClick: (documentType: DocumentTypeT, file: File | null) => void;
    handleAddFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SafetyTechDocActions: FC<IProps> = ({
    isOwner,
    uploadLabel,
    btnTitle = "Upload Document",
    handleClick,
    handleAddFile,
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdownClick = useCallback(
        (documentType: DocumentTypeT) => {
            const fileInput = fileInputRef.current;
            if (fileInput && fileInput.files && fileInput.files[0]) {
                handleClick(documentType, fileInput.files[0]);
                fileInput.value = "";
            } else {
                handleClick(documentType, null);
            }
            setIsDropdownOpen(false);
        },
        [handleClick],
    );

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    return (
        (isOwner || true) && (
            <div className="flex">
                <div className="relative">
                    <button
                        className="btn"
                        onClick={toggleDropdown}
                        aria-haspopup="true"
                        aria-expanded={isDropdownOpen}
                    >
                        {btnTitle}
                    </button>
                    {isDropdownOpen && (
                        <ul className="absolute dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                            <DropdownItem
                                label={DocumentType.SDS}
                                onClick={handleDropdownClick}
                            />
                            <DropdownItem
                                label={DocumentType.COF_A}
                                onClick={handleDropdownClick}
                            />
                            <DropdownItem
                                label={DocumentType.TECH_INFO_SHEET}
                                onClick={handleDropdownClick}
                            />
                        </ul>
                    )}
                </div>
                <div className="w-1/3 flex-col ml-4">
                    <input
                        ref={fileInputRef}
                        className="w-full file-input file-input-bordered"
                        type="file"
                        accept=".doc, .docx, .pdf, .txt, .xls, .xlsx, .rtf"
                        onChange={handleAddFile}
                    />
                    <label className="label mt-1">
                        <span>{uploadLabel}</span>
                    </label>
                </div>
            </div>
        )
    );
};
