export const renderValueWithNonZeroDigits = (
    input: number | string | null | undefined,
    digits: number = 2,
): string => {
    if (input == null) return "";

    const value = input?.toString();
    const dotIndex = value?.indexOf(".");

    if (dotIndex === -1) return value;

    const firstNonZeroIndex = value?.slice(dotIndex + 1)?.search(/[1-9]/);
    if (firstNonZeroIndex === -1) return value.slice(0, dotIndex + 5);

    const cutoffIndex = dotIndex + 1 + firstNonZeroIndex + digits;
    const roundedValue = parseFloat(value.slice(0, cutoffIndex));

    return roundedValue?.toString();
};
