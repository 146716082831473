import React, { FC } from "react";
import { useLogout } from "common/hooks";

interface IProps {
    activeItem: string;
    onItemClick: (item: string) => void;
}

const menuItems = ["My Profile", "Saved Items"];

export const ProfileMenu: FC<IProps> = ({ activeItem, onItemClick }) => {
    const { handleLogout } = useLogout();

    return (
        <div className="bg-profile w-1/5 rounded-tl-2xl rounded-bl-2xl p-4 flex items-center flex-col gap-4 shadow-md">
            {menuItems.map((item) => (
                <div
                    key={item}
                    className={`flex items-center justify-center w-[129px] h-[61px] rounded-full cursor-pointer transition-transform transform ${
                        activeItem === item
                            ? "bg-mint-color gray-color font-semibold text-md border-2 border-green-500"
                            : item === menuItems[3]
                              ? "bg-profile red-color text-md"
                              : "bg-profile gray-color font-semibold text-md"
                    }  hover:scale-105 hover:border-green-500`}
                    onClick={() => onItemClick(item)}
                >
                    {item}
                </div>
            ))}
            <div
                className={
                    "flex items-center justify-center w-[129px] h-[61px] rounded-full cursor-pointer transition-transform transform bg-profile gray-color font-semibold text-md hover:scale-105 hover:border-green-500"
                }
                onClick={handleLogout}
            >
                Log out
            </div>
            <div
                className={
                    "flex items-center justify-center w-[129px] h-[61px] rounded-full cursor-pointer transition-transform transform bg-profile red-color text-md hover:scale-105 hover:border-green-500"
                }
                onClick={() => {}}
            >
                Delete account
            </div>
        </div>
    );
};
