import React, { FC, ChangeEvent, useState, memo } from "react";
import {
    Details,
    FilterInputBox,
    DropdownWrapper,
    CheckboxGroup,
} from "components/shared";
import {
    useGetSubIngredientOptions,
    useGetEnvironmentalClass,
    useGetOverallRank,
} from "../queries";
import { type IFilter } from "common/types";
import { useHandleUpdateFilters } from "common/hooks";

interface IBoxes {
    environmental_class: boolean;
    overall_environmental_rank: boolean;
    function: boolean;
}

interface IProps {
    onUpdateFilters: (
        key: string,
        { value, label }: IFilter[keyof IFilter],
    ) => void;
    filters: IFilter;
}

export const Filters: FC<IProps> = memo(({ onUpdateFilters, filters }) => {
    const [isOpen, setIsOpen] = useState<IBoxes>({
        environmental_class: false,
        overall_environmental_rank: false,
        function: false,
    });

    const handleToggleVisibility = (name: string) => {
        setIsOpen((curr) => ({
            ...curr,
            [name]: !curr[name as keyof IBoxes],
        }));
    };

    const handleUpdateFilters = useHandleUpdateFilters({
        onUpdateFilters,
        filters,
    });

    const handleUpdateFilterInput = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        filterValue?: string,
    ) => {
        const { name, value } = e.target;
        onUpdateFilters(name, {
            value: value,
            label: name || "",
            filterValue: filterValue || "",
        });
    };

    const { data: overallRank } = useGetOverallRank();
    const { data: environmentalClass } = useGetEnvironmentalClass();
    const { data: functionOptions } = useGetSubIngredientOptions("function");

    return (
        <form>
            <div className="w-full bg-white max-w-lg p-4 my-2 rounded-md sm:shadow-lg">
                <FilterInputBox
                    name="sicode"
                    value={filters?.sicode?.value || ""}
                    placeholder="INCI Code"
                    onChange={(e) => handleUpdateFilterInput(e, "INCI Code")}
                />
                <Details
                    isOpen={isOpen.function}
                    text="INCI Function"
                    onOpen={() => handleToggleVisibility("function")}
                >
                    <DropdownWrapper
                        name="function"
                        options={functionOptions}
                        value={filters?.function?.value || ""}
                        onChange={handleUpdateFilters({
                            key: "function",
                            label: "INCI Function",
                        })}
                    />
                </Details>

                <Details
                    isOpen={isOpen.environmental_class}
                    text="Environmental Class"
                    onOpen={() => handleToggleVisibility("environmental_class")}
                >
                    <DropdownWrapper
                        name="environmental_class"
                        options={environmentalClass}
                        value={filters?.environmental_class?.value || ""}
                        onChange={handleUpdateFilters({
                            key: "environmental_class",
                            label: "Environmental Class",
                        })}
                    />
                </Details>
                <Details
                    isOpen={isOpen.overall_environmental_rank}
                    text="Overall Environmental Score"
                    onOpen={() =>
                        handleToggleVisibility("overall_environmental_rank")
                    }
                >
                    <DropdownWrapper
                        name="overall_environmental_rank"
                        options={overallRank}
                        value={filters?.overall_environmental_rank?.value || ""}
                        onChange={handleUpdateFilters({
                            key: "overall_environmental_rank",
                            label: "Overall Environmental Score",
                        })}
                    />
                </Details>
                <CheckboxGroup
                    value="yes"
                    label="Skin Sensitizer"
                    onChange={handleUpdateFilters({
                        key: "skin_sensitizer",
                        label: "Skin Sensitizer",
                    })}
                    isChecked={filters?.skin_sensitizer?.value === "yes"}
                />
                <CheckboxGroup
                    value="yes"
                    label="Food Use"
                    onChange={handleUpdateFilters({
                        key: "food_use",
                        label: "Food Use",
                    })}
                    isChecked={filters?.food_use?.value === "yes"}
                />
                <CheckboxGroup
                    value="yes"
                    label="CIR Reviewed"
                    onChange={handleUpdateFilters({
                        key: "cir_reviewed",
                        label: "CIR Reviewed",
                    })}
                    isChecked={filters?.cir_reviewed?.value === "yes"}
                />
                <CheckboxGroup
                    value="yes"
                    label="Contains Gluten"
                    onChange={handleUpdateFilters({
                        key: "gluten",
                        label: "Gluten",
                    })}
                    isChecked={filters?.gluten?.value === "yes"}
                />
            </div>
        </form>
    );
});
