import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import instance from "./axios/instance";
import { type IOption } from "common/types";
import { type DeparturePoint } from "pages/subIngredient";

class SafetySummaryService extends BaseService {
    constructor(
        public baseUrl = backendKeys.safetySummary,
        private service = instance,
    ) {
        super(baseUrl);
    }

    async getSafetySummaryOptions(): Promise<IOption[]> {
        return this.handleRequest<IOption[]>(
            this.service.get(this.getFullUrl("/")),
        );
    }

    async getToxReferenceOptions(): Promise<IOption[]> {
        return this.handleRequest<IOption[]>(
            this.service.get(this.getFullUrl("/tox-reference")),
        );
    }

    async getLocalEffectRefOptions(): Promise<IOption[]> {
        return this.handleRequest<IOption[]>(
            this.service.get(this.getFullUrl("/local-effect-reference")),
        );
    }

    async createDeparturePoint(
        payload: Partial<DeparturePoint>,
    ): Promise<DeparturePoint> {
        return this.handleRequest<DeparturePoint>(
            this.service.post(this.getFullUrl("/"), payload),
        );
    }

    async createToxicologicalReferenceValues(
        payload: Partial<DeparturePoint>,
    ): Promise<DeparturePoint> {
        return this.handleRequest<DeparturePoint>(
            this.service.post(this.getFullUrl("/tox-reference"), payload),
        );
    }

    async createLocalEffectValues(
        payload: Partial<DeparturePoint>,
    ): Promise<DeparturePoint> {
        return this.handleRequest<DeparturePoint>(
            this.service.post(
                this.getFullUrl("/local-effect-reference"),
                payload,
            ),
        );
    }
}

export const safetySummaryService = new SafetySummaryService();
