import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../button/Button";

interface IProps {
    onSubmit: () => void;
    onRemove: () => void;
    isEditing?: boolean;
    setIsEditing?: Dispatch<SetStateAction<boolean>>;
}

export const ActionButtons: FC<IProps> = ({
    onSubmit,
    onRemove,
    isEditing,
    setIsEditing,
}) => {
    const handleEditClick = useCallback(() => {
        setIsEditing && setIsEditing((state) => !state);
        if (isEditing) {
            onSubmit();
        }
    }, [setIsEditing, isEditing, onSubmit]);

    return (
        <div className="flex">
            <Button className="btn-sm" onClick={handleEditClick}>
                <FontAwesomeIcon
                    size={!isEditing ? "sm" : undefined}
                    icon={isEditing ? faCheck : faEdit}
                />
            </Button>

            <Button className="btn-sm" onClick={onRemove}>
                <FontAwesomeIcon size="sm" icon={faTrashCan} />
            </Button>
        </div>
    );
};
