import classNames from "classnames";
import React from "react";

interface IProps {
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    value: string;
    color?: string;
}

export const ModesSelect: React.FC<IProps> = ({
    onChange,
    value,
    color = "select-warning",
}) => (
    <select
        className={classNames(
            "select w-full select-sm max-w-xxs join-item",
            color,
        )}
        name="mode_of_action_of_toxic_effect"
        value={value || ""}
        onChange={onChange}
    >
        <option value=""></option>
        <option value="narcosis">Narcosis</option>
        <option value="non-narcosis">Non-narcosis</option>
        <option value="neurotoxicity">Neurotoxicity</option>
        <option value="reactivity">Reactivity</option>
        <option value="uncoupler">Uncoupler</option>
        <option value="ache inhibition">AChE inhibition</option>
        <option value="missing">Missing</option>
    </select>
);
