import { IProductTypeProperties } from "common/types";

export const productTypeProperties: IProductTypeProperties = {
    body_lotion: {
        grams_applied_per_day: "7.82",
        skin_retention_factor: "1.00",
    },
    deodorant_non_spray: {
        grams_applied_per_day: "1.50",
        skin_retention_factor: "1.00",
    },
    deodorant_spray: {
        grams_applied_per_day: "0.69",
        skin_retention_factor: "1.00",
    },
    deodorant_spray_ethanol_based: {
        grams_applied_per_day: "1.43",
        skin_retention_factor: "1.00",
    },
    eyeliner: {
        grams_applied_per_day: "0.01",
        skin_retention_factor: "1.00",
    },
    eyeshadow: {
        grams_applied_per_day: "0.02",
        skin_retention_factor: "1.00",
    },
    face_cream: {
        grams_applied_per_day: "1.54",
        skin_retention_factor: "1.00",
    },
    hair_conditioner: {
        grams_applied_per_day: "3.92",
        skin_retention_factor: "0.01",
    },
    hair_styling_products: {
        grams_applied_per_day: "4.00",
        skin_retention_factor: "0.10",
    },
    hand_cream: {
        grams_applied_per_day: "2.16",
        skin_retention_factor: "1.00",
    },
    lipstick: {
        grams_applied_per_day: "0.06",
        skin_retention_factor: "1.00",
    },
    liquid_foundation: {
        grams_applied_per_day: "0.51",
        skin_retention_factor: "1.00",
    },
    make_up_remover: {
        grams_applied_per_day: "5",
        skin_retention_factor: "0.1",
    },
    mascara: {
        grams_applied_per_day: "0.03",
        skin_retention_factor: "1",
    },
    mouthwash: {
        grams_applied_per_day: "21.62",
        skin_retention_factor: "0.1",
    },
    nail_polish: {
        grams_applied_per_day: "0.05",
        skin_retention_factor: "1.00",
    },
    nail_polish_remover: {
        grams_applied_per_day: "0.45",
        skin_retention_factor: "0.1",
    },
    oxidative_permanent_hair_dyes: {
        grams_applied_per_day: "100",
        skin_retention_factor: "0.1",
    },
    semi_permanent_hair_dyes: {
        grams_applied_per_day: "35",
        skin_retention_factor: "0.1",
    },
    shampoo: {
        grams_applied_per_day: "10.46",
        skin_retention_factor: "0.01",
    },
    shower_gel: {
        grams_applied_per_day: "18.67",
        skin_retention_factor: "0.01",
    },
    sunscreen: {
        grams_applied_per_day: "18",
        skin_retention_factor: "1.00",
    },
    toothpaste: {
        grams_applied_per_day: "2.75",
        skin_retention_factor: "0.1",
    },
};
