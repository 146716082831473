import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useGetFormulaCompare } from "../../queries";

export const RawIngredients = () => {
    const formulaCompare = useGetFormulaCompare();

    return (
        <div data-tab="compare-RI" className="overflow-x-auto">
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden shadow-lg">
                <thead>
                    <tr className="border-b border-primary-main-dgray">
                        <th className="p-2">RI Code</th>
                        <th className="p-2 border-r border-primary-main-dgray">
                            Description
                        </th>
                        {formulaCompare?.formulas?.map((formula) => (
                            <th
                                key={uuidv4()}
                                className="p-2"
                                style={{ color: "olive", maxWidth: "150px" }}
                            >
                                {formula.ficode}
                                <p>{formula.description}</p>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="overflow-x">
                    {formulaCompare?.rawIngredients?.map((rawIngredient) => (
                        <tr key={uuidv4()}>
                            <td className="p-2">{rawIngredient.ricode}</td>
                            <td className="p-2 border-r border-primary-main-dgray">
                                {rawIngredient.description}
                            </td>
                            {rawIngredient.weight_percents.map(
                                (weightPercent) => (
                                    <td
                                        key={uuidv4()}
                                        className="p-2"
                                        style={{ color: "olive" }}
                                    >
                                        {weightPercent}%
                                    </td>
                                ),
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
