import React, { ReactNode, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
// import { storageKeys } from 'common/constants';

interface IProps {
    children: ReactNode;
}

export const RouterProvider: React.FC<IProps> = ({ children }) => {
    const { initialized, keycloak } = useKeycloak();

    useEffect(() => {
        if (keycloak && initialized) {
            keycloak.onTokenExpired = () => keycloak.updateToken();
        }

        return () => {
            if (keycloak) {
                keycloak.onTokenExpired = () => {};
            }
        };
    }, [initialized, keycloak]);

    return <BrowserRouter>{children}</BrowserRouter>;
};
