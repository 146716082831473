import React, { FC } from "react";

interface IProps {
    totalWeight: number;
}

export const TotalWeightRow: FC<IProps> = ({ totalWeight }) => {
    if (!totalWeight) return null;

    return (
        <tr className="border-b border-primary-main-dgray">
            <td className="font-bold py-2 px-1 pl-4">Total weight:</td>
            <td className="py-2 text-center">{`${totalWeight}%`}</td>
            {[...Array(6)].map((_, idx) => (
                <td key={idx}></td>
            ))}
        </tr>
    );
};
