import React from "react";

interface IProps {
    children: React.ReactNode;
    name: string;
    value: string;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
    disabled?: boolean;
}

export const Select: React.FC<IProps> = ({
    children,
    name,
    value,
    onChange,
    disabled = false,
}) => (
    <select
        className="select select-bordered w-full m-0"
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
    >
        {children}
    </select>
);
