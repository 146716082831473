import { create } from "zustand";

export interface IState {
    key: (string | number)[];
}

export interface IAction {
    setKey: (key: (string | number)[]) => void;
}

export const useFormulaCompareQuery = create<IState & IAction>((set) => ({
    key: [],
    setKey: (key) => set(() => ({ key })),
}));
