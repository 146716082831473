import { useCallback, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { routerKeys, storageKeys } from "common/constants";

export const useLogout = () => {
    const { keycloak } = useKeycloak();
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const handleLogout = useCallback(async () => {
        if (keycloak && !isLoggingOut) {
            setIsLoggingOut(true);
            localStorage.removeItem(storageKeys.accessToken);

            try {
                await keycloak.logout({
                    redirectUri: window.location.origin + routerKeys.authLogin,
                });
            } finally {
                setIsLoggingOut(false);
            }
        }
    }, [keycloak, isLoggingOut]);

    return { handleLogout };
};
