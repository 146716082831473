import * as Yup from "yup";

export const validationSchema = Yup.object()
    .shape({
        description: Yup.string().required("Name is required"),
        rawIngredients: Yup.array().of(
            Yup.object()
                .shape({
                    ricode: Yup.string(),
                    description: Yup.string().required("Name is required"),
                    weight_percent: Yup.number()
                        .required("Weight Percent is required")
                        .min(
                            0,
                            "Weight Percent must be greater than or equal to 0",
                        )
                        .max(
                            100,
                            "Weight Percent must be smaller than or equal to 100",
                        ),
                    subIngredients: Yup.array().of(
                        Yup.object().shape({
                            name: Yup.string().required("Name is required"),
                            weight_percent: Yup.number()
                                .required("Weight Percent is required")
                                .min(
                                    0,
                                    "Weight Percent must be greater than or equal to 0",
                                )
                                .max(
                                    100,
                                    "Weight Percent must be smaller than or equal to 100",
                                ),
                        }),
                    ),
                    contaminants: Yup.array().of(
                        Yup.object().shape({
                            name: Yup.string().required(
                                "Contaminant Name is required",
                            ),
                            description: Yup.string(),
                            ppm: Yup.number()
                                .required("PPM is required")
                                .min(
                                    0,
                                    "PPM must be greater than or equal to 0",
                                ),
                        }),
                    ),
                })
                .test(
                    "total-sub-ingredient-weight-percent",
                    "Total weight percent of INCIs must not exceed 100",
                    function (value) {
                        const subIngredients = value?.subIngredients || [];
                        const totalWeightPercent = subIngredients.reduce(
                            (acc, ingredient) => {
                                if (isNaN(+ingredient.weight_percent)) {
                                    const value = +(
                                        `${ingredient.weight_percent}`.split(
                                            " ",
                                        )[0] || 0
                                    );
                                    return acc + value;
                                }

                                return acc + +ingredient.weight_percent;
                            },
                            0,
                        );
                        return totalWeightPercent <= 100;
                    },
                ),
        ),
    })
    .test(
        "total-raw-ingredient-weight-percent",
        "Total weight percent of rawIngredients must not exceed 100",
        function (value) {
            const rawIngredients = value?.rawIngredients || [];
            const totalWeightPercent = rawIngredients.reduce(
                (acc, ingredient) => {
                    if (isNaN(+ingredient.weight_percent)) {
                        const value = +(
                            `${ingredient.weight_percent}`.split(" ")[0] || 0
                        );
                        return acc + value;
                    }

                    return acc + +ingredient.weight_percent;
                },
                0,
            );

            return totalWeightPercent <= 100;
        },
    );
