import React, { ChangeEvent, FC, useMemo } from "react";
import { TableRow } from "components/shared";
import {
    useGetSafetySummaryOptions,
    useGetToxicReferenceOptions,
} from "pages/subIngredient/subIngredientProfile/queries";
import { IEnvironmentData } from "pages/subIngredient/subIngredientProfile/libs/types";
import { FormElement } from "common/types/formElement";

interface IProps {
    data: IEnvironmentData;
    onChangeToxicityReference: (e: ChangeEvent<FormElement>) => void;
    onChangePointOfDeparture: (e: ChangeEvent<FormElement>) => void;
    handleChangeValue: (e: ChangeEvent<FormElement>) => void;
}

export const LeftTable: FC<IProps> = ({
    data,
    onChangeToxicityReference,
    onChangePointOfDeparture,
    handleChangeValue,
}) => {
    const { data: safetySummaryOptions } = useGetSafetySummaryOptions();
    const { data: toxicologicalReferenceOptions } =
        useGetToxicReferenceOptions();

    const podValue = useMemo(
        () =>
            data.point_of_departure[
                data.point_of_departure_select.toLowerCase()
            ] ?? "",
        [data.point_of_departure, data.point_of_departure_select],
    );

    const toxicValue = useMemo(
        () =>
            data.toxicity_reference[
                data.toxicity_reference_select.toLowerCase()
            ] ?? "",
        [data.toxicity_reference, data.toxicity_reference_select],
    );
    return (
        <div className="md:w-1/2 lg:w-1/2 md:mr-5">
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg mb-2">
                <tbody>
                    <TableRow
                        header="Point of Departure"
                        name="point_of_departure"
                        value={data?.point_of_departure_select}
                        onChange={onChangePointOfDeparture}
                        options={safetySummaryOptions}
                        type="dropdown"
                    />
                    <TableRow
                        header="PoD Value (mg/kg)"
                        name="pod_value"
                        value={podValue}
                        onChange={handleChangeValue}
                    />
                    <TableRow
                        header="Authoritative Toxicity References"
                        name="toxicity_reference"
                        value={data?.toxicity_reference_select}
                        onChange={onChangeToxicityReference}
                        options={toxicologicalReferenceOptions}
                        type="dropdown"
                    />
                    <TableRow
                        header="Authoritative Toxicity Reference Value"
                        name="toxic_value"
                        value={toxicValue}
                        onChange={handleChangeValue}
                    />
                </tbody>
            </table>
        </div>
    );
};
