import React, { FC, useCallback, useState } from "react";
import { Details } from "components/shared/details";
import { type IFilter } from "common/types";

interface IBoxes {
    someFilters: boolean;
}

interface IProps {
    onUpdateFilters: (
        key: string,
        { value, label }: IFilter[keyof IFilter],
    ) => void;
    filters: IFilter;
}

export const Filters: FC<IProps> = () => {
    const [isOpen, setIsOpen] = useState<IBoxes>({
        someFilters: false,
    });

    const handleToggleVisibility = useCallback(() => {
        setIsOpen((curr) => ({
            ...curr,
            someFilters: !curr["someFilters" as keyof IBoxes],
        }));
    }, []);

    return (
        <form>
            <div className="w-full max-w-lg px-4 py-2">
                <Details
                    isOpen={isOpen.someFilters}
                    text="Filters"
                    onOpen={handleToggleVisibility}
                >
                    <>Some filters</>
                </Details>
            </div>
        </form>
    );
};
