import React, { FC } from "react";
import { Button } from "../button";
import { UploadFormula } from "pages/formulas/formulas/components/uploadFormula";

interface IProps {
    title: string;
    createFn: () => void;
    isUpload?: boolean;
}

export const ButtonBlock: FC<IProps> = ({ title, createFn, isUpload }) => {
    return (
        <div className="flex justify-center items-center gap-3">
            {isUpload && <UploadFormula />}
            <Button
                className="btn-create h-14 px-10 py-1"
                onClick={createFn}
                text={`Create ${title}`}
                isSplitText
            />
        </div>
    );
};
