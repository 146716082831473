import React, { FC, useCallback } from "react";

interface IProps {
    text?: string;
    referenceText?: string;
    referenceId?: string;
}

export const Description: FC<IProps> = ({
    text = "Short description of the model",
    referenceText = "",
    referenceId = "",
}) => {
    const scrollToReference = useCallback(() => {
        if (!referenceId) return;
        const element = document.getElementById(referenceId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, [referenceId]);

    return (
        <section
            className="p-4 bg-blue-100 border border-blue-200 rounded-lg"
            aria-labelledby="description-heading"
        >
            <p id="description-heading" className="text-gray-800 text-lg">
                <strong>Model description:</strong> {text}
                {referenceText && referenceId && (
                    <button
                        className="ml-2 text-blue-600 hover:underline"
                        onClick={scrollToReference}
                        aria-label={`Scroll to ${referenceText}`}
                        type="button"
                    >
                        {referenceText}
                    </button>
                )}
            </p>
        </section>
    );
};
