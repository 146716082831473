import React, { FC, useCallback, useState } from "react";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { Button } from "components/shared";
import { ITradename } from "common/types";
import { useUpdateTradenameMutation } from "pages/rawIngredient/rawIngredientProfile/queries";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import {
    defaultToastOptions,
    defaultErrorToastOptions,
    toastTexts,
} from "common/constants";
import { toast } from "react-toastify";

interface IProps {
    tradename: ITradename;
}

export const ManufacturerRow: FC<IProps> = ({ tradename }) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const { id = "" } = useParams();

    const { mutate: updateTradename } = useUpdateTradenameMutation();
    const queryClient = useQueryClient();

    const formik = useFormik({
        initialValues: {
            vendorId: tradename.vendor?.id || "",
            tradenameId: tradename.id || "",
            description: tradename.vendor?.description || "",
            tradename: tradename.tradename || "",
        },
        onSubmit: (payload) => {
            updateTradename(payload, {
                onSuccess: () => {
                    setEditMode(false);
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.rawIngredient, id],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.tradenames, id],
                    });

                    toast.success(toastTexts.updated, defaultToastOptions);
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            });
        },
    });

    const handleSaveClick = useCallback(() => {
        formik.handleSubmit();
        setEditMode(false);
    }, []);

    const handleEditModeClick = useCallback(() => {
        setEditMode((state) => !state);
    }, []);

    return (
        <tr
            className={classNames(
                "flex flex-wrap sm:table-row mb-2 sm:mb-0",
                editMode && "bg-gray-100",
            )}
        >
            <td className="w-auto sm:w-1/6  p-2 sm:p-3">
                {tradename.vendor?.vendor_code}
            </td>
            <td className="w-auto sm:w-2/6 p-2 sm:p-3">
                {editMode ? (
                    <input
                        name="description"
                        value={formik.values.description || ""}
                        onChange={formik.handleChange}
                        className="rounded px-2 py-0.5 border border-gray-300"
                    />
                ) : (
                    <div className="px-2 py-0.5">
                        {tradename.vendor?.description}
                    </div>
                )}
            </td>
            <td className="w-auto sm:w-2/6 p-2 sm:p-3">
                {editMode ? (
                    <input
                        name="tradename"
                        value={formik.values.tradename}
                        onChange={formik.handleChange}
                        className="rounded px-2 py-0.5 border border-gray-300"
                    />
                ) : (
                    <div className="px-2 py-0.5">{tradename.tradename}</div>
                )}
            </td>
            <td className="w-auto sm:w-1/6 p-2 sm:p-3">
                {editMode ? (
                    <Button
                        className="btn-sm text-white min-w-[90px]"
                        onClick={handleSaveClick}
                    >
                        <FontAwesomeIcon icon={faSave} className="sm:mr-1" />
                        <span className="text-sm">Save</span>
                    </Button>
                ) : (
                    <Button
                        className="btn-sm text-white min-w-[90px]"
                        onClick={handleEditModeClick}
                    >
                        <FontAwesomeIcon icon={faEdit} className="sm:mr-1" />
                        <span className="text-sm">Edit</span>
                    </Button>
                )}
            </td>
        </tr>
    );
};
