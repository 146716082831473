import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Header, InfoCard, NavLinks, SearchBlock } from "./components";
import {
    defaultErrorToastOptions,
    routerKeys,
    toastTexts,
} from "common/constants";
import { LoadingToast } from "components/shared/loadingToast";
import { ToastContainer, toast } from "react-toastify";
import {
    useGetContaminantsStatistic,
    useLastContaminants,
} from "pages/contaminant/contaminants/queries";
import {
    useGetFormulasStatistic,
    useGetLastSearchFormulas,
} from "pages/formulas/formulas/queries";
import {
    useGetRawIngredientsStatistic,
    useLastRawIngredients,
} from "pages/rawIngredient/rawIngredients/queries";
import {
    useGetSubIngredientsStatistic,
    useLastSubIngredients,
} from "pages/subIngredient/subIngredients/queries";
import { ScrollUpButton } from "components/shared/scrollUpButton";
import { TableInfo } from "./components/TableInfo";
import { DataTable, IconLink, StatusView } from "components/shared";
import { ColumnDef } from "@tanstack/react-table";
import { IFormulaItem } from "pages/formulas/formulas/types";
import { IRawIngredientItem } from "pages/rawIngredient/types";
import { IPreparedSubIngredient } from "pages/formulas/formula-profile/types";
import { IContaminant } from "common/types";
import { formatLabel, modifyDate } from "common/utils";

const MAX_ITEMS_PER_TABLE = 2;

type ItemLimits = {
    formulas: number;
    rawIngredients: number;
    inci: number;
    contaminants: number;
};

export const Dashboard = () => {
    const [limit, setLimit] = useState<ItemLimits>({
        formulas: MAX_ITEMS_PER_TABLE,
        rawIngredients: MAX_ITEMS_PER_TABLE,
        inci: MAX_ITEMS_PER_TABLE,
        contaminants: MAX_ITEMS_PER_TABLE,
    });
    const [query, setQuery] = useState("");

    const { data: contaminantsStatistic } = useGetContaminantsStatistic();
    const { data: formulasStatistic } = useGetFormulasStatistic();
    const { data: rawIngredientsStatistic } = useGetRawIngredientsStatistic();
    const { data: subIngredientsStatistic } = useGetSubIngredientsStatistic();

    const handleUpdateQuery = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const handleIncreaseLimit = (type: string) => {
        setLimit((curr) => ({
            ...curr,
            [type]: +curr[type as keyof ItemLimits] + MAX_ITEMS_PER_TABLE,
        }));
    };

    const handleDecreaseLimit = (type: string) => {
        setLimit((curr) => ({
            ...curr,
            [type]: Math.max(
                +curr[type as keyof ItemLimits] - MAX_ITEMS_PER_TABLE,
                MAX_ITEMS_PER_TABLE,
            ),
        }));
    };

    const queryParamsFormula = useMemo(
        () =>
            new URLSearchParams(
                Object.entries({
                    query,
                    limit: limit.formulas.toString(),
                }),
            ).toString(),
        [limit.formulas, query],
    );

    const queryParamsRawIngredient = useMemo(
        () =>
            new URLSearchParams(
                Object.entries({
                    query,
                    limit: limit.rawIngredients.toString(),
                }),
            ).toString(),
        [limit.rawIngredients, query],
    );

    const queryParamsINCI = useMemo(
        () =>
            new URLSearchParams(
                Object.entries({ query, limit: limit.inci.toString() }),
            ).toString(),
        [limit.inci, query],
    );

    const queryParamsContaminant = useMemo(
        () =>
            new URLSearchParams(
                Object.entries({ query, limit: limit.contaminants.toString() }),
            ).toString(),
        [limit.contaminants, query],
    );

    const {
        data: formulas = [],
        isLoading: isFormulasLoading,
        isError: isFormulasError,
    } = useGetLastSearchFormulas(queryParamsFormula);

    const {
        data: rawIngredients = [],
        isLoading: isLoadingRawIngredient,
        isError: isErrorRawIngredient,
    } = useLastRawIngredients({ queryParams: queryParamsRawIngredient });

    const {
        data: subIngredients = [],
        isLoading: isLoadingSubIngredient,
        isError: isErrorSubIngredient,
    } = useLastSubIngredients({ queryParams: queryParamsINCI });

    const {
        data: contaminants = [],
        isLoading: isLoadingContaminant,
        isError: isErrorContaminant,
    } = useLastContaminants({ queryParams: queryParamsContaminant });

    useEffect(() => {
        if (
            isFormulasError ||
            isErrorRawIngredient ||
            isErrorSubIngredient ||
            isErrorContaminant
        ) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        }
    }, [
        isFormulasError,
        isErrorRawIngredient,
        isErrorSubIngredient,
        isErrorContaminant,
    ]);

    const formulaColumns = useMemo<ColumnDef<IFormulaItem>[]>(
        () => [
            {
                accessorKey: "ficode",
                header: () => <span className="min-w-[100px]">FI Code</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">{row.original.ficode}</span>
                ),
            },
            {
                accessorKey: "description",
                header: () => (
                    <span className="min-w-[200px]">Description</span>
                ),
                cell: ({ row }) => (
                    <span className="min-w-[200px]">
                        {row.original.description}
                    </span>
                ),
            },
            {
                accessorKey: "status",
                header: () => <span className="min-w-[100px]">Status</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {formatLabel(row.getValue("status"))}
                    </span>
                ),
            },
            {
                accessorKey: "created_by",
                header: () => <span className="min-w-[100px]">Created By</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {row.original.created_by}
                    </span>
                ),
                size: 100,
            },
            {
                accessorKey: "create_date",
                header: () => <span className="min-w-[100px]">Created</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {modifyDate(row.original.date_created)}
                    </span>
                ),
                size: 100,
            },
            {
                id: "edit_formula",
                accessorKey: "edit_formula",
                header: () => <span className=" flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.formulas}
                        id={row.original.id}
                    />
                ),
                size: 100,
            },
        ],
        [],
    );

    const rawIngredientColumns = useMemo<ColumnDef<IRawIngredientItem>[]>(
        () => [
            {
                accessorKey: "ricode",
                header: () => <span className="min-w-[100px]">RI Code</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">{row.original.ricode}</span>
                ),
            },
            {
                accessorKey: "description",
                header: () => (
                    <span className="min-w-[150px]">Description</span>
                ),
                cell: ({ row }) => (
                    <span className="min-w-[150px]">
                        {row.original.description}
                    </span>
                ),
            },
            {
                accessorKey: "status",
                header: () => <span className="min-w-[100px]">Status</span>,
                cell: ({ row }) => (
                    <StatusView
                        className="min-w-[100px]"
                        title={row.original.status}
                        active={row.original.status === "Complete"}
                    />
                ),
            },
            {
                accessorKey: "created_by",
                header: () => <span className="min-w-[100px]">Created By</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {row.original.created_by}
                    </span>
                ),
                size: 100,
            },
            {
                accessorKey: "create_date",
                header: () => <span className="min-w-[100px]">Created</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {modifyDate(row.original.date_created)}
                    </span>
                ),
                size: 100,
            },
            {
                id: "edit",
                accessorKey: "edit",
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.rawIngredient}
                        id={row.original.id}
                    />
                ),
                size: 100,
            },
        ],
        [],
    );

    const subIngredientColumns = useMemo<ColumnDef<IPreparedSubIngredient>[]>(
        () => [
            {
                accessorKey: "sicode",
                header: () => <span className="min-w-[100px]">SI Code</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">{row.original.sicode}</span>
                ),
                size: 100,
            },
            {
                accessorKey: "description",
                header: () => <span className="min-w-[200px]">Name</span>,
                cell: ({ row }) => (
                    <span className="min-w-[200px]">{row.original.name}</span>
                ),
                size: 200,
            },
            {
                accessorKey: "status",
                header: () => <span className="min-w-[100px]">Status</span>,
                cell: ({ row }) => (
                    <StatusView
                        className="min-w-[100px]"
                        title={row.original.status || ""}
                        active={row.original.status === "Active"}
                    />
                ),
                size: 100,
            },
            {
                accessorKey: "created_by",
                header: () => <span className="min-w-[100px]">Created By</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {row.original.created_by}
                    </span>
                ),
                size: 100,
            },
            {
                accessorKey: "create_date",
                header: () => <span className="min-w-[100px]">Created</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {modifyDate(row.original.date_created)}
                    </span>
                ),
                size: 100,
            },
            {
                id: "edit",
                accessorKey: "edit",
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.subIngredient}
                        id={row.original.id}
                    />
                ),
            },
        ],
        [],
    );

    const contaminantColumns = useMemo<ColumnDef<IContaminant>[]>(
        () => [
            {
                accessorKey: "name",
                header: () => <span className="min-w-[200px]">Name</span>,
                cell: ({ row }) => (
                    <span className="min-w-[200px]">{row.original.name}</span>
                ),
                size: 200,
            },
            {
                accessorKey: "description",
                header: () => (
                    <span className="min-w-[200px]">Description</span>
                ),
                cell: ({ row }) => (
                    <span className="min-w-[200px]">
                        {row.original.description}
                    </span>
                ),
                size: 200,
            },
            {
                accessorKey: "created_by",
                header: () => <span className="min-w-[100px]">Created By</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {row.original.created_by}
                    </span>
                ),
                size: 100,
            },
            {
                accessorKey: "date_created",
                header: () => <span className="min-w-[100px]">Created</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {modifyDate(row.original.date_created)}
                    </span>
                ),
                size: 100,
            },
            {
                id: "edit",
                accessorKey: "edit",
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.contaminants}
                        id={row.original.id}
                    />
                ),
                size: 100,
            },
        ],
        [],
    );

    return (
        <>
            <Header />
            <div className="grid lg:grid-cols-4 gap-4 mx-10">
                <InfoCard
                    title="Formulas"
                    link={routerKeys.formulas}
                    count={formulasStatistic?.totalCount}
                    increaseCount={formulasStatistic?.createdToday}
                    description=""
                />
                <InfoCard
                    title="Raw Ingredients"
                    link={routerKeys.rawIngredient}
                    count={rawIngredientsStatistic?.totalCount}
                    increaseCount={rawIngredientsStatistic?.createdToday}
                    description=""
                />
                <InfoCard
                    title="INCI"
                    link={routerKeys.subIngredient}
                    count={subIngredientsStatistic?.totalCount}
                    increaseCount={subIngredientsStatistic?.createdToday}
                    description=""
                />
                <InfoCard
                    title="Contaminants"
                    link={routerKeys.contaminants}
                    count={contaminantsStatistic?.totalCount}
                    increaseCount={contaminantsStatistic?.createdToday}
                    description=""
                />
            </div>
            <div className="flex flex-col md:flex-row gap-8 mx-10 my-3">
                <div className="flex-grow">
                    <SearchBlock value={query} onChange={handleUpdateQuery} />
                </div>
                <div className="flex-shrink-0">
                    <NavLinks />
                </div>
            </div>

            <h3 className="text-2xl font-semibold my-5 mx-10">
                Your last searches
            </h3>

            <div className="mx-10 mb-10">
                {/* FORMULAS */}
                <TableInfo
                    title="Formulas"
                    handleIncreaseLimit={() => handleIncreaseLimit("formulas")}
                    handleDecreaseLimit={() => handleDecreaseLimit("formulas")}
                    isVisibleMoreButton
                >
                    <DataTable
                        className="my-4"
                        data={formulas}
                        columns={formulaColumns}
                    />
                </TableInfo>
                {/* RAW INGREDIENTS */}
                <TableInfo
                    title="Raw Ingredients"
                    handleIncreaseLimit={() =>
                        handleIncreaseLimit("rawIngredients")
                    }
                    handleDecreaseLimit={() =>
                        handleDecreaseLimit("rawIngredients")
                    }
                    isVisibleMoreButton
                >
                    <DataTable
                        className="my-4"
                        data={rawIngredients}
                        columns={rawIngredientColumns}
                    />
                </TableInfo>
                {/* SUB INGREDIENTS */}
                <TableInfo
                    title="Sub Ingredients"
                    handleIncreaseLimit={() => handleIncreaseLimit("inci")}
                    handleDecreaseLimit={() => handleDecreaseLimit("inci")}
                    isVisibleMoreButton
                >
                    <DataTable
                        className="my-4"
                        data={subIngredients}
                        columns={subIngredientColumns}
                    />
                </TableInfo>

                {/* CONTAMINANTS */}
                <TableInfo
                    title="Contaminants"
                    handleIncreaseLimit={() =>
                        handleIncreaseLimit("contaminants")
                    }
                    handleDecreaseLimit={() =>
                        handleDecreaseLimit("contaminants")
                    }
                    isVisibleMoreButton
                >
                    <DataTable
                        className="my-4"
                        data={contaminants}
                        columns={contaminantColumns}
                    />
                </TableInfo>
            </div>

            <ScrollUpButton height={600} />
            {isFormulasLoading ||
            isLoadingRawIngredient ||
            isLoadingSubIngredient ||
            isLoadingContaminant ? (
                <LoadingToast />
            ) : (
                <ToastContainer />
            )}
        </>
    );
};
