import React, { FC } from "react";
import { TableRow } from ".";
import { Table } from "components/shared/table";
import { IContaminant } from "common/types";

interface IProps {
    contaminants?: IContaminant[];
}

export const ContaminantsTable: FC<IProps> = React.memo(({ contaminants }) => {
    return (
        <Table
            withHash={true}
            headNames={[
                "Contaminant Name",
                "Contaminant Description",
                "Created",
            ]}
        >
            {contaminants?.map((contaminant) => (
                <TableRow contaminant={contaminant} key={contaminant.id} />
            ))}
        </Table>
    );
});
