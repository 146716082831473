import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import instanse from "./axios/instance";
import { IContaminant } from "common/types";

class ContaminantService extends BaseService {
    constructor(
        public baseUrl = backendKeys.contaminants,
        private fetchingService = instanse,
    ) {
        super(baseUrl);
    }

    async getContaminant({ id }: { id: string }) {
        return this.handleRequest<IContaminant>(
            this.fetchingService.get(this.getFullUrl(`/${id}`)),
        );
    }

    async removeContaminant({ id }: { id: string }) {
        return this.handleRequest(
            this.fetchingService.delete(this.getFullUrl(`/${id}`)),
        );
    }
}

export const contaminantService = new ContaminantService();
