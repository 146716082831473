import classNames from "classnames";
import { Button } from "components/shared/button";
import React, { useState } from "react";

interface IProps {
    isLoading?: boolean;
    reportEndpoints: string[];
    handleOpenEndpoint: (key: string) => void;
    isDisabled: boolean;
}

export const Dropdown: React.FC<IProps> = ({
    isLoading = false,
    reportEndpoints,
    handleOpenEndpoint,
    isDisabled,
}) => {
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);

    const handleToggleDropdownVisibility = () => {
        setIsOpenDropdown(!isOpenDropdown);
    };

    const handleClickOnEndpoint = (key: string) => {
        setIsOpenDropdown(false);
        handleOpenEndpoint(key);
    };

    return (
        <div className={classNames("dropdown", { loading: false })}>
            <Button
                text="Add Toxicity Endpoints"
                isLoading={isLoading}
                onClick={handleToggleDropdownVisibility}
                className={classNames({ "btn-disabled": isDisabled })}
            />
            {isOpenDropdown && (
                <ul
                    tabIndex={0}
                    className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-200"
                >
                    {reportEndpoints.map((key) => (
                        <li key={key}>
                            <a onClick={() => handleClickOnEndpoint(key)}>
                                {key}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
