import React, { memo } from "react";
import { IReport } from "common/types";
import { faClose, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { modifyDate } from "common/utils";

interface IProps {
    file: IReport;
    onDeleteFile: (file: IReport) => void;
    onDownloadFile: (file: IReport) => void;
}

export const ReportRow: React.FC<IProps> = memo(
    ({ file, onDeleteFile, onDownloadFile }) => {
        return (
            <tr className="border-b last:border-none hover:bg-gray-50 transition">
                <td className="w-3/4 text-sm md:text-base lg:text-base px-4 py-2">
                    <button
                        onClick={() => onDownloadFile(file)}
                        className="text-[#113261] hover:text-blue-800 transition"
                    >
                        {file.filename}{" "}
                        <span className="text-gray-500 text-sm italic">
                            ({modifyDate(file.modified)})
                        </span>
                    </button>
                </td>
                <td className="w-1/4 text-right px-4 py-2">
                    <div className="flex items-center justify-end gap-2">
                        <button
                            className="btn btn-sm flex items-center gap-1"
                            onClick={() => onDownloadFile(file)}
                        >
                            <FontAwesomeIcon icon={faFileDownload} />
                            <span>Download</span>
                        </button>
                        <button
                            className="btn btn-sm btn-error flex items-center gap-1"
                            onClick={() => onDeleteFile(file)}
                        >
                            <FontAwesomeIcon icon={faClose} />
                            <span>Delete</span>
                        </button>
                    </div>
                </td>
            </tr>
        );
    },
);
