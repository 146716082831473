import React, { memo } from "react";

interface IProps {
    value: string;
    isChecked: boolean;
    label: string;
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const CheckboxLabel: React.FC<IProps> = memo(
    ({ value, isChecked, label, name, onChange, disabled = false }) => (
        <label className="flex gap-2">
            <input
                type="radio"
                className="radio mt-1"
                name={name}
                value={value}
                onChange={onChange}
                checked={isChecked}
                disabled={disabled}
            />
            <p>{label}</p>
        </label>
    ),
);
