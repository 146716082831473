import React, { FC } from "react";
import { Title } from "components/shared";
import { type IContaminantFormula } from "pages/contaminant/contaminants/types";
import { Link } from "react-router-dom";
import { routerKeys } from "common/constants";
import { buildLink } from "common/utils";

interface IProps {
    items?: IContaminantFormula[];
}

export const FIContaminantsTable: FC<IProps> = ({ items }) =>
    Boolean(items?.length) ? (
        <div className="lg:mx-10">
            <Title text="Contaminants in Formulas" className="mt-4" />
            <div className="overflow-x-auto mt-4 w-1/2 md:w-1/2 lg:w-1/2 md:mr-5">
                <table className="w-full bg-grey-100  overflow-hidden sm:shadow-lg rounded-lg">
                    <thead>
                        <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                            <th className="p-3 md:p-3 lg:p-3 text-left text-xs md:text-base lg-text-base lg:min-w-[100px] break-words">
                                Formula
                            </th>
                            <th className="w-1/4 p-3 md:p-3 lg:p-3 text-center text-xs md:text-base lg-text-base lg:min-w-[100px] break-words">
                                Concentration (ppm)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items?.map((item) => (
                            <tr key={item.id}>
                                <td className="p-1 md:p-2 lg:p-3 text-left text-xs md:text-base">
                                    <Link
                                        to={buildLink(
                                            routerKeys.formulas,
                                            item.id,
                                        )}
                                        className="text-[#113261] hover:underline"
                                    >
                                        {item.name}
                                    </Link>
                                </td>
                                <td className="p-1 md:p-2 lg:p-3 text-center text-xs md:text-base">
                                    {item.ppm}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    ) : null;
