import React, { FC } from "react";
import { type IFormulaSubIngredient } from "pages/formulas/formula-profile/types";

interface IProps {
    item: IFormulaSubIngredient;
}

export const SubIngredientRow: FC<IProps> = ({ item }) => (
    <div key={item.id}>
        {item?.name && (
            <>
                {item.name}
                {" - "}
                {item.rawSub?.weight_percent
                    ? `${item.rawSub.weight_percent}%`
                    : ""}
            </>
        )}
    </div>
);
