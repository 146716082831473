import { useMutation, useQuery } from "@tanstack/react-query";
import { useContaminantStore } from "../store";
import { queryKeys } from "common/constants";
import { contaminantsService } from "services";

export const useGetContaminant = () => {
    const { key } = useContaminantStore();
    return useUploadContaminant(`${key?.[1]}`);
};

export const useUploadContaminant = (id: string) => {
    const queryKey = [queryKeys.contaminants, id];

    return useQuery({
        queryKey,
        queryFn: () => contaminantsService.getContaminant({ id }),
    });
};

export const useRemoveContaminant = () => {
    return useMutation({
        mutationFn: ({ id }: { id: string }) =>
            contaminantsService.removeContaminant({ id }),
    });
};
