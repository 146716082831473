import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import "./styles.scss";

interface IProps {
    value?: string;
    onChange?: (content: string) => void;
    className?: string;
    disabled?: boolean;
}

const config = {
    readonly: false,
    uploader: {
        insertImageAsBase64URI: true,
    },
    imageDefaultWidth: 400,
    minHeight: 400,
    showCharsCounter: false,
    showWordsCounter: false,
    showPoweredBy: false,
};

export const Editor: FC<IProps> = (props: IProps) => {
    const editor = useRef(null);
    const [content, setContent] = useState(props.value || "");

    const handleContentChange = useCallback(
        (newContent: string) => {
            setContent(newContent);
            if (props.onChange) {
                props.onChange(newContent);
            }
        },
        [props.onChange, content],
    );

    const extendedConfig = useMemo(
        () => ({ ...config, readonly: props.disabled || false }),
        [props.disabled],
    );

    return (
        <div className="mt-4">
            <JoditEditor
                className={props.className}
                ref={editor}
                value={content}
                config={extendedConfig}
                onBlur={handleContentChange}
            />
        </div>
    );
};
