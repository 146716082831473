import { ReportEndpointKey } from "../types";

export const reportParagraphs: ReportEndpointKey[] = [
    "Acute toxicity/Systemic or repeat dose toxicity",
    "Dermal absorption",
    "Eye irritation",
    "Full text",
    "Genotoxicity",
    "Photoallergy",
    "Phototoxicity",
    "Reproductive/Developmental toxicity",
    "Skin irritation",
    "Skin sensitization",
];
