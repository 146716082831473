import { Title } from "components/shared/title";
import React, { useCallback, useState } from "react";
import {
    AddRelatedIngredients,
    ContaminantsTab,
    RawIngredients,
    SubIngredients,
    TabsHeader,
} from "./components";
import { Tab } from "./types";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { storageKeys } from "common/constants";

const tabs: Tab[] = ["Raw Ingredients", "INCIs", "Contaminants"];

type ContentMap = {
    [key in Tab]: React.ReactNode;
};

export const CompositionTab = () => {
    const [selectedTab, setSelectedTab] = useState<Tab>(
        (localStorage.getItem(storageKeys.compositionTab) as Tab) || tabs[0],
    );

    const onSelectTab = useCallback(
        (tab: Tab) => {
            setSelectedTab(tab);
            localStorage.setItem(storageKeys.compositionTab, tab);
        },
        [setSelectedTab],
    );

    const { isOwner } = useFormulaProfileState();

    const contentMap: ContentMap = {
        ["Raw Ingredients"]: <RawIngredients />,
        ["INCIs"]: <SubIngredients />,
        Contaminants: <ContaminantsTab />,
    };

    return (
        <div>
            <div className="md:flex lg:flex">
                <div className="md:w-1/2 lg:w-1/2 mx-10 my-3">
                    <Title text="Formula Composition" />
                    {(isOwner || true) && (
                        <AddRelatedIngredients selectedTab={selectedTab} />
                    )}
                    <TabsHeader
                        tabs={tabs}
                        selectedTab={selectedTab}
                        onChange={onSelectTab}
                    />
                    {contentMap[selectedTab]}
                </div>
            </div>
        </div>
    );
};
