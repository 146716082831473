import classNames from "classnames";
import React from "react";

interface IProps {
    className?: string;
}

export const BurgerIcon: React.FC<IProps> = ({ className = "" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="white"
        className={classNames("w-6 h-6", className)}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
        />
    </svg>
);
