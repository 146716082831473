import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { type RequestStatus } from "pages/subIngredient/subIngredientProfile/libs";
import { CircularProgress } from "@mui/material";

interface IProps {
    status?: RequestStatus;
    data?: { message?: string | null; isSensitizer?: boolean } | null;
}

const SensitizersKey = {
    NOT_YET_PREDICTED: {
        divClass: "prediction-category--0",
        divText: "Unknown",
    },
    FAILED: {
        divClass: "prediction-category--0",
        divText:
            "Prediction failed. Provide the result of DPRA assay to evaluate the compound as sensitizer/non-sensitizer.",
    },
    IS_SENSITIZERS: {
        divClass: "prediction-category--4",
        divText: "Sensitizer",
    },
    PRIMITIVE_NON_SENSITIZERS: {
        divClass: "prediction-category--1",
        divText: "Not a sensitizer",
    },
    PRIMITIVE_NON_SENSITIZERS_WITH_MSG: {
        divClass: "prediction-category--0",
        divText:
            "Provide the result of DPRA assay to evaluate the compound as sensitizer/non-sensitizer.",
    },
};

const getDivAttributes = (
    sensitizersKey: keyof typeof SensitizersKey,
    message?: string | null,
): [string, string] => {
    const keyConfig = SensitizersKey[sensitizersKey];
    const text =
        sensitizersKey === "PRIMITIVE_NON_SENSITIZERS_WITH_MSG" && message
            ? message
            : keyConfig.divText;
    return [keyConfig.divClass, text];
};

export const MajorityVotingPrediction: FC<IProps> = ({ status, data }) => {
    const { isSensitizer, message } = data || {};

    const sensitizersKey = useMemo<keyof typeof SensitizersKey>(() => {
        if (isSensitizer === true) return "IS_SENSITIZERS";
        if (isSensitizer === false) return "PRIMITIVE_NON_SENSITIZERS";
        return "PRIMITIVE_NON_SENSITIZERS_WITH_MSG";
    }, [isSensitizer]);

    const [divClass, divText] = getDivAttributes(sensitizersKey, message);

    if (status === "PENDING") {
        return (
            <div className="space-y-2">
                <div className="text-sm text-gray-700">OECD 203</div>
                <div className="flex items-center space-x-2">
                    <CircularProgress size={20} color="inherit" />
                    <span>Loading...</span>
                </div>
            </div>
        );
    }

    if (!status) return null;

    return (
        <div className="flex flex-col space-y-2">
            <div className="text-sm font-semibold text-blue-700">OECD 203</div>
            <div className={classNames("text-sm", divClass)}>{divText}</div>
        </div>
    );
};
