export * from "./Paper";
export * from "./Search";
export * from "./Plus";
export * from "./Minus";
export * from "./Edit";
export * from "./Burger";
export * from "./Close";
export * from "./ArrowTop";
export * from "./Delete";
export * from "./EditWithPapper";
export * from "./Folder";
export * from "./Download";
export * from "./SaveIcon";
export * from "./SubIngredientIcon";
export * from "./ArchiveAdd";
