import React, { FC } from "react";
import { IContaminant } from "common/types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUpdateContaminant } from "../queries";
import { toast } from "react-toastify";
import { defaultToastOptions, toastTexts } from "common/constants";
import { InputWrapper } from "components/shared/inputWrapper";
import { Button } from "components/shared/button";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "common/constants";

interface IProperties {
    handleClose: () => void;
    contaminant: IContaminant;
}

const UpdateSchema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
});

export const UpdateContaminantForm: FC<IProperties> = ({
    handleClose,
    contaminant,
}) => {
    const { mutate: updateContaminant } = useUpdateContaminant();
    const queryClient = useQueryClient();

    const formik = useFormik({
        initialValues: {
            name: contaminant.name || "",
            description: contaminant.description || "",
        },
        validationSchema: UpdateSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            updateContaminant(
                {
                    id: contaminant.id,
                    body: {
                        description: values.description,
                        name: values.name,
                    },
                },
                {
                    onSuccess: () => {
                        toast.success(
                            "Success! Contaminant has been updated",
                            defaultToastOptions,
                        );

                        queryClient.invalidateQueries({
                            queryKey: [queryKeys.contaminants],
                        });
                        handleClose();
                    },
                    onError: () => {
                        toast.error(toastTexts.error, defaultToastOptions);
                    },
                },
            );
        },
    });

    const { values, errors, touched, handleChange, handleSubmit } = formik;

    return (
        <>
            <form onSubmit={handleSubmit}>
                <InputWrapper
                    isError={Boolean(errors.name && touched.name)}
                    error={errors.name}
                    label="Name"
                >
                    <input
                        type="text"
                        name="name"
                        className="w-full input input-bordered"
                        value={values.name}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <InputWrapper
                    isError={Boolean(errors.description && touched.description)}
                    error={errors.description}
                    label="Description"
                >
                    <textarea
                        className="textarea textarea-bordered w-full flex-grow"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <div className="flex items-center justify-end gap-2 mt-3">
                    <Button text="Cancel" onClick={handleClose} />
                    <Button text="Update" type="submit" />
                </div>
            </form>
        </>
    );
};
