import React, { useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { checkItemsId } from "common/utils";
import classNames from "classnames";

interface IProps<T> {
    id: number;
    onAction: (id: number) => void;
    items: T[];
    attribute: keyof T;
    className?: string;
    isDisabled?: boolean;
}

export const SaveButton = <T,>({
    id,
    onAction,
    items,
    attribute,
    className = "",
    isDisabled = false,
}: IProps<T>) => {
    const isSaved = useMemo(
        () => checkItemsId(items, id, attribute),
        [id, items, attribute],
    );

    const buttonStyle = useMemo(
        () => ({
            disabled: isSaved,
            "cursor-pointer": !isSaved,
        }),
        [isSaved],
    );

    const handleButtonClick = useCallback(() => onAction(id), [id]);

    const { icon, color, title } = useMemo(
        () =>
            isSaved
                ? {
                      icon: faCheckCircle,
                      color: "#C9C9C9",
                      title: "Formula saved",
                  }
                : {
                      icon: faPlusCircle,
                      color: "secondary",
                      title: "Bookmark formula",
                  },
        [isSaved],
    );

    return (
        <div className={`flex items-center px-2 ${className}`}>
            <button
                className={classNames("text-secondary-info", buttonStyle)}
                onClick={handleButtonClick}
                disabled={isDisabled && isSaved}
                title={title?.toString()}
            >
                <FontAwesomeIcon color={color} size="lg" icon={icon} />
            </button>
        </div>
    );
};
