import classNames from "classnames";
import React from "react";

interface IProps {
    className?: string;
}

export const SearchIcon: React.FC<IProps> = ({ className }) => (
    <svg
        aria-hidden="true"
        className={classNames("w-7 h-7 text-white pr-1", className)}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        ></path>
    </svg>
);
