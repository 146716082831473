import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import instance from "./axios/instance";
import {
    IFDAClass,
    IOption,
    IProductTypePropertiesByCategory,
    IProductTypes,
} from "common/types";
import { UpdateProductTypePropertiesRequest } from "pages/formulas/formula-profile/types";

class FDAService extends BaseService {
    constructor(
        public baseUrl = backendKeys.fdaClasses,
        private fetchingService = instance,
    ) {
        super(baseUrl);
    }

    async create(payload: Pick<IFDAClass, "code" | "name" | "created_by">) {
        return this.handleRequest<IFDAClass>(
            this.fetchingService.post(this.baseUrl, payload),
        );
    }

    async getAll() {
        const fdaClasses = await this.handleRequest<IFDAClass[]>(
            this.fetchingService.get(this.getFullUrl("/")),
        );

        return fdaClasses.map(({ id, code, name }) => ({
            key: `${id}`,
            value: `${code} ${name}`,
        }));
    }

    async getCategoryBodies() {
        return this.handleRequest<IOption[]>(
            this.fetchingService.get(this.getFullUrl("/categories")),
        );
    }

    async removeFdaClass({ id }: { id: string }) {
        return this.handleRequest<void>(
            this.fetchingService.delete(this.getFullUrl(`/${id}`)),
        );
    }

    async getProductTypes({ fdaClassId }: { fdaClassId: string }) {
        const productTypes = fdaClassId
            ? await this.handleRequest<IProductTypes[]>(
                  this.fetchingService.get(
                      this.getFullUrl(`/product-types/${fdaClassId}`),
                  ),
              )
            : [];

        return productTypes.map(({ id, name }) => ({ key: id, value: name }));
    }

    async getProductTypesByClass(categoryBody: {
        category: string;
        disableAdd: boolean;
    }) {
        return this.handleRequest<IOption[]>(
            this.fetchingService.post(this.getFullUrl(`/product-types/`), {
                categoryBody,
            }),
        );
    }

    async getAllProductTypes() {
        return this.handleRequest<IProductTypes[]>(
            this.fetchingService.get(this.getFullUrl("/product-types/")),
        );
    }

    async createProductType(
        payload: Pick<
            IProductTypes,
            | "name"
            | "regulatoryBody"
            | "skin_retention_factor"
            | "grams_applied_per_day"
            | "created_by"
        >,
    ) {
        return this.handleRequest<IProductTypes>(
            this.fetchingService.post(
                this.getFullUrl(`/product-type/`),
                payload,
            ),
        );
    }

    async removeProductType(id: string) {
        return this.handleRequest<void>(
            this.fetchingService.delete(this.getFullUrl(`/product-type/${id}`)),
        );
    }

    async getProductTypeProperties() {
        return this.handleRequest<IProductTypePropertiesByCategory>(
            this.fetchingService.get(
                this.getFullUrl(`/product-type-properties`),
            ),
        );
    }

    async updateProductTypeProperties(
        payload: UpdateProductTypePropertiesRequest,
    ) {
        return this.handleRequest(
            this.fetchingService.put(
                this.getFullUrl(`/product-type-properties`),
                payload,
            ),
        );
    }
}
export const fdaService = new FDAService();
