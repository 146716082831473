export const classMap: Record<string, [string, string]> = {
    NS: ["prediction-category--1", "Not a sensitiser"],
    "NC (Non sensitizer)": ["prediction-category--1", "Not a sensitiser"],
    "Non-sensitiser": ["prediction-category--1", "Not a sensitiser"],
    "Very weak sensitiser": ["prediction-category--5", "Very weak sensitizer"],
    "NC (Not classified)": ["prediction-category--0", "Not classified"],
    "1* (Sensitizer)": ["prediction-category--2", "1* (Sensitizer)"],
    "IC (Inconclusive)": ["prediction-category--0", "Inconclusive"],
    Weak: ["prediction-category--2", "Weak sensitiser"],
    "Weak sensitiser": ["prediction-category--2", "Weak sensitiser"],
    "Weak to moderate sensitiser": [
        "prediction-category--2",
        "Weak to moderate",
    ],
    Moderate: ["prediction-category--3", "Moderate sensitiser"],
    "Moderate sensitiser": ["prediction-category--3", "Moderate sensitiser"],
    "Others or 1B": ["prediction-category--3", "1B (Other sensitizer)"],
    "1B (Other sensitizer)": [
        "prediction-category--3",
        "1B (Other sensitizer)",
    ],
    Sensitiser: ["prediction-category--4", "Sensitiser"],
    Strong: ["prediction-category--4", "Strong sensitiser"],
    "Strong sensitiser": ["prediction-category--4", "Strong sensitiser"],
    "Strong to extreme sensitiser": [
        "prediction-category--4",
        "Strong to extreme",
    ],
    "1A (Strong)": ["prediction-category--4", "1A (Strong)"],
    "Strong or 1A": ["prediction-category--4", "1A (Strong)"],
};
