import React, { FC, ChangeEvent, memo } from "react";
import { Tooltip } from "react-tooltip";
import { InputWrapper } from ".";
import { DropdownWrapper } from "components/shared";
import { useCategoryBodies } from "pages/formulas/formula-profile/queries";
import { useProductTypesByClass } from "pages/formulas/formula-profile/hooks";
import { FormikErrors } from "formik";
import { type MosFormData } from "pages/formulas/formula-profile/types";

interface IProps {
    values: MosFormData;
    errors: FormikErrors<MosFormData>;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const Form: FC<IProps> = memo(({ values, errors, onChange }) => {
    const { data: regulatoryBodyOptions } = useCategoryBodies();
    const productTypesData = useProductTypesByClass(
        values?.regulatory_body,
        true,
    );

    return (
        <form className="text-sm">
            <div className="flex mb-3">
                <InputWrapper label="Regulatory body">
                    <DropdownWrapper
                        error={errors.regulatory_body}
                        value={values.regulatory_body}
                        name="regulatory_body"
                        onChange={onChange}
                        options={regulatoryBodyOptions ?? []}
                        disableDefaultOption
                    />
                </InputWrapper>

                <InputWrapper label="Product type">
                    <DropdownWrapper
                        error={errors.product_type}
                        value={values.product_type}
                        defaultLabel=""
                        name="product_type"
                        onChange={onChange}
                        options={productTypesData ?? []}
                    />
                </InputWrapper>
            </div>
            <div className="flex mb-3">
                <InputWrapper label="Grams applied/day">
                    <input
                        id="grams-applied-day-tooltip"
                        type="text"
                        className="w-full input input-bordered"
                        name="grams_applied_per_day"
                        value={values.grams_applied_per_day}
                        onChange={onChange}
                    />
                    {errors?.grams_applied_per_day && (
                        <div className="text-red-500 text-sm mt-1">
                            {errors.grams_applied_per_day}
                        </div>
                    )}
                </InputWrapper>
                <InputWrapper label="Skin retention factor">
                    <input
                        type="text"
                        id="skin-retention-factor"
                        className="w-full input input-bordered"
                        name="skin_retention_factor"
                        value={values.skin_retention_factor}
                        onChange={onChange}
                    />
                    {errors?.skin_retention_factor && (
                        <div className="text-red-500 text-sm mt-1">
                            {errors.skin_retention_factor}
                        </div>
                    )}
                </InputWrapper>
            </div>
            <div className="w-1/2 flex mt-2">
                <InputWrapper label="Body weight">
                    <input
                        type="text"
                        className="w-full input input-bordered"
                        name="body_weight"
                        value={values.body_weight}
                        onChange={onChange}
                    />
                    {errors?.body_weight && (
                        <div className="text-red-500 text-sm mt-1">
                            {errors.body_weight}
                        </div>
                    )}
                </InputWrapper>
            </div>

            <Tooltip
                anchorSelect="#grams-applied-day-tooltip"
                content="This factor is calculated based on average usage data for the selected product type."
                variant="info"
            />
            <Tooltip
                anchorSelect="#skin-retention-factor"
                content="This factor is calculated based on product formulation and application method."
                variant="info"
                place="bottom"
            />
        </form>
    );
});
