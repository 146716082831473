import React, { FC, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileWord } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

interface IProps {
    className?: string;
    title?: string;
    onGenerate: (format: "pdf" | "docx") => void;
}

const icons = [
    {
        format: "pdf",
        icon: faFilePdf,
        color: "#dc3545",
        hoverBg: "hover:bg-red-100",
    },
    {
        format: "docx",
        icon: faFileWord,
        color: "blue",
        hoverBg: "hover:bg-blue-100",
    },
];

export const ReportGeneration: FC<IProps> = memo(
    ({ className = "", title = "Create report", onGenerate }) => (
        <div className={classNames("flex items-center", className)}>
            <span className="mr-2 font-medium">{title}:</span>

            {icons.map(({ format, icon, color, hoverBg }) => (
                <button
                    key={format}
                    onClick={() => onGenerate(format as "pdf" | "docx")}
                    aria-label={`Generate report in ${format.toUpperCase()} format`}
                    className={`p-1 rounded-full ${hoverBg} transition-colors`}
                >
                    <FontAwesomeIcon icon={icon} size="lg" color={color} />
                </button>
            ))}
        </div>
    ),
);
