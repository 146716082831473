import React, { Dispatch, FC, SetStateAction } from "react";
import { FieldArray, FormikErrors, FormikTouched } from "formik";
import { IParsedRawIngredient } from "pages/rawIngredient/types";
import { AddSubIngredient } from "../../../AddSubIngredient";
import { SubIngredientItem } from "../../../SubIngredientItem";

export const SubIngredientsSection: FC<{
    index: number;
    item: IParsedRawIngredient;
    itemErrors: FormikErrors<IParsedRawIngredient> | undefined | string;
    setIsError: Dispatch<SetStateAction<Record<string, boolean>>>;
    itemTouched: FormikTouched<IParsedRawIngredient>;
    itemValues: IParsedRawIngredient;
    handleChange: (e: React.ChangeEvent<unknown>) => void;
    totalSubWeight: number;
    isOpenSubDialog: boolean;
    handleToggleOpenSubDialog: () => void;
}> = ({
    index,
    item,
    itemErrors,
    itemTouched,
    itemValues,
    handleChange,
    totalSubWeight,
    isOpenSubDialog,
    handleToggleOpenSubDialog,
}) => (
    <>
        <div className="w-full mt-10 mb-6 flex justify-between items-center">
            <div className="font-bold periwinkle-color">Included INCIs</div>
            <button
                className="black-button mt-4 !font-bold text-lg"
                onClick={handleToggleOpenSubDialog}
                type="button"
                disabled={totalSubWeight >= 100 || isOpenSubDialog}
            >
                <span className="mr-1 text-sm uppercase">Add INCI</span>
            </button>
        </div>

        <FieldArray name={`rawIngredients.${index}.subIngredients`}>
            {({ push, remove }) => (
                <>
                    <div className="flex flex-col gap-2">
                        {item?.subIngredients?.map((_, subIndex) => (
                            <SubIngredientItem
                                key={subIndex}
                                errors={
                                    itemErrors as
                                        | FormikErrors<IParsedRawIngredient>
                                        | undefined
                                }
                                touched={itemTouched}
                                values={itemValues}
                                prefix={`rawIngredients.${index}`}
                                index={subIndex}
                                handleChange={handleChange}
                                remove={remove}
                            />
                        ))}

                        {item.subIngredients && (
                            <div className="font-semibold mt-4 ml-1">
                                Total: {totalSubWeight}%
                            </div>
                        )}

                        {itemErrors && typeof itemErrors === "string" && (
                            <div className="mt-4 text-red-600 font-medium">
                                {itemErrors}
                            </div>
                        )}
                    </div>

                    {isOpenSubDialog && (
                        <AddSubIngredient
                            push={push}
                            onClose={handleToggleOpenSubDialog}
                            totalSubWeight={totalSubWeight}
                        />
                    )}
                </>
            )}
        </FieldArray>
    </>
);
