import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import instance from "./axios/instance";
import {
    IRawIngredientResponse,
    IUpdateRawIngredientRequest,
    ICreateRawIngredientBody,
    IOption,
    IngredientRelation,
    IRawIngredientsResponse,
} from "pages/rawIngredient/types";
import {
    ICreateTradeNameRequest,
    ISubIngredient,
    ISubIngredientError,
    ITradename,
    IUpdateTradenameRequestBody,
    UpdateManufacturerTradenameBody,
} from "common/types";
import {
    SafetyDocumentBody,
    SafetyDocumentDeleteBody,
    SafetyDocumentResponse,
} from "pages/rawIngredient/rawIngredientProfile/libs";
import { buildLink, jsonToFormData } from "common/utils";
import { ICreateSubIngredientBody } from "pages/subIngredient/subIngredients/libs/types";

class RawIngredientService extends BaseService {
    constructor(
        public baseUrl = backendKeys.rawIngredients,
        private fetchingService = instance,
    ) {
        super(baseUrl);
    }

    async getRawIngredient({ id }: { id: string }) {
        return this.handleRequest<IRawIngredientResponse>(
            this.fetchingService.get(this.getFullUrl(`/${id}`)),
        );
    }

    async getRawIngredientByName(name: string) {
        return this.handleRequest<IRawIngredientsResponse>(
            this.fetchingService.post(this.getFullUrl("/search"), {
                name,
            }),
        );
    }

    async removeRawIngredient(id: string) {
        return this.handleRequest(
            this.fetchingService.delete(this.getFullUrl(`/${id}`)),
        );
    }

    async createRawIngredient(payload: ICreateRawIngredientBody) {
        return this.handleRequest<IRawIngredientResponse>(
            this.fetchingService.post(this.baseUrl, payload),
        );
    }

    async checkIsUserOwner(payload: { ricode: string; description: string }) {
        return this.handleRequest<{ isExist: boolean; isOwner: boolean }>(
            this.fetchingService.post(this.getFullUrl(`/checkOwner`), payload),
        );
    }

    async updateRawIngredient({
        id,
        body,
    }: {
        id: string;
        body: IUpdateRawIngredientRequest;
    }) {
        return this.handleRequest<IRawIngredientResponse>(
            this.fetchingService.put(this.getFullUrl(`/${id}`), body),
        );
    }

    async createRawIngredientTradeName(payload: ICreateTradeNameRequest) {
        return this.handleRequest<ITradename>(
            this.fetchingService.post(this.getFullUrl("/tradename"), payload),
        );
    }

    async updateRawIngredientTradeName(
        id: string,
        body: IUpdateTradenameRequestBody,
    ) {
        return this.handleRequest<ITradename>(
            this.fetchingService.post(
                this.getFullUrl(`/tradename/${id}`),
                body,
            ),
        );
    }

    async updateRawIngredientSubIngredient(payload: ICreateSubIngredientBody) {
        return this.handleRequest<ISubIngredient | ISubIngredientError>(
            this.fetchingService.put(
                this.getFullUrl("/sub-ingredient"),
                payload,
            ),
        );
    }

    async getManufacturerOptions(): Promise<IOption[]> {
        return this.fetchOptionsByField("vendors_id");
    }

    async getTradeNames(): Promise<IOption[]> {
        return this.fetchOptionsByField("tradename");
    }

    private async fetchOptionsByField(fieldName: string): Promise<IOption[]> {
        const response = await this.handleRequest<{ [key: string]: string }[]>(
            this.fetchingService.get(this.getFullUrl(`/filter/${fieldName}/`)),
        );

        return response.map(({ [fieldName]: value }) => ({
            label: value,
            value: value,
        }));
    }

    async updateRelation({
        body,
        id,
        ingredientId,
        relationType,
    }: IngredientRelation) {
        return this.handleRequest(
            this.fetchingService.put(
                this.getFullUrl(`/${id}/${ingredientId}/${relationType}`),
                body,
            ),
        );
    }

    async deleteRelation({
        id,
        ingredientId,
        relationType,
    }: {
        id: string;
        ingredientId: string;
        relationType: string;
    }) {
        return this.handleRequest(
            this.fetchingService.delete(
                this.getFullUrl(`/${id}/${ingredientId}/${relationType}`),
            ),
        );
    }

    async uploadSafetyDocument(payload: SafetyDocumentBody): Promise<void> {
        const { id, ...data } = payload;
        const formData = jsonToFormData(data);

        return this.handleRequest(
            this.fetchingService.post(
                this.getFullUrl(
                    buildLink(id, backendKeys.api.rawIngredients.fileUpload),
                ),
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            ),
        );
    }

    async getUploadedSafetyDocuments(
        id: number,
    ): Promise<SafetyDocumentResponse> {
        return this.handleRequest(
            this.fetchingService.get(
                this.getFullUrl(`/${id}/safety-documents`),
            ),
        );
    }

    async deleteSafetyDocument(
        payload: SafetyDocumentDeleteBody,
    ): Promise<void> {
        const { id, type } = payload;

        return this.handleRequest<void>(
            this.fetchingService.delete(
                this.getFullUrl(`/${id}/safety-documents/${type}`),
            ),
        );
    }

    async getTradeNamesByRawIngredientId(id: string) {
        return this.handleRequest<ITradename[]>(
            this.fetchingService.get(this.getFullUrl(`/${id}/tradename`)),
        );
    }

    async deleteRawIngredientTradeName(id: string | number) {
        return this.handleRequest<Promise<void>>(
            this.fetchingService.delete(this.getFullUrl(`/${id}/tradename`)),
        );
    }

    async updateManufacturerAndTradename(
        payload: UpdateManufacturerTradenameBody,
    ) {
        return this.handleRequest<ITradename>(
            this.fetchingService.put(this.getFullUrl("/tradename"), payload),
        );
    }
}

export const rawIngredientService = new RawIngredientService();
