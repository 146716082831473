import classNames from "classnames";
import React from "react";

interface IProps {
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    color?: string;
}

export const CommonInput: React.FC<IProps> = ({
    name,
    value,
    onChange,
    disabled = false,
    color = "input-primary",
}) => (
    <input
        type="text"
        className={classNames(
            "input input-bordered input-sm max-w-xxxs join-item",
            color,
        )}
        name={name || ""}
        value={value || ""}
        onChange={onChange}
        disabled={disabled}
    />
);
