import { IFilter } from "common/types";

export const defaultFilterValues: IFilter = {
    ricode: {
        value: "",
        label: "",
        filterValue: "",
    },
    gluten: {
        value: "",
        label: "",
        filterValue: "",
    },
    animal_derived: {
        value: "",
        label: "",
        filterValue: "",
    },
    animal_testing: {
        value: "",
        label: "",
        filterValue: "",
    },
    cfi_acceptable: {
        value: "",
        label: "",
        filterValue: "",
    },
    country_of_origin: {
        value: "",
        label: "",
        filterValue: "",
    },
    query: {
        value: "",
        label: "",
        filterValue: "",
    },
    tradename: {
        value: "",
        label: "",
        filterValue: "",
    },
    manufacturer: {
        value: "",
        label: "",
        filterValue: "",
    },
};
