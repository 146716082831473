import { defaultToastOptions, toastTexts } from "common/constants";
import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

export const LoadingToast = () => {
    useEffect(() => {
        const toastId = toast.loading(toastTexts.loading, {
            ...defaultToastOptions,
            containerId: "LoadingToast",
        });

        return () => {
            toast.dismiss(toastId);
        };
    }, []);

    return <ToastContainer containerId="LoadingToast" />;
};
