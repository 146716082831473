import React, { FC } from "react";
import { FormulaStatus } from "common/data";
import classNames from "classnames";

interface IProps {
    status?: keyof typeof FormulaStatus;
    defaultText?: string;
}

export const Status: FC<IProps> = ({ status, defaultText = "" }) => (
    <div className="flex items-center">
        <span
            className={classNames(
                "inline-block px-3 py-1 min-w-10 rounded-full text-sm",
                !defaultText &&
                    (["FUNCTIONAL", "FOUND", "Complete", "Active"].some(
                        (s) => status === s,
                    )
                        ? "bg-green-200"
                        : "bg-red-200"),
            )}
        >
            {status ? FormulaStatus[status] : defaultText}
        </span>
    </div>
);
