export const renderValue = (
    input?: number | string | null,
    number: number = 5,
    is100Max: boolean = false,
): string => {
    if (input === null || input === undefined || input === "") {
        return "";
    }

    const value = String(input);

    if (is100Max && +value > 100) {
        return "100";
    }

    const dotIndex = value.indexOf(".");
    const isNegative = value[0] === "-";

    if (dotIndex === -1) {
        return value;
    }

    const decimalPartLength = value.slice(dotIndex + 1).length;

    if (isNegative && decimalPartLength <= number) {
        return value.slice(0, dotIndex + decimalPartLength + 1);
    }

    if (!isNegative && decimalPartLength <= number - 1) {
        return value;
    }

    const newValue = value.slice(
        0,
        dotIndex + (isNegative ? number + 1 : number),
    );

    return newValue.endsWith("0") ? newValue.slice(0, -1) : newValue;
};
