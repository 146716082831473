import React, { useEffect, useMemo, useState, FC, memo } from "react";
import { useNavigate } from "react-router-dom";
import { MultiValue } from "react-select";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import {
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { InputWrapper, SearchableSelect, FormActions } from "components/shared";
import {
    useCreateRawIngredient,
    useFindSimilarItems,
    useRawIngredient,
} from "../queries";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { IOption } from "common/types";
import { useGetAllRawIngredients } from "pages/adminPanel/queries";
import { IRawIngredientResponse } from "pages/rawIngredient/types";
import { buildLink, getErrorMessage } from "common/utils";
import { AxiosError } from "axios";
import classNames from "classnames";

interface IProps {
    handleClose: () => void;
    formulas_id?: string;
    rawIngredient?: IRawIngredientResponse;
    isLink?: boolean;
}

const CreateSchema = Yup.object().shape({
    description: Yup.string().required("Name is required"),
});

export const CreateRawIngredientForm: FC<IProps> = memo(
    ({ handleClose, formulas_id, rawIngredient = null, isLink = false }) => {
        const [selectedRawIngredient, setSelectedRawIngredient] =
            useState<IOption | null>(null);
        const queryClient = useQueryClient();
        const navigate = useNavigate();

        const [similarRawId, setSimilarRawId] = useState<string>("");

        const { data: currRawIngredient } = useRawIngredient(
            selectedRawIngredient?.value ?? "",
        );

        const handleUpdateRelatedList = (newValue: IOption | null) => {
            setSelectedRawIngredient(newValue);
        };

        const { rawIngredients, rawIngredientsTotalWeight } =
            useFormulaProfileState();

        const maxValue = useMemo(
            () => 100 - +rawIngredientsTotalWeight,
            [rawIngredientsTotalWeight],
        );

        const { mutate: createRawIngredient } = useCreateRawIngredient();

        const ExtendedSchema = useMemo(() => {
            if (formulas_id) {
                return CreateSchema.concat(
                    Yup.object().shape({
                        weight_percent: Yup.number()
                            .max(
                                maxValue,
                                `Weight percent must be less than or equal to ${maxValue}`,
                            )
                            .min(
                                0,
                                "Weight percent must be greater than or equal to 0",
                            )
                            .required("Weight percent is required"),
                    }),
                );
            }
            return CreateSchema;
        }, [formulas_id, maxValue]);

        const formik = useFormik({
            initialValues: {
                ricode: rawIngredient?.ricode,
                description: rawIngredient?.description || "",
                ...(formulas_id ? { weight_percent: "" } : {}),
            },
            validationSchema: ExtendedSchema,
            validateOnChange: true,
            validateOnMount: true,
            enableReinitialize: true,
            onSubmit: (values) => {
                const toastId = toast.loading(
                    toastTexts.loading,
                    defaultToastOptions,
                );
                const weight_percent = values.weight_percent?.toString();

                if (currRawIngredient) {
                    createRawIngredient(
                        {
                            ...values,
                            ricode: values?.ricode,
                            description: values.description.trim(),
                            ...(weight_percent ? { weight_percent } : {}),
                            ...(formulas_id ? { formulas_id } : {}),
                        },

                        {
                            onSuccess: () => {
                                toast.update(toastId, {
                                    ...defaultSuccessToastUpdate,
                                    render: "Raw ingredient has been added",
                                });
                                queryClient.invalidateQueries({
                                    queryKey: [queryKeys.rawIngredients],
                                });
                                queryClient.removeQueries({
                                    queryKey: [queryKeys.rawIngredients, "all"],
                                });

                                formik.resetForm();
                                setSelectedRawIngredient(null);
                                handleClose();
                            },
                            onError(error: Error) {
                                toast.dismiss(toastId);
                                const message = getErrorMessage(
                                    error as AxiosError,
                                );

                                formik.setFieldError(
                                    "description",
                                    typeof message === "string"
                                        ? message
                                        : "An unexpected error occurred",
                                );
                            },
                        },
                    );
                } else {
                    createRawIngredient(
                        {
                            ...values,
                            ricode: values?.ricode,
                            description: values.description.trim(),
                            ...(weight_percent ? { weight_percent } : {}),
                            ...(formulas_id ? { formulas_id } : {}),
                        },
                        {
                            onSuccess: (response: IRawIngredientResponse) => {
                                const successResponse =
                                    response as IRawIngredientResponse;

                                navigate(
                                    buildLink(
                                        routerKeys.rawIngredient,
                                        successResponse.id,
                                    ),
                                );

                                toast.success(
                                    "Raw ingredient has been created",
                                    defaultToastOptions,
                                );
                                queryClient.invalidateQueries({
                                    queryKey: [queryKeys.rawIngredients],
                                });
                                queryClient.removeQueries({
                                    queryKey: [queryKeys.rawIngredients, "all"],
                                });

                                formik.resetForm();
                                setSelectedRawIngredient(null);
                                handleClose();
                            },
                            onError(error: Error) {
                                toast.dismiss(toastId);
                                const message = getErrorMessage(
                                    error as AxiosError,
                                );

                                formik.setFieldError(
                                    "description",
                                    typeof message === "string"
                                        ? message
                                        : "An unexpected error occurred",
                                );
                            },
                        },
                    );
                }
            },
        });

        const { data: similar } = useFindSimilarItems(
            formik.values.description,
        );

        const { data, isLoading } = useGetAllRawIngredients();

        const options = useMemo(() => {
            if (data) {
                return data
                    .filter(
                        (item) =>
                            !rawIngredients
                                .map(({ id }) => id)
                                .includes(item.id),
                    )
                    .map((item) => ({
                        value: String(item.id),
                        label: `${item.ricode}, ${item.description}`,
                    })) as MultiValue<IOption>;
            }
            return [];
        }, [data, rawIngredients]);

        useEffect(() => {
            if (selectedRawIngredient) {
                const [ricode, description] =
                    selectedRawIngredient.label.split(",");

                formik.setFieldValue("ricode", ricode.trim());
                formik.setFieldValue("description", description.trim());
            }
        }, [selectedRawIngredient]);

        const { values, errors, touched, handleChange, resetForm } = formik;

        const handleResetForm = () => {
            resetForm();
            setSelectedRawIngredient(null);
        };

        const { data: rawIngredientFromSimilarList } =
            useRawIngredient(similarRawId);

        useEffect(() => {
            if (rawIngredientFromSimilarList) {
                handleUpdateRelatedList({
                    value: String(rawIngredientFromSimilarList.id),
                    label: `${rawIngredientFromSimilarList.ricode}, ${rawIngredientFromSimilarList.description}`,
                });
            }
        }, [rawIngredientFromSimilarList]);

        if (+rawIngredientsTotalWeight >= 100 && formulas_id) {
            return (
                <div className="w-full">
                    <h6 className="text-xl text-gray-500 text-center">
                        In the form, there already exists an equal distribution
                        of raw gradients totaling 100%.
                    </h6>
                </div>
            );
        }

        return (
            <form
                className={classNames("w-full", {
                    "min-h-[350px]": similar?.length,
                })}
                onSubmit={formik.handleSubmit}
            >
                {formulas_id && Boolean(options.length) && (
                    <div className="mb-4 text-sm text-center font-semibold text-gray-500">
                        Choose an existing completed raw ingredient or set a
                        name to create a new one
                    </div>
                )}
                <div className="flex flex-col gap-4">
                    {formulas_id && Boolean(options.length) && (
                        <div className="px-1">
                            <SearchableSelect
                                isLoading={isLoading}
                                options={options}
                                values={selectedRawIngredient}
                                onChange={handleUpdateRelatedList}
                            />
                        </div>
                    )}
                    {formulas_id && (
                        <InputWrapper
                            isError={Boolean(
                                errors.weight_percent && touched.weight_percent,
                            )}
                            error={errors.weight_percent}
                            label="Weight percent"
                        >
                            <input
                                type="number"
                                name="weight_percent"
                                className="w-full input input-bordered"
                                value={values.weight_percent}
                                onChange={handleChange}
                            />
                            <div className="text-xs text-gray-500 mt-1">
                                Weight percent (w/w): the mass of the solute
                                divided by the total mass of the mixture
                            </div>
                        </InputWrapper>
                    )}
                    <InputWrapper
                        isError={Boolean(
                            errors.description && touched.description,
                        )}
                        error={errors.description}
                        label="Name"
                    >
                        <textarea
                            className="textarea textarea-bordered w-full flex-grow"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            rows={2}
                        />
                    </InputWrapper>
                </div>
                <FormActions
                    baseLink={routerKeys.rawIngredient}
                    links={similar}
                    handleResetForm={handleResetForm}
                    onClick={isLink ? undefined : setSimilarRawId}
                    submitButtonText={
                        currRawIngredient && !isLink ? "Update" : "Create"
                    }
                />
            </form>
        );
    },
);
