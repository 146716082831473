import { ChangeEvent } from "react";
import { IFilter } from "common/types";

interface IProps {
    onUpdateFilters: (
        key: string,
        { value, label }: IFilter[keyof IFilter],
    ) => void;
    filters: IFilter;
}

export const useHandleUpdateFilters =
    ({ onUpdateFilters, filters }: IProps) =>
    ({ key, label }: { key: string; label: string }) =>
    (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        filterValue?: string,
    ) => {
        const currentFilter = filters[key] || {
            value: "",
            label: "",
            filterValue: "",
        };

        onUpdateFilters(key, {
            value: currentFilter.value === e.target.value ? "" : e.target.value,
            label: label || "",
            filterValue: filterValue || "",
        });
    };
