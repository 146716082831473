import React, { useCallback, memo, ReactNode } from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames";

interface IProps {
    pageCount: number;
    currentPage: number;
    onPageChange: (pageIndex: number) => void;
    pageRangeDisplayed?: number;
    marginPagesDisplayed?: number;
    previousLabel?: ReactNode;
    nextLabel?: ReactNode;
    breakLabel?: ReactNode;
    className?: string;
}

export const ReactPagination = memo<IProps>(
    ({
        pageCount,
        currentPage,
        onPageChange,
        pageRangeDisplayed = 4,
        marginPagesDisplayed = 2,
        previousLabel = null,
        nextLabel = null,
        breakLabel = null,
        className = "",
    }) => {
        const handlePageChange = useCallback(
            (selectedPage: { selected: number }) => {
                onPageChange(selectedPage.selected);
            },
            [onPageChange],
        );

        return (
            <ReactPaginate
                forcePage={currentPage}
                previousLabel={previousLabel}
                nextLabel={nextLabel}
                breakLabel={breakLabel}
                pageCount={pageCount}
                marginPagesDisplayed={marginPagesDisplayed}
                pageRangeDisplayed={pageRangeDisplayed}
                onPageChange={handlePageChange}
                containerClassName={classNames(
                    "pagination__container",
                    className,
                )}
                breakClassName="pagination__element__page"
                breakLinkClassName="pagination__element__link"
                activeClassName="pagination__element__active"
                pageClassName="pagination__element__page"
                pageLinkClassName="pagination__element__link"
                previousClassName="pagination__element__previous"
                nextClassName="pagination__element__next"
                previousLinkClassName="pagination__element__previous"
                nextLinkClassName="pagination__element__next"
            />
        );
    },
);
