import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import instance from "./axios/instance";
import {
    ISubIngredientResponse,
    MoleculeData,
    ReportEndpointKey,
    UpdateSubIngredientRequest,
} from "pages/subIngredient/subIngredientProfile/libs/types";
import { IReport, ISubIngredient, IToxicology } from "common/types";
import { ICreateSubIngredientBodyForBulkCreate } from "pages/subIngredient/subIngredients/libs/types";

class SubIngredientService extends BaseService {
    constructor(
        public baseUrl = backendKeys.subIngredients,
        private fetchingService = instance,
    ) {
        super(baseUrl);
    }

    async getSubIngredient({ id }: { id: string }) {
        return this.handleRequest<ISubIngredientResponse>(
            this.fetchingService.get(this.getFullUrl(`/${id}`)),
        );
    }

    async removeSubIngredient({ id }: { id: string }) {
        return this.handleRequest(
            this.fetchingService.delete(this.getFullUrl(`/${id}`)),
        );
    }

    async update({
        id,
        body,
    }: {
        id: string;
        body: UpdateSubIngredientRequest;
    }) {
        return this.handleRequest<ISubIngredientResponse>(
            this.fetchingService.put(this.getFullUrl(`/${id}`), body),
        );
    }

    async calculateEnvEndpoint({
        id,
        body,
    }: {
        id: string;
        body: Partial<IToxicology>;
    }) {
        return this.handleRequest<IToxicology>(
            this.fetchingService.post(this.getFullUrl(`/${id}/eecalc`), body),
        );
    }

    async addSafetyEndpoint({
        id,
        body,
    }: {
        id: string;
        body: { safetyEndpoint: ReportEndpointKey };
    }) {
        return this.handleRequest<ISubIngredientResponse>(
            this.fetchingService.put(this.getFullUrl(`/${id}/report`), body),
        );
    }

    async getReports({ sicode }: { sicode: string }) {
        return this.handleRequest<IReport[]>(
            this.fetchingService.get(`files/${this.baseUrl}/inci/${sicode}`),
        );
    }

    async getReport({
        sicode,
        filename,
    }: {
        sicode: string;
        filename: string;
    }) {
        return this.handleRequest<Blob>(
            this.fetchingService.get(
                `files/${this.baseUrl}/inci/${sicode}/${filename}`,
            ),
        );
    }

    async uploadReport({
        sicode,
        filename,
        body,
    }: {
        sicode: string;
        filename: string;
        body: FormData;
    }) {
        return this.handleRequest(
            this.fetchingService.post(
                `files/${this.baseUrl}/inci/${sicode}/${filename}`,
                body,
            ),
        );
    }

    async createSubIngredients({
        body,
    }: {
        body: ICreateSubIngredientBodyForBulkCreate[];
    }) {
        return this.handleRequest<ISubIngredient>(
            this.fetchingService.post(this.getFullUrl("/createBulk"), body),
        );
    }

    async updateEmptyNames() {
        return this.handleRequest(
            this.fetchingService.put(this.getFullUrl("/updateNames")),
        );
    }

    async removeReport({
        sicode,
        filename,
    }: {
        sicode: string;
        filename: string;
    }) {
        return this.handleRequest(
            this.fetchingService.delete(
                `files/${this.baseUrl}/inci/${sicode}/${filename}`,
            ),
        );
    }

    async getOperaSkinSafetyData(smile: string): Promise<MoleculeData> {
        const endpoint =
            "https://opera.saferworldbydesign.com/opera/base-descriptors";
        const body = { smiles: smile.trim().split("") };

        const response = await this.handleRequest(
            this.fetchingService.post(endpoint, body),
        );

        return response?.data?.[0] || {};
    }
}

export const subIngredientService = new SubIngredientService();
