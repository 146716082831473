import { PageTemplate } from "components/page-template";
import React, { useEffect, useMemo, useState } from "react";
import {
    contaminantsContent,
    defaultErrorToastOptions,
    routerKeys,
    toastTexts,
} from "common/constants";
import { LoadingToast } from "components/shared/loadingToast";
import { useGetContaminants } from "./queries";
import { CreateContaminantForm, Filters } from "./components";
import { ToastContainer, toast } from "react-toastify";
import { ModalTemplate } from "components/shared/modalTemplate";
import { ButtonBlock } from "components/shared/button-block/ButtonBlock";
import { DataTable, FilterBlock, IconLink } from "components/shared";
import { ColumnDef } from "@tanstack/react-table";
import { TableContaminant } from "./types";
import { useFilter, useModal, useURLStateSync } from "common/hooks";
import { modifyDate } from "common/utils";

export const Contaminants = () => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const {
        filters,
        pagination,
        sorting,
        setFilters,
        setPagination,
        setSorting,
        getParamsString,
    } = useURLStateSync();

    const {
        data: contaminants,
        isLoading,
        isError,
        refetch,
    } = useGetContaminants({
        queryParams: getParamsString(),
        sorting,
    });

    useEffect(() => {
        if (isError) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        }
    }, [isError]);

    const {
        modalRef: createModalRef,
        openModal,
        closeModal,
    } = useModal({
        onClose: () => {
            refetch();
        },
    });

    const {
        handleUpdateFilters,
        handleUpdateSearch,
        handleFilterVisible,
        handleRemoveFilter,
    } = useFilter({
        setFilters,
        setIsFilterVisible,
    });

    const columns = useMemo<ColumnDef<TableContaminant>[]>(
        () => [
            {
                accessorKey: "name",
                header: () => <span className="max-w-[200px]">Name</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[200px]"
                        to={routerKeys.contaminants}
                        id={row.original.id}
                    >
                        <span
                            className="truncate ... max-w-[200px]"
                            title={row.getValue("name")}
                        >
                            {row.getValue("name")}
                        </span>
                    </IconLink>
                ),
            },
            {
                accessorKey: "description",
                header: () => <span>Description</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[150px]"
                        to={routerKeys.contaminants}
                        id={row.original.id}
                    >
                        <span
                            className="truncate max-w-[150px] cursor-pointer"
                            title={row.getValue("description")}
                        >
                            {row.getValue("description")}
                        </span>
                    </IconLink>
                ),
            },
            {
                accessorKey: "date_created",
                header: () => <span>Date Created</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_created"))}</span>
                ),
            },
            {
                accessorKey: "date_modified",
                header: () => <span>Date Modified</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_modified"))}</span>
                ),
            },
            {
                id: "action",
                accessorKey: "action",
                enableSorting: false,
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.contaminants}
                        id={row.original.id}
                    />
                ),
            },
        ],
        [],
    );

    return (
        <PageTemplate
            title={contaminantsContent.title}
            subtitle={contaminantsContent.subTitle}
            searchValue={filters?.query?.value}
            searchPlaceholder={contaminantsContent.placeholder}
            onChange={handleUpdateSearch}
            buttonBlock={
                <ButtonBlock
                    title={contaminantsContent.title}
                    createFn={openModal}
                />
            }
        >
            <div className="md:flex lg:flex py-4">
                <ModalTemplate
                    text="Create Contaminant"
                    onClose={closeModal}
                    modalRef={createModalRef}
                    className="max-w-[600px]"
                >
                    <CreateContaminantForm handleClose={closeModal} />
                </ModalTemplate>
                <div className="w-full px-10">
                    {/* Filter button and filters status */}
                    <FilterBlock
                        isFilterVisible={isFilterVisible}
                        onFilterVisible={handleFilterVisible}
                        onRemoveFilter={handleRemoveFilter}
                        filters={filters}
                    />

                    <DataTable
                        data={contaminants?.rows || []}
                        columns={columns}
                        pagination={pagination}
                        setPagination={setPagination}
                        totalCount={contaminants?.count || 0}
                        isFilterVisible={isFilterVisible}
                        sorting={sorting}
                        setSorting={setSorting}
                    >
                        {/* Custom filters */}
                        <Filters
                            onUpdateFilters={handleUpdateFilters}
                            filters={filters}
                        />
                    </DataTable>
                </div>
                {isLoading ? <LoadingToast /> : <ToastContainer />}
            </div>
        </PageTemplate>
    );
};
