import { PageTemplate } from "components/page-template";
import React from "react";
import { Header } from "./components";
import { ToastContainer } from "react-toastify";
import { Forms } from "./components/Forms";

export const AdminPanel = () => {
    return (
        <PageTemplate title="Admin Panel" withSearch={false}>
            <Header />
            <Forms />
            <ToastContainer />
        </PageTemplate>
    );
};
