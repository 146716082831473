import React, { FC, KeyboardEvent } from "react";
import { TableCeil } from "components/shared/tableCeil";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
    defaultErrorToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { toast } from "react-toastify";
import { useRawIngredientStore } from "pages/rawIngredient/rawIngredientProfile/store";
import {
    useDeleteRelation,
    useUpdateRelation,
} from "pages/rawIngredient/rawIngredientProfile/queries";
import { IContaminant } from "common/types";
import { ActionButtons } from "components/shared";

interface IProps {
    contaminant: IContaminant;
}

export const ContaminantRow: FC<IProps> = ({ contaminant }) => {
    const { id = "" } = useParams();
    const queryClient = useQueryClient();
    const { isOwner } = useRawIngredientStore();

    const navigate = useNavigate();

    const navigateToContaminantPage = () => {
        navigate(`${routerKeys.contaminants}/${contaminant.id}`);
    };

    const { mutate: updateRelation } = useUpdateRelation();
    const { mutate: deleteRelation } = useDeleteRelation();

    const formik = useFormik({
        initialValues: {
            ppm: contaminant.rawIngredientsContaminants?.ppm || "",
        },
        validationSchema: Yup.object().shape({
            ppm: Yup.number()
                .min(0, "PPM must be greater than or equal to 0")
                .required("PPM is required"),
        }),
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: ({ ppm }) => {
            updateRelation(
                {
                    id,
                    ingredientId: `${contaminant.id}`,
                    relationType: "contaminants",
                    body: { value: ppm },
                },
                {
                    onSuccess() {
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.rawIngredient, id],
                        });
                    },
                    onError() {
                        toast.error(toastTexts.error, defaultErrorToastOptions);
                    },
                },
            );
        },
    });

    const handleRemoveIngredient = () => {
        deleteRelation(
            {
                id,
                ingredientId: `${contaminant.id}`,
                relationType: "contaminants",
            },
            {
                onSuccess() {
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.rawIngredient, id],
                    });
                },
                onError() {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            formik.handleSubmit();
        }
    };

    return (
        <>
            <tr className="relative">
                <TableCeil
                    text={contaminant.name}
                    onClick={navigateToContaminantPage}
                    className="cursor-pointer"
                />
                <TableCeil className="w-1/2">
                    <input
                        type="number"
                        name="ppm"
                        onChange={formik.handleChange}
                        value={formik.values.ppm}
                        onKeyDown={handleKeyDown}
                        className="w-full lg:w-24 py-1 px-2 rounded-md border border-gray-300 bg-white text-gray-900 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    />
                </TableCeil>
                {(isOwner || true) && (
                    <TableCeil>
                        <ActionButtons
                            onSubmit={formik.handleSubmit}
                            onRemove={handleRemoveIngredient}
                        />
                    </TableCeil>
                )}
            </tr>
        </>
    );
};
