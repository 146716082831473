import { TableHeaderCeil } from "components/shared/tableHeaderCeil";
import { Title } from "components/shared/title";
import React from "react";
import { TabTable } from "../../TabTable";
import { useGetRawIngredient } from "pages/rawIngredient/rawIngredientProfile/queries";
import { AddRelatedIngredients } from "../../";
import { useRawIngredientStore } from "pages/rawIngredient/rawIngredientProfile/store";
import { ContaminantRow } from "./ContaminantRow";
import { RawIngredientProfileTab } from "pages/rawIngredient/enums";

export const ContaminantsTab = () => {
    const rawIngredient = useGetRawIngredient();
    const { isOwner } = useRawIngredientStore();

    return (
        <div>
            <div className="md:flex lg:flex mx-10">
                <div className="w-1/2 md:w-1/2 lg:w-1/2 md:mr-5">
                    <Title
                        text={RawIngredientProfileTab.CONTAMINANTS}
                        className="mt-2"
                    />
                    {(isOwner || true) && (
                        <AddRelatedIngredients type="Contaminant" />
                    )}
                    <TabTable>
                        <thead className="bg-indigo-100">
                            <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                                <TableHeaderCeil text="Name" />
                                <TableHeaderCeil text="Value (ppm)" />
                                {Boolean(
                                    rawIngredient?.contaminants?.length,
                                ) && <TableHeaderCeil className="w-2" />}
                            </tr>
                        </thead>
                        <tbody className="sm:flex-none">
                            {rawIngredient?.contaminants?.length ? (
                                <>
                                    {rawIngredient.contaminants
                                        .sort((a, b) =>
                                            a.name.localeCompare(b.name),
                                        )
                                        .map((contaminant) => (
                                            <ContaminantRow
                                                key={contaminant.id}
                                                contaminant={contaminant}
                                            />
                                        ))}
                                </>
                            ) : (
                                <tr>
                                    <td className="p-3">
                                        No Contaminants found
                                    </td>
                                    <td />
                                </tr>
                            )}
                        </tbody>
                    </TabTable>
                </div>
                <div className="w-1/2 md:w-1/2 lg:w-1/2 md:ml-5">
                    <Title text="Chemical Specification" className="mt-2" />
                </div>
            </div>
        </div>
    );
};
