import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { type IFilter } from "common/types";
import { type SortingState, type PaginationState } from "@tanstack/react-table";

const abbreviation: Record<string, string> = {
    cir: "CIR",
} as const;

const getLabelFromKey = (key: string) =>
    key
        ?.split("_")
        .map((w) =>
            abbreviation?.[w]
                ? abbreviation[w]
                : w.charAt(0).toUpperCase() + w.slice(1),
        )
        .join(" ");

export const useURLStateSync = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [filters, setFilters] = useState<IFilter>({});
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [sorting, setSorting] = useState<SortingState>([]);
    const isInitialMount = useRef(true);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const parsedFilters: IFilter = {};
        params.forEach((value, key) => {
            if (key !== "offset" && key !== "limit" && key !== "sort") {
                parsedFilters[key] = {
                    value,
                    label: getLabelFromKey(key),
                    filterValue: value === "yes" ? getLabelFromKey(key) : value,
                };
            }
        });
        setFilters(parsedFilters);

        const offset = parseInt(params.get("offset") || "0", 10);
        const limit = parseInt(params.get("limit") || "10", 10);
        setPagination({
            pageIndex: Math.floor(offset / limit),
            pageSize: limit,
        });

        const sortString = params.get("sort") || "";
        const parsedSorting: SortingState = sortString
            ? JSON.parse(sortString)
            : [];
        setSorting(parsedSorting);
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams();

        Object.entries(filters)
            .filter(([_, filter]) => filter?.value)
            .forEach(([key, filter]) => searchParams.append(key, filter.value));

        searchParams.set(
            "offset",
            `${pagination.pageIndex * pagination.pageSize}`,
        );
        searchParams.set("limit", `${pagination.pageSize}`);

        if (sorting.length > 0) {
            searchParams.set("sort", JSON.stringify(sorting));
        }

        const params = searchParams.toString();

        navigate({ search: params });
    }, [filters, pagination, sorting, navigate]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        setPagination((prev) => ({
            ...prev,
            pageIndex: 0,
        }));
    }, [filters]);

    const getParamsString = () => {
        const searchParams = new URLSearchParams();

        Object.entries(filters)
            .filter(([_, filter]) => filter?.value)
            .forEach(([key, filter]) => searchParams.append(key, filter.value));

        searchParams.set(
            "offset",
            `${pagination.pageIndex * pagination.pageSize}`,
        );
        searchParams.set("limit", `${pagination.pageSize}`);

        return searchParams.toString();
    };

    return {
        filters,
        pagination,
        sorting,
        setFilters,
        setPagination,
        setSorting,
        getParamsString,
    };
};
