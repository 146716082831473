import React, { FC, ReactNode, useEffect } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import { routerKeys, storageKeys } from "common/constants";

const authInstance = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
    clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
});

interface IProps {
    children: ReactNode;
}

export const KeycloakProvider: FC<IProps> = ({ children }) => {
    useEffect(() => {
        authInstance.onTokenExpired = () => {
            authInstance.updateToken(30).catch(() => {
                localStorage.removeItem(storageKeys.accessToken);
                window.location.href = routerKeys.authLogin;
            });
        };
    }, []);

    return (
        <ReactKeycloakProvider
            authClient={authInstance}
            initOptions={{ checkLoginIframe: false }}
            onTokens={({ token }) => {
                if (token) {
                    localStorage.setItem(storageKeys.accessToken, token);
                } else {
                    localStorage.removeItem(storageKeys.accessToken);
                }
            }}
            onEvent={(event) => {
                if (
                    [
                        "onInitError",
                        "onAuthError",
                        "onAuthRefreshError",
                    ].includes(event)
                ) {
                    localStorage.removeItem(storageKeys.accessToken);
                    window.location.href = routerKeys.authLogin;
                }
            }}
        >
            {children}
        </ReactKeycloakProvider>
    );
};
