import { FormulaProfileTab } from "../../types";
import React from "react";
import { PropertyTab, MosTab, ReportTab, CompositionTab } from ".";
import { TestsTab } from "./testsTab";
import { SimilarTab } from "./similarTab";

interface IProps {
    selectedTab: FormulaProfileTab;
}

export const TabContent: React.FC<IProps> = ({ selectedTab }) => {
    switch (selectedTab) {
        case "Properties":
            return <PropertyTab />;
        case "Composition":
            return <CompositionTab />;
        case "MOS":
            return <MosTab />;
        case "Reports":
            return <ReportTab />;
        case "Testing":
            return <TestsTab />;
        case "Similarity":
            return <SimilarTab />;
    }
};
