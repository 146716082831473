import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import { IFormula } from "pages/formulas/formula-profile/types";
import React from "react";
import { TableRow } from "./TableRow";

interface IProps {
    toggleOpenModal: () => void;
}

export const FormulaInfoCard: React.FC<IProps> = ({ toggleOpenModal }) => {
    const queryClient = useQueryClient();

    const formula = queryClient.getQueryData<IFormula>([
        queryKeys.formula,
        353,
    ]);

    return (
        <div className="w-full md:w-6/12 lg:w-6/12 h-auto">
            <div className="flex flex-col mb-10">
                <div className="flex flex-row">
                    <div className="w-full">
                        <h3 className="text-base">
                            <span className="mr-1">
                                {formula?.ficode || "00001-02"}
                            </span>
                            {formula?.description ||
                                "FACE POWDERS-MOISTURIZER RESTAGE;TRANSLUCENT BEIGE-LOOSE POWDER-INT/EUR"}
                        </h3>
                    </div>
                </div>
                <div className="flex flex-row mt-2.5">
                    <div className="w-full">
                        <h3 className="text-sm text-primary-main-black"></h3>
                    </div>
                </div>
                <div className="flex flex-row my-4 text-sm">
                    <div className="w-3/12">
                        <p className="text-left text-primary-brand-main">
                            Formula Assesment
                        </p>
                    </div>
                    <div className="w-3/12">
                        <p className="text-left">Fragrance Evaluation</p>
                    </div>
                </div>

                <div className="flex flex-row my-4">
                    <div className="w-full">
                        <table className="table-fixed w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-md mb-2">
                            <tbody>
                                <TableRow
                                    text="Eye Irritation"
                                    paragraphText="Pass"
                                />
                                <TableRow
                                    text="Skin Irritation"
                                    paragraphText="Pass"
                                />
                                <TableRow
                                    text="Photoreactivity"
                                    paragraphText="Pass"
                                />
                                <TableRow
                                    text="Skin Sensitization (QRA) Probalistic Model"
                                    paragraphText="Pass"
                                />
                                <tr
                                    onClick={toggleOpenModal}
                                    className="cursor-pointer"
                                >
                                    <th className="text-sm text-primary-main-black">
                                        MOS Calculation
                                    </th>
                                    <td>
                                        <p className="float-right py-1 text-right bg-red-100 items-center text-red-400 leading-none rounded-full inline-flex text-sm px-4 my-2 mx-3">
                                            {/* {"mosAssesment.status"} */}
                                            Status
                                        </p>
                                    </td>
                                </tr>
                                <TableRow
                                    text="Critical Contaminant Evaluation"
                                    paragraphText="Pass"
                                />
                                <TableRow
                                    text="Environmental Score"
                                    paragraphText="Pass"
                                />
                                <TableRow
                                    text="Biodegredation Index"
                                    paragraphText="Pass"
                                />
                                <TableRow
                                    text="Acute LD50 Assessment"
                                    paragraphText="Pass"
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
