import React from "react";
import { TableRow } from ".";
import { Table } from "components/shared/table";
import { ISubIngredient } from "common/types";

interface IProps {
    subIngredients?: ISubIngredient[];
}

export const SubIngredientsTable: React.FC<IProps> = ({ subIngredients }) => {
    return (
        <Table
            withHash={true}
            headNames={["INCI Code", "INCI Name", "INCI function"]}
        >
            <>
                {subIngredients?.map((subIngredient) => (
                    <TableRow
                        subIngredient={subIngredient}
                        key={subIngredient.id}
                    />
                ))}
            </>
        </Table>
    );
};
