import { MinusIcon, PlusIcon } from "assets/icons";
import classNames from "classnames";
import { getLastModifier } from "common/utils";
import React, { useMemo } from "react";
import { Tooltip } from "react-tooltip";

interface IProps {
    isOpen: boolean;
    text: string;
    children: React.ReactNode;
    onOpen: () => void;
    detailsClassName?: string;
    summaryClassName?: string;
    editorClassName?: string;
    lastModifiedBy?: Record<string, Record<string, string | null>>;
    endpointId?: string;
}

export const Details: React.FC<IProps> = React.memo(
    ({
        isOpen,
        text,
        children,
        onOpen,
        detailsClassName = "",
        summaryClassName = "",
        editorClassName = "",
        lastModifiedBy,
        endpointId,
    }) => {
        const tooltipMessage = useMemo(() => {
            if (endpointId && lastModifiedBy?.[endpointId]) {
                return `Field changed by ${getLastModifier(
                    lastModifiedBy,
                    endpointId,
                )}`;
            }

            return null;
        }, [lastModifiedBy, endpointId]);

        return (
            <>
                <details
                    open={isOpen}
                    className={classNames(
                        "flex flex-col w-full cursor-pointer mb-3",
                        detailsClassName,
                    )}
                >
                    <summary
                        className={classNames(
                            "flex items-center w-full bg-white text-dark py-3 group",
                            summaryClassName,
                        )}
                        onClick={(e) => {
                            e.preventDefault();
                            onOpen();
                        }}
                    >
                        <span className="flex items-center justify-center">
                            {isOpen ? <MinusIcon /> : <PlusIcon />}
                        </span>
                        <b className="pl-2">{text}</b>
                        {endpointId && tooltipMessage && (
                            <span
                                className="flex items-center justify-center w-5 h-5 p-2 ml-2 rounded-full bg-blue-100 transition-all cursor-pointer"
                                id={`modified-by-${endpointId}`}
                            >
                                ?
                            </span>
                        )}
                    </summary>
                    {isOpen && (
                        <div className={editorClassName}>{children}</div>
                    )}
                </details>
                {tooltipMessage && (
                    <Tooltip
                        anchorSelect={`#modified-by-${endpointId}`}
                        variant="info"
                        place="top"
                        className="z-10 text-sm !bg-gray-700 !rounded-md p-2 !shadow-lg"
                    >
                        {tooltipMessage}
                    </Tooltip>
                )}
            </>
        );
    },
);
