import React, { FC } from "react";
import classNames from "classnames";

interface ITableCeilProps {
    text?: string | null;
    className?: string;
    children?: React.ReactNode;
    onClick?: () => void;
}

export const TableCeil: FC<ITableCeilProps> = ({
    text,
    className = "",
    children,
    onClick = () => {},
}) => (
    <td
        className={classNames(
            "p-1 md:p-2 lg:p-3 text-left text-xs md:text-base",
            className,
        )}
        onClick={onClick}
    >
        {text}
        {children}
    </td>
);
