import { useCallback, useEffect, useRef } from "react";

interface IProps {
    onClose?: () => void;
}

export const useModal = ({ onClose }: IProps) => {
    const modalRef = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (onClose) {
            onClose();
        }
    }, []);

    const openModal = useCallback(() => {
        if (modalRef.current) {
            modalRef.current.showModal();
        }
    }, []);

    const closeModal = useCallback(() => {
        if (modalRef.current) {
            if (onClose) {
                onClose();
            }
            modalRef.current.close();
        }
    }, [onClose]);

    return { modalRef, openModal, closeModal };
};
