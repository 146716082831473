import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import {
    subIngredientService,
    subIngredientsService,
    safetySummaryService,
} from "services";
import { useSubIngredientStore } from "../store";
import {
    DeparturePoint,
    ISubIngredientResponse,
    MoleculeDataType,
    ReportEndpointKey,
    UpdateSubIngredientRequest,
} from "../libs/types";
import { IOption, IToxicology } from "common/types";
import { mapDataFromOpera } from "../components/tabs/skinSafetyTab/libs/helpers/mapDataFromOpera";
import { predictionService } from "services/predictionService";

export const useUploadSubIngredient = (id: string) => {
    const queryKey = [queryKeys.subIngredients, id];

    return useQuery({
        queryKey,
        queryFn: () => subIngredientService.getSubIngredient({ id }),
    });
};

export const useUploadEnvironmentalClass = () => {
    return useQuery({
        queryKey: [queryKeys.subIngredientsEnvironmentalClass],
        queryFn: () => subIngredientsService.getEnvironmentalClassOptions(),
    });
};

export const useGetFunctionOptions = () => {
    return useQuery<IOption[]>({
        queryKey: [queryKeys.subIngredientsFunctionOptions],
        queryFn: () => subIngredientsService.getFunctionOptions(),
    });
};

export const useUpdateSubIngredient = () =>
    useMutation({
        mutationFn: (payload: {
            id: string;
            body: UpdateSubIngredientRequest;
        }) => subIngredientService.update(payload),
    });

export const useCalculateEnvEndpoint = () => {
    return useMutation({
        mutationFn: ({
            id,
            body,
        }: {
            id: string;
            body: Partial<IToxicology>;
        }) => subIngredientService.calculateEnvEndpoint({ id, body }),
    });
};

export const useAddSafetyEndpoint = () => {
    return useMutation({
        mutationFn: ({
            id,
            body,
        }: {
            id: string;
            body: { safetyEndpoint: ReportEndpointKey };
        }) => subIngredientService.addSafetyEndpoint({ id, body }),
    });
};

export const useGetSubIngredient = () => {
    const { key } = useSubIngredientStore();
    const queryClient = useQueryClient();

    return queryClient.getQueryData<ISubIngredientResponse>(key);
};

export const useFetchSubIngredient = () => {
    const { key } = useSubIngredientStore();
    const [_, id] = key;
    return useQuery<ISubIngredientResponse>({
        queryKey: key,
        queryFn: () =>
            subIngredientService.getSubIngredient({ id: id?.toString() }),
        refetchOnWindowFocus: false,
    });
};

export const useGetSubIngredientReports = ({ sicode }: { sicode: string }) => {
    return useQuery({
        queryKey: [queryKeys.subIngredientReports, sicode],
        queryFn: () => subIngredientService.getReports({ sicode }),
    });
};

export const useAddSubIngredientReport = () => {
    return useMutation({
        mutationFn: ({
            sicode,
            filename,
            body,
        }: {
            sicode: string;
            filename: string;
            body: FormData;
        }) =>
            subIngredientService.uploadReport({
                sicode,
                filename,
                body,
            }),
    });
};

export const useRemoveSubIngredientReport = () => {
    return useMutation({
        mutationFn: ({
            sicode,
            filename,
        }: {
            sicode: string;
            filename: string;
        }) =>
            subIngredientService.removeReport({
                sicode,
                filename,
            }),
    });
};

export const useRemoveSubIngredient = () =>
    useMutation({
        mutationFn: ({ id }: { id: string }) =>
            subIngredientService.removeSubIngredient({ id }),
    });

export const useCrammerClassOptions = () =>
    useQuery<IOption[]>({
        queryKey: [queryKeys.crammerClassOptions],
        queryFn: () => subIngredientsService.getCrammerClassOptions(),
    });

export const useGetSafetySummaryOptions = () =>
    useQuery<IOption[]>({
        queryKey: [queryKeys.safetySummaryOptions],
        queryFn: () => safetySummaryService.getSafetySummaryOptions(),
        select: (data) => [
            ...data,
            { label: "Add your own", value: "add_value" },
        ],
    });

export const useGetToxicReferenceOptions = () =>
    useQuery<IOption[]>({
        queryKey: [queryKeys.toxicReferenceOptions],
        queryFn: () => safetySummaryService.getToxReferenceOptions(),
        select: (data) => [
            ...data,
            { label: "Add your own", value: "add_value" },
        ],
    });

export const useLocalEffectRefOptions = () =>
    useQuery<IOption[]>({
        queryKey: [queryKeys.localEffectRefOptions],
        queryFn: () => safetySummaryService.getLocalEffectRefOptions(),
        select: (data) => [
            ...data,
            { label: "Add your own", value: "add_value" },
        ],
    });

export const useCreateDeparturePoint = () =>
    useMutation({
        mutationFn: (payload: Partial<DeparturePoint>) =>
            safetySummaryService.createDeparturePoint(payload),
    });

export const useCreateToxicologicalReferenceValues = () =>
    useMutation({
        mutationFn: (payload: Partial<DeparturePoint>) =>
            safetySummaryService.createToxicologicalReferenceValues(payload),
    });

export const useCreateLocalEffectValues = () =>
    useMutation({
        mutationFn: (payload: Partial<DeparturePoint>) =>
            safetySummaryService.createLocalEffectValues(payload),
    });

export const useOperaSkinSafetyData = () =>
    useMutation({
        mutationFn: async (smile: string) => {
            const data =
                await subIngredientService.getOperaSkinSafetyData(smile);
            return mapDataFromOpera(data);
        },
    });

export const useAnnPrediction = () =>
    useMutation({
        mutationFn: (data: MoleculeDataType) =>
            predictionService.postAnnPredict(data),
    });

export const useRegressionPrediction = () =>
    useMutation({
        mutationFn: (data: MoleculeDataType) =>
            predictionService.postRegressionPredict(data),
    });

export const useVoitingPrediction = () =>
    useMutation({
        mutationFn: (data: MoleculeDataType) =>
            predictionService.postVoitingPredict(data),
    });

export const useSkinDoctorPrediction = () =>
    useMutation({
        mutationFn: (smiles: string) =>
            predictionService.postSkinDoctorCP(smiles),
    });

export const useTG497Prediction = () =>
    useMutation({
        mutationFn: (data: MoleculeDataType) =>
            predictionService.postTG497Predict(data),
    });

export const useRifmBnPrediction = () =>
    useMutation({
        mutationFn: (data: MoleculeDataType) =>
            predictionService.postRifmBnPredict(data),
    });

export const useRfPrediction = () =>
    useMutation({
        mutationFn: ({
            data,
            selectedModel,
        }: {
            data: MoleculeDataType;
            selectedModel: number;
        }) => predictionService.postRfPredict(data, selectedModel),
    });

export const useRifmHnoelHloelPrediction = () =>
    useMutation({
        mutationFn: ({
            data,
            selectedModel,
        }: {
            data: MoleculeDataType;
            selectedModel: number;
        }) => predictionService.postRifmHnoelHloelPredict(data, selectedModel),
    });

export const useIts3Prediction = () =>
    useMutation({
        mutationFn: ({
            data,
            isMichaelAcceptor,
        }: {
            data: MoleculeDataType;
            isMichaelAcceptor: boolean;
        }) => predictionService.postIts3Predict(data, isMichaelAcceptor),
    });

export const useBDFPrediction = () =>
    useMutation({
        mutationFn: ({
            data,
            skinDoctorPredictedClass,
            modelType,
        }: {
            data: MoleculeDataType;
            skinDoctorPredictedClass: number | null;
            modelType: "bdfA" | "bdfB";
        }) =>
            predictionService.postBdfPredict(
                data,
                skinDoctorPredictedClass,
                modelType,
            ),
    });
