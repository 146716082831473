import React, { ChangeEvent, FC, memo, useCallback, useState } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { MoleculeDataModel } from "pages/subIngredient/subIngredientProfile/libs";
import { SectionHeader } from "../SectionHeader";
import { InputLabel } from "../InputLabel";
import { renderValueWithNonZeroDigits } from "../../libs";
import { InputBlock } from "../InputBlock";
import { RequiredDescription } from "../prediction/components/RequiredDescription";
import { FormikErrors } from "formik";
import { Switcher } from "../Switcher";

interface IProps {
    values: MoleculeDataModel;
    errors: FormikErrors<MoleculeDataModel>;
    onChange: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
    isMichaelAcceptor: boolean;
    toggleMichaelAcceptor: () => void;
}

const inputClassName =
    "w-full bg-gray-100 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200";

export const BayesianTab: FC<IProps> = memo(
    ({
        values,
        errors,
        onChange,
        isMichaelAcceptor,
        toggleMichaelAcceptor,
    }) => {
        const [isOpenSelect, setIsOpenSelect] = useState(false);

        const handleOpenSelect = useCallback(() => {
            setIsOpenSelect((state) => !state);
        }, []);

        const handleSelectChange = useCallback(
            (event: SelectChangeEvent<string>) => {
                const syntheticEvent = {
                    target: {
                        name: event.target.name,
                        value: event.target.value,
                    },
                } as ChangeEvent<HTMLInputElement | HTMLSelectElement>;
                onChange(syntheticEvent);
            },
            [onChange],
        );

        return (
            <div className="flex flex-col gap-[20px]">
                <section className="flex flex-col gap-[40px]">
                    <SectionHeader
                        className="mt-4"
                        title="Review molecular descriptors"
                        text="Values are calculated based on validated QSAR model. Please modify the values if missing or you have better estimates."
                    />
                    <div className="flex flex-col flex-nowrap gap-5">
                        <RequiredDescription />
                        <div className="flex flex-wrap gap-10">
                            <div className="flex flex-col">
                                <InputLabel
                                    text="logKow"
                                    tooltipText="Octanol/water partition coefficient calculated using OPERA QSAR model."
                                    errorText={errors.logKow}
                                    isRequired
                                >
                                    <input
                                        className="bg-gray-50 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200"
                                        type="text"
                                        name="logKow"
                                        onChange={onChange}
                                        value={values.logKow}
                                    />
                                </InputLabel>
                            </div>
                            <div className="flex flex-col">
                                <InputLabel
                                    text="Protein binding (%)"
                                    tooltipText="Percent of compound bound to plasma-proteins calculated using OPERA QSAR model."
                                    errorText={errors.proteinBinding}
                                    isRequired
                                >
                                    <input
                                        className="bg-gray-50 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200"
                                        type="text"
                                        name="proteinBinding"
                                        onChange={onChange}
                                        value={renderValueWithNonZeroDigits(
                                            values.proteinBinding,
                                            1,
                                        )}
                                    />
                                </InputLabel>
                            </div>
                            <div className="flex flex-col">
                                <InputLabel
                                    text="logD @ pH7"
                                    tooltipText="Octanol/water partition coefficient at pH 7 calculated using OPERA QSAR model."
                                    errorText={errors.logDpH7}
                                    isRequired
                                >
                                    <input
                                        type="text"
                                        className="bg-gray-50 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200"
                                        name="logDpH7"
                                        onChange={onChange}
                                        value={renderValueWithNonZeroDigits(
                                            values.logDpH7,
                                            2,
                                        )}
                                    />
                                </InputLabel>
                            </div>
                            <div className="flex flex-col">
                                <InputLabel
                                    text="Michael acceptor"
                                    tooltipText="If a compound is a Michael acceptor, a post-prediction correction is performed to accomodate for the anti-inflammatory effect of such chemicals."
                                >
                                    <div className="mt-2">
                                        <Switcher
                                            isChecked={isMichaelAcceptor}
                                            toggleIsChecked={
                                                toggleMichaelAcceptor
                                            }
                                        />
                                    </div>
                                </InputLabel>
                            </div>
                            <div className="flex flex-col">
                                <InputLabel
                                    text="Water solubility @ pH7 (M)"
                                    tooltipText="Aqueous solubility at pH 7 calculated using OPERA QSAR model."
                                    errorText={errors.wspH7Ml}
                                    isRequired
                                >
                                    <input
                                        className="bg-gray-50 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200"
                                        type="text"
                                        name="wspH7Ml"
                                        onChange={onChange}
                                        value={values.wspH7Ml}
                                    />
                                </InputLabel>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="flex flex-col gap-[40px]">
                    <SectionHeader
                        title="Enter experimental values"
                        text="Input data for assays associated with three key events in the AOP for Skin sensitisation."
                        text2="Recommended to improve prediction estimate."
                    />

                    <div className="flex flex-wrap gap-5">
                        <InputBlock
                            title="Covalent binding to skin proteins"
                            subtitle="DPRA"
                        >
                            <InputLabel
                                text="DPRACys (% depleted)"
                                tooltipText="Percent of cysteine peptide depletion in the DPRA assay."
                                errorText={errors.dpraAssayDPRACys}
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="dpraAssayDPRACys"
                                    onChange={onChange}
                                    value={values.dpraAssayDPRACys}
                                />
                            </InputLabel>
                            <InputLabel
                                text="DPRALys (% depleted)"
                                tooltipText="Percent of lysine peptide depletion in the DPRA assay."
                                errorText={errors.dpraAssayDPRALys}
                            >
                                <input
                                    className={inputClassName}
                                    type="text"
                                    name="dpraAssayDPRALys"
                                    onChange={onChange}
                                    value={values.dpraAssayDPRALys}
                                />
                            </InputLabel>
                        </InputBlock>

                        <InputBlock
                            title="Keratinocyte activation"
                            subtitle="KeratinoSens™"
                        >
                            <InputLabel
                                text="EC1.5 (µM)"
                                tooltipText="Concentration yielding 1.5-fold induction in Nrf2-dependent luciferase activity in the KeratinoSens™ assay. If the compound was inactive, use the highest tested concentration as an input. If the EC1.5 value is greater than the IC50 value, then the assay is treated as negative and 2000 uM should be used for EC1.5"
                                errorText={
                                    errors.keratinoSenseAssayJaworskaKEC15
                                }
                            >
                                <input
                                    className={inputClassName}
                                    type="text"
                                    name="keratinoSenseAssayJaworskaKEC15"
                                    onChange={onChange}
                                    value={
                                        values.keratinoSenseAssayJaworskaKEC15
                                    }
                                />
                            </InputLabel>
                            <InputLabel
                                text="EC3 (µM)"
                                tooltipText="Concentration yielding 3-fold induction in Nrf2-dependent luciferase activity in the KeratinoSens™ assay. If the compound was inactive, use the highest tested concentration as an input. If the EC3 value is greater than the IC50 value, then the assay is treated as negative and 2000 uM should be used for EC3."
                                errorText={
                                    errors.keratinoSenseAssayJaworskaKEC3
                                }
                            >
                                <input
                                    className={inputClassName}
                                    type="text"
                                    name="keratinoSenseAssayJaworskaKEC3"
                                    onChange={onChange}
                                    value={
                                        values.keratinoSenseAssayJaworskaKEC3
                                    }
                                />
                            </InputLabel>
                            <InputLabel
                                text="IC50 (µM)"
                                tooltipText="The half-maximal inhibitory concentration (IC50) in the KeratinoSens™ assay. If the compound was inactive, use the highest tested concentration as an input."
                                errorText={
                                    errors.keratinoSenseAssayJaworskaKEC3
                                }
                            >
                                <input
                                    className={inputClassName}
                                    type="text"
                                    name="keratinoSenseAssayJaworskaIC50"
                                    onChange={onChange}
                                    value={
                                        values.keratinoSenseAssayJaworskaIC50
                                    }
                                />
                            </InputLabel>
                        </InputBlock>

                        <InputBlock
                            title="Adverse outcome pathway"
                            subtitle="hCLAT"
                        >
                            <InputLabel
                                text="EC1.5 (µM)"
                                tooltipText="Concentration yielding 1.5-fold induction in Nrf2-dependent luciferase activity in the hCLAT assay. If the compound was inactive, use the highest tested concentration as an input. If the EC1.5 value is greater than the IC50 value, then the assay is treated as negative and 2000 uM should be used for EC1.5."
                                errorText={errors.hClatAssayEC150}
                            >
                                <input
                                    className={inputClassName}
                                    type="text"
                                    name="hClatAssayEC150"
                                    onChange={onChange}
                                    value={values.hClatAssayEC150}
                                />
                            </InputLabel>
                            <InputLabel
                                text="EC2 (µM)"
                                tooltipText="Concentration yielding 2-fold induction in Nrf2-dependent luciferase activity in the hCLAT assay. If the compound was inactive, use the highest tested concentration as an input. If the EC2 value is greater than the IC50 value, then the assay is treated as negative and 2000 uM should be used for EC2."
                                errorText={errors.hClatAssayEC200}
                            >
                                <input
                                    className={inputClassName}
                                    type="text"
                                    name="hClatAssayEC200"
                                    onChange={onChange}
                                    value={values.hClatAssayEC200}
                                />
                            </InputLabel>
                            <InputLabel
                                text="CV75 (µM)"
                                tooltipText="The concentration at which 75% of cells are viable in the hCLAT assay. If the compound was inactive, use the highest tested concentration as an input."
                                errorText={errors.hClatAssayCV75}
                            >
                                <input
                                    className={inputClassName}
                                    type="text"
                                    name="hClatAssayCV75"
                                    onChange={onChange}
                                    value={values.hClatAssayCV75}
                                />
                            </InputLabel>
                        </InputBlock>

                        <InputBlock title="In silico" subtitle="TIMES-SS">
                            <InputLabel
                                text="Network run parameters"
                                tooltipText="The number of times the Bayesian Network was run to account for uncertainties in input values. The user is able to run Bayesian Network only once for each set of values."
                            >
                                <Select
                                    open={isOpenSelect}
                                    onClick={handleOpenSelect}
                                    fullWidth
                                    onOpen={handleOpenSelect}
                                    value={values?.timesSSBayesianNetwork}
                                    onChange={handleSelectChange}
                                    name="timesSSBayesianNetwork"
                                    className="custom-select"
                                >
                                    <MenuItem value="1">
                                        Non-sensitiser
                                    </MenuItem>
                                    <MenuItem value="2">
                                        Weak or moderate
                                    </MenuItem>
                                    <MenuItem value="3">
                                        Strong or extreme
                                    </MenuItem>
                                </Select>
                            </InputLabel>
                        </InputBlock>
                    </div>
                </section>
            </div>
        );
    },
);
