// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jodit-status-bar .jodit-status-bar__item.jodit-status-bar__item-right,
.jodit-toolbar-button_about {
  display: none !important;
}

.jodit-status-bar:hover {
  border: none !important;
  background-color: inherit !important;
  box-shadow: none !important;
}

.jodit-status-bar {
  background-color: inherit !important;
  border: none !important;
}

.jodit-add-new-line {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/editor/styles.scss"],"names":[],"mappings":"AAAA;;EAEI,wBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,oCAAA;EACA,2BAAA;AACJ;;AAEA;EACI,oCAAA;EACA,uBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ","sourcesContent":[".jodit-status-bar .jodit-status-bar__item.jodit-status-bar__item-right,\n.jodit-toolbar-button_about {\n    display: none !important;\n}\n\n.jodit-status-bar:hover {\n    border: none !important;\n    background-color: inherit !important;\n    box-shadow: none !important;\n}\n\n.jodit-status-bar {\n    background-color: inherit !important;\n    border: none !important;\n}\n\n.jodit-add-new-line {\n    display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
