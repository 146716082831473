import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { IManualInput, IMosIngredientData } from "./types";
import { Form, ReportTemplate, Table } from "./components";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import BigNumber from "bignumber.js";
import { IPreparedSubIngredient } from "pages/formulas/formula-profile/types";
import {
    useGetFormula,
    useUpdateMos,
    useUpdateProductTypeProperties,
} from "pages/formulas/formula-profile/queries";
import { toast } from "react-toastify";
import { defaultToastOptions, toastTexts } from "common/constants";
import { useQueryClient } from "@tanstack/react-query";
import jsPDF from "jspdf";
import { ReportGeneration, Button, Title } from "components/shared";
import html2canvas from "html2canvas-pro";
import { mapReport } from "./utils";
import { useGetUserProfile } from "pages/profile/libs";
import { asBlob } from "html-docx-js-typescript";
import { saveAs } from "file-saver";
import { MosConstants } from "pages/formulas/formula-profile/enums";
import { useMosIngredientData } from "pages/formulas/formula-profile/hooks";
import { useFormik } from "formik";
import * as Yup from "yup";

const { DEFAULT_BODY_WEIGHT, DAILY_EXPOSURE_MULTIPLIER, DECIMALS } =
    MosConstants;

const updateMosSchema = Yup.object().shape({
    product_type: Yup.string(),
    regulatory_body: Yup.string(),
    grams_applied_per_day: Yup.number().typeError("Value must be a number"),
    skin_retention_factor: Yup.number().typeError("Value must be a number"),
    body_weight: Yup.number().typeError("Value must be a number"),
});

export const MosTab = () => {
    const queryClient = useQueryClient();
    const formula = useGetFormula();
    const { key, subIngredients, isOwner } = useFormulaProfileState();
    const { data: userInfo } = useGetUserProfile();

    const [mosSubIngredients, setMosSubIngredients] = useState<
        IPreparedSubIngredient[]
    >([]);
    const [manualInputs, setManualInputs] = useState<IManualInput[]>([]);
    const { mosIngredientData, setMosIngredientData } =
        useMosIngredientData(formula);

    const mappedReportData = useMemo(
        () => mapReport({ data: mosSubIngredients, formula, userInfo }),
        [mosSubIngredients, formula, userInfo],
    );

    useEffect(() => {
        if (!mosSubIngredients?.length && subIngredients) {
            setMosSubIngredients(subIngredients);
        }
    }, [subIngredients]);

    const calculateMos = useCallback(() => {
        const productType = mosIngredientData?.product_type?.value;
        if (!productType) return;

        const { body_weight, grams_applied_per_day, skin_retention_factor } =
            mosIngredientData;

        setMosSubIngredients((current) =>
            current.map((subIngredient, index) => {
                const dermalPenetration =
                    manualInputs?.[index]?.dermalPenetration ||
                    subIngredient.dermal_penetration;

                const noael =
                    manualInputs?.[index]?.noael || subIngredient.noael;

                const dailyExposure = new BigNumber(
                    grams_applied_per_day?.value || 0,
                )
                    .times(skin_retention_factor.value)
                    .times(dermalPenetration)
                    .div(100)
                    .times(subIngredient.rawSub.raw_weight_percent)
                    .div(100)
                    .times(DAILY_EXPOSURE_MULTIPLIER);
                const systematicDailyExposure = dailyExposure.div(
                    body_weight.value,
                );

                const mos = new BigNumber(noael)
                    .div(systematicDailyExposure)
                    .decimalPlaces(DECIMALS);

                return {
                    ...subIngredient,
                    skin_retention_factor: skin_retention_factor?.value || "",
                    daily_exposure: dailyExposure
                        ?.decimalPlaces(DECIMALS)
                        .isNaN()
                        ? ""
                        : dailyExposure?.decimalPlaces(DECIMALS)?.toString(),
                    systematic_daily_exposure: systematicDailyExposure
                        .decimalPlaces(DECIMALS)
                        .isNaN()
                        ? ""
                        : systematicDailyExposure
                              .decimalPlaces(DECIMALS)
                              .toString(),
                    MOS: mos.isNaN() ? "" : mos.toString(),
                };
            }),
        );
    }, [setMosSubIngredients, mosIngredientData, manualInputs]);

    useEffect(() => {
        calculateMos();
    }, [mosIngredientData, calculateMos]);

    const handleChangeValue = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { name, value } = e.target;
            setMosIngredientData((current) => ({
                ...current,
                [name]: { ...current[name as keyof IMosIngredientData], value },
            }));
        },
        [],
    );

    const { mutate: updateFormulaMos, isPending } = useUpdateMos();
    const { mutate: updateProductTypeProperties } =
        useUpdateProductTypeProperties();

    const formik = useFormik({
        initialValues: {
            product_type: mosIngredientData?.product_type?.value || "",
            regulatory_body: mosIngredientData?.regulatory_body?.value || "",
            grams_applied_per_day:
                mosIngredientData?.grams_applied_per_day?.value || "",
            skin_retention_factor:
                mosIngredientData?.skin_retention_factor?.value || "",
            body_weight: DEFAULT_BODY_WEIGHT,
        },
        validationSchema: updateMosSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            updateFormulaMos(
                {
                    id: formula?.id ?? "",
                    body: {
                        product_type: values?.product_type || null,
                        regulatory_body: values?.regulatory_body || null,
                        body_weight: mosIngredientData?.body_weight?.value,
                        mosSubIngredients,
                    },
                },
                {
                    onSuccess: (data) => {
                        toast.success(
                            `${toastTexts.success} Formula has been updated.`,
                            defaultToastOptions,
                        );

                        queryClient.setQueryData(key, data);
                    },
                    onError: () => {
                        toast.error(toastTexts.error, defaultToastOptions);
                    },
                },
            );

            if (
                !values?.grams_applied_per_day ||
                !values?.skin_retention_factor
            )
                return;

            updateProductTypeProperties(
                {
                    product_type: values.product_type,
                    regulatory_body: values.regulatory_body,
                    grams_applied_per_day: values.grams_applied_per_day,
                    skin_retention_factor: values.skin_retention_factor,
                },
                {
                    onSuccess: () => {
                        toast.success(
                            `${toastTexts.success} Product Type Properties has been updated.`,
                            defaultToastOptions,
                        );
                    },
                    onError: () => {
                        toast.error(toastTexts.error, defaultToastOptions);
                    },
                },
            );
        },
    });

    const { handleSubmit, values, errors } = formik;

    const handleManualInputChange = useCallback(
        (updatedInputs: IManualInput[]) => {
            setManualInputs(updatedInputs);
        },
        [],
    );

    const generatePDF = useCallback(() => {
        const html = document.querySelector("#report-id");
        if (!html) {
            throw new Error("Bad ID");
        }

        html2canvas(html as HTMLElement, { scale: 1.4, useCORS: true }).then(
            (canvas) => {
                const _pageWidth = 210;
                const _pageHeight = 297;
                const contentWidth = canvas.width;
                const contentHeight = canvas.height;
                const position = {
                    x: 10,
                    y: 10,
                    page: 0,
                    topMargin: 10,
                    remainHeight: contentHeight,
                };

                const pageHeight =
                    (contentWidth / (_pageWidth - position.x * 2)) *
                    _pageHeight;

                const imgWidth = _pageWidth - position.x * 2;
                const imgHeight =
                    ((_pageWidth - position.y * 2) / contentWidth) *
                    (contentHeight + position.y * 2);

                const pageData = canvas.toDataURL("image/jpeg", 1.0);
                const pdf = new jsPDF("p", "mm", "a4");

                if (position.remainHeight < pageHeight) {
                    pdf.addImage(
                        pageData,
                        "JPEG",
                        position.x,
                        position.y,
                        imgWidth,
                        imgHeight,
                    );
                    pdf.rect(
                        0,
                        _pageHeight - position.y,
                        _pageWidth,
                        position.y,
                        "F",
                    );
                } else {
                    while (position.remainHeight > 0) {
                        pdf.addImage(
                            pageData,
                            "JPEG",
                            position.x,
                            position.y,
                            imgWidth,
                            imgHeight,
                        );
                        pdf.setFillColor(255, 255, 255);
                        pdf.rect(0, 0, _pageWidth, position.topMargin, "F");
                        pdf.rect(
                            0,
                            _pageHeight - position.topMargin,
                            _pageWidth,
                            position.topMargin,
                            "F",
                        );

                        position.remainHeight -= pageHeight;
                        position.y -= _pageHeight - position.topMargin * 2;
                        position.page += 1;

                        if (position.remainHeight > 0) {
                            pdf.addPage();
                        }
                    }
                }

                pdf.save(`mos_report_${mappedReportData?.productId}.pdf`);
            },
        );
    }, []);

    const generateDOCX = useCallback(() => {
        const htmlElement = document.querySelector("#report-id");

        if (!htmlElement) {
            throw new Error("No HTML div element found");
        }

        const html = htmlElement.outerHTML;

        if (!html) {
            throw new Error("No HTML found to convert");
        }

        try {
            asBlob(html, {
                margins: { top: 100 },
                orientation: "portrait",
            }).then((blob) =>
                saveAs(
                    blob as Blob,
                    `mos_report_${mappedReportData?.productId}.doc`,
                ),
            );
        } catch (error) {
            console.error("Error generating DOCX:", error);
        }
    }, []);

    const handleReportGeneration = useCallback((format: "pdf" | "docx") => {
        if (format === "pdf") {
            generatePDF();
        } else if (format === "docx") {
            generateDOCX();
        }
    }, []);

    return (
        <>
            <ReportTemplate reportData={mappedReportData} />
            <div className="mx-10 my-3">
                <div className="flex justify-between items-center">
                    <Title text="MOS Calculation" />
                    <a
                        href="https://ec.europa.eu/health/scientific_committees/consumer_safety/docs/sccs_s_006.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                    >
                        Reference Document for MOS Calculation
                    </a>
                </div>
                <Form
                    values={values}
                    errors={errors}
                    onChange={handleChangeValue}
                />
                <ReportGeneration
                    className="my-2"
                    onGenerate={handleReportGeneration}
                />
                <Table
                    subIngredients={mosSubIngredients}
                    onManualInputChange={handleManualInputChange}
                />

                {(isOwner || true) && (
                    <Button
                        isLoading={isPending}
                        text="Save MOS"
                        onClick={handleSubmit}
                        className="mt-2"
                    />
                )}
            </div>
        </>
    );
};
