import React, { FC } from "react";
import { Oval } from "react-loader-spinner";
import classNames from "classnames";
import { Button } from "../button/Button";

interface IProps {
    text: string;
    onClick: () => void;
    isLoading?: boolean;
    disabled?: boolean;
    className?: string;
    loaderColor?: string;
    loaderSize?: number;
    smiles?: string;
}

export const ButtonWithLoader: FC<IProps> = ({
    text,
    onClick,
    isLoading = false,
    disabled = false,
    className = "",
    loaderColor = "#3B82F6",
    loaderSize = 20,
    smiles = "",
}) => (
    <div className="flex flex-col justify-center gap-5">
        <Button
            onClick={onClick}
            disabled={isLoading || disabled}
            className={classNames(
                "btn-filter shadow-md flex items-center justify-center gap-2 px-4",
                {
                    "bg-gray-300 cursor-not-allowed": disabled || isLoading,
                },
                className,
            )}
        >
            {isLoading ? (
                <>
                    <Oval
                        height={loaderSize}
                        width={loaderSize}
                        color={loaderColor}
                        secondaryColor="#BFDBFE"
                        ariaLabel="loading"
                        strokeWidth={4}
                    />
                    <span>Loading...</span>
                </>
            ) : (
                <span>{text}</span>
            )}
        </Button>
        {smiles && (
            <div className=" border border-[#bfdbfe] bg-gray-100 rounded-lg p-2 font-bold text-[#113261] my-4">
                <span>SMILES:</span>
                <span className=" font-semibold ml-1">{smiles}</span>
            </div>
        )}
    </div>
);
