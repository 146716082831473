import React, { FC, memo } from "react";
import {
    defaultErrorToastUpdate,
    // defaultErrorToastOptions,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    toastTexts,
} from "common/constants";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { InputWrapper } from "components/shared/inputWrapper";
import * as yup from "yup";
import { Button } from "components/shared/button";
import { useCreateProductType } from "pages/formulas/formula-profile/queries";
import { useQueryClient } from "@tanstack/react-query";
import { IError } from "common/types";
import { useKeycloak } from "@react-keycloak/web";

interface IProps {
    regulatoryBody: string;
    onClose: () => void;
    updateProductType: (name: string, productTypeId: string) => void;
}

const createSchema = yup.object().shape({
    name: yup.string().required("Product type is required"),
    skin_retention_factor: yup
        .number()
        .typeError("Skin retention factor must be a number"),
    grams_applied_per_day: yup
        .number()
        .typeError("Grams applied per day must be a number"),
});

export const AddProductTypeForm: FC<IProps> = memo(
    ({ onClose, updateProductType, regulatoryBody }) => {
        const queryClient = useQueryClient();
        const { keycloak } = useKeycloak();
        const { mutate: createProductType } = useCreateProductType();

        const formik = useFormik({
            initialValues: {
                name: "",
                skin_retention_factor: "",
                grams_applied_per_day: "",
            },
            validationSchema: createSchema,
            validateOnChange: true,
            validateOnMount: true,
            enableReinitialize: true,
            onSubmit: (values) => {
                const toastId = toast.loading(
                    toastTexts.loading,
                    defaultToastOptions,
                );

                createProductType(
                    {
                        ...values,
                        regulatoryBody,
                        created_by: keycloak?.tokenParsed?.email,
                    },
                    {
                        onSuccess: (data) => {
                            toast.update(toastId, {
                                ...defaultSuccessToastUpdate,
                                render: "Success! Product type has been added.",
                            });

                            updateProductType("product_type", `${data.id}`);
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.productTypes],
                            });

                            formik.resetForm();
                            onClose();
                        },
                        onError: (err: IError) => {
                            const message = err?.response?.data?.message || "";

                            if (message.includes("name")) {
                                formik.setFieldError("name", message);
                            } else {
                                toast.update(toastId, defaultErrorToastUpdate);
                            }
                        },
                    },
                );
            },
        });

        const { values, errors, touched, handleChange, resetForm } = formik;

        return (
            <form
                className="w-full flex flex-col gap-6"
                onSubmit={formik.handleSubmit}
            >
                <div className="text-red-500 text-sm">
                    <span>*</span> - required fields
                </div>
                <InputWrapper
                    isError={Boolean(errors.name && touched.name)}
                    error={errors.name}
                    label="Product type"
                    required
                >
                    <input
                        type="text"
                        name="name"
                        className="w-full input input-bordered"
                        value={values.name}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <InputWrapper
                    isError={Boolean(
                        errors.grams_applied_per_day &&
                            touched.grams_applied_per_day,
                    )}
                    error={errors.grams_applied_per_day}
                    label="Grams applied/day"
                >
                    <input
                        type="text"
                        name="grams_applied_per_day"
                        className="w-full input input-bordered"
                        value={values.grams_applied_per_day}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <InputWrapper
                    isError={Boolean(
                        errors.skin_retention_factor &&
                            touched.skin_retention_factor,
                    )}
                    error={errors.skin_retention_factor}
                    label="Skin retention factor"
                >
                    <input
                        type="text"
                        name="skin_retention_factor"
                        className="w-full input input-bordered"
                        value={values.skin_retention_factor}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <div className="flex items-center justify-end gap-2 mt-3">
                    <Button text="Clear" onClick={resetForm} />
                    <Button text="Create" type="submit" />
                </div>
            </form>
        );
    },
);
