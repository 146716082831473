import { IToxicology } from "common/types";

export const emptyToxicology: IToxicology = {
    overall_environmental_rank: "",
    p_raw_sub_rank: "",
    weighted_sub_rank_water: "",
    water_raw_sub_rank_srv: "",
    water_percent: "",
    total_for_weighting: "",
    water_t1_2: "",
    biowin3: "",
    biowin5: "",
    weighted_sub_rank_sediment: "",
    sed_raw_sub_rank: "",
    sed_incl: "",
    sediment_percent: "",
    sed_t1_2: "",
    weighted_sub_rank_soil: "",
    soil_raw_sub_rank: "",
    soil_incl: "",
    soil_percent: "",
    soil_t1_2: "",
    bioacc_srv: "1",
    mw: "",
    ready_bio_pred: "",
    bcf: "",
    toxicity_sub_rank_value_srv: "",
    aquatic_sub_rank_value_srv: "",
    sediment_sub_rank_value_srv: "",
    soil_sub_rank_value_srv: "",
    aquatic_pnec_in_mg_l: "",
    sediment_pnec_in_mg_l: "",
    soil_pnec_in_mg_l: "",
    critical_toxicity_value_ctv: "",
    endpoint_standard_factor_fes: "",
    species_variation_factor_fsv: "",
    mode_of_action_factor_fmoa: "",
    log_koc: "",
    standard_ecotoxicity_value_sev_acute: "",
    standard_ecotoxicity_value_sev_subchronic: "",
    standard_ecotoxicity_value_sev_chronic: "",
    number_of_fish_species_tested: "",
    number_of_daphnia_species_tested: "",
    number_of_algae_species_tested: "",
    mode_of_action_of_toxic_effect: "",
    fish_acute_toxicity_lc50_in_mg_l: "",
    daphnia_magna_acute_toxicity_ec50_in_mg_l: "",
    algae_acute_toxicity_ec50_in_mg_l: "",
    fish_subchronic_toxicity_in_mg_l: "",
    daphnia_magna_subchronic_toxicity_in_mg_l: "",
    algae_subchronic_toxicity_in_mg_l: "",
    fish_chronic_toxicity_in_mg_l: "",
    daphnia_magna_chronic_toxicity_in_mg_l: "",
    algae_chronic_toxicity_value_in_mg_l: "",
    pmt: "",
    eutrophication_raw_score: "",
    smog_raw_score: "no",
    mineral_raw_score: "",
    volatile_raw_score: "",
    gw_raw_score: "",
    other_issues: null,
    rare_potential_impact: "",
    fix_mw: null,
    fix_bp: null,
    fix_vp: null,
    fix_biowin3: null,
    fix_biowin5: null,
    fix_ready_bio_pred: null,
    fix_water_percent: null,
    fix_sediment_percent: null,
    fix_soil_percent: null,
    fix_sed_incl: null,
    fix_soil_incl: null,
    fix_water_t1_2: null,
    fix_sed_t1_2: null,
    fix_soil_t1_2: null,
    fix_water_raw_sub_rank_srv: null,
    fix_sed_raw_sub_rank: null,
    fix_soil_raw_sub_rank: null,
    fix_total_for_weighting: null,
    fix_weighted_sub_rank_water: null,
    fix_weighted_sub_rank_sediment: null,
    fix_weighted_sub_rank_soil: null,
    fix_p_raw_sub_rank: null,
    fix_bcf: null,
    fix_bioacc_srv: null,
    fix_log_koc: null,
    fix_water_solubility: null,
    fix_pmt: null,
    fix_p_count: null,
    fix_eutrophication_raw_score: null,
    fix_volatile_raw_score: null,
    fix_smog_raw_score: null,
    fix_mineral_raw_score: null,
    fix_gw_raw_score: null,
    fix_rare_potential_impact: null,
    fix_fish_acute_toxicity_lc50_in_mg_l: null,
    fix_daphnia_magna_acute_toxicity_ec50_in_mg_l: null,
    fix_tetrahymena_pyriformis_acute_tox_lc50_in_mg_l: null,
    fix_algae_acute_toxicity_ec50_in_mg_l: null,
    fix_worm_acute_toxicity_ec50_in_mg_l: null,
    fix_fish_subchronic_toxicity_in_mg_l: null,
    fix_daphnia_magna_subchronic_toxicity_in_mg_l: null,
    fix_tetrahymena_pyriformis_subchronic_tox_in_mg_l: null,
    fix_algae_subchronic_toxicity_in_mg_l: null,
    fix_worm_subchronic_toxicity_in_mg_l: null,
    fix_fish_chronic_toxicity_in_mg_l: null,
    fix_daphnia_magna_chronic_toxicity_in_mg_l: null,
    fix_algae_chronic_toxicity_value_in_mg_l: null,
    fix_tetrahymena_pyriformis_chronic_tox_in_mg_l: null,
    fix_worm_chronic_toxicity_in_mg_l: null,
    fix_standard_ecotoxicity_value_sev_acute: null,
    fix_standard_ecotoxicity_value_sev_subchronic: null,
    fix_standard_ecotoxicity_value_sev_chronic: null,
    fix_standard_ecotoxicity_value_sev_final: null,
    fix_critical_toxicity_value_ctv: null,
    fix_endpoint_standard_factor_fes: null,
    fix_number_of_fish_species_tested: null,
    fix_number_of_daphnia_species_tested: null,
    fix_number_of_algae_species_tested: null,
    fix_total_of_species_tested: null,
    fix_species_variation_factor_fsv: null,
    fix_mode_of_action_of_toxic_effect: null,
    fix_mode_of_action_factor_fmoa: null,
    fix_aquatic_pnec_in_mg_l: null,
    fix_sediment_pnec_needed_in_yes_or_no: null,
    fix_soil_pnec_needed_in_yes_or_no: null,
    fix_sediment_pnec_in_mg_l: null,
    fix_soil_pnec_in_mg_l: null,
    fix_aquatic_sub_rank_value_srv: null,
    fix_sediment_sub_rank_value_srv: null,
    fix_soil_sub_rank_value_srv: null,
    fix_toxicity_sub_rank_value_srv: null,
    fix_overall_environmental_rank: null,
};
