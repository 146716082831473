import { routerKeys } from "common/constants";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

export const Form = () => {
    const { id } = useParams();
    const [query, setQuery] = useState("");
    const [searchedFormulas, setSearchedFormulas] = useState([]);

    const handleUpdateQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    useEffect(() => {
        console.log(searchedFormulas);

        setSearchedFormulas([]);
    }, [query]);

    return (
        <div className="w-full md:w-6/12 lg:w-6/12 h-auto">
            <div className="flex flex-row mb-3">
                <div className="w-6/12 text-left">
                    <p>Suggested Formulas</p>
                </div>
                <div className="w-6/12 text-right">
                    <p className="text-secondary-success">
                        <Link
                            to={`${routerKeys.formulas}/${id}/${routerKeys.compare}`}
                        >
                            Start Comparison
                        </Link>
                    </p>
                </div>
            </div>
            <div className="flex flex-row">
                <form className="w-full">
                    <div className="mb-1">
                        <input
                            type="text"
                            name="fullName"
                            className="borderless-input w-full"
                            placeholder="Search formula to compare..."
                            value={query}
                            onChange={handleUpdateQuery}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
