import { type IOption } from "common/types";

export const getOptions = <T>(
    data: T[],
    mapFn: (item: T) => IOption | null,
): IOption[] =>
    data.reduce((acc, item) => {
        const mappedItem = mapFn(item);
        if (mappedItem) {
            acc.push(mappedItem);
        }
        return acc;
    }, [] as IOption[]);
