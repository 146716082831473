import { EditWithPapperIcon } from "assets/icons";
import { routerKeys } from "common/constants";
import React from "react";
import { Link } from "react-router-dom";
import { IRawIngredientItem } from "../../types";

interface IProps {
    rawIngredient: IRawIngredientItem;
}

export const TableRow: React.FC<IProps> = React.memo(({ rawIngredient }) => {
    return (
        <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
            <td className="border-grey-light border hover:bg-gray-100 p-3 cursor-pointer">
                <Link to={`${routerKeys.rawIngredient}/${rawIngredient.id}`}>
                    <EditWithPapperIcon />
                </Link>
            </td>
            <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                {rawIngredient.ricode}
            </td>
            <td className="border-grey-light border hover:bg-gray-100 p-3 text-red-400 hover:text-red-600 cursor-pointer">
                <Link to={`${routerKeys.rawIngredient}/${rawIngredient.id}`}>
                    {rawIngredient.description}
                </Link>
            </td>
            <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                {rawIngredient.status}
            </td>
        </tr>
    );
});
