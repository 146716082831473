export const convertMarker = (
    marker: boolean | string | null,
): boolean | null => {
    if (marker === null || marker === undefined) return null;

    if (typeof marker === "string")
        return marker ? marker === "positive" : null;

    return marker;
};

export const convertMarkerValueToShow = (
    marker?: boolean | string | null,
): string => {
    if (marker === null || marker === undefined) return "";

    return typeof marker === "string"
        ? marker
        : marker
          ? "positive"
          : "negative";
};
