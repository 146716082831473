import React, { ReactElement, useMemo } from "react";
import {
    ColumnDef,
    OnChangeFn,
    PaginationState,
    SortingState,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { ReactPagination } from "../react-pagination/ReactPagination";

interface IProps<T> {
    columns: ColumnDef<T>[];
    data: T[];
    pagination?: PaginationState;
    setPagination?: OnChangeFn<PaginationState>;
    totalCount?: number;
    isFilterVisible?: boolean;
    sorting?: SortingState;
    setSorting?: OnChangeFn<SortingState>;
    children?: React.ReactNode;
    className?: string;
}

export const DataTable = <T,>({
    columns,
    data,
    pagination,
    setPagination,
    totalCount = 0,
    isFilterVisible = false,
    sorting,
    setSorting,
    children,
    className = "",
}: IProps<T>): ReactElement => {
    const tableInstance = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: sorting ? getSortedRowModel() : undefined,
        getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount: pagination ? totalCount : undefined,
        state: {
            pagination,
            sorting,
        },
        manualPagination: Boolean(pagination),
        autoResetPageIndex: false,
    });

    const currentPage = useMemo(
        () =>
            pagination
                ? Math.min(
                      tableInstance.getState().pagination.pageIndex,
                      Math.max(tableInstance.getPageCount() - 1, 0),
                  )
                : 0,
        [pagination, tableInstance],
    );

    return (
        <div
            className={classNames("flex w-full flex-col md:flex-row", {
                "md:flex-row": isFilterVisible,
                "md:flex-col": !isFilterVisible,
                className,
            })}
        >
            {isFilterVisible && (
                <div className="w-full md:w-1/5 lg:w-1/5 py-2 mr-4">
                    {children}
                </div>
            )}
            <div
                className={classNames("w-full pt-4", {
                    "md:w-3/4 lg:w-3/4": isFilterVisible,
                    "md:w-full": !isFilterVisible,
                })}
            >
                <div className="w-full overflow-x-auto">
                    <table className="w-full rounded-lg overflow-hidden">
                        <thead className="light-gray-bg">
                            {tableInstance
                                .getHeaderGroups()
                                .map((headerGroup) => (
                                    <tr
                                        key={headerGroup.id}
                                        className="border-b light-gray-bg"
                                    >
                                        {headerGroup.headers.map((header) => (
                                            <th
                                                key={header.id}
                                                className="py-2 px-1 md:px-2 md:max-w-[300px] lg:max-w-full md:min-w-[50px] text-left text-xs sm:text-sm md:text-base border-b-2 border-gray-200 bg-white"
                                                colSpan={header.colSpan}
                                            >
                                                <div
                                                    className={classNames({
                                                        "cursor-pointer select-none":
                                                            header.column.getCanSort(),
                                                        "cursor-default":
                                                            !header.column.getCanSort(),
                                                    })}
                                                    onClick={
                                                        header.column.getCanSort()
                                                            ? header.column.getToggleSortingHandler()
                                                            : undefined
                                                    }
                                                >
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                    {(sorting &&
                                                        {
                                                            asc: " 🔼",
                                                            desc: " 🔽",
                                                        }[
                                                            header.column.getIsSorted() as string
                                                        ]) ??
                                                        null}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                        </thead>
                        <tbody>
                            {tableInstance.getRowModel().rows.map((row) => (
                                <tr key={row.id} className="border-b-2">
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            key={cell.id}
                                            className="py-2 px-1 md:px-2 md:max-w-[300px] lg:max-w-full md:min-w-[100px] text-xs sm:text-sm md:text-base overflow-hidden border-b-2 border-gray-200 bg-white"
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {pagination && totalCount > 0 && (
                    <div className="mt-10 flex flex-col sm:flex-row items-center gap-4 sm:gap-6">
                        <ReactPagination
                            pageCount={tableInstance.getPageCount()}
                            currentPage={currentPage}
                            onPageChange={tableInstance.setPageIndex}
                            breakLabel={".."}
                            previousLabel="prev"
                            nextLabel="next"
                        />

                        <select
                            className="text-sm sm:text-base"
                            value={tableInstance.getState().pagination.pageSize}
                            onChange={(e) => {
                                tableInstance.setPageSize(
                                    Number(e.target.value),
                                );
                            }}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                        <div className="text-xs sm:text-sm md:text-base">
                            {`Results: ${totalCount}`}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
