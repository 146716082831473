import { TableHeaderCeil } from "components/shared/tableHeaderCeil";
import React from "react";
import { TabTable } from "../../../TabTable";
import { useGetRawIngredient } from "pages/rawIngredient/rawIngredientProfile/queries";

export const TestsTable = () => {
    const rawIngredient = useGetRawIngredient();

    return (
        <TabTable>
            <thead className="">
                <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                    <TableHeaderCeil text="Test Code" />
                    <TableHeaderCeil text="Test Attributes" />
                    <TableHeaderCeil text="Test Results/Conclusion" />
                </tr>
            </thead>
            <tbody className="sm:flex-none">
                {rawIngredient?.rawIngredientsTests?.length ? (
                    <>
                        {rawIngredient?.rawIngredientsTests?.map((test) => (
                            <tr
                                className="wrap sm:table-row mb-2 sm:mb-0"
                                key={test.id}
                            >
                                <td className="p-3">{test.test.test_code}</td>
                                <td className="p-3">
                                    {test.attributes.map((attribute) => (
                                        <div key={attribute.id}>
                                            {`${attribute.name}: ${attribute.attributes.value}`}
                                        </div>
                                    ))}
                                </td>
                                <td className="p-3">
                                    {test.results.map((result) => (
                                        <div key={result.id}>
                                            {`${result.name}: ${result.results.value}`}
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td className="p-3">No Tests found</td>
                        <td />
                        <td />
                    </tr>
                )}
            </tbody>
        </TabTable>
    );
};
