import React, { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { Description } from "./Description";
import {
    RegressionTab,
    VoitingTab,
    BDFTab,
    RfTab,
    Rifm,
    AnnTab,
    Tg497,
    BayesianTab,
} from "./tabs";
import descriptions from "../libs/data/description.json";
import { type MoleculeDataModel } from "pages/subIngredient/subIngredientProfile/libs";
import { FormikErrors } from "formik";

interface IProps {
    tab: string;
    onChange: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
    isLoading?: boolean;
    skinDoctorCP?: string;
    setSkinDoctorCP?: Dispatch<SetStateAction<string>>;
    isMichaelAcceptor: boolean;
    toggleMichaelAcceptor: () => void;
    values: MoleculeDataModel;
    errors: FormikErrors<MoleculeDataModel>;
}

export const TabContent: FC<IProps> = ({
    tab,
    onChange,
    errors,
    skinDoctorCP,
    setSkinDoctorCP,
    isMichaelAcceptor,
    toggleMichaelAcceptor,
    values,
}) => {
    switch (tab) {
        case "OECD 2o3":
            return (
                <>
                    <Description
                        text={descriptions["2o3"]}
                        referenceText="[1]"
                        referenceId="ref-1"
                    />
                    <VoitingTab
                        values={values}
                        errors={errors}
                        onChange={onChange}
                    />
                </>
            );
        case "Multiple regression":
            return (
                <>
                    <Description
                        text={descriptions["Multiple regression"]}
                        referenceText="[2]"
                        referenceId="ref-2"
                    />
                    <RegressionTab
                        values={values}
                        errors={errors}
                        onChange={onChange}
                    />
                </>
            );
        case "Neural network":
            return (
                <>
                    <Description
                        text={descriptions["Neural network"]}
                        referenceText="[4-6]"
                        referenceId="ref-4"
                    />
                    <AnnTab
                        values={values}
                        errors={errors}
                        onChange={onChange}
                    />
                </>
            );
        case "OECD ITS":
            return (
                <>
                    <Description
                        text={descriptions["OECD ITS"]}
                        referenceText="[1]"
                        referenceId="ref-1"
                    />
                    <Tg497
                        values={values}
                        errors={errors}
                        onChange={onChange}
                    />
                </>
            );
        case "Random forest":
            return (
                <>
                    <Description
                        text={descriptions["Random forest"]}
                        referenceText="[3]"
                        referenceId="ref-3"
                    />
                    <RfTab
                        values={values}
                        errors={errors}
                        onChange={onChange}
                    />
                </>
            );
        case "RIFM":
            return (
                <>
                    <Description
                        text={descriptions.RIFM}
                        referenceText="[9]"
                        referenceId=""
                    />
                    <Rifm values={values} errors={errors} onChange={onChange} />
                </>
            );
        case "BDF":
            return (
                <>
                    <Description
                        text={descriptions.BDF}
                        referenceText="[8]"
                        referenceId=""
                    />
                    <BDFTab
                        values={values}
                        errors={errors}
                        onChange={onChange}
                        skinDoctorCP={skinDoctorCP}
                        setSkinDoctorCP={setSkinDoctorCP}
                    />
                </>
            );
        default:
            return (
                <>
                    <Description
                        text={descriptions["Bayesian network"]}
                        referenceText="[7]"
                        referenceId="ref-7"
                    />
                    <BayesianTab
                        values={values}
                        errors={errors}
                        onChange={onChange}
                        isMichaelAcceptor={isMichaelAcceptor}
                        toggleMichaelAcceptor={toggleMichaelAcceptor}
                    />
                </>
            );
    }
};
