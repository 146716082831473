interface TabEntry {
    key: string;
    status: string | null;
}

export const TabData: Record<string, TabEntry> = {
    OECD_2o3: { key: "OECD 2o3", status: null },
    OECD_ITS: { key: "OECD ITS", status: null },
    MULTIPLE_REGRESSION: { key: "Multiple regression", status: null },
    RANDOM_FOREST: { key: "Random forest", status: null },
    NEURAL_NETWORK: { key: "Neural network", status: null },
    BAYESIAN_NETWORK: { key: "Bayesian network", status: null },
    BDF: { key: "BDF", status: null },
    RIFM: { key: "RIFM", status: null },
} as const;

export const tabDataList = Object.values(TabData);

export const getVisibleTabs = () => {
    return tabDataList.filter((tab) => tab.status !== "hidden");
};
