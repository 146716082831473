export type INCISource = {
    [key: string]: { url: string; text: string; date?: string };
};

export const InciSource: INCISource = {
    cosIng: {
        url: "https://ec.europa.eu/growth/sectors/cosmetics/cosing_en",
        text: "CosIng Database",
    },
};
