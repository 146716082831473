import React, { ChangeEvent, FC, Dispatch, SetStateAction } from "react";
import { IManualInput } from "../types";

type IProps = {
    index: number;
    field: keyof IManualInput;
    value?: string;
    value_fix?: boolean;
    onChange: Dispatch<SetStateAction<IManualInput[]>>;
};

export const CheckboxInput: FC<IProps> = ({
    index,
    field,
    value = "",
    value_fix = false,
    onChange,
}) => {
    const handleCheckboxChange = () =>
        onChange((state) => {
            const newState = [...state];

            newState[index] = {
                ...state?.[index],
                [`${field}_fix`]: !value_fix,
            };

            return newState;
        });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        onChange((state) => {
            const newState = [...state];
            newState[index] = {
                ...state?.[index],
                [field]: newValue,
            };
            return newState;
        });
    };

    return (
        <div className="flex justify-center items-center">
            <label className="flex items-center">
                <input
                    type="checkbox"
                    checked={value_fix}
                    onChange={handleCheckboxChange}
                    className="checkbox checkbox-xs rounded-t-none"
                />
                <span className="mx-0 px-0 text-sm">fix</span>
            </label>
            {value_fix ? (
                <input
                    type="text"
                    value={value}
                    onChange={handleInputChange}
                    className="border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2 px-2 py-0.5 max-w-[100px]"
                />
            ) : (
                <span className="text-gray-700 ml-2">{value}</span>
            )}
        </div>
    );
};
