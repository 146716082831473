import React, { FC, memo } from "react";
import { DocumentTypeT } from "pages/rawIngredient/rawIngredientProfile/libs";

interface IProps {
    label: DocumentTypeT;
    onClick: (documentType: DocumentTypeT) => void;
}

export const DropdownItem: FC<IProps> = memo(({ label, onClick }) => (
    <li>
        <a onClick={() => onClick(label)} role="button" tabIndex={0}>
            {label}
        </a>
    </li>
));
