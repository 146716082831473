import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import instance from "./axios/instance";
import { IVendor } from "common/types";

class VendorService extends BaseService {
    constructor(
        public baseUrl = backendKeys.vendors,
        private fetchingService = instance,
    ) {
        super(baseUrl);
    }

    async create({ body }: { body: Pick<IVendor, "description"> }) {
        return this.handleRequest<IVendor>(
            this.fetchingService.post(this.baseUrl, body),
        );
    }

    async getAll() {
        return this.handleRequest<IVendor[]>(
            this.fetchingService.get(this.getFullUrl("/all")),
        );
    }
}
export const vendorService = new VendorService();
