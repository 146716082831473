import React, {
    FC,
    useCallback,
    useMemo,
    KeyboardEvent,
    useState,
    ChangeEvent,
} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { ActionButtons, EditableInput, TableCeil } from "components/shared";
import {
    defaultErrorToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { toast } from "react-toastify";
import { useRawIngredientStore } from "pages/rawIngredient/rawIngredientProfile/store";
import {
    useDeleteRelation,
    useUpdateRelation,
} from "pages/rawIngredient/rawIngredientProfile/queries";
import { IModifiedSubIngredient } from "pages/rawIngredient/types";

interface IProps {
    subIngredient: IModifiedSubIngredient;
    totalAmount: number;
}

export const SubIngredientRow: FC<IProps> = ({
    subIngredient,
    totalAmount,
}) => {
    const [isEditing, setIsEditing] = useState(false);

    const { id = "" } = useParams();
    const queryClient = useQueryClient();
    const { isOwner } = useRawIngredientStore();
    const navigate = useNavigate();

    const navigateToSubIngredientPage = useCallback(() => {
        navigate(`${routerKeys.subIngredient}/${subIngredient.id}`);
    }, [navigate, subIngredient.id]);

    const { mutate: updateRelation } = useUpdateRelation();
    const { mutate: deleteRelation } = useDeleteRelation();

    const maxValue = useMemo(() => {
        if (+totalAmount === 100) {
            return +subIngredient.rawIngredientsSubIngredients.weight_percent;
        } else {
            return (
                100 -
                +totalAmount +
                +subIngredient.rawIngredientsSubIngredients.weight_percent
            );
        }
    }, [
        totalAmount,
        subIngredient.rawIngredientsSubIngredients.weight_percent,
    ]);

    const validationSchema = Yup.object().shape({
        weight_percent: Yup.number()
            .max(
                maxValue,
                `Weight percent must be less than or equal to ${maxValue}`,
            )
            .min(0, "Weight percent must be greater than or equal to 0")
            .required("Weight percent is required"),
    });

    const formik = useFormik({
        initialValues: {
            weight_percent:
                subIngredient.rawIngredientsSubIngredients.weight_percent,
        },
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: ({ weight_percent }) => {
            updateRelation(
                {
                    id,
                    ingredientId: `${subIngredient.id}`,
                    relationType: "subIngredients",
                    body: { value: weight_percent },
                },
                {
                    onSuccess() {
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.rawIngredient, id],
                        });
                    },
                    onError() {
                        toast.error(toastTexts.error, defaultErrorToastOptions);
                    },
                },
            );
        },
    });

    const handleRemoveIngredient = useCallback(() => {
        deleteRelation(
            {
                id,
                ingredientId: `${subIngredient.id}`,
                relationType: "subIngredients",
            },
            {
                onSuccess() {
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.rawIngredient, id],
                    });
                },
                onError() {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [deleteRelation, id, queryClient, subIngredient.id]);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                event.preventDefault();
                formik.handleSubmit();
                if (formik.isValid) {
                    setIsEditing(false);
                }
            }
        },
        [formik],
    );

    const handleSubmit = useCallback(() => {
        formik.handleSubmit();
        if (formik.isValid) {
            setIsEditing(false);
        }
    }, [formik]);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            const formattedValue = value.replace(",", ".");
            formik.setFieldValue("weight_percent", formattedValue);
        },
        [formik],
    );

    return (
        <>
            <tr className="relative">
                <TableCeil
                    text={subIngredient.sicode}
                    className="sticky left-0 z-10 w-1/6 sm:w-[200px] lg:w-[250px]"
                />
                <TableCeil
                    text={subIngredient.name || ""}
                    onClick={navigateToSubIngredientPage}
                    className="cursor-pointer w-2/6 sm:w-[150px] lg:w-[250px]"
                />
                <TableCeil className="w-1/6">
                    <EditableInput
                        name="weight_percent"
                        value={formik.values.weight_percent}
                        isEditing={isEditing}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        className="text-center"
                        inputClassName="text-center"
                        displayClassName="font-semibold text-gray-700"
                        autoFocus={isEditing}
                    />
                    {formik.errors.weight_percent &&
                        formik.touched.weight_percent && (
                            <div className="mt-1 w-full text-[#dc2626] text-sm">
                                <span>{formik.errors.weight_percent}</span>
                            </div>
                        )}
                </TableCeil>

                <TableCeil
                    className="whitespace-nowrap hidden sm:table-cell w-1/6"
                    text={subIngredient.cas_number}
                />
                {(isOwner || true) && (
                    <TableCeil className="w-1/6">
                        <ActionButtons
                            onSubmit={handleSubmit}
                            onRemove={handleRemoveIngredient}
                            isEditing={isEditing}
                            setIsEditing={
                                formik.isValid ? setIsEditing : undefined
                            }
                        />
                    </TableCeil>
                )}
            </tr>
        </>
    );
};
