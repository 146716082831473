import classNames from "classnames";
import React, { memo } from "react";

interface IProps {
    value?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    inputClassName?: string;
    name: string;
    isDisabled?: boolean;
    type?: string;
    id?: string;
}

export const TableInputCeil: React.FC<IProps> = memo(
    ({
        value = "",
        onChange,
        className = "",
        inputClassName = "",
        name,
        isDisabled = false,
        type = "text",
        id,
    }) => (
        <div className={classNames("flex p-3", className)}>
            <input
                id={id}
                type={type}
                className={classNames(
                    "w-full input input-bordered",
                    inputClassName,
                )}
                name={name}
                value={value}
                onChange={onChange}
                disabled={isDisabled}
            />
        </div>
    ),
);
