import { IToxicology } from "common/types";
import React, { ChangeEvent, FC } from "react";
import { LCAIndicatorsRow } from "../forms";
import { TableTitleCeil } from "pages/subIngredient/subIngredientProfile/components";
import { TableRadioButtonsCeil } from "pages/subIngredient/subIngredientProfile/components/TableRadiobuttonsCeil";

interface IProps {
    toxicology: IToxicology;
    handleUpdate: (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
    isOwner?: boolean;
}

export const RightTable: FC<IProps> = ({
    toxicology,
    handleUpdate,
    isOwner = true,
}) => {
    return (
        <div className="md:w-1/2 lg:w-1/2 md:ml-5">
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg mb-2">
                <tbody>
                    <tr>
                        <TableTitleCeil text="Eutrophication" />
                        <TableRadioButtonsCeil
                            name="eutrophication_raw_score"
                            onChange={handleUpdate}
                            checkedValue={toxicology?.eutrophication_raw_score}
                            isDisabled={!isOwner}
                        />
                    </tr>
                    <tr>
                        <TableTitleCeil text="Smog" />
                        <TableRadioButtonsCeil
                            name="smog_raw_score"
                            onChange={handleUpdate}
                            checkedValue={toxicology?.smog_raw_score}
                            isDisabled={!isOwner}
                        />
                    </tr>
                    <tr>
                        <TableTitleCeil text="Mineral depletion" />
                        <TableRadioButtonsCeil
                            name="mineral_raw_score"
                            onChange={handleUpdate}
                            checkedValue={toxicology?.mineral_raw_score}
                            isDisabled={!isOwner}
                        />
                    </tr>
                    <tr>
                        <TableTitleCeil text="Global warming" />
                        <TableRadioButtonsCeil
                            name="gw_raw_score"
                            onChange={handleUpdate}
                            checkedValue={toxicology?.gw_raw_score}
                            isDisabled={!isOwner}
                        />
                    </tr>
                    <tr>
                        <TableTitleCeil text="Other Issue(s)" />
                        <TableRadioButtonsCeil
                            name="other_issues"
                            onChange={handleUpdate}
                            checkedValue={toxicology?.other_issues}
                            isDisabled={!isOwner}
                        />
                    </tr>
                    <LCAIndicatorsRow
                        data={toxicology}
                        onChange={handleUpdate}
                    />
                </tbody>
            </table>
        </div>
    );
};
