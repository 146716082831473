export const rinseOptions = [
    {
        label: "Rinse Off",
        value: "rinseoff",
    },
    {
        label: "Leave On",
        value: "leaveon",
    },
];
