import { Dispatch, SetStateAction, useCallback } from "react";
import { IFilter } from "common/types";

interface IProps {
    setFilters: Dispatch<SetStateAction<IFilter>>;
    setIsFilterVisible: Dispatch<SetStateAction<boolean>>;
}

export const useFilter = ({ setFilters, setIsFilterVisible }: IProps) => {
    const handleUpdateFilters = useCallback(
        (
            key: string,
            { value, label, filterValue }: Partial<IFilter[keyof IFilter]>,
        ) =>
            setFilters((currentFilters) => ({
                ...currentFilters,
                [key]: {
                    label: label ?? "",
                    value: value ?? "",
                    filterValue: filterValue ?? "",
                },
            })),
        [setFilters],
    );

    const handleUpdateSearch = useCallback(
        (query: string) => {
            setFilters((currentFilters) => ({
                ...currentFilters,
                query: {
                    value: query,
                    label: "Search",
                    filterValue: query,
                },
            }));
        },
        [setFilters],
    );

    const handleFilterVisible = useCallback(() => {
        setIsFilterVisible((prev) => !prev);
    }, [setIsFilterVisible]);

    const handleRemoveFilter = useCallback(
        (key: string) => {
            setFilters((currentFilters) => ({
                ...currentFilters,
                [key]: {
                    ...currentFilters[key],
                    value: "",
                    label: "",
                },
            }));
        },
        [setFilters],
    );

    return {
        handleUpdateFilters,
        handleUpdateSearch,
        handleFilterVisible,
        handleRemoveFilter,
    };
};
