import React, { ChangeEvent, FC } from "react";

interface IProps {
    name: string;
    label: string;
    value: string;
    editMode: boolean;
    errorMessage?: string;
    isError?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const InfoInputField: FC<IProps> = ({
    name,
    label,
    value,
    editMode,
    errorMessage = "",
    isError = false,
    onChange,
}) => {
    return (
        <div className="flex flex-col mb-4 mr-4 lg:mr-10 min-w-[350px]">
            <label className="text-md font-semibold mb-1">{label}</label>
            {editMode || isError ? (
                <>
                    <input
                        name={name}
                        type="text"
                        value={value}
                        onChange={onChange}
                        className={`border rounded p-2 text-md w-full ${
                            isError ? "border-red-500" : "border-gray-300"
                        }`}
                    />
                    {isError && (
                        <div className="text-red-500 text-sm mt-1">
                            {errorMessage}
                        </div>
                    )}
                </>
            ) : (
                <div className="text-md">{value || "-"}</div>
            )}
        </div>
    );
};
