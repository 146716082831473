import React, { FC } from "react";
import { type PredictionStatuses } from "pages/subIngredient/subIngredientProfile/libs";
import { MajorityVotingPrediction } from "./MajorityVotingPrediction";
import { CommonPredictionComponent } from "./common-prediction";
import { RegressionPrediction } from "./RegressionPrediction";
import { PredictionComponent } from "./prediction-component/PredictionComponent";

interface IProps {
    predictions?: PredictionStatuses;
}

export const PredictionStatus: FC<IProps> = ({ predictions }) => (
    <div className="space-y-2">
        <MajorityVotingPrediction
            status={predictions?.voitingPredict?.status}
            data={predictions?.voitingPredict?.data}
        />
        <CommonPredictionComponent
            title="OECD ITS"
            potency={predictions?.tg497Predict?.data?.itsPotency}
            status={predictions?.tg497Predict?.status}
        />
        <RegressionPrediction
            prediction={predictions?.regressionPredict}
            status={predictions?.regressionPredict?.status}
        />
        <CommonPredictionComponent
            title="Random forest (GHS)"
            potency={predictions?.rFPredictGHS?.chemicalPotency}
            status={predictions?.rFPredictGHS?.status}
        />
        <CommonPredictionComponent
            title="Random forest (ECETOC)"
            potency={predictions?.rFPredictECETOC?.chemicalPotency}
            status={predictions?.rFPredictECETOC?.status}
        />
        <CommonPredictionComponent
            title="Random forest (Binary)"
            potency={predictions?.["rFPredictP&N"]?.chemicalPotency}
            status={predictions?.["rFPredictP&N"]?.status}
        />
        <CommonPredictionComponent
            title="RIFM BN"
            potency={predictions?.rifmBnPredict?.data?.humanPotencyClass}
            status={predictions?.rifmBnPredict?.status}
        />
        <CommonPredictionComponent
            title="Neural network"
            potency={predictions?.annPredict?.data?.chemicalPotency}
            status={predictions?.annPredict?.status}
        />
        <PredictionComponent
            data={predictions?.its3Predict?.data}
            status={predictions?.its3Predict?.status}
            predictedClass={predictions?.its3Predict?.predictedClass}
        />
        <CommonPredictionComponent
            title="SkinDoctor CP"
            potency={predictions?.skinDoctorPredict?.sensitiserPotency}
            status={predictions?.skinDoctorPredict?.status}
        />
        <CommonPredictionComponent
            title="BDF A"
            potency={predictions?.bdfAPredict?.data?.sensitiserClass}
            status={predictions?.bdfAPredict?.status}
            confidenceValue={predictions?.bdfAPredict?.data?.maxBayesFactor}
        />
        <CommonPredictionComponent
            title="BDF B"
            potency={predictions?.bdfBPredict?.data?.sensitiserClass}
            status={predictions?.bdfBPredict?.status}
            confidenceValue={predictions?.bdfBPredict?.data?.maxBayesFactor}
        />
    </div>
);
