import * as yup from "yup";

export const createSubIngredientSchema = yup.object({
    sicode: yup.string().required("Field is required"),
    status: yup.string(),
    cas_number: yup.string(),
    description: yup.string(),
    name: yup.string(),
    noael: yup.string(),
    noael_inh: yup.string(),
    nesil: yup.string(),
    adi: yup.string(),
    rfd: yup.string(),
    ttc: yup.string(),
    class: yup.string(),
    dermal_penetration: yup.string(),
    reference: yup.string(),
    comments: yup.string(),
    function: yup.string(),
    ci_numbers: yup.string(),
    cir_status: yup.string(),
    cir_conclusion: yup.string(),
    cir_citation: yup.string(),
    cir_reviewed: yup.string(),
    cir_ro: yup.string(),
    cir_lo: yup.string(),
    iecic_ro: yup.string(),
    iecic_lo: yup.string(),
    uv_filter: yup.string(),
    preservatives: yup.string(),
    freesalereportfunction: yup.string(),
    food_use: yup.string(),
    colorant: yup.string(),
    unique_smiles: yup.string(),
    cramer_class: yup.string(),
    environmental_class: yup.string(),
    gluten: yup.string(),
    skin_sensitizer: yup.string(),
    restricted_list_scope: yup.string(),
    restricted_level: yup.string(),
    avon_tox_reviewed: yup.string(),
});
