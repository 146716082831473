import BaseService from "./baseService";
import instance from "./axios/instance";
import axios from "axios";
import {
    type AnnaPredictionResponse,
    type RegressionPredictionResponse,
    type Its3PredictionResponse,
    type MoleculeDataType,
    type VoitingPredictionResponse,
    type TG497PredictionResponse,
    type RfPredictionResponse,
    type SkinDoctorResponse,
} from "pages/subIngredient/subIngredientProfile";
import { modifyValueToMax } from "common/utils";
import { convertDpraFromPercentDepletedToRemaining } from "pages/subIngredient/subIngredientProfile/components/tabs/skinSafetyTab/libs";

class PredictionService extends BaseService {
    private readonly REGRESSION_PREDICT_URL = "regression-predict";
    private readonly VOITING_PREDICT_URL = "majority-voting-predict";
    private readonly ANN_PREDICT_URL = "ann-predict";
    private readonly TG497_PREDICT_URL = "tg497-predict";
    private readonly ITS3_PREDICT_URL = "its3-predict";
    private readonly BDF_PREDICT_URL = "bdf-predict";
    private readonly RF_PREDICT_URL = "rf-predict";
    private readonly RIFM_BN_PREDICT_URL = "rifm-bn-predict";
    private readonly SCIN_DOCTOR_CP_PREDICT_URL = "skin-doctor-cp-predict";
    private token: string | null = null;

    constructor(
        public baseUrl = "https://dev.saferskin.saferworldbydesign.com/api/",
        private fetchingService = axios,
    ) {
        super(baseUrl);

        this.fetchingService.interceptors.request.use(
            async (config) => {
                if (!this.token) {
                    try {
                        this.token = (
                            await instance.get("/auth/token")
                        ).data?.token;
                    } catch (error) {
                        console.error("Failed to retrieve token:", error);
                    }
                }
                if (this.token) {
                    config.headers.Authorization = `Bearer ${this.token}`;
                }
                return config;
            },
            (error) => Promise.reject(error),
        );
    }

    buildUrl(url: string) {
        return this.baseUrl + url;
    }

    formatNumber(num: string | null): number | null {
        return num ? parseFloat(num) : null;
    }

    async postRegressionPredict(
        body: MoleculeDataType,
    ): Promise<RegressionPredictionResponse> {
        const {
            kmaxMultipleRegression: cor1C420AssayKMax,
            keratinoSenseAssayJaworskaIC50,
            keratinoSenseAssayJaworskaKEC15,
            smiles,
            vpMultipleRegression: vapourPressure,
        } = body;

        return this.handleRequest(
            this.fetchingService.post(
                this.buildUrl(this.REGRESSION_PREDICT_URL),
                {
                    cor1C420AssayKMax: this.formatNumber(cor1C420AssayKMax),
                    keratinoSenseAssayJaworskaIC50: this.formatNumber(
                        keratinoSenseAssayJaworskaIC50,
                    ),
                    keratinoSenseAssayJaworskaKEC15: this.formatNumber(
                        keratinoSenseAssayJaworskaKEC15,
                    ),
                    smiles,
                    vapourPressure: this.formatNumber(vapourPressure),
                },
            ),
        );
    }

    async postVoitingPredict(
        body: MoleculeDataType,
    ): Promise<VoitingPredictionResponse> {
        const {
            dpraAssayDPRACys,
            dpraAssayDPRALys,
            hClatAssayCD54Marker,
            hClatAssayCD86Marker,
            keratinoSenseAssayJaworskaKEC15,
            smiles,
        } = body;

        return this.handleRequest(
            this.fetchingService.post(this.buildUrl(this.VOITING_PREDICT_URL), {
                dpraAssayDPRACys: this.formatNumber(dpraAssayDPRACys),
                dpraAssayDPRALys: this.formatNumber(dpraAssayDPRALys),
                hClatAssayCD54Marker,
                hClatAssayCD86Marker,
                keratinoSenseAssayJaworskaKEC15: this.formatNumber(
                    keratinoSenseAssayJaworskaKEC15,
                ),
                smiles,
            }),
        );
    }

    async postAnnPredict(
        body: MoleculeDataType,
    ): Promise<AnnaPredictionResponse> {
        const {
            dpraAssayDPRACys,
            dpraAssayDPRALys,
            hClatAssayCV75,
            hClatAssayEC150,
            hClatAssayEC200,
            modifiedhClatAssayCV75,
            modifiedhClatAssayEC150,
            modifiedhClatAssayEC200,
            keratinoSenseAssayJaworskaKEC15,
            unclearImax: keratinoSenseAssayKSImax,
            toxtree: toxtreePredictedClass,
            timesSSNeuralNetwork,
            smiles,
        } = body;

        const isModifyDataForAnn =
            toxtreePredictedClass || timesSSNeuralNetwork;

        return this.handleRequest(
            this.fetchingService.post(this.buildUrl(this.ANN_PREDICT_URL), {
                dpraAssayDPRACys: this.formatNumber(dpraAssayDPRACys),
                dpraAssayDPRALys: this.formatNumber(dpraAssayDPRALys),
                hClatAssayCV75: isModifyDataForAnn
                    ? modifyValueToMax(modifiedhClatAssayCV75, 100000)
                    : modifyValueToMax(hClatAssayCV75, 10000),
                hClatAssayEC150: isModifyDataForAnn
                    ? modifyValueToMax(modifiedhClatAssayEC150, 100000)
                    : modifyValueToMax(hClatAssayEC150, 10000),
                hClatAssayEC200: isModifyDataForAnn
                    ? modifyValueToMax(modifiedhClatAssayEC200, 100000)
                    : modifyValueToMax(hClatAssayEC200, 10000),
                keratinoSenseAssayKEC15: modifyValueToMax(
                    keratinoSenseAssayJaworskaKEC15,
                    3000,
                ),
                keratinoSenseAssayKSImax: modifyValueToMax(
                    keratinoSenseAssayKSImax,
                    10000,
                ),
                toxtreePredictedClass: this.formatNumber(toxtreePredictedClass),
                timesSSNeuralNetwork: this.formatNumber(timesSSNeuralNetwork),
                smiles,
            }),
        );
    }

    async postSkinDoctorCP(smiles: string): Promise<SkinDoctorResponse> {
        return this.handleRequest(
            this.fetchingService.post(
                this.buildUrl(this.SCIN_DOCTOR_CP_PREDICT_URL),
                { smiles, errorSignificance: 0.2 },
            ),
        );
    }

    async postTG497Predict(
        body: MoleculeDataType,
    ): Promise<TG497PredictionResponse> {
        const {
            dpraAssayDPRACys,
            dpraAssayDPRALys,
            hClatAssayEC150,
            hClatAssayEC200,
            derekOecdIts,
            smiles,
        } = body;

        return this.handleRequest(
            this.fetchingService.post(this.buildUrl(this.TG497_PREDICT_URL), {
                dpraAssayDPRACys: this.formatNumber(dpraAssayDPRACys),
                dpraAssayDPRALys: this.formatNumber(dpraAssayDPRALys),
                hClatAssayEC150: modifyValueToMax(hClatAssayEC150, 10000),
                hClatAssayEC200: modifyValueToMax(hClatAssayEC200, 10000),
                derekOECD: this.formatNumber(derekOecdIts),
                smiles,
            }),
        );
    }

    async postIts3Predict(
        body: MoleculeDataType,
        isMichaelsAcceptor?: boolean,
    ): Promise<Its3PredictionResponse> {
        const {
            dpraAssayDPRACys,
            dpraAssayDPRALys,
            hClatAssayCV75,
            hClatAssayEC150,
            hClatAssayEC200,
            keratinoSenseAssayJaworskaIC50,
            keratinoSenseAssayJaworskaKEC3,
            keratinoSenseAssayJaworskaKEC15,
            logDpH7,
            logKow,
            molecularWeight,
            priorProbabilityClass1NonSensitizer = null,
            priorProbabilityClass2WeakSensitizer = null,
            priorProbabilityClass3ModerateSensitizer = null,
            priorProbabilityClass4StrongSensitizer = null,
            proteinBinding,
            smiles,
            timesSSBayesianNetwork,
            wspH7Ml,
        } = body;

        return this.handleRequest(
            this.fetchingService.post(this.buildUrl(this.ITS3_PREDICT_URL), {
                dpraAssayDPRACys: this.formatNumber(dpraAssayDPRACys),
                dpraAssayDPRALys: this.formatNumber(dpraAssayDPRALys),
                hClatAssayCV75: modifyValueToMax(hClatAssayCV75, 10000),
                hClatAssayEC150: modifyValueToMax(hClatAssayEC150, 10000),
                hClatAssayEC200: modifyValueToMax(hClatAssayEC200, 10000),
                isMichaelsAcceptor,
                keratinoSenseAssayJaworskaIC50: this.formatNumber(
                    keratinoSenseAssayJaworskaIC50,
                ),
                keratinoSenseAssayJaworskaKEC3: this.formatNumber(
                    keratinoSenseAssayJaworskaKEC3,
                ),
                keratinoSenseAssayJaworskaKEC15: this.formatNumber(
                    keratinoSenseAssayJaworskaKEC15,
                ),
                logDpH7: this.formatNumber(logDpH7),
                logKow: this.formatNumber(logKow),
                molecularWeight: this.formatNumber(molecularWeight),
                priorProbabilityClass1NonSensitizer,
                priorProbabilityClass2WeakSensitizer,
                priorProbabilityClass3ModerateSensitizer,
                priorProbabilityClass4StrongSensitizer,
                proteinBinding: this.formatNumber(proteinBinding),
                smiles,
                timesSSBayesianNetwork: this.formatNumber(
                    timesSSBayesianNetwork,
                ),
                wsph7Ml: this.formatNumber(wspH7Ml),
            }),
        );
    }

    async postBdfPredict(
        body: MoleculeDataType,
        skinDoctorPredictedClass: number | null,
        modelType: "bdfA" | "bdfB",
    ): Promise<{ result: string }> {
        const {
            smiles,
            dpraAssayDPRACys,
            dpraAssayDPRALys,
            hClatAssayCV75,
            hClatAssayEC150,
            hClatAssayEC200,
            keratinoSenseAssayJaworskaIC50,
            keratinoSenseAssayJaworskaKEC3,
            keratinoSenseAssayJaworskaKEC15,
            kdpraBDFlogKmax: cor1C420AssayKMax,
            logDpH7,
            fionBDF: fion = null,
            proteinBinding,
            wspH7Ml,
            kdpraBDF: kDPRA,
            molecularWeight,
            ...rest
        } = body;

        const modifiedDpraAssayDPRACys =
            convertDpraFromPercentDepletedToRemaining(dpraAssayDPRACys);
        const modifiedDpraAssayDPRALys =
            convertDpraFromPercentDepletedToRemaining(dpraAssayDPRALys);

        let derek = null;

        if (modelType === "bdfB" && rest.derekBDFb) {
            derek =
                Number(rest.derekBDFb) > 4
                    ? 4
                    : this.formatNumber(rest.derekBDFb);
        }

        return this.handleRequest(
            this.fetchingService.post(this.buildUrl(this.BDF_PREDICT_URL), {
                dpraAssayDPRACys: modifiedDpraAssayDPRACys,
                dpraAssayDPRALys: modifiedDpraAssayDPRALys,
                hClatAssayCV75: this.formatNumber(hClatAssayCV75),
                hClatAssayEC150: this.formatNumber(hClatAssayEC150),
                hClatAssayEC200: this.formatNumber(hClatAssayEC200),
                keratinoSenseAssayJaworskaIC50: this.formatNumber(
                    keratinoSenseAssayJaworskaIC50,
                ),
                keratinoSenseAssayJaworskaKEC3: this.formatNumber(
                    keratinoSenseAssayJaworskaKEC3,
                ),
                keratinoSenseAssayJaworskaKEC15: this.formatNumber(
                    keratinoSenseAssayJaworskaKEC15,
                ),
                cor1C420AssayKMax: this.formatNumber(cor1C420AssayKMax),
                logDpH7: this.formatNumber(logDpH7),
                fion: this.formatNumber(fion),
                proteinBinding: modifyValueToMax(proteinBinding, 100),
                skinDoctorPredictedClass,
                wsph7Ml: this.formatNumber(wspH7Ml),
                molecularWeight: this.formatNumber(molecularWeight),
                kDPRA,
                smiles,
                derek,
                model: modelType === "bdfB" ? 1 : 0,
            }),
        );
    }

    async postRfPredict(
        body: MoleculeDataType,
        selectedModel: number,
    ): Promise<RfPredictionResponse> {
        const {
            smiles,
            molecularWeight,
            meltingPointDEGCOperaPred: meltingPoint,
            surfaceTension,
        } = body;

        return this.handleRequest(
            this.fetchingService.post(this.buildUrl(this.RF_PREDICT_URL), {
                smiles,
                molecularWeight: this.formatNumber(molecularWeight),
                meltingPoint: this.formatNumber(meltingPoint),
                surfaceTension: this.formatNumber(surfaceTension),
                selectedModel,
            }),
        );
    }

    async postRifmBnPredict(
        body: MoleculeDataType,
    ): Promise<{ result: string }> {
        const {
            smiles,
            dpraAssayDPRALys,
            dpraAssayDPRACys,
            keratinoSenseAssayJaworskaKEC15,
            keratinoSenseAssayJaworskaIC50,
            hClatAssayEC150,
            hClatAssayCV75,
            llna,
            sensis,
            timesSSBayesianNetwork,
        } = body;

        return this.handleRequest(
            this.fetchingService.post(this.buildUrl(this.RIFM_BN_PREDICT_URL), {
                smiles,
                dpraAssayDPRALys: this.formatNumber(dpraAssayDPRALys),
                dpraAssayDPRACys: this.formatNumber(dpraAssayDPRACys),
                keratinoSenseAssayJaworskaKEC15: this.formatNumber(
                    keratinoSenseAssayJaworskaKEC15,
                ),
                keratinoSenseAssayJaworskaIC50: this.formatNumber(
                    keratinoSenseAssayJaworskaIC50,
                ),
                hClatAssayEC150: modifyValueToMax(hClatAssayEC150, 1000),
                hClatAssayCV75: modifyValueToMax(hClatAssayCV75, 1000),
                llna: modifyValueToMax(llna, 25000),
                sensis: this.formatNumber(sensis),
                timesSSBayesianNetwork: this.formatNumber(
                    timesSSBayesianNetwork,
                ),
            }),
        );
    }

    async postRifmHnoelHloelPredict(body: MoleculeDataType, model: number) {
        const {
            smiles,
            dpraAssayDPRALys,
            dpraAssayDPRACys,
            keratinoSenseAssayJaworskaKEC15,
            keratinoSenseAssayJaworskaIC50,
            hClatAssayEC150,
            hClatAssayCV75,
            llna,
        } = body;
        return this.handleRequest(
            this.fetchingService.post(this.buildUrl(this.BDF_PREDICT_URL), {
                smiles,
                dpraAssayDPRALys: this.formatNumber(dpraAssayDPRALys),
                dpraAssayDPRACys: this.formatNumber(dpraAssayDPRACys),
                keratinoSenseAssayJaworskaKEC15: this.formatNumber(
                    keratinoSenseAssayJaworskaKEC15,
                ),
                keratinoSenseAssayJaworskaIC50: this.formatNumber(
                    keratinoSenseAssayJaworskaIC50,
                ),
                hClatAssayEC150: modifyValueToMax(hClatAssayEC150, 1000),
                hClatAssayCV75: modifyValueToMax(hClatAssayCV75, 1000),
                llna: modifyValueToMax(llna, 25000),
                model,
            }),
        );
    }
}

export const predictionService = new PredictionService();
