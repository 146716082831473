export const routerKeys = {
    root: "/",
    formulas: "/formulas/",
    dashboard: "/dashboard",
    rawIngredient: "/raw-ingredient",
    subIngredient: "/sub-ingredient",
    contaminants: "/contaminants",
    adminPanel: "/admin-panel",
    fragrance: "/fragrance",
    report: "/report",
    any: "*",
    compare: "compare",
    assessment: "assessment",
    compareWith: "compareWith",
    auth: "/auth",
    login: "login",
    profile: "profile",
    authLogin: "/auth/login",
} as const;
