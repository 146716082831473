import React, { FC, memo } from "react";
import { FieldArray, FormikErrors, FormikTouched } from "formik";
import { ContaminantItem } from "../../../ContaminantItem";
import { AddContaminant } from "../../../AddContaminant";
import { IParsedRawIngredient } from "pages/rawIngredient/types";

export const ContaminantsSection: FC<{
    index: number;
    item: IParsedRawIngredient;
    itemErrors: FormikErrors<IParsedRawIngredient> | undefined | string;
    itemTouched: FormikTouched<IParsedRawIngredient>;
    itemValues: IParsedRawIngredient;
    handleChange: (e: React.ChangeEvent<unknown>) => void;
    isOpenContaminantDialog: boolean;
    handleToggleOpenContaminantDialog: () => void;
}> = memo(
    ({
        index,
        item,
        itemErrors,
        itemTouched,
        itemValues,
        handleChange,
        isOpenContaminantDialog,
        handleToggleOpenContaminantDialog,
    }) => (
        <>
            <div className="w-full mt-10 mb-6 flex justify-between items-center">
                <div className="font-bold periwinkle-color">
                    Included Contaminants
                </div>
                <button
                    className="black-button mt-4 !font-bold text-lg"
                    onClick={handleToggleOpenContaminantDialog}
                    type="button"
                    disabled={isOpenContaminantDialog}
                >
                    <span className="mr-1 text-sm uppercase">
                        Add Contaminant
                    </span>
                </button>
            </div>
            <FieldArray name={`rawIngredients.${index}.contaminants`}>
                {({ push, remove }) => (
                    <>
                        <div className="flex flex-col gap-2">
                            {item?.contaminants?.map((_, contaminantIndex) => (
                                <ContaminantItem
                                    key={contaminantIndex}
                                    errors={
                                        itemErrors as FormikErrors<IParsedRawIngredient>
                                    }
                                    touched={itemTouched}
                                    values={itemValues}
                                    index={contaminantIndex}
                                    handleChange={handleChange}
                                    remove={remove}
                                    prefix={`rawIngredients.${index}`}
                                />
                            ))}
                        </div>
                        {isOpenContaminantDialog && (
                            <AddContaminant
                                onClose={handleToggleOpenContaminantDialog}
                                push={push}
                            />
                        )}
                    </>
                )}
            </FieldArray>
        </>
    ),
);
