import React, { FC, useCallback } from "react";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    queryKeys,
    toastTexts,
} from "common/constants";
import { Button } from "components/shared/button";
import { InputWrapper } from "components/shared/inputWrapper";
import { useFormik } from "formik";
import { useCreateRawIngredientTradeName } from "pages/rawIngredient/rawIngredientProfile/queries";
import { IModifiedTrandname } from "pages/rawIngredient/types";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useQueryClient } from "@tanstack/react-query";
import { getErrorMessage } from "common/utils";
import { AxiosError } from "axios";
import { TradeNameList } from "./TradeNameList";

interface IProps {
    handleClose: () => void;
    tradenames?: IModifiedTrandname[];
}

const CreateSchema = Yup.object().shape({
    manufacturer: Yup.string().required("Manufacturer is required"),
    tradename: Yup.string().required("Tradename is required"),
});

export const CreateTradename: FC<IProps> = ({}) => {
    const { id = "" } = useParams();
    const { mutate } = useCreateRawIngredientTradeName();

    const queryClient = useQueryClient();

    const formik = useFormik({
        initialValues: {
            manufacturer: "",
            tradename: "",
            raw_ingredients_id: id,
        },
        validationSchema: CreateSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            mutate(values, {
                onSuccess() {
                    toast.success(toastTexts.created, defaultToastOptions);
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.rawIngredient, id],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.tradenames, id],
                    });
                    resetForm();
                },
                onError(error) {
                    const errMsg = getErrorMessage(error as AxiosError);
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                    formik.setErrors({
                        ...formik.errors,
                        manufacturer: errMsg as string,
                        tradename: errMsg as string,
                    });
                },
            });
        },
    });

    const handleClearForm = useCallback(() => {
        formik.resetForm();
    }, [formik.resetForm]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <InputWrapper
                className="w-full mb-3"
                isError={Boolean(
                    formik.errors.manufacturer && formik.touched.manufacturer,
                )}
                error={formik.errors.manufacturer}
                label="Manufacturer"
            >
                <input
                    name="manufacturer"
                    className="w-full input input-bordered"
                    value={formik.values.manufacturer}
                    onChange={formik.handleChange}
                    placeholder="Enter Manufacturer"
                />
            </InputWrapper>
            <InputWrapper
                className="w-full mb-3"
                isError={Boolean(
                    formik.errors.tradename && formik.touched.tradename,
                )}
                error={formik.errors.tradename}
                label="Trade Name"
            >
                <input
                    name="tradename"
                    className="w-full input input-bordered"
                    value={formik.values.tradename}
                    onChange={formik.handleChange}
                    placeholder="Enter Trade Name"
                />
            </InputWrapper>
            <div className="flex gap-2 mt-10 px-1 justify-end items-center">
                <Button onClick={handleClearForm} text="Clear" />
                <Button text="Add Trade Name" type="submit" />
            </div>
            <TradeNameList id={id} />
        </form>
    );
};
