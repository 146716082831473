import { IToxicology } from "common/types";
import React, { useRef } from "react";
import {
    InputGroup,
    FormLabelTemplate,
    CommonInput,
    LabelDivider,
    InputTableCeil,
    YesNoSelect,
} from "..";
import { ModalTemplate } from "components/shared/modalTemplate";
// import { useSubIngredientStore } from "pages/subIngredient/subIngredientProfile/store";

interface IProps {
    data: IToxicology;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
}

export const PersistenceSubRankRow: React.FC<IProps> = ({ data, onChange }) => {
    const persistenceModalRef = useRef<HTMLDialogElement>(null);

    // const { isOwner } = useSubIngredientStore();

    const openModal = () => {
        if (persistenceModalRef.current) {
            persistenceModalRef.current.showModal();
        }
    };

    const closeModal = () => {
        if (persistenceModalRef.current) {
            persistenceModalRef.current.close();
        }
    };

    return (
        <tr>
            <InputTableCeil
                text="Persistence Sub-rank"
                name="fix_p_raw_sub_rank"
                checked={Boolean(data.fix_p_raw_sub_rank)}
                onChange={onChange}
                // isDisabled={!isOwner}
            />
            <td className="flex p-3">
                <InputGroup
                    onChange={onChange}
                    value={data.p_raw_sub_rank}
                    name="p_raw_sub_rank"
                    onClick={openModal}
                    // isDisabled={!isOwner}
                />
                <ModalTemplate
                    text="Overall environmental score"
                    onClose={closeModal}
                    modalRef={persistenceModalRef}
                >
                    <div className="flex">
                        <FormLabelTemplate
                            text="Persistence sub rank"
                            name="fix_p_raw_sub_rank"
                            onChange={onChange}
                            checked={Boolean(data.fix_p_raw_sub_rank)}
                        >
                            <CommonInput
                                name="p_raw_sub_rank"
                                value={data.p_raw_sub_rank}
                                onChange={onChange}
                                color="input-accent"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="=" />

                        <FormLabelTemplate
                            text="weighted water sub rank"
                            name="fix_weighted_sub_rank_water"
                            onChange={onChange}
                            checked={Boolean(data.fix_weighted_sub_rank_water)}
                        >
                            <CommonInput
                                name="weighted_sub_rank_water"
                                value={data.weighted_sub_rank_water}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="+" />

                        <FormLabelTemplate
                            text="weighted sediment srv"
                            name="fix_weighted_sub_rank_sediment"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_weighted_sub_rank_sediment,
                            )}
                        >
                            <CommonInput
                                name="weighted_sub_rank_sediment"
                                value={data.weighted_sub_rank_sediment}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="+" />

                        <FormLabelTemplate
                            text="weighted soil sub rank"
                            name="fix_weighted_sub_rank_soil"
                            onChange={onChange}
                            checked={Boolean(data.fix_weighted_sub_rank_soil)}
                        >
                            <CommonInput
                                name="weighted_sub_rank_soil"
                                value={data.weighted_sub_rank_soil}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="+ 1" />
                    </div>

                    <div className="divider" />

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="weighted water sub rank"
                            name="fix_weighted_sub_rank_water"
                            onChange={onChange}
                            checked={Boolean(data.fix_weighted_sub_rank_water)}
                        >
                            <CommonInput
                                name="weighted_sub_rank_water"
                                value={data.weighted_sub_rank_water}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="=" />

                        <FormLabelTemplate
                            text="weighted water sub rank"
                            name="fix_water_raw_sub_rank_srv"
                            onChange={onChange}
                            checked={Boolean(data.fix_water_raw_sub_rank_srv)}
                        >
                            <CommonInput
                                name="water_raw_sub_rank_srv"
                                value={data.water_raw_sub_rank_srv}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="x" />

                        <FormLabelTemplate
                            text="Partitioning to water"
                            name="fix_water_percent"
                            onChange={onChange}
                            checked={Boolean(data.fix_water_percent)}
                        >
                            <CommonInput
                                name="water_percent"
                                value={data.water_percent}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="/" />

                        <FormLabelTemplate
                            text="Total for weighting"
                            name="fix_total_for_weighting"
                            onChange={onChange}
                            checked={Boolean(data.fix_total_for_weighting)}
                        >
                            <CommonInput
                                name="total_for_weighting"
                                value={data.total_for_weighting}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>
                    </div>
                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="weighted sediment srv"
                            name="fix_weighted_sub_rank_sediment"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_weighted_sub_rank_sediment,
                            )}
                        >
                            <CommonInput
                                name="weighted_sub_rank_sediment"
                                value={data.weighted_sub_rank_sediment}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="=" />

                        <FormLabelTemplate
                            text="sediment sub rank value"
                            name="fix_sed_raw_sub_rank"
                            onChange={onChange}
                            checked={Boolean(data.fix_sed_raw_sub_rank)}
                        >
                            <CommonInput
                                name="sed_raw_sub_rank"
                                value={data.sed_raw_sub_rank}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="x" />

                        <FormLabelTemplate
                            text="Partitioning to sediment"
                            name="fix_sediment_percent"
                            onChange={onChange}
                            checked={Boolean(data.fix_sediment_percent)}
                        >
                            <CommonInput
                                name="sediment_percent"
                                value={data.sediment_percent}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="/" />

                        <FormLabelTemplate
                            text="Total for weighting"
                            name="fix_total_for_weighting"
                            onChange={onChange}
                            checked={Boolean(data.fix_total_for_weighting)}
                        >
                            <CommonInput
                                name="total_for_weighting"
                                value={data.total_for_weighting}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="if" />

                        <FormLabelTemplate
                            text="sediment concern"
                            name="fix_sed_incl"
                            onChange={onChange}
                            checked={Boolean(data.fix_sed_incl)}
                        >
                            <YesNoSelect
                                name="sed_incl"
                                value={data.sed_incl}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>
                    </div>
                    <div className="flex">
                        <FormLabelTemplate
                            text="weighted soil sub rank"
                            name="fix_weighted_sub_rank_soil"
                            onChange={onChange}
                            checked={Boolean(data.fix_weighted_sub_rank_soil)}
                        >
                            <CommonInput
                                name="weighted_sub_rank_soil"
                                value={data.weighted_sub_rank_soil}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="=" />

                        <FormLabelTemplate
                            text="soil sub rank value"
                            name="fix_soil_raw_sub_rank"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_raw_sub_rank)}
                        >
                            <CommonInput
                                name="soil_raw_sub_rank"
                                value={data.soil_raw_sub_rank}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="x" />

                        <FormLabelTemplate
                            text="Partitioning to soil"
                            name="fix_soil_percent"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_percent)}
                        >
                            <CommonInput
                                name="soil_percent"
                                value={data.soil_percent}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="/" />

                        <FormLabelTemplate
                            text="Total for weighting"
                            name="fix_total_for_weighting"
                            onChange={onChange}
                            checked={Boolean(data.fix_total_for_weighting)}
                        >
                            <CommonInput
                                name="total_for_weighting"
                                value={data.total_for_weighting}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="if" />

                        <FormLabelTemplate
                            text="soil concern"
                            name="fix_soil_incl"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_incl)}
                        >
                            <YesNoSelect
                                name="soil_incl"
                                value={data.soil_incl}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>
                    </div>

                    <div className="divider" />

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="water srv"
                            name="fix_water_raw_sub_rank_srv"
                            onChange={onChange}
                            checked={Boolean(data.fix_water_raw_sub_rank_srv)}
                        >
                            <CommonInput
                                name="water_raw_sub_rank_srv"
                                value={data.water_raw_sub_rank_srv}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="depends on" />

                        <FormLabelTemplate
                            text="water half life"
                            name="fix_water_t1_2"
                            onChange={onChange}
                            checked={Boolean(data.fix_water_t1_2)}
                        >
                            <CommonInput
                                name="water_t1_2"
                                value={data.water_t1_2}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="and" />

                        <FormLabelTemplate
                            text="readily biodegradable"
                            name="fix_ready_bio_pred"
                            onChange={onChange}
                            checked={Boolean(data.fix_ready_bio_pred)}
                        >
                            <YesNoSelect
                                name="ready_bio_pred"
                                value={data.ready_bio_pred}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>
                    </div>
                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="sediment sub rank value"
                            name="fix_sed_raw_sub_rank"
                            onChange={onChange}
                            checked={Boolean(data.fix_sed_raw_sub_rank)}
                        >
                            <CommonInput
                                name="sed_raw_sub_rank"
                                value={data.sed_raw_sub_rank}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="depends on" />

                        <FormLabelTemplate
                            text="sediment half life"
                            name="fix_sed_t1_2"
                            onChange={onChange}
                            checked={Boolean(data.fix_sed_t1_2)}
                        >
                            <CommonInput
                                name="sed_t1_2"
                                value={data.sed_t1_2}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="and" />

                        <FormLabelTemplate
                            text="sediment concern"
                            name="fix_sed_incl"
                            onChange={onChange}
                            checked={Boolean(data.fix_sed_incl)}
                        >
                            <YesNoSelect
                                name="sed_incl"
                                value={data.sed_incl}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>
                    </div>
                    <div className="flex">
                        <FormLabelTemplate
                            text="soil sub rank value"
                            name="fix_soil_raw_sub_rank"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_raw_sub_rank)}
                        >
                            <CommonInput
                                name="soil_raw_sub_rank"
                                value={data.soil_raw_sub_rank}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="depends on" />

                        <FormLabelTemplate
                            text="soil half life"
                            name="fix_soil_t1_2"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_t1_2)}
                        >
                            <CommonInput
                                name="soil_t1_2"
                                value={data.soil_t1_2}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="and" />

                        <FormLabelTemplate
                            text="soil concern"
                            name="fix_soil_incl"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_incl)}
                        >
                            <YesNoSelect
                                name="soil_incl"
                                value={data.soil_incl}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>
                    </div>

                    <div className="divider" />

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="sediment concern"
                            name="fix_sed_incl"
                            onChange={onChange}
                            checked={Boolean(data.fix_sed_incl)}
                        >
                            <YesNoSelect
                                name="sed_incl"
                                value={data.sed_incl}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="if" />

                        <FormLabelTemplate
                            text="readily biodegradable"
                            name="fix_ready_bio_pred"
                            onChange={onChange}
                            checked={Boolean(data.fix_ready_bio_pred)}
                        >
                            <YesNoSelect
                                name="ready_bio_pred"
                                value={data.ready_bio_pred}
                                onChange={onChange}
                                color="select-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text='= "No" and' />

                        <FormLabelTemplate
                            text="Partitioning to sediment"
                            name="fix_sediment_percent"
                            onChange={onChange}
                            checked={Boolean(data.fix_sediment_percent)}
                        >
                            <CommonInput
                                name="sediment_percent"
                                value={data.sediment_percent}
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="> 10%" />
                    </div>

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="soil concern"
                            name="fix_soil_incl"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_incl)}
                        >
                            <YesNoSelect
                                name="soil_incl"
                                value={data.soil_incl}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="if" />

                        <FormLabelTemplate
                            text="readily biodegradable"
                            name="fix_ready_bio_pred"
                            onChange={onChange}
                            checked={Boolean(data.fix_ready_bio_pred)}
                        >
                            <YesNoSelect
                                name="ready_bio_pred"
                                value={data.ready_bio_pred}
                                onChange={onChange}
                                color="select-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text='= "No" and' />

                        <FormLabelTemplate
                            text="Partitioning to soil"
                            name="fix_soil_percent"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_percent)}
                        >
                            <CommonInput
                                name="soil_percent"
                                value={data.soil_percent}
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="> 10%" />
                    </div>

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="water half life"
                            name="fix_water_t1_2"
                            onChange={onChange}
                            checked={Boolean(data.fix_water_t1_2)}
                        >
                            <CommonInput
                                name="water_t1_2"
                                value={data.water_t1_2}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="= 4662.3 x e^(" />

                        <FormLabelTemplate
                            text="biowin 3"
                            name="fix_biowin3"
                            onChange={onChange}
                            checked={Boolean(data.fix_biowin3)}
                        >
                            <CommonInput
                                name="biowin3"
                                value={data.biowin3}
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text=")" />
                    </div>

                    <div className="flex">
                        <FormLabelTemplate
                            text="readily biodegradable"
                            name="fix_ready_bio_pred"
                            onChange={onChange}
                            checked={Boolean(data.fix_ready_bio_pred)}
                        >
                            <YesNoSelect
                                name="ready_bio_pred"
                                value={data.ready_bio_pred}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="if" />

                        <FormLabelTemplate
                            text="biowin 3"
                            name="fix_biowin3"
                            onChange={onChange}
                            checked={Boolean(data.fix_biowin3)}
                        >
                            <CommonInput
                                name="biowin3"
                                value={data.biowin3}
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="> 2.75 and" />

                        <FormLabelTemplate
                            text="biowin 5"
                            name="fix_biowin5"
                            onChange={onChange}
                            checked={Boolean(data.fix_biowin5)}
                        >
                            <CommonInput
                                name="biowin5"
                                value={data.biowin5}
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="> 0.5" />
                    </div>

                    <div className="divider" />
                </ModalTemplate>
            </td>
        </tr>
    );
};
