/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Select, { MultiValue } from "react-select";
import "./style.css";

interface IOption {
    value: string;
    label: string;
}

type ChangeHandler = (newValue: IOption | MultiValue<IOption> | any) => void;

interface IProps {
    isLoading: boolean;
    options: MultiValue<IOption>;
    values: MultiValue<IOption> | IOption | null;
    onChange: ChangeHandler;
    isMulty?: boolean;
}

export const SearchableSelect: React.FC<IProps> = ({
    isLoading = false,
    options,
    values,
    onChange,
    isMulty,
}) => {
    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            value={values || {}}
            isLoading={isLoading}
            isMulti={isMulty || undefined}
            isClearable={true}
            isSearchable={true}
            onChange={onChange}
            options={options}
        />
    );
};
