export const buildLink = (
    ...parts: (string | string[] | number | number[])[]
): string => {
    return "/".concat(
        parts
            .flat()
            .map((part: string | number) =>
                `${part}`.replace(/(^\/+|\/+$)/g, ""),
            )
            .filter(Boolean)
            .join("/"),
    );
};
