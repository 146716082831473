import React, { FC, useCallback, useState } from "react";
import { IPreparedSubIngredient } from "pages/formulas/formula-profile/types";
import classNames from "classnames";
import { SubIngredientLink } from "./SubIngredientLink";
import { CheckboxInput } from "./CheckboxInput";
import { IManualInput } from "../types";

interface IProps {
    subIngredients: IPreparedSubIngredient[];
    onManualInputChange: (manualInputs: IManualInput[]) => void;
}

export const SubIngredientMOSTable: FC<IProps> = ({
    subIngredients,
    onManualInputChange,
}) => {
    const initialInputs = subIngredients.map((it) => ({
        dermalPenetration: it?.dermal_penetration || "",
        noael: it?.noael || "",
    }));

    const [_manualInputs, setManualInputs] =
        useState<IManualInput[]>(initialInputs);

    const handleInputChange = useCallback(
        (index: number, field: keyof IManualInput, value: string) => {
            setManualInputs((prev) => {
                const updated = [...prev];
                updated[index] = { ...updated[index], [field]: value };
                onManualInputChange(updated);
                return updated;
            });
        },
        [onManualInputChange],
    );

    return (
        <>
            {subIngredients.map((subIngredient, index) => (
                <tr key={subIngredient.id}>
                    <td className="py-2 px-1 sm:px-3 cursor-pointer break-words max-w-sm sm:max-w-sm overflow-hidden text-ellipsis">
                        <SubIngredientLink
                            id={subIngredient.id}
                            sicode={subIngredient.sicode}
                            name={subIngredient.name}
                            description={subIngredient.description}
                        />
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        {subIngredient?.rawSub?.raw_weight_percent
                            ? `${subIngredient?.rawSub?.raw_weight_percent}%`
                            : ""}
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        {subIngredient?.skin_retention_factor || ""}
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        <CheckboxInput
                            index={index}
                            field="dermalPenetration"
                            value={subIngredient?.dermal_penetration}
                            onChange={handleInputChange}
                        />
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        {subIngredient?.daily_exposure || ""}
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        {subIngredient?.systematic_daily_exposure || ""}
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        <CheckboxInput
                            index={index}
                            field="noael"
                            value={subIngredient?.noael}
                            onChange={handleInputChange}
                        />
                    </td>
                    <td
                        className={classNames("py-2 px-1 sm:px-3 text-center", {
                            error:
                                subIngredient.MOS && +subIngredient.MOS < 100,
                        })}
                    >
                        {subIngredient.MOS || ""}
                    </td>
                </tr>
            ))}
        </>
    );
};
