import React, { FC, ReactNode, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    title?: string;
    children?: ReactNode;
    isVisibleMoreButton?: boolean;
    handleIncreaseLimit?: () => void;
    handleDecreaseLimit?: () => void;
}

export const TableInfo: FC<IProps> = memo(
    ({
        title = "",
        children,
        isVisibleMoreButton = false,
        handleIncreaseLimit,
        handleDecreaseLimit,
    }) => (
        <>
            <h3 className="text-lg font-normal uppercase mx-10 mb-4">
                {title}
            </h3>

            <div className="flex justify-end mr-4">
                {isVisibleMoreButton && (
                    <button
                        className="flex flex-col items-center text-blue-950"
                        onClick={handleDecreaseLimit}
                        aria-label="Decrease limit"
                    >
                        <FontAwesomeIcon size="lg" icon={faCircleMinus} />
                    </button>
                )}
            </div>

            {children}
            <div className="text-secondary-info justify-center font-bold p-4 flex md:flex">
                {isVisibleMoreButton && (
                    <button
                        className="flex flex-col items-center text-blue-950"
                        onClick={handleIncreaseLimit}
                        aria-label="Increase limit"
                    >
                        <FontAwesomeIcon size="lg" icon={faCirclePlus} />
                    </button>
                )}
            </div>
        </>
    ),
);
