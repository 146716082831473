// React-query keys
export const QUERY_KEYS = {
    TODOS: "todos",
    TODO: "todo",
} as const;

// Backend Routes
export const BACKEND_KEYS = {
    API_VERSION: "api",
    BASE_URL: process.env.REACT_APP_SERVER_URL,
} as const;
