import React from "react";

interface IProps {
    text: string;
}

export const Label: React.FC<IProps> = ({ text }) => (
    <p className="w-1/4 px-3 md:px-3 lg:px-3 text-sm md:text-base lg-text-base flex items-center">
        <b>{text}</b>
    </p>
);
