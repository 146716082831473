import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { routerKeys } from "common/constants/routerKeys";
import { Formulas } from "pages/formulas/formulas";
import { FormulaProfile } from "pages/formulas/formula-profile";
import { FormulaCompare } from "pages/formulas/formula-compare";
import { FormulaAssessment } from "pages/formulas/formula-assessment";
import { RawIngredients } from "pages/rawIngredient/rawIngredients";
import { RawIngredientProfile } from "pages/rawIngredient/rawIngredientProfile";
import { SubIngredients } from "pages/subIngredient/subIngredients";
import { SubIngredientProfile } from "pages/subIngredient/subIngredientProfile";
import { LoginPage } from "pages/auth/login";
import { Dashboard } from "pages/dashboard";
import { ContaminantProfilePage } from "pages/contaminant/contaminantProfile";
import { Contaminants } from "pages/contaminant/contaminants";
import { AdminPanel } from "pages/adminPanel";
import { Fragrance } from "pages/fragrance";
import { Report } from "pages/report";
import { PrivateRoutes } from "./PrivateRoutes";
import { Profile } from "pages/pages";

export const Navigation = () => (
    <Routes>
        <Route element={<PrivateRoutes />}>
            <Route
                path={routerKeys.root}
                element={<Navigate to={routerKeys.formulas} replace={true} />}
            />

            <Route path={routerKeys.profile} element={<Profile />} />
            <Route path={routerKeys.dashboard} element={<Dashboard />} />
            <Route path={routerKeys.formulas}>
                <Route index={true} element={<Formulas />} />
                <Route path=":id" element={<FormulaProfile />} />
                <Route
                    path={`:id/${routerKeys.assessment}`}
                    element={<FormulaAssessment />}
                />
                <Route
                    path={`:id/${routerKeys.compare}`}
                    element={<FormulaCompare />}
                />
            </Route>
            <Route path={routerKeys.rawIngredient}>
                <Route index={true} element={<RawIngredients />} />
                <Route path=":id" element={<RawIngredientProfile />} />
            </Route>
            <Route path={routerKeys.subIngredient}>
                <Route index={true} element={<SubIngredients />} />
                <Route path=":id" element={<SubIngredientProfile />} />
            </Route>
            <Route path={routerKeys.contaminants}>
                <Route index={true} element={<Contaminants />} />
                <Route path=":id" element={<ContaminantProfilePage />} />
            </Route>

            <Route path={routerKeys.adminPanel} element={<AdminPanel />} />
            <Route path={routerKeys.fragrance} element={<Fragrance />} />
            <Route path={routerKeys.report} element={<Report />} />
            <Route
                path={routerKeys.any}
                element={<Navigate to={routerKeys.formulas} replace={true} />}
            />
        </Route>
        <Route path={routerKeys.auth}>
            <Route
                index={true}
                element={<Navigate to={routerKeys.login} replace={true} />}
            />
            <Route
                index={true}
                path={routerKeys.login}
                element={<LoginPage />}
            />
        </Route>
    </Routes>
);
