import React, { ChangeEvent, FormEvent, useState } from "react";
import { Button } from "components/shared/button";
import {
    useRemoveProductType,
    useGetAllProductTypes,
} from "pages/adminPanel/queries";
import { toast } from "react-toastify";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    toastTexts,
} from "common/constants";

export const RemoveProductType = () => {
    const { data: productTypes } = useGetAllProductTypes();
    const [selectedItem, setSelectedItem] = useState("");

    const { mutate: remove, isPending } = useRemoveProductType();
    const handleChangeValue = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedItem(e.target.value);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (selectedItem) {
            remove(selectedItem, {
                onSuccess: () => {
                    setSelectedItem("");
                    toast.success(toastTexts.success, defaultToastOptions);
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            });
        }
    };

    return (
        <form
            className="w-full flex flex-col gap-2 max-w-96"
            onSubmit={handleSubmit}
        >
            <div className="w-full">
                <select
                    className="select select-bordered w-full"
                    name="product_type"
                    value={selectedItem}
                    onChange={handleChangeValue}
                >
                    <option value="" disabled>
                        Select Product Type
                    </option>
                    {productTypes?.map(({ key, value }, idx) => (
                        <option value={key} key={`${key}-${idx}`}>
                            {value}
                        </option>
                    ))}
                </select>
            </div>
            <div className="p-1 w-full flex justify-end space-x-2 flex-wrap mt-2">
                <Button
                    text="Remove Product Type"
                    className="w-full min-[100px]:w-max"
                    type="submit"
                    isLoading={isPending}
                />
            </div>
        </form>
    );
};
