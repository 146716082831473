import React, { FC } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { v4 as uuids4 } from "uuid";

interface ITableHeaderCeilProps {
    text?: string;
    className?: string;
    tooltipMessage?: string;
    idx?: number;
}

export const TableHeaderCeil: FC<ITableHeaderCeilProps> = ({
    text,
    className = "",
    tooltipMessage,
    idx = uuids4(),
}) => (
    <th
        id={`tooltip-${idx}`}
        className={classNames(
            "p-3 md:p-3 lg:p-3 text-left text-xs md:text-base lg-text-base lg:min-w-[100px] break-words",
            { "cursor-pointer": tooltipMessage },
            className,
        )}
    >
        {tooltipMessage && (
            <div className="inline-block">
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    size="lg"
                    className="text-primary"
                />
                <Tooltip
                    anchorSelect={`#tooltip-${idx}`}
                    variant="info"
                    place="top"
                >
                    {tooltipMessage.split("\\n").map((message, index) => (
                        <p key={`tooltip-${idx}-${index}`}>{message}</p>
                    ))}
                </Tooltip>
            </div>
        )}
        <span className="ml-2">{text}</span>
    </th>
);
