import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import { toast } from "react-toastify";
import { useGetFormula } from "pages/formulas/formula-profile/queries";
import { defaultErrorToastOptions, toastTexts } from "common/constants";
import { handleDownloadFile } from "common/utils";
import { formulaService } from "services";
import { IReport } from "common/types";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    reportFile: IReport;
    openModal: () => void;
    setReportFile: Dispatch<SetStateAction<IReport>>;
}

export const ReportRow: FC<IProps> = ({
    openModal,
    reportFile,
    setReportFile,
}) => {
    const formula = useGetFormula();

    const { isOwner } = useFormulaProfileState();

    const handleDownload = async () => {
        if (!isOwner) {
            return;
        }

        formulaService
            .getFormulaReport({
                ficode: formula?.ficode || "",
                filename: reportFile.filename,
            })
            .then((response) => {
                handleDownloadFile(response, reportFile.filename);
            })
            .catch(() => {
                toast.error(toastTexts.error, defaultErrorToastOptions);
            });
    };

    const handleOpenModal = useCallback(() => {
        openModal();
        setReportFile(reportFile);
    }, [reportFile]);

    return (
        <>
            <tr>
                <td className="w-3/4 text-xs md:text-base lg:text-base p-3">
                    <a
                        className="cursor-pointer hover:font-bold"
                        onClick={handleDownload}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                        <span className="ml-2">
                            {reportFile.filename} (
                            {new Date(reportFile.modified).toLocaleDateString(
                                "en-US",
                                {
                                    dateStyle: "medium",
                                },
                            )}
                            )
                        </span>
                    </a>
                </td>
                <td className="flex p-3">
                    <button
                        className="btn btn-xs"
                        data-file={reportFile.filename}
                        onClick={handleOpenModal}
                    >
                        delete
                    </button>
                </td>
            </tr>
        </>
    );
};
