import React, { FC } from "react";
import { Tooltip } from "react-tooltip";
import { IPreparedSubIngredient } from "pages/formulas/formula-profile/types";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { SubIngredientMOSTable } from "./SubIngredientMOSTable";
import { TotalWeightRow } from "./TotalWeightRow";
import { IManualInput } from "../types";

interface IProps {
    subIngredients: IPreparedSubIngredient[];
    onManualInputChange: (manualInputs: IManualInput[]) => void;
}

export const Table: FC<IProps> = ({ subIngredients, onManualInputChange }) => {
    const { subIngredientsTotalWeight } = useFormulaProfileState();

    return (
        <table className="table-auto w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg">
            <thead>
                <tr className="border-b border-primary-main-dgray">
                    <th className="py-2 pl-1">SI Code / Name</th>
                    <th>
                        <span id="totalWeightTooltip">Total weight</span>
                        <Tooltip
                            anchorSelect="#totalWeightTooltip"
                            variant="info"
                            content="Total weight is the sum of all individual sub-ingredients'
                weights in the formula."
                        />
                    </th>
                    <th>
                        <span id="skinRetentionTooltip">Skin Reten.</span>
                        <Tooltip
                            anchorSelect="#skinRetentionTooltip"
                            variant="info"
                            content="Skin Retention is the percentage of the applied product that remains on the skin after application."
                        />
                    </th>
                    <th>
                        <span id="dermalPenetrationTooltip">
                            Dermal Penetr.
                        </span>
                        <Tooltip
                            anchorSelect="#dermalPenetrationTooltip"
                            variant="info"
                            content="Dermal Penetration indicates how much of the product penetrates into the skin."
                        />
                    </th>
                    <th>
                        <span id="dailyExposureTooltip">Daily Exposure</span>
                        <Tooltip
                            anchorSelect="#dailyExposureTooltip"
                            variant="info"
                            content="Daily Exposure represents the amount of product applied daily based on standard usage patterns."
                        />
                    </th>
                    <th>
                        <span id="systemicExpTooltip">Systemic Exp.</span>
                        <Tooltip
                            anchorSelect="#systemicExpTooltip"
                            variant="info"
                            content="Systemic Exposure is the amount of the product that enters
                systemic circulation."
                        />
                    </th>
                    <th>
                        <span id="noaelTooltip">NOAEL</span>
                        <Tooltip
                            anchorSelect="#noaelTooltip"
                            variant="info"
                            content=" NOAEL (No Observed Adverse Effect Level) is the highest dose at which no adverse effects are observed."
                        />
                    </th>
                    <th>
                        <span id="mosTooltip">MOS</span>
                        <Tooltip
                            anchorSelect="#mosTooltip"
                            variant="info"
                            content="MOS (Margin of Safety) is the ratio of NOAEL to the estimated
                daily exposure. A higher MOS indicates a safer margin."
                        />
                    </th>
                </tr>
            </thead>
            <tbody>
                <SubIngredientMOSTable
                    subIngredients={subIngredients}
                    onManualInputChange={onManualInputChange}
                />
                <TotalWeightRow
                    totalWeight={Number(subIngredientsTotalWeight)}
                />
            </tbody>
        </table>
    );
};
