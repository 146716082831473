import { modifyDate } from "./modifyDate";

export const getLastModifier = (
    modifierData: Record<string, Record<string, string | null>>,
    fieldName: string,
): string | null => {
    const fieldData = modifierData?.[fieldName];
    if (!fieldData) return null;

    const { assessor, company_name, company_email, date_modified } = fieldData;

    if (!assessor || !company_email) return null;

    const contactInfo = company_name || company_email;
    const formattedDate = date_modified
        ? ` at ${modifyDate(date_modified)}`
        : "";

    return `${assessor} (${contactInfo})${formattedDate}`;
};
