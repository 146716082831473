import classNames from "classnames";
import React from "react";

interface IProps {
    value: string;
    placeholder: string;
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
}

export const FilterInputBox: React.FC<IProps> = ({
    value,
    name,
    onChange,
    placeholder,
    className = "",
}) => (
    <div className={classNames("flex flex-row", className)}>
        <div className="mb-3 w-full">
            <input
                type="text"
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                value={value}
                className="input input-bordered input-sm w-full"
            />
        </div>
    </div>
);
