export const productTypes = [
    { id: 1, value: "body_lotion", label: "Body Lotion" },
    { id: 2, value: "deodorant_non_spray", label: "Deodorant (Non-Spray)" },
    { id: 3, value: "deodorant_spray", label: "Deodorant Spray" },
    {
        id: 4,
        value: "deodorant_spray_ethanol_based",
        label: "Deodorant Spray (Ethanol-Based)",
    },
    { id: 5, value: "eyeliner", label: "Eyeliner" },
    { id: 6, value: "eyeshadow", label: "Eyeshadow" },
    { id: 7, value: "face_cream", label: "Face Cream" },
    { id: 8, value: "hair_conditioner", label: "Hair Conditioner" },
    { id: 9, value: "hair_styling_products", label: "Hair Styling Products" },
    { id: 10, value: "hand_cream", label: "Hand Cream" },
    { id: 11, value: "lipstick", label: "Lipstick" },
    { id: 12, value: "liquid_foundation", label: "Liquid Foundation" },
    { id: 13, value: "make_up_remover", label: "Make-Up Remover" },
    { id: 14, value: "mascara", label: "Mascara" },
    { id: 15, value: "mouthwash", label: "Mouthwash" },
    { id: 16, value: "nail_polish", label: "Nail Polish" },
    { id: 17, value: "nail_polish_remover", label: "Nail Polish Remover" },
    {
        id: 18,
        value: "oxidative_permanent_hair_dyes",
        label: "Oxidative Permanent Hair Dyes",
    },
    {
        id: 19,
        value: "semi_permanent_hair_dyes",
        label: "Semi-Permanent Hair Dyes",
    },
    { id: 20, value: "shampoo", label: "Shampoo" },
    { id: 21, value: "shower_gel", label: "Shower Gel" },
    { id: 22, value: "sunscreen", label: "Sunscreen" },
    { id: 23, value: "toothpaste", label: "Toothpaste" },
];
