import { type MoleculeDataType } from "pages/subIngredient/subIngredientProfile/libs/types";

type WithoutBoolean = Omit<
    MoleculeDataType,
    | "hClatAssayCD54Marker"
    | "hClatAssayCD86Marker"
    | "convertedCD54Marker"
    | "convertedCD86Marker"
>;

const isValid = (
    value: number | string | boolean | undefined | null,
): boolean => {
    return value === 0 || Boolean(value);
};

const modifyValueToMax = (
    value: number | string | null,
    maxValue: number,
): string | null => {
    if (!value && value !== 0) return null;

    return +value <= maxValue ? `${value}` : `${maxValue}`;
};

const convertMarker = (
    marker: string | boolean | number | null | undefined,
): boolean | null => {
    if (marker == null || marker === "") return null;

    if (typeof marker === "boolean") return marker;

    return marker === "positive";
};

const processRestFields = (rest: Partial<WithoutBoolean>): void => {
    Object.entries(rest).forEach(([key, value]) => {
        if (typeof value === "string") {
            if (!value.length) {
                rest[key as keyof WithoutBoolean] = null;
            }
        }
    });
};

const modifyHClatValues = (
    rest: Partial<WithoutBoolean>,
    keys: Array<keyof WithoutBoolean>,
    maxValue = 10000,
): void => {
    if (!rest.molecularWeight) {
        keys.forEach((key) => (rest[key] = null));
        return;
    }

    keys.forEach((key) => {
        const currentValue = rest[key];
        if (!currentValue) return;

        const calculatedValue =
            (Number(currentValue) * 1000) / Number(rest.molecularWeight);
        rest[`modified${key as string}` as keyof WithoutBoolean] =
            `${calculatedValue}`;
        rest[key] = modifyValueToMax(calculatedValue, maxValue);
    });
};

const modifyDpraValues = (
    rest: Partial<WithoutBoolean>,
    curr: Partial<WithoutBoolean>,
    keys: Array<keyof WithoutBoolean>,
    maxValue = 100,
): void =>
    keys.forEach((key) => {
        rest[key] = modifyValueToMax(curr[key] ?? null, maxValue);
    });

const modifyKeratinoSenseValues = (
    rest: Partial<WithoutBoolean>,
    keys: Array<keyof WithoutBoolean>,
    maxValue = 2000,
): void => {
    keys.forEach((key) => {
        rest[key] = modifyValueToMax(rest?.[key] ?? null, maxValue);
    });
};

export const modifyData = (model: MoleculeDataType): MoleculeDataType => {
    const {
        hClatAssayCD54Marker,
        hClatAssayCD86Marker,
        chemicalName,
        casNumber,
        smiles,
        kdpraBDF,
        derekOecdIts,
        dpraAssayDPRACys,
        dpraAssayDPRALys,
        ...rest
    } = model;

    const dpraKeys: Array<keyof WithoutBoolean> = [
        "dpraAssayDPRALys",
        "dpraAssayDPRACys",
    ];
    const hClatKeys: Array<keyof WithoutBoolean> = [
        "hClatAssayCV75",
        "hClatAssayEC150",
        "hClatAssayEC200",
    ];
    const keratinoSenseKeys: Array<keyof WithoutBoolean> = [
        "keratinoSenseAssayJaworskaIC50",
        "keratinoSenseAssayJaworskaKEC3",
        "keratinoSenseAssayJaworskaKEC15",
    ];

    const kdpraMap: Record<string, string> = {
        "1B/NC": "1",
        "1A": "2",
    };

    processRestFields(rest);
    modifyDpraValues(rest, { dpraAssayDPRACys, dpraAssayDPRALys }, dpraKeys);
    modifyHClatValues(rest, hClatKeys);
    modifyKeratinoSenseValues(rest, keratinoSenseKeys);

    return {
        hClatAssayCD54Marker: convertMarker(hClatAssayCD54Marker),
        hClatAssayCD86Marker: convertMarker(hClatAssayCD86Marker),
        dpraAssayDPRACys,
        dpraAssayDPRALys,
        chemicalName,
        casNumber,
        smiles: smiles ?? null,
        derekOecdIts: isValid(derekOecdIts) ? derekOecdIts : null,
        kdpraBDF: kdpraBDF ? kdpraMap[kdpraBDF] : null,
        ...rest,
    };
};
