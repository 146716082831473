import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useUpdateFormulaCompare } from "./queries";
import { Content, Header } from "./components";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    toastTexts,
} from "common/constants";
import { LoadingToast } from "components/shared/loadingToast";
import { ToastContainer, toast } from "react-toastify";

export const FormulaCompare = () => {
    const { id = "" } = useParams();
    const [searchParams = ""] = useSearchParams();

    const { data, isLoading, isError } = useUpdateFormulaCompare({
        id,
        searchParams: searchParams.toString(),
    });

    if (isLoading) {
        return <LoadingToast />;
    }

    if (!data || isError) {
        if (isError) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        } else {
            toast.info(toastTexts.dataLack, defaultToastOptions);
        }
        return <ToastContainer />;
    }

    return (
        <div>
            <Header
                ficode={data.source.ficode}
                description={data.source.description}
            />
            <Content />
        </div>
    );
};
