import React, { FC } from "react";

interface IProps {
    children: React.ReactNode;
}

export const TabTable: FC<IProps> = ({ children }) => (
    <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg">
        {children}
    </table>
);
