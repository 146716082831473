import { Details } from "components/shared/details";
import React, { useState } from "react";
import { AddVendorForm } from "./forms/AddVendorForm";
import { IBoxes } from "../types";
// import { ImportSub } from "./forms/Import";
// import { RemoveFdaClass } from "./forms/RemoveFdaClass";
import { RemoveProductType } from "./forms/RemoveProductType";

export const Forms = () => {
    const [isOpen, setIsOpen] = useState<IBoxes>({
        vendorForm: false,
        import: false,
        removeFdaClass: false,
        removeProductType: false,
    });

    const handleToggleVisibility = (name: keyof IBoxes) => {
        setIsOpen((curr) => ({
            ...curr,
            [name]: !curr[name],
        }));
    };

    return (
        <div className="flex flex-wrap p-4">
            <Details
                isOpen={isOpen.vendorForm}
                text="Create a manufacturer"
                onOpen={() => handleToggleVisibility("vendorForm")}
                detailsClassName="w-full xl:w-1/2"
                summaryClassName="text-xl"
            >
                <AddVendorForm />
            </Details>
            {/*             <Details
                isOpen={isOpen.import}
                text="Import INCI"
                onOpen={() => handleToggleVisibility("import")}
                detailsClassName="w-full xl:w-1/2"
                summaryClassName="text-xl"
            >
                <ImportSub />
            </Details> */}
            {/* <Details
                isOpen={isOpen.removeFdaClass}
                text="Remove FDA class"
                onOpen={() => handleToggleVisibility("removeFdaClass")}
                detailsClassName="w-full xl:w-1/2"
                summaryClassName="text-xl"
            >
                <RemoveFdaClass />
            </Details> */}
            <Details
                isOpen={isOpen.removeProductType}
                text="Remove product type"
                onOpen={() => handleToggleVisibility("removeProductType")}
                detailsClassName="w-full xl:w-1/2"
                summaryClassName="text-xl"
            >
                <RemoveProductType />
            </Details>
        </div>
    );
};
