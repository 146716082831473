import { FormikErrors, FormikTouched } from "formik";
import React, { FC, useCallback } from "react";
import { InputWrapper } from "components/shared/inputWrapper";
import {
    IParsedContaminant,
    IParsedRawIngredient,
} from "pages/rawIngredient/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    index: number;
    prefix: string;
    errors?: FormikErrors<IParsedRawIngredient>;
    touched: FormikTouched<IParsedRawIngredient>;
    values: IParsedRawIngredient;
    handleChange: (e: React.ChangeEvent<unknown>) => void;
    remove: <X = unknown>(index: number) => X | undefined;
}

export const ContaminantItem: FC<IProps> = ({
    index,
    prefix,
    errors,
    touched,
    values,
    handleChange,
    remove,
}) => {
    const itemErrors = errors?.contaminants?.[index] as
        | FormikErrors<IParsedContaminant>
        | undefined;
    const itemTouched = touched.contaminants?.[index] || {};
    const itemValues = values.contaminants?.[index];

    const handleRemove = useCallback(() => {
        remove(index);
    }, [index]);

    return (
        <div className="flex flex-col gap-4 border rounded-lg p-4 shadow-sm bg-white relative">
            <button
                onClick={handleRemove}
                className="absolute top-2 right-2 h-8 w-8 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                type="button"
                title="Remove Contaminant"
            >
                <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>

            <div className="flex gap-4 items-center">
                <InputWrapper
                    isError={Boolean(itemErrors?.name) && itemTouched?.name}
                    error={itemErrors?.name}
                    label="Name"
                    className="flex-1"
                >
                    <input
                        name={`${prefix}.contaminants.${index}.name`}
                        className="w-full input input-bordered rounded-lg p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300 transition"
                        value={itemValues.name}
                        onChange={handleChange}
                        placeholder="Contaminant Name"
                    />
                </InputWrapper>

                <InputWrapper
                    isError={Boolean(itemErrors?.ppm) && itemTouched?.ppm}
                    error={itemErrors?.ppm}
                    label="PPM"
                    className="flex-1"
                >
                    <input
                        type="number"
                        name={`${prefix}.contaminants.${index}.ppm`}
                        className="w-full input input-bordered rounded-lg p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300 transition"
                        value={itemValues.ppm}
                        onChange={handleChange}
                        placeholder="Parts per Million"
                    />
                </InputWrapper>

                <InputWrapper
                    isError={
                        Boolean(itemErrors?.description) &&
                        itemTouched?.description
                    }
                    error={itemErrors?.description}
                    label="Description"
                    className="flex-1"
                >
                    <input
                        name={`${prefix}.contaminants.${index}.description`}
                        className="w-full input input-bordered rounded-lg p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300 transition"
                        value={itemValues.description}
                        onChange={handleChange}
                        placeholder="Description"
                    />
                </InputWrapper>
            </div>
        </div>
    );
};
