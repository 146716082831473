import { IToxicology } from "common/types";
import React, { useRef } from "react";
import {
    InputGroup,
    InputTableCeil,
    FormLabelTemplate,
    CommonInput,
    LabelDivider,
    YesNoSelect,
} from "..";
import { ModalTemplate } from "components/shared/modalTemplate";
// import { useSubIngredientStore } from "pages/subIngredient/subIngredientProfile/store";

interface IProps {
    data: IToxicology;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
}

export const LCAIndicatorsRow: React.FC<IProps> = ({ data, onChange }) => {
    const LCAIndicators = useRef<HTMLDialogElement>(null);

    // const { isOwner } = useSubIngredientStore();

    const openModal = () => {
        if (LCAIndicators.current) {
            LCAIndicators.current.showModal();
        }
    };

    const closeModal = () => {
        if (LCAIndicators.current) {
            LCAIndicators.current.close();
        }
    };

    return (
        <tr>
            <InputTableCeil
                text="LCA Indicators Sub-rank"
                name="fix_rare_potential_impact"
                checked={Boolean(data.fix_rare_potential_impact)}
                onChange={onChange}
                // isDisabled={!isOwner}
            />
            <td className="flex p-3">
                <InputGroup
                    onChange={onChange}
                    value={data.rare_potential_impact}
                    name="rare_potential_impact"
                    onClick={openModal}
                    // isDisabled={!isOwner}
                />
                <ModalTemplate
                    text="LCA Indicators Sub-rank (rare potential impact SRV)"
                    onClose={closeModal}
                    modalRef={LCAIndicators}
                >
                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="rare pot. impact"
                            name="fix_rare_potential_impact"
                            onChange={onChange}
                            checked={Boolean(data.fix_rare_potential_impact)}
                        >
                            <CommonInput
                                name="rare_potential_impact"
                                value={data.rare_potential_impact}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="depends on" />
                    </div>

                    <div className="flex">
                        <FormLabelTemplate text="rare pot. impact">
                            <YesNoSelect
                                onChange={onChange}
                                value={data?.pmt}
                                name="pmt"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="," />

                        <FormLabelTemplate text="Eutrophication">
                            <YesNoSelect
                                onChange={onChange}
                                value={data.eutrophication_raw_score}
                                name="eutrophication_raw_score"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="," />

                        <FormLabelTemplate text="Smog">
                            <YesNoSelect
                                onChange={onChange}
                                value={data.smog_raw_score}
                                name="smog_raw_score"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="," />

                        <FormLabelTemplate text="Mineral">
                            <YesNoSelect
                                onChange={onChange}
                                value={data.mineral_raw_score}
                                name="mineral_raw_score"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="," />

                        <FormLabelTemplate text="Volatile">
                            <YesNoSelect
                                onChange={onChange}
                                value={data.volatile_raw_score}
                                name="volatile_raw_score"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="and" />

                        <FormLabelTemplate text="Global warming">
                            <YesNoSelect
                                onChange={onChange}
                                value={data.gw_raw_score}
                                name="gw_raw_score"
                            />
                        </FormLabelTemplate>
                    </div>

                    <div className="divider" />
                </ModalTemplate>
            </td>
        </tr>
    );
};
