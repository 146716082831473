import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import instance from "./axios/instance";
import { IFormulaSaved, IUser } from "common/types";
import { buildLink } from "common/utils";

class UserService extends BaseService {
    constructor(
        public baseUrl = backendKeys.users,
        private fetchingService = instance,
    ) {
        super(baseUrl);
    }

    async getUserLocation(): Promise<Pick<IUser, "country_name" | "city">> {
        const response = (await this.handleRequest(
            this.fetchingService.get(backendKeys.getLocation),
        )) as { country_name: string; city: string };

        return response;
    }

    async updateUser(payload: Partial<IUser>) {
        return this.handleRequest(
            this.fetchingService.put(
                this.getFullUrl(backendKeys.root),
                payload,
            ),
        );
    }

    async saveFormula(payload: { userId: string; formulaId: number }) {
        return this.handleRequest(
            this.fetchingService.post(
                this.getFullUrl(backendKeys.api.formulas.save),
                payload,
            ),
        );
    }

    async getSavedFormulas(): Promise<IFormulaSaved[]> {
        return this.handleRequest(
            this.fetchingService.get(
                this.getFullUrl(backendKeys.api.formulas.saved),
            ),
        );
    }

    async removeSavedFormula(id: number) {
        return this.handleRequest(
            this.fetchingService.delete(
                this.getFullUrl(buildLink(backendKeys.api.formulas.saved, id)),
            ),
        );
    }
}

export const userService = new UserService();
