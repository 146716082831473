import React from "react";
import { Navigation } from "navigation";
import { Navbar } from "components/navbar";
import { Footer } from "components/shared";

export const AppContent = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex flex-col flex-grow">
                <Navbar />
                <Navigation />
            </div>
            <Footer />
        </div>
    );
};
