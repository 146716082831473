import React, { FC } from "react";
import { Link } from "react-router-dom";
import { routerKeys } from "common/constants";
import { Tooltip } from "react-tooltip";

interface IProps {
    id: string | number;
    sicode: string;
    name?: string;
    description: string | null;
}

export const SubIngredientLink: FC<IProps> = ({
    id,
    sicode,
    name,
    description = null,
}) => (
    <Link to={`${routerKeys.subIngredient}/${id}`} className="hover:font-bold">
        {sicode}{" "}
        {(name || description) && (
            <span id={`sub-ingredient-description-${id}`}>
                {name ?? description}
            </span>
        )}
        {description && (
            <Tooltip
                anchorSelect={`#sub-ingredient-description-${id}`}
                variant="dark"
                content={description}
            />
        )}
    </Link>
);
