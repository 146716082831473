import React, { FC, memo } from "react";
import { IToxicology } from "common/types";
import {
    InputGroup,
    FormLabelTemplate,
    CommonInput,
    LabelDivider,
    Label,
} from "..";
import { ModalTemplate } from "components/shared/modalTemplate";
import { useModal } from "common/hooks";

interface IProps {
    data: IToxicology;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const OveralScoreForm: FC<IProps> = memo(({ data, onChange }) => {
    const {
        modalRef: overalModalRef,
        openModal,
        closeModal,
    } = useModal({
        onClose: () => {},
    });

    return (
        <div className="mb-2 rounded-lg  bg-[#EDE9FE] dark:bg-gray-800">
            <div className="md:flex lg:flex py-3 md:w-full lg:w-full">
                <Label text="Overall Environmental Score:" />
                <InputGroup
                    onChange={onChange}
                    value={data.overall_environmental_rank}
                    name="overall_environmental_rank"
                    onClick={openModal}
                    isDisabledInput={true}
                />
                <ModalTemplate
                    text="Overall environmental score"
                    onClose={closeModal}
                    modalRef={overalModalRef}
                >
                    <div className="flex">
                        <FormLabelTemplate text="Overall env. score">
                            <CommonInput
                                name="overall_environmental_rank"
                                value={data.overall_environmental_rank}
                                onChange={onChange}
                                disabled={true}
                            />
                        </FormLabelTemplate>
                        <LabelDivider text="= round (" />

                        <FormLabelTemplate
                            text="Persistence srv"
                            name="fix_p_raw_sub_rank"
                            onChange={onChange}
                            checked={Boolean(data.fix_p_raw_sub_rank)}
                        >
                            <CommonInput
                                name="p_raw_sub_rank"
                                value={data.p_raw_sub_rank}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>
                        <LabelDivider text="x 0.3 +" />

                        <FormLabelTemplate
                            text="Bioacc. srv"
                            name="fix_bioacc_srv"
                            onChange={onChange}
                            checked={Boolean(data.fix_bioacc_srv)}
                        >
                            <CommonInput
                                name="bioacc_srv"
                                value={data.bioacc_srv}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>
                        <LabelDivider text="x 0.2 +" />

                        <FormLabelTemplate
                            text="rare pot. impact"
                            name="fix_rare_potential_impact"
                            onChange={onChange}
                            checked={Boolean(data.fix_rare_potential_impact)}
                        >
                            <CommonInput
                                name="rare_potential_impact"
                                value={data.rare_potential_impact}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>
                        <LabelDivider text="x 0.2 +" />

                        <FormLabelTemplate
                            text="Toxicity srv"
                            name="fix_toxicity_sub_rank_value_srv"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_toxicity_sub_rank_value_srv,
                            )}
                        >
                            <CommonInput
                                name="toxicity_sub_rank_value_srv"
                                value={data.toxicity_sub_rank_value_srv}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>
                        <LabelDivider text="x 0.3)" />
                    </div>
                </ModalTemplate>
            </div>
        </div>
    );
});
