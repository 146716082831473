import React, { FC } from "react";
import { SubIngredientRow } from "./SubIngredientRow";
import { IFormulaSubIngredient } from "pages/formulas/formula-profile/types";

interface IProps {
    items: IFormulaSubIngredient[];
}

export const SubIngredientsList: FC<IProps> = ({ items }) => (
    <>
        {items
            .sort((a, b) => {
                if (!a?.name) return 1;
                if (!b?.name) return -1;
                return a.name.localeCompare(b.name);
            })
            ?.map((sub) => <SubIngredientRow key={sub.id} item={sub} />)}
    </>
);
