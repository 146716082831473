import { defaultErrorToastOptions, toastTexts } from "common/constants";
import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

export const ErrorToast = () => {
    useEffect(() => {
        toast.error(toastTexts.error, {
            ...defaultErrorToastOptions,
            containerId: "ErrorToast",
        });
    }, []);

    return <ToastContainer containerId="ErrorToast" />;
};
