export const testsConfig = {
    CLNCI: {
        attributes: {
            concentration_percent: true,
            patch_type: true,
            panel_size: true,
        },
        results: { total_score: true, conclusion: true },
    },
    CLNCU: {
        attributes: { concentration_percent: true, patch_type: true },
    },
    CLNHAAC: {
        attributes: { concentration_percent: true, patch_type: true },
        results: { results_acnegenicity: true, conclusion: true },
    },
    CLNHACD: {
        attributes: {
            concentration_percent: true,
            patch_type: true,
            panel_size: true,
        },
        results: { results_comedogenicity: true, conclusion: true },
    },
    CLNHAHA: {
        attributes: {
            concentration_percent: true,
            patch_type: true,
            panel_size: true,
        },
        results: {
            induction_results: true,
            challenge_results: true,
            comments: true,
            conclusion: true,
        },
    },
    CLNHAHABR: {
        attributes: {
            concentration_percent: true,
            patch_type: true,
            panel_size: true,
        },
        results: {
            induction_results: true,
            challenge_results: true,
            comments: true,
            conclusion: true,
        },
    },
    CLNHAPA: {
        attributes: {
            concentration_percent: true,
            patch_type: true,
            panel_size: true,
        },
        results: {
            results_photoallergenicity: true,
            comments: true,
            conclusion: true,
        },
    },
    CLNHAPT: {
        attributes: {
            concentration_percent: true,
            patch_type: true,
            panel_size: true,
        },
        results: { results_phototoxicity: true, conclusion: true },
    },
    CLNIU: {
        attributes: {
            test_duration_days: true,
            patch_type: true,
            panel_size: true,
        },
        results: {
            no_with_clinical_changes: true,
            nature_of_clinical_change: true,
            no_with_subjective_changes: true,
            nature_of_subjective_change: true,
            conclusion: true,
        },
    },
    CLNMCAC: {
        results: { primary_irritation_index_pii: true, conclusion: true },
    },
    CLNMCMC: {
        results: { primary_irritation_index_pii: true, conclusion: true },
    },
    CLNSI: {
        attributes: {
            concentration_percent: true,
            patch_type: true,
            panel_size: true,
        },
        results: { primary_irritation_index_pii: true, conclusion: true },
    },
    CLNST: {
        results: {
            total_discomfort_score: true,
            discomfort_index: true,
            control_score_bt_test_score: true,
            test_score_bt_control_score: true,
        },
    },
    CLNUT: {
        attributes: { physician_staff: true, panel_size: true },
        results: {
            percent_of_total_clinical_changes: true,
            w_clin_increase_in_irritation: true,
            w_clin_increase_in_dryness: true,
            percent_of_total_perceived_responses: true,
            reporting_visible_irritation: true,
            reporting_visible_dryness: true,
            reporting_discomfort: true,
        },
    },
    TOX3T3: {
        attributes: { ic50: true, pif: true, mpe: true },
        results: { conclusion: true },
    },
    TOXAMES: {
        results: { conclusion: true },
    },
    TOXANA: {
        results: { conclusion: true },
    },
    TOXBCOP: {
        results: { mean_opacity_value_10_min_exp_2_hr_post_exp: true },
    },
    TOXCV: {
        results: { rc50: true, conclusion: true },
    },
    TOXEO: {
        attributes: { concentration_percent: true },
        results: { score_et50_minutes: true },
    },
    TOXEPIDERM: {
        attributes: { concentration: true },
        results: {
            score_et50_minutes: true,
            conclusion_dermal_irritation: true,
            classification: true,
        },
    },
    TOXEPIORAL: {
        attributes: { concentration_percent: true },
        results: {
            score_et50_minutes: true,
            conclusion_oral_irritation: true,
            classification: true,
        },
    },
    TOXEPIPHOTO: {
        attributes: { concentration_percent: true, exposure_time_hours: true },
        results: {
            viability_percent_of_control_w_uva: true,
            viability_percent_of_control_w_o_uva: true,
            conclusion_phototoxic: true,
        },
    },
    TOXET: {
        attributes: { concentration_percent: true },
    },
    TOXIM: {
        attributes: { concentration_percent: true },
    },
    TOXPI: {
        results: { final_pii: true },
    },
};
