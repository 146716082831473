import { Button } from "components/shared/button";
import { ModalTemplate } from "components/shared/modalTemplate";
import React, { FC, ReactNode } from "react";
import { Tab } from "../types";

import { useParams } from "react-router-dom";
import { useUploadFormula } from "pages/formulas/formula-profile/queries";
import { CreateINCIForm as CreateSubIngredientsForm } from "pages/subIngredient/subIngredients/components";
import { CreateContaminantForm } from "pages/contaminant/contaminants/components";
import { useModal } from "common/hooks";
import { CreateRawIngredientForm } from "pages/rawIngredient/rawIngredients/components";

interface IProps {
    selectedTab: Tab;
}

export const AddRelatedIngredients: FC<IProps> = ({ selectedTab }) => {
    const text = selectedTab.slice(0, -1);
    const { id = "" } = useParams();
    const { refetch } = useUploadFormula({ id });

    const {
        modalRef: createFormulaRef,
        openModal,
        closeModal,
    } = useModal({
        onClose: () => {
            refetch();
        },
    });

    const ContentMap: Record<Tab, ReactNode> = {
        ["Raw Ingredients"]: (
            <CreateRawIngredientForm
                handleClose={closeModal}
                formulas_id={id}
            />
        ),
        ["INCIs"]: (
            <CreateSubIngredientsForm
                handleClose={closeModal}
                formulas_id={id}
            />
        ),
        Contaminants: (
            <CreateContaminantForm handleClose={closeModal} formulas_id={id} />
        ),
    };

    return (
        <div className="w-full flex my-4">
            {selectedTab !== "INCIs" ? (
                <Button text={`Add ${text}`} onClick={openModal} />
            ) : (
                <div className="w-[120px] h-[49px]"></div>
            )}
            <ModalTemplate
                text={`Add ${text}`}
                onClose={closeModal}
                modalRef={createFormulaRef}
                className="max-w-[600px]"
            >
                {ContentMap[selectedTab]}
            </ModalTemplate>
        </div>
    );
};
