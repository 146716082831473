import React, { FC, useMemo } from "react";
import { CircularProgress } from "@mui/material";
import { CommonConfidence } from "./components";
import { classMap } from "../../../../libs";
import classNames from "classnames";
import { type RequestStatus } from "pages/subIngredient/subIngredientProfile/libs";

interface IMessageProps {
    title: string;
}

const Message: FC<IMessageProps> = ({ title }) => (
    <div className="text-sm font-semibold text-blue-700">{title}</div>
);

const LoadingState = () => (
    <div className="flex items-center space-x-2">
        <CircularProgress size={20} color="inherit" />
        <span>Loading...</span>
    </div>
);

interface IProps {
    potency?: string | null;
    status?: RequestStatus;
    title: string;
    confidenceValue?: number;
}

export const CommonPredictionComponent: FC<IProps> = ({
    potency = "NC (Not classified)",
    status,
    title,
    confidenceValue,
}) => {
    const [divClass, divText] = useMemo(() => {
        return (
            classMap?.[potency || "NC (Not classified)"] || [
                "prediction-category--0",
                "Not classified",
            ]
        );
    }, [potency]);

    const renderConfidence = confidenceValue !== undefined && (
        <CommonConfidence value={confidenceValue} />
    );

    if (status === "FAILED") {
        return (
            <div className="flex flex-col space-y-2">
                <Message title={title} />
                <div className="p-2 text-sm prediction-category--0">
                    Mandatory parameters missing
                </div>
                {renderConfidence}
            </div>
        );
    }

    if (status === "PENDING") {
        return (
            <div className="space-y-2">
                <div className="text-sm text-gray-700">{title}</div>
                <LoadingState />
            </div>
        );
    }

    if (status === "SUCCESS") {
        return (
            <div className="flex flex-col space-y-2">
                <Message title={title} />
                <div className={classNames("p-2 text-sm", divClass)}>
                    {divText}
                </div>
                {renderConfidence}
            </div>
        );
    }

    return null;
};
