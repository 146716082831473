import React, { useCallback, useEffect, useState } from "react";
import { ButtonWithLoader, TabContentTemplate, Title } from "components/shared";
import { useSubIngredientData } from "pages/subIngredient/subIngredientProfile/hooks";
import {
    useAnnPrediction,
    useBDFPrediction,
    useIts3Prediction,
    useOperaSkinSafetyData,
    useRegressionPrediction,
    useRfPrediction,
    useRifmBnPrediction,
    useRifmHnoelHloelPrediction,
    useSkinDoctorPrediction,
    useTG497Prediction,
    useVoitingPrediction,
} from "pages/subIngredient/subIngredientProfile/queries";
import { DataModeling, Prediction } from "./components";
import {
    type PredictionStatuses,
    type MoleculeDataType,
    initialMoleculeDataModel,
} from "pages/subIngredient/subIngredientProfile/libs";
import {
    TabData,
    convertSkinDoctorCP,
    modifyData,
    moleculeValidationSchema,
    potencyGenerator,
    sensitizationClassDecoder,
} from "./libs";
import { useFormik } from "formik";

export const SkinSafetyTab = () => {
    const [selectedTab, setSelectedTab] = useState<string>(
        TabData.OECD_2o3.key,
    );
    const [moleculeData, setMoleculeData] = useState<MoleculeDataType>(
        {} as MoleculeDataType,
    );
    const [isMichaelAcceptor, setIsMichaelAcceptor] = useState<boolean>(false);
    const [predictions, setPredictions] = useState<PredictionStatuses>();
    const [skinDoctorCP, setSkinDoctorCP] = useState("");

    const { data: subIngredient } = useSubIngredientData();
    const { mutate: getOperaData, status } = useOperaSkinSafetyData();

    useEffect(() => {
        if (subIngredient?.unique_smiles) {
            setMoleculeData((state) => ({
                ...state,
                smiles: subIngredient.unique_smiles,
            }));
        }
    }, [subIngredient?.unique_smiles]);

    const toggleMichaelAcceptor = useCallback(() => {
        setIsMichaelAcceptor((state) => !state);
    }, [isMichaelAcceptor]);

    if (!subIngredient?.unique_smiles) {
        return (
            <div className="md:mx-5 lg:mx-10 mt-2.5 flex items-center justify-center p-4  bg-red-50 text-red-600 rounded-lg">
                <span className="text-lg font-semibold">
                    No SMILES data available
                </span>
            </div>
        );
    }

    const { mutate: makeAnnPrediction } = useAnnPrediction();
    const { mutate: makeRegressionPrediction } = useRegressionPrediction();
    const { mutate: makeVoitingPrediction } = useVoitingPrediction();
    const { mutate: makeSkinDoctorPrediction } = useSkinDoctorPrediction();
    const { mutate: makeTG497Prediction } = useTG497Prediction();
    const { mutate: makeRf1Prediction } = useRfPrediction();
    const { mutate: makeRf2Prediction } = useRfPrediction();
    const { mutate: makeRf3Prediction } = useRfPrediction();
    const { mutate: makeRifmBnPrediction } = useRifmBnPrediction();
    const { mutate: makeRifmHnoelHloelPrediction } =
        useRifmHnoelHloelPrediction();
    const { mutate: makeIts3Prediction } = useIts3Prediction();
    const { mutate: makeBDFAPrediction } = useBDFPrediction();
    const { mutate: makeBDFBPrediction } = useBDFPrediction();

    const formik = useFormik({
        initialValues: initialMoleculeDataModel,
        validationSchema: moleculeValidationSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,

        onSubmit: async (values) => {
            if (predictions) {
                Object.keys(predictions)?.forEach((key) => {
                    setPredictions((state) => ({
                        ...state,
                        [key]: {
                            ...([
                                predictions?.[key as keyof PredictionStatuses],
                            ] || {}),
                            status: "PENDING",
                        },
                    }));
                });
            }

            const model = { ...moleculeData, ...values };
            const modifiedData = modifyData(
                model as unknown as MoleculeDataType,
            );

            const {
                keratinoSenseAssayJaworskaIC50 = null,
                keratinoSenseAssayJaworskaKEC15 = null,
            } = modifiedData;

            const preparedKeratinoSenseAssayForRegressionModel = {
                keratinoSenseAssayJaworskaIC50:
                    typeof keratinoSenseAssayJaworskaIC50 === "string" &&
                    +keratinoSenseAssayJaworskaIC50 >= 2000
                        ? "4000"
                        : keratinoSenseAssayJaworskaIC50,
                keratinoSenseAssayJaworskaKEC15:
                    typeof keratinoSenseAssayJaworskaKEC15 === "string" &&
                    +keratinoSenseAssayJaworskaKEC15 >= 2000
                        ? "4000"
                        : keratinoSenseAssayJaworskaKEC15,
            };

            let skinDoctorPredictedClass: number | null = null;

            try {
                await Promise.allSettled([
                    makeRegressionPrediction(
                        {
                            ...(model as unknown as MoleculeDataType),
                            ...preparedKeratinoSenseAssayForRegressionModel,
                        },
                        {
                            onSuccess: (data) =>
                                setPredictions((curr) => ({
                                    ...curr,
                                    regressionPredict: {
                                        ...curr?.regressionPredict,
                                        status: "SUCCESS",
                                        data,
                                        sensitizerClass:
                                            sensitizationClassDecoder(
                                                data?.sensitizerClass,
                                            ),
                                    },
                                })),
                            onError: (err) =>
                                setPredictions((curr) => ({
                                    ...curr,
                                    regressionPredict: {
                                        ...curr?.regressionPredict,
                                        status: "FAILED",

                                        data: null,
                                        message: err.message,
                                    },
                                })),
                        },
                    ),
                    makeVoitingPrediction(modifiedData, {
                        onSuccess: (data) =>
                            setPredictions((curr) => ({
                                ...curr,
                                voitingPredict: {
                                    ...curr?.voitingPredict,
                                    status: "SUCCESS",
                                    data,
                                },
                            })),
                        onError: () =>
                            setPredictions((curr) => ({
                                ...curr,
                                voitingPredict: {
                                    ...curr?.voitingPredict,
                                    status: "FAILED",
                                    data: null,
                                },
                            })),
                    }),
                    makeAnnPrediction(modifiedData, {
                        onSuccess: (data) =>
                            setPredictions((curr) => ({
                                ...curr,
                                annPredict: {
                                    ...(curr?.annPredict || {}),
                                    status: "SUCCESS",
                                    data,
                                },
                            })),
                        onError: () =>
                            setPredictions((curr) => ({
                                ...curr,
                                annPredict: {
                                    ...(curr?.annPredict || {}),
                                    status: "FAILED",
                                    data: null,
                                },
                            })),
                    }),
                    makeTG497Prediction(modifiedData, {
                        onSuccess: (data) =>
                            setPredictions((curr) => ({
                                ...curr,
                                tg497Predict: {
                                    ...curr?.tg497Predict,
                                    data,
                                    status: "SUCCESS",
                                },
                            })),
                        onError: () =>
                            setPredictions((curr) => ({
                                ...curr,
                                tg497Predict: {
                                    ...curr?.tg497Predict,
                                    data: null,
                                    status: "FAILED",
                                },
                            })),
                    }),
                    makeRf1Prediction(
                        { data: modifiedData, selectedModel: 1 },
                        {
                            onSuccess: (data) =>
                                setPredictions((curr) => ({
                                    ...curr,
                                    rFPredictECETOC: {
                                        ...curr?.rFPredictECETOC,
                                        data,
                                        status: "SUCCESS",
                                        chemicalPotency: potencyGenerator(
                                            data?.chemicalPotency,
                                        ),
                                    },
                                })),
                            onError: () =>
                                setPredictions((curr) => ({
                                    ...curr,
                                    rFPredictECETOC: {
                                        ...curr?.rFPredictECETOC,
                                        data: null,
                                        status: "FAILED",
                                    },
                                })),
                        },
                    ),
                    makeRf2Prediction(
                        { data: modifiedData, selectedModel: 2 },
                        {
                            onSuccess: (data) =>
                                setPredictions((curr) => ({
                                    ...curr,
                                    rFPredictGHS: {
                                        ...curr?.rFPredictGHS,
                                        data,
                                        status: "SUCCESS",
                                        chemicalPotency: potencyGenerator(
                                            data?.chemicalPotency,
                                        ),
                                    },
                                })),
                            onError: () =>
                                setPredictions((curr) => ({
                                    ...curr,
                                    rFPredictGHS: {
                                        ...curr?.rFPredictGHS,
                                        data: null,
                                        status: "FAILED",
                                    },
                                })),
                        },
                    ),
                    makeRf3Prediction(
                        { data: modifiedData, selectedModel: 3 },
                        {
                            onSuccess: (data) =>
                                setPredictions((curr) => ({
                                    ...curr,
                                    ["rFPredictP&N"]: {
                                        ...curr?.["rFPredictP&N"],
                                        data,
                                        status: "SUCCESS",
                                        chemicalPotency: potencyGenerator(
                                            data?.chemicalPotency,
                                        ),
                                    },
                                })),
                            onError: () =>
                                setPredictions((curr) => ({
                                    ...curr,
                                    ["rFPredictP&N"]: {
                                        ...curr?.["rFPredictP&N"],
                                        data: null,
                                        status: "FAILED",
                                    },
                                })),
                        },
                    ),
                    makeRifmBnPrediction(modifiedData, {
                        onSuccess: (data) =>
                            setPredictions((curr) => ({
                                ...curr,
                                rifmBnPredict: {
                                    ...curr?.rifmBnPredict,
                                    data: JSON.parse(data.result),
                                    status: "SUCCESS",
                                },
                            })),
                        onError: () =>
                            setPredictions((curr) => ({
                                ...curr,
                                rifmBnPredict: {
                                    ...curr?.rifmBnPredict,
                                    data: null,
                                    status: "FAILED",
                                },
                            })),
                    }),
                    makeIts3Prediction(
                        { data: modifiedData, isMichaelAcceptor },
                        {
                            onSuccess: (data) =>
                                setPredictions((curr) => ({
                                    ...curr,
                                    its3Predict: {
                                        ...curr?.its3Predict,
                                        data,
                                        status: "SUCCESS",
                                        predictedClass:
                                            sensitizationClassDecoder(
                                                data?.predictionResult
                                                    ?.predictedClass,
                                            ),
                                    },
                                })),
                            onError: () =>
                                setPredictions((curr) => ({
                                    ...curr,
                                    its3Predict: {
                                        ...curr?.its3Predict,
                                        data: null,
                                        status: "FAILED",
                                    },
                                })),
                        },
                    ),
                    makeRifmHnoelHloelPrediction({
                        data: modifiedData,
                        selectedModel: 1,
                    }),
                    makeRifmHnoelHloelPrediction({
                        data: modifiedData,
                        selectedModel: 0,
                    }),
                    makeSkinDoctorPrediction(model?.smiles as string, {
                        onSuccess: (data) => {
                            const value = convertSkinDoctorCP(
                                data?.finalPrediction,
                            );
                            skinDoctorPredictedClass = value;

                            return setPredictions((curr) => ({
                                ...curr,
                                skinDoctorPredict: {
                                    ...curr?.skinDoctorPredict,
                                    data,
                                    status: "SUCCESS",
                                    sensitiserPotency: data?.finalPrediction,
                                },
                            }));
                        },
                        onError: () => {
                            setPredictions((curr) => ({
                                ...curr,
                                skinDoctorPredict: {
                                    ...curr?.skinDoctorPredict,
                                    data: null,
                                    status: "FAILED",
                                },
                            }));
                        },
                        onSettled: async () => {
                            await Promise.allSettled([
                                makeBDFAPrediction(
                                    {
                                        data: modifiedData,
                                        skinDoctorPredictedClass,
                                        modelType: "bdfA",
                                    },
                                    {
                                        onSuccess: (data) =>
                                            setPredictions((curr) => ({
                                                ...curr,
                                                bdfAPredict: {
                                                    ...curr?.bdfAPredict,
                                                    data: JSON.parse(
                                                        data?.result,
                                                    ),
                                                    status: "SUCCESS",
                                                },
                                            })),
                                        onError: () =>
                                            setPredictions((curr) => ({
                                                ...curr,
                                                bdfAPredict: {
                                                    ...curr?.bdfAPredict,
                                                    data: null,
                                                    status: "FAILED",
                                                },
                                            })),
                                    },
                                ),

                                makeBDFBPrediction(
                                    {
                                        data: modifiedData,
                                        skinDoctorPredictedClass,
                                        modelType: "bdfB",
                                    },
                                    {
                                        onSuccess: (data) =>
                                            setPredictions((curr) => ({
                                                ...curr,
                                                bdfBPredict: {
                                                    ...curr?.bdfBPredict,
                                                    data: JSON.parse(
                                                        data?.result,
                                                    ),
                                                    status: "SUCCESS",
                                                },
                                            })),
                                        onError: () =>
                                            setPredictions((curr) => ({
                                                ...curr,
                                                bdfBPredict: {
                                                    ...curr?.bdfBPredict,
                                                    data: null,
                                                    status: "FAILED",
                                                },
                                            })),
                                    },
                                ),
                            ]);

                            if (!skinDoctorCP) {
                                setSkinDoctorCP(
                                    skinDoctorPredictedClass?.toString() ?? "",
                                );
                            }
                        },
                    }),
                ]);
            } catch (error) {
                console.error("Prediction error:", error);
            }
        },
    });

    const handleLoadButtonClick = useCallback(() => {
        if (subIngredient && subIngredient.unique_smiles) {
            getOperaData(subIngredient.unique_smiles, {
                onSuccess: (data) => {
                    setMoleculeData((state) => ({ ...state, ...data }));

                    Object.entries(data).forEach(([key, value]) => {
                        if (
                            Object.keys(initialMoleculeDataModel).includes(key)
                        ) {
                            formik.setFieldValue(key, value);
                        }
                    });
                },
            });
        }
    }, [getOperaData, subIngredient]);

    useEffect(() => {
        const updatedObject = Object.keys(formik.values)
            .filter((key) => key in moleculeData)
            .reduce((acc: { [key: string]: unknown }, key) => {
                acc[key] = formik.values[key as keyof typeof formik.values];
                return acc;
            }, {});

        setMoleculeData((state) => ({
            ...state,
            ...updatedObject,
        }));
    }, [formik.values]);

    return (
        <TabContentTemplate>
            <div className="flex">
                <Title text="Skin Safety" />
            </div>
            <div className="flex flex-col ">
                <ButtonWithLoader
                    text="Load Molecule Data"
                    onClick={handleLoadButtonClick}
                    isLoading={status === "pending"}
                    className="max-w-1/3"
                    smiles={subIngredient?.unique_smiles}
                />

                <form onSubmit={formik.handleSubmit}>
                    <div className="flex gap-4">
                        <DataModeling
                            className="max-w-3/4"
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            skinDoctorCP={skinDoctorCP}
                            setSkinDoctorCP={setSkinDoctorCP}
                            isMichaelAcceptor={isMichaelAcceptor}
                            toggleMichaelAcceptor={toggleMichaelAcceptor}
                            onChange={formik.handleChange}
                            values={formik.values}
                            errors={formik.errors}
                        />
                        <Prediction
                            className="max-w-1/4"
                            model={moleculeData}
                            dataStatus={status}
                            predictions={predictions}
                            errors={formik.errors}
                        />
                    </div>
                </form>
            </div>
        </TabContentTemplate>
    );
};
