import * as yup from "yup";

export const createFormulaSchema = yup.object({
    ficode: yup.string().required("Field is required"),
    alternate_code: yup.string(),
    status: yup.string(),
    description: yup.string(),
    release_date: yup.string(),
    initial_ph_min: yup.string(),
    initial_ph_max: yup.string(),
    physical_state: yup.string(),
    fda_product_classification: yup.string(),
    rinseoff_leaveon: yup.string(),
    flammability_code: yup.string(),
    emulsion: yup.string(),
    ingredient_guideline_product_type_info: yup.string(),
    usertype: yup.string(),
    version: yup.string(),
    tests_notes: yup.string(),
    product_type: yup.string(),
    grams_applied_per_day: yup.string(),
    body_weight: yup.string().default("60"),
    skin_retention_factor: yup.string(),
});
