import { Title } from "components/shared";
import React, { ChangeEvent, useState } from "react";
import { SafetyTechDocActions } from "./components";
import {
    useGetRawIngredient,
    useUploadSafetyDocument,
    useUploadedSafetyDocuments,
    useDeleteSafetyDocument,
} from "pages/rawIngredient/rawIngredientProfile/queries";
import { DocumentTypeT, SafetyDocLabel } from ".";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
} from "common/constants";
// import { CloseIcon } from "assets/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export const SafetyTechnicalDocuments = () => {
    const [uploadLabel, setUploadLabel] = useState("");

    const rawIngredient = useGetRawIngredient();
    const { data: uploadedFiles, refetch } = useUploadedSafetyDocuments(
        rawIngredient?.id ?? null,
    );

    const { mutate: uploadFile } = useUploadSafetyDocument();
    const { mutate: deleteFile } = useDeleteSafetyDocument();

    const handleUploadDocument = (type: DocumentTypeT, file: File | null) => {
        if (file && rawIngredient?.id) {
            uploadFile(
                {
                    id: rawIngredient.id,
                    type,
                    file,
                },
                {
                    onSuccess: () => {
                        toast.success(
                            "File uploaded successfully.",
                            defaultToastOptions,
                        );
                        setUploadLabel("");
                        refetch();
                    },
                    onError: (error) => {
                        toast.error(
                            `Upload failed: ${error.message}`,
                            defaultErrorToastOptions,
                        );
                    },
                },
            );
        } else {
            setUploadLabel("");
        }
    };

    const handleAddFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            const validFileTypes = [
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ];
            if (!validFileTypes.includes(file.type)) {
                const errorMessage =
                    "Invalid file type. Please upload a PDF, Word document, or Excel file.";
                toast.error(errorMessage, defaultErrorToastOptions);
                setUploadLabel(errorMessage);
                return;
            }

            const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
            if (file.size > maxSizeInBytes) {
                const errorMessage =
                    "File is too large. Maximum size allowed is 10MB.";
                toast.error(errorMessage, defaultErrorToastOptions);
                setUploadLabel(errorMessage);
                return;
            }
        } else {
            setUploadLabel("");
        }
    };

    const handleDeleteFile = (fileType: keyof typeof SafetyDocLabel) => {
        if (rawIngredient?.id) {
            deleteFile(
                { id: rawIngredient.id, type: fileType },
                {
                    onSuccess: () => {
                        toast.success(
                            "File deleted successfully.",
                            defaultToastOptions,
                        );
                        refetch();
                    },
                    onError: (error) => {
                        toast.error(
                            `Delete failed: ${error.message}`,
                            defaultErrorToastOptions,
                        );
                    },
                },
            );
        }
    };

    return (
        <div className="mx-10 my-3">
            <div className="flex mb-4">
                <Title text="Safety & Technical Documents" />
            </div>
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                <table className="w-full text-left">
                    <tbody>
                        {Object.keys(uploadedFiles || {})?.length ? (
                            Object.entries(uploadedFiles || {}).map(
                                ([key, value], index) => (
                                    <tr
                                        key={`${key}-${index}`}
                                        className="hover:bg-gray-100 border-b"
                                    >
                                        <td className="w-3/4 p-3">
                                            <Link
                                                className="flex items-center primary-color hover:font-bold"
                                                to={value}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                />
                                                <span className="ml-2 inline-block">
                                                    {SafetyDocLabel[
                                                        key as keyof typeof SafetyDocLabel
                                                    ] || key}
                                                </span>
                                            </Link>
                                        </td>
                                        <td className="flex p-3">
                                            <button
                                                className="btn btn-xs"
                                                onClick={() =>
                                                    handleDeleteFile(
                                                        key as keyof typeof SafetyDocLabel,
                                                    )
                                                }
                                            >
                                                delete
                                            </button>
                                        </td>
                                    </tr>
                                ),
                            )
                        ) : (
                            <tr>
                                <td className="w-3/4 text-xs md:text-base lg:text-base p-3">
                                    No files uploaded
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <SafetyTechDocActions
                    isOwner={true}
                    uploadLabel={uploadLabel}
                    handleClick={handleUploadDocument}
                    handleAddFile={handleAddFile}
                />
            </div>
        </div>
    );
};
