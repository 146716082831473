export const buildLinkWithQuery = (
    base: string,
    id?: number,
    queryParams?: Record<string, string>,
): string => {
    const url = new URL(id ? base + id : base, window.location.origin);

    if (queryParams) {
        Object.keys(queryParams).forEach((key) =>
            url.searchParams.append(key, queryParams[key]),
        );
    }

    return url.toString();
};
