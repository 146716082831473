export const profileConfig = {
    userInfo: {
        title: "User account",
        subtitle:
            "Welcome to your SmartSafety User Account! Here, you can easily access and edit your profile information. For any questions, please contact us at info@smartsafety.com.",
    },
    savedItems: {
        title: "Saved items",
        subtitle: "Welcome to your SmartSafety Saved Items.",
    },
};
