import React from "react";

interface IProps {
    headNames: string[];
    withHash?: boolean;
    children: React.ReactNode;
}

interface ITableHeadCeilProps {
    text: string;
}

const TableHeadCeil: React.FC<ITableHeadCeilProps> = ({ text }) => (
    <th className="p-2 md:p-3 lg:p-3 text-left text-sm md:text-base lg-text-base border-b border-primary-main-dgray">
        {text}
    </th>
);

export const Table: React.FC<IProps> = React.memo(
    ({ headNames, withHash, children }) => {
        return (
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg mb-2">
                <thead className="bg-indigo-100">
                    <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                        {withHash && (
                            <th
                                className="p-3 text-left border-b border-primary-main-dgray"
                                style={{ width: 30 }}
                            >
                                #
                            </th>
                        )}
                        {headNames.map((name) => (
                            <TableHeadCeil text={name} key={name} />
                        ))}
                    </tr>
                </thead>
                <tbody className="flex-1 sm:flex-none">{children}</tbody>
            </table>
        );
    },
);
