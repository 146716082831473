import { ArrowTop } from "assets/icons";
import classNames from "classnames";
import React, { useState, useEffect, FC } from "react";

interface IProps {
    height?: number;
    className?: string;
}

export const ScrollUpButton: FC<IProps> = ({
    height = 400,
    className = "",
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const goUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop;
        setIsVisible(scrollTop > height);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return isVisible ? (
        <div className="fixed right-10 bottom-10">
            <button
                className={classNames(
                    `btn btn-circle !bg-accent !rounded-full`,
                    className,
                )}
                onClick={goUp}
            >
                <ArrowTop color="white" />
            </button>
        </div>
    ) : null;
};
