import { Title } from "components/shared/title";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import { TableCeil } from "components/shared/tableCeil";
import { TableHeaderCeil } from "components/shared/tableHeaderCeil";
import { useGetFormula } from "pages/formulas/formula-profile/queries";

export const TestsTab = () => {
    const formula = useGetFormula();

    return (
        <div>
            <div className="md:flex lg:flex">
                <div className="md:w-2/3 lg:w-2/3 mx-10 my-3">
                    <Title text="Toxicological and Clinical Safety Testing of the Formula" />

                    <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg">
                        <thead>
                            <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                                <TableHeaderCeil text="Test" />
                                <TableHeaderCeil text="Attributes" />
                                <TableHeaderCeil text="Results" />
                            </tr>
                        </thead>
                        <tbody>
                            {formula?.formulasTests?.length ? (
                                <>
                                    {formula?.formulasTests.map((test) => (
                                        <tr key={uuidv4()}>
                                            <td className="p-3 md:p-3 lg:p-3 text-left text-xs md:text-base lg-text-base">
                                                {test.test_code}
                                            </td>
                                            <td className="p-3 md:p-3 lg:p-3 text-left text-xs md:text-base lg-text-base">
                                                {test.attributes.map(
                                                    (attribute, attrIndex) => (
                                                        <div key={attrIndex}>
                                                            {attribute.name}:{" "}
                                                            {attribute.value}
                                                        </div>
                                                    ),
                                                )}
                                            </td>
                                            <td className="p-3 md:p-3 lg:p-3 text-left text-xs md:text-base lg-text-base">
                                                {test.results.map(
                                                    (result, resultIndex) => (
                                                        <div key={resultIndex}>
                                                            {result.name}:{" "}
                                                            {result.value}
                                                        </div>
                                                    ),
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <TableCeil text="No Tests found" />
                                    <TableCeil />
                                    <TableCeil />
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
