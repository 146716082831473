import { IReport } from "common/types";
import { modifyDate } from "common/utils";
import React from "react";

interface IProps {
    file: IReport;
    deleteFile: () => void;
    handleDownload: () => Promise<void>;
}

export const ReportRow: React.FC<IProps> = ({
    file,
    deleteFile,
    handleDownload,
}) => {
    return (
        <tr>
            <td className="w-3/4 text-xs md:text-base lg:text-base p-3">
                <div>
                    <button onClick={handleDownload}>{`${
                        file.filename
                    } (${modifyDate(file.modified)})`}</button>
                </div>
            </td>
            <td className="flex p-3">
                <button className="btn btn-xs" onClick={deleteFile}>
                    delete
                </button>
            </td>
        </tr>
    );
};
