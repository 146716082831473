import React, { ChangeEvent, FC, useState, useEffect } from "react";
import { IManualInput } from "../types";

type IProps = {
    index: number;
    field: keyof IManualInput;
    value: string;
    onChange: (index: number, field: keyof IManualInput, value: string) => void;
};

export const CheckboxInput: FC<IProps> = ({
    index,
    field,
    value,
    onChange,
}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        if (!isChecked) {
            setInputValue(value);
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value) {
            setInputValue(e.target.value);
        }
    };

    useEffect(() => {
        if (isChecked) {
            onChange(index, field, inputValue);
        } else {
            onChange(index, field, value);
        }
    }, [inputValue, isChecked, onChange, value]);

    return (
        <div className="flex justify-center items-center">
            <label className="flex items-center">
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="checkbox checkbox-xs rounded-t-none"
                />
                <span className="mx-0 px-0 text-sm">fix</span>
            </label>
            {isChecked ? (
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2 px-2 py-0.5 max-w-[100px]"
                />
            ) : (
                <span className="text-gray-700 ml-2">{value}</span>
            )}
        </div>
    );
};
