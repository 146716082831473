import React from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { InputWrapper } from "components/shared/inputWrapper";
import { Button } from "../../../../components/shared/button";
import {
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    toastTexts,
} from "../../../../common/constants";
import { useCreateVendor } from "pages/adminPanel/queries";
import { createVendorSchema } from "pages/adminPanel/schemas";

export const AddVendorForm = ({
    createButton = "Create",
    hideClearButton = false,
    className = "",
    onCreateVendor = () => {},
}) => {
    const { mutate: createVendor, isPending } = useCreateVendor();

    const formik = useFormik({
        initialValues: {
            description: "",
        },
        validationSchema: createVendorSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            createVendor(
                { body: values },
                {
                    onSuccess: () => {
                        toast.update(toastId, {
                            ...defaultSuccessToastUpdate,
                            render: toastTexts.success,
                        });
                        onCreateVendor();
                    },
                    onError: () => {
                        toast.update(toastId, defaultErrorToastUpdate);
                    },
                },
            );
        },
    });

    const { values, errors, touched, handleChange } = formik;

    return (
        <form
            className="w-full flex flex-col gap-2 max-w-96"
            onSubmit={formik.handleSubmit}
        >
            <div className={className}>
                <div className="flex flex-row items-end gap-2">
                    <InputWrapper
                        isError={Boolean(
                            errors.description && touched.description,
                        )}
                        error={errors.description}
                        label="Manufacturer"
                    >
                        <input
                            type="text"
                            name="description"
                            className="w-full input input-bordered"
                            value={values.description}
                            onChange={handleChange}
                        />
                    </InputWrapper>
                    {!hideClearButton && (
                        <Button
                            text="Clear"
                            className="min-w-max"
                            onClick={() => formik.resetForm()}
                        />
                    )}
                    <Button
                        text={createButton}
                        className="min-w-max"
                        type="submit"
                        isLoading={isPending}
                    />
                </div>
            </div>
        </form>
    );
};
