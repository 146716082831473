import { TableHeaderCeil } from "components/shared/tableHeaderCeil";
import { Title } from "components/shared/title";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TableCeil } from "components/shared/tableCeil";
import { useNavigate, useParams } from "react-router-dom";
import { routerKeys } from "common/constants";
import { useUploadFormulaSimilar } from "pages/formulas/formula-profile/queries";
import { LoadingToast } from "components/shared/loadingToast";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";

export const SimilarTab = () => {
    const { id = "" } = useParams();
    const navigate = useNavigate();
    const [compareWith, setCompareWith] = useState<number[]>([]);
    const searchParams = new URLSearchParams();
    const { isOwner } = useFormulaProfileState();

    const { data: similarFormulas, isLoading } = useUploadFormulaSimilar({
        id,
    });

    const handleUpdateCompareWith = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const { value } = event.target;

        if (compareWith.includes(+value)) {
            setCompareWith((currentValues) =>
                currentValues.filter((element) => element !== +value),
            );
        } else {
            setCompareWith((currentValues) => [...currentValues, +value]);
        }
    };

    const handleNavigateToCompare = () => {
        if (compareWith.length) {
            searchParams.append(routerKeys.compareWith, compareWith.join(","));
            navigate(`${routerKeys.compare}?${searchParams}`);
        } else {
            navigate(routerKeys.compare);
        }
    };

    return (
        <div>
            <div className="md:flex lg:flex">
                <div className="md:w-2/3 lg:w-2/3 mx-10 my-3">
                    <Title text="Suggested Reference Formulas">
                        {(isOwner || true) && (
                            <button
                                className="btn btn-sm ml-10"
                                onClick={handleNavigateToCompare}
                            >
                                Compare
                            </button>
                        )}
                    </Title>

                    <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg">
                        <thead className="bg-indigo-100">
                            <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                                <TableHeaderCeil />
                                <TableHeaderCeil text="FICODE" />
                                <TableHeaderCeil text="Description" />
                                <TableHeaderCeil text="Test Code attributes and results" />
                                <TableHeaderCeil text="% Similarity" />
                            </tr>
                        </thead>
                        <tbody>
                            {similarFormulas?.map((similarFormula) => (
                                <tr key={uuidv4()}>
                                    <TableCeil>
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            value={
                                                similarFormula.target_formulas_id
                                            }
                                            // disabled={!isOwner}
                                            onChange={handleUpdateCompareWith}
                                            checked={compareWith.includes(
                                                similarFormula.target_formulas_id,
                                            )}
                                        />
                                    </TableCeil>
                                    <TableCeil>
                                        {similarFormula.similarity.ficode}
                                    </TableCeil>
                                    <TableCeil>
                                        {similarFormula.similarity.description}
                                    </TableCeil>
                                    <TableCeil>
                                        {/* {similarFormula.similarity.formulasTests.map(
                                            (test) => (
                                                <div key={uuidv4()}>
                                                    <div>{test.test_code}</div>
                                                    <div>
                                                        {test.attributes.map(
                                                            (attribute) => (
                                                                <div
                                                                    key={uuidv4()}
                                                                >
                                                                    {
                                                                        attribute.name
                                                                    }
                                                                    :{" "}
                                                                    {
                                                                        attribute.value
                                                                    }
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                    <div>
                                                        {test.results.map(
                                                            (result) => (
                                                                <div
                                                                    key={uuidv4()}
                                                                >
                                                                    {
                                                                        result.name
                                                                    }
                                                                    :{" "}
                                                                    {
                                                                        result.value
                                                                    }
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                </div>
                                            ),
                                        )} */}
                                    </TableCeil>
                                    <TableCeil>
                                        {similarFormula.score}
                                    </TableCeil>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {isLoading && <LoadingToast />}
        </div>
    );
};
