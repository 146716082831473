import React, { FC } from "react";
import { RawIngredientProfileTabT } from "pages/rawIngredient/types";
import { CompositionTab } from "./tabs/compositionTab";
import { TradeNameTab } from "./tabs/tradeNameTab";
import { ContaminantsTab } from "./tabs/contaminantsTab";
import { RawIngredientProfileTab } from "pages/rawIngredient/enums";
import { SafetyTechnicalDocuments } from "./tabs/safetyTechnicalDocuments";

interface IProps {
    selectedTab: RawIngredientProfileTabT;
}

export const TabContent: FC<IProps> = ({ selectedTab }) => {
    switch (selectedTab) {
        case RawIngredientProfileTab.COMPOSITION:
            return <CompositionTab />;
        case RawIngredientProfileTab.TRADENAME:
            return <TradeNameTab />;
        case RawIngredientProfileTab.CONTAMINANTS:
            return <ContaminantsTab />;
        case RawIngredientProfileTab.SAFETY_TECHNICAL_DOCUMENTS:
            return <SafetyTechnicalDocuments />;
        default:
            return <CompositionTab />;
    }
};
