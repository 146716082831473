import React, { ChangeEvent, FC, memo } from "react";
import {
    TableTitleCeil,
    TableInputCeil,
    TableRadioButtonsCeil,
    TableTextareaCeil,
    TableMultiSelect,
} from "./components";
import { CheckboxGroup, DropdownWrapper } from "components/shared";
import { useSubIngredientStore } from "pages/subIngredient/subIngredientProfile/store";
import { IOption } from "common/types";
import classNames from "classnames";

interface IProps {
    className?: string;
    header: string;
    headerClassName?: string;
    content?: string | null;
    comment?: string;
    contentClassName?: string;
    onChange?: (
        e:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | ChangeEvent<HTMLSelectElement>,
    ) => void;
    onChangeMultiSelect?: (selected: string[], name: string) => void;
    onChangeCIRReviewed?: (e: ChangeEvent<HTMLInputElement>) => void;
    value?: string | null;
    values?: string[] | null;
    name?: string;
    isOwner?: boolean;
    options?: IOption[];
    defaultOption?: boolean;
    defaultLabel?: string;
    defaultValue?: string;
    isCIRReviewed?: boolean;
    type?:
        | "input"
        | "dropdown"
        | "text"
        | "radio"
        | "cir_reviewed"
        | "multi_select";
}

const RenderInput: FC<Omit<IProps, "header">> = memo(
    ({
        content = "",
        comment = "",
        contentClassName,
        onChange = () => {},
        onChangeCIRReviewed = () => {},
        onChangeMultiSelect = () => {},
        value,
        values,
        name,
        isOwner = false,
        type,
        options,
        defaultOption = false,
        defaultValue = "",
        defaultLabel,
        isCIRReviewed = false,
    }) => {
        switch (type) {
            case "input":
                return (
                    <TableInputCeil
                        onChange={onChange}
                        value={value || ""}
                        name={name || ""}
                        isDisabled={!isOwner}
                    />
                );
            case "dropdown":
                return (
                    <td className="flex p-3">
                        <DropdownWrapper
                            className="w-full"
                            name={name || ""}
                            value={value || ""}
                            onChange={onChange}
                            disableDefaultOption={!defaultOption}
                            options={options}
                            defaultLabel={defaultLabel}
                            defaultValue={defaultValue}
                            disabled={!isOwner}
                        />
                    </td>
                );
            case "multi_select":
                return (
                    <TableMultiSelect
                        className="w-full"
                        name={name || ""}
                        value={values || [""]}
                        onChange={onChangeMultiSelect}
                        options={options}
                        isDisabled={!isOwner}
                    />
                );

            case "text":
                return content ? (
                    <TableTitleCeil
                        text={content}
                        className={contentClassName}
                    />
                ) : null;
            case "radio":
                return (
                    <>
                        <TableRadioButtonsCeil
                            className="pb-0"
                            name={name || ""}
                            onChange={onChange}
                            checkedValue={value || ""}
                            isDisabled={!isOwner}
                        />
                        <TableTextareaCeil
                            onChange={onChange}
                            value={comment || ""}
                            name={`${name || ""}_comment`}
                            placeholder="Add a comment"
                            isDisabled={!isOwner}
                        />
                    </>
                );
            case "cir_reviewed":
                return (
                    <>
                        <td className="flex p-3">
                            <CheckboxGroup
                                value="Yes"
                                label="Select if CIR has been reviewed. This is required to insert a citation."
                                onChange={onChangeCIRReviewed}
                                isChecked={isCIRReviewed}
                                isDisabled={!isOwner}
                            />
                        </td>
                        {isCIRReviewed && (
                            <TableTextareaCeil
                                onChange={onChange}
                                value={content || ""}
                                name={`${name || ""}_citation`}
                                placeholder="Add CIR Citation"
                                isDisabled={!isOwner}
                            />
                        )}
                    </>
                );
            default:
                return null;
        }
    },
);

export const TableRow: FC<IProps> = memo(
    ({
        className = "",
        header,
        headerClassName,
        content,
        contentClassName,
        onChange = () => {},
        value,
        values,
        name,
        comment,
        options,
        defaultOption,
        defaultLabel,
        defaultValue,
        isCIRReviewed = false,
        onChangeCIRReviewed,
        onChangeMultiSelect,
        type = "input",
    }) => {
        const { isOwner } = useSubIngredientStore();

        if (type === "text" && !content) return null;

        return (
            <tr className={className}>
                <TableTitleCeil
                    text={header}
                    className={classNames("w-1/3", headerClassName)}
                />
                <RenderInput
                    type={type}
                    content={content}
                    comment={comment}
                    contentClassName={contentClassName}
                    onChange={onChange}
                    value={value || ""}
                    values={values || []}
                    name={name || ""}
                    isOwner={isOwner}
                    options={options}
                    defaultOption={defaultOption}
                    defaultLabel={defaultLabel}
                    defaultValue={defaultValue}
                    isCIRReviewed={isCIRReviewed}
                    onChangeCIRReviewed={onChangeCIRReviewed}
                    onChangeMultiSelect={onChangeMultiSelect}
                />
            </tr>
        );
    },
);
