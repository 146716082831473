import React from "react";

export const Header = () => (
    <div className="grid lg:grid-cols-2 gap-4 mx-10 my-8">
        <div>
            <h2 className="text-2xl font-semibold">Dashboard</h2>
            <p className="text-[#4d596a] text-sm md:text-base lg:text-base">
                Welcome to the SmartSafety Dashboard! Here, you can quickly view
                an overview of the database counts and conveniently access your
                recent searches.
            </p>
        </div>
    </div>
);
