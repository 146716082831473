import classNames from "classnames";
import React from "react";

interface IProps {
    className?: string;
    color?: string;
}

export const CloseIcon: React.FC<IProps> = ({
    className = "",
    color = "white",
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        width="24"
        height="24"
        className={classNames("w-6 h-6", className)}
    >
        <path
            d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
            fill={color}
        />
    </svg>
);
