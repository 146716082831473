import { IToxicology } from "common/types";
import React, { useRef } from "react";
import {
    InputGroup,
    InputTableCeil,
    FormLabelTemplate,
    CommonInput,
    LabelDivider,
    ModesSelect,
} from "..";
import { ModalTemplate } from "components/shared/modalTemplate";
// import { useSubIngredientStore } from "pages/subIngredient/subIngredientProfile/store";

interface IProps {
    data: IToxicology;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
}

export const ToxicitySubRankRow: React.FC<IProps> = ({ data, onChange }) => {
    const toxicityModalRef = useRef<HTMLDialogElement>(null);
    // const { isOwner } = useSubIngredientStore();

    const openModal = () => {
        if (toxicityModalRef.current) {
            toxicityModalRef.current.showModal();
        }
    };

    const closeModal = () => {
        if (toxicityModalRef.current) {
            toxicityModalRef.current.close();
        }
    };

    return (
        <tr>
            <InputTableCeil
                text="Toxicity Sub-rank"
                name="fix_toxicity_sub_rank_value_srv"
                checked={Boolean(data.fix_toxicity_sub_rank_value_srv)}
                onChange={onChange}
                // isDisabled={!isOwner}
            />
            <td className="flex p-3">
                <InputGroup
                    onChange={onChange}
                    value={data.toxicity_sub_rank_value_srv}
                    name="toxicity_sub_rank_value_srv"
                    onClick={openModal}
                    // isDisabled={!isOwner}
                />
                <ModalTemplate
                    text="Toxicity sub rank value"
                    onClose={closeModal}
                    modalRef={toxicityModalRef}
                >
                    <div className="flex">
                        <FormLabelTemplate
                            text="Toxicity sub rank"
                            name="fix_toxicity_sub_rank_value_srv"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_toxicity_sub_rank_value_srv,
                            )}
                        >
                            <CommonInput
                                name="toxicity_sub_rank_value_srv"
                                value={data.toxicity_sub_rank_value_srv}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="= highest of (" />

                        <FormLabelTemplate
                            text="aquatic sub rank"
                            name="fix_aquatic_sub_rank_value_srv"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_aquatic_sub_rank_value_srv,
                            )}
                        >
                            <CommonInput
                                name="aquatic_sub_rank_value_srv"
                                value={data.aquatic_sub_rank_value_srv}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="," />

                        <FormLabelTemplate
                            text="sediment sub rank"
                            name="fix_sediment_sub_rank_value_srv"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_sediment_sub_rank_value_srv,
                            )}
                        >
                            <CommonInput
                                name="sediment_sub_rank_value_srv"
                                value={data.sediment_sub_rank_value_srv}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="and" />

                        <FormLabelTemplate
                            text="soil sub rank"
                            name="fix_soil_sub_rank_value_srv"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_sub_rank_value_srv)}
                        >
                            <CommonInput
                                name="soil_sub_rank_value_srv"
                                value={data.soil_sub_rank_value_srv}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text=") + 1" />
                    </div>

                    <div className="divider" />

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="aquatic sub rank"
                            name="fix_aquatic_sub_rank_value_srv"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_aquatic_sub_rank_value_srv,
                            )}
                        >
                            <CommonInput
                                name="aquatic_sub_rank_value_srv"
                                value={data.aquatic_sub_rank_value_srv}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="depends on" />

                        <FormLabelTemplate
                            text="aquatic pnec in mg/l"
                            name="fix_aquatic_pnec_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(data.fix_aquatic_pnec_in_mg_l)}
                        >
                            <CommonInput
                                name="aquatic_pnec_in_mg_l"
                                value={data.aquatic_pnec_in_mg_l}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>
                    </div>
                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="sediment sub rank"
                            name="fix_sediment_sub_rank_value_srv"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_sediment_sub_rank_value_srv,
                            )}
                        >
                            <CommonInput
                                name="sediment_sub_rank_value_srv"
                                value={data.sediment_sub_rank_value_srv}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="depends on" />

                        <FormLabelTemplate
                            text="sediment pnec mg/l"
                            name="fix_sediment_pnec_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(data.fix_sediment_pnec_in_mg_l)}
                        >
                            <CommonInput
                                name="sediment_pnec_in_mg_l"
                                value={data.sediment_pnec_in_mg_l}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>
                    </div>
                    <div className="flex">
                        <FormLabelTemplate
                            text="soil sub rank"
                            name="fix_soil_sub_rank_value_srv"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_sub_rank_value_srv)}
                        >
                            <CommonInput
                                name="soil_sub_rank_value_srv"
                                value={data.soil_sub_rank_value_srv}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="depends on" />

                        <FormLabelTemplate
                            text="soil pnec in mg/l"
                            name="fix_soil_pnec_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_pnec_in_mg_l)}
                        >
                            <CommonInput
                                name="soil_pnec_in_mg_l"
                                value={data.soil_pnec_in_mg_l}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>
                    </div>

                    <div className="divider" />

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="aquatic pnec in mg/l"
                            name="fix_aquatic_pnec_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(data.fix_aquatic_pnec_in_mg_l)}
                        >
                            <CommonInput
                                name="aquatic_pnec_in_mg_l"
                                value={data.aquatic_pnec_in_mg_l}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="=" />

                        <FormLabelTemplate
                            text="CTV"
                            name="fix_critical_toxicity_value_ctv"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_critical_toxicity_value_ctv,
                            )}
                        >
                            <CommonInput
                                name="critical_toxicity_value_ctv"
                                value={data.critical_toxicity_value_ctv}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="/ (" />

                        <FormLabelTemplate
                            text="FES"
                            name="fix_endpoint_standard_factor_fes"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_endpoint_standard_factor_fes,
                            )}
                        >
                            <CommonInput
                                name="endpoint_standard_factor_fes"
                                value={data.endpoint_standard_factor_fes}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="x" />

                        <FormLabelTemplate
                            text="FSV"
                            name="fix_species_variation_factor_fsv"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_species_variation_factor_fsv,
                            )}
                        >
                            <CommonInput
                                name="species_variation_factor_fsv"
                                value={data.species_variation_factor_fsv}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="x" />

                        <FormLabelTemplate
                            text="FMOA"
                            name="fix_mode_of_action_factor_fmoa"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_mode_of_action_factor_fmoa,
                            )}
                        >
                            <CommonInput
                                name="mode_of_action_factor_fmoa"
                                value={data.mode_of_action_factor_fmoa}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text=")" />
                    </div>

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="sediment pnec mg/l"
                            name="fix_sediment_pnec_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(data.fix_sediment_pnec_in_mg_l)}
                        >
                            <CommonInput
                                name="sediment_pnec_in_mg_l"
                                value={data.sediment_pnec_in_mg_l}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="=" />

                        <FormLabelTemplate
                            text="aquatic pnec in mg/l"
                            name="fix_aquatic_pnec_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(data.fix_aquatic_pnec_in_mg_l)}
                        >
                            <CommonInput
                                name="aquatic_pnec_in_mg_l"
                                value={data.aquatic_pnec_in_mg_l}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="x ( 10^log(" />

                        <FormLabelTemplate
                            text="loc koc"
                            name="fix_log_koc"
                            onChange={onChange}
                            checked={Boolean(data.fix_log_koc)}
                        >
                            <CommonInput
                                name="log_koc"
                                value={data.log_koc}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text=") x 0.0217 + 0.783 )" />
                    </div>

                    <div className="flex">
                        <FormLabelTemplate
                            text="soil pnec in mg/l"
                            name="fix_soil_pnec_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(data.fix_soil_pnec_in_mg_l)}
                        >
                            <CommonInput
                                name="soil_pnec_in_mg_l"
                                value={data.soil_pnec_in_mg_l}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="=" />

                        <FormLabelTemplate
                            text="aquatic pnec in mg/l"
                            name="fix_aquatic_pnec_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(data.fix_aquatic_pnec_in_mg_l)}
                        >
                            <CommonInput
                                name="aquatic_pnec_in_mg_l"
                                value={data.aquatic_pnec_in_mg_l}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="x ( 10^log(" />

                        <FormLabelTemplate
                            text="loc koc"
                            name="fix_log_koc"
                            onChange={onChange}
                            checked={Boolean(data.fix_log_koc)}
                        >
                            <CommonInput
                                name="log_koc"
                                value={data.log_koc}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text=") x 0.01764 + 0.1176 )" />
                    </div>

                    <div className="divider" />

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="CTV"
                            name="fix_critical_toxicity_value_ctv"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_critical_toxicity_value_ctv,
                            )}
                        >
                            <CommonInput
                                name="critical_toxicity_value_ctv"
                                value={data.critical_toxicity_value_ctv}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="depends on" />

                        <FormLabelTemplate
                            text="SEV acute"
                            name="fix_standard_ecotoxicity_value_sev_acute"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_standard_ecotoxicity_value_sev_acute,
                            )}
                        >
                            <CommonInput
                                name="standard_ecotoxicity_value_sev_acute"
                                value={
                                    data.standard_ecotoxicity_value_sev_acute
                                }
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="," />

                        <FormLabelTemplate
                            text="SEV subchronic"
                            name="fix_standard_ecotoxicity_value_sev_subchronic"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_standard_ecotoxicity_value_sev_subchronic,
                            )}
                        >
                            <CommonInput
                                name="standard_ecotoxicity_value_sev_subchronic"
                                value={
                                    data.standard_ecotoxicity_value_sev_subchronic
                                }
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="and" />

                        <FormLabelTemplate
                            text="SEV chronic"
                            name="fix_standard_ecotoxicity_value_sev_chronic"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_standard_ecotoxicity_value_sev_chronic,
                            )}
                        >
                            <CommonInput
                                name="standard_ecotoxicity_value_sev_chronic"
                                value={
                                    data.standard_ecotoxicity_value_sev_chronic
                                }
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>
                    </div>

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="FSV"
                            name="fix_species_variation_factor_fsv"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_species_variation_factor_fsv,
                            )}
                        >
                            <CommonInput
                                name="species_variation_factor_fsv"
                                value={data.species_variation_factor_fsv}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="depends on" />

                        <FormLabelTemplate
                            text="Fish species tested"
                            name="fix_number_of_fish_species_tested"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_number_of_fish_species_tested,
                            )}
                        >
                            <CommonInput
                                name="number_of_fish_species_tested"
                                value={data.number_of_fish_species_tested}
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="," />

                        <FormLabelTemplate
                            text="Daphnia species tested"
                            name="fix_number_of_daphnia_species_tested"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_number_of_daphnia_species_tested,
                            )}
                        >
                            <CommonInput
                                name="number_of_daphnia_species_tested"
                                value={data.number_of_daphnia_species_tested}
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="and" />

                        <FormLabelTemplate
                            text="Algae species tested"
                            name="fix_number_of_algae_species_tested"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_number_of_algae_species_tested,
                            )}
                        >
                            <CommonInput
                                name="number_of_algae_species_tested"
                                value={data.number_of_algae_species_tested}
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>
                    </div>

                    <div className="flex">
                        <FormLabelTemplate
                            text="FMOA"
                            name="fix_mode_of_action_factor_fmoa"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_mode_of_action_factor_fmoa,
                            )}
                        >
                            <CommonInput
                                name="mode_of_action_factor_fmoa"
                                value={data.mode_of_action_factor_fmoa}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="depends on" />

                        <FormLabelTemplate
                            text="Mode of action"
                            name="fix_mode_of_action_of_toxic_effect"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_mode_of_action_of_toxic_effect,
                            )}
                        >
                            <ModesSelect
                                value={data.mode_of_action_of_toxic_effect}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>
                    </div>

                    <div className="divider" />

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="SEV acute"
                            name="fix_standard_ecotoxicity_value_sev_acute"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_standard_ecotoxicity_value_sev_acute,
                            )}
                        >
                            <CommonInput
                                name="standard_ecotoxicity_value_sev_acute"
                                value={
                                    data.standard_ecotoxicity_value_sev_acute
                                }
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="= lowest of (" />

                        <FormLabelTemplate
                            text="Fish acute toxicity"
                            name="fix_fish_acute_toxicity_lc50_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_fish_acute_toxicity_lc50_in_mg_l,
                            )}
                        >
                            <CommonInput
                                name="fish_acute_toxicity_lc50_in_mg_l"
                                value={data.fish_acute_toxicity_lc50_in_mg_l}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="," />

                        <FormLabelTemplate
                            text="Daphnia acute toxicity"
                            name="fix_daphnia_magna_acute_toxicity_ec50_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_daphnia_magna_acute_toxicity_ec50_in_mg_l,
                            )}
                        >
                            <CommonInput
                                name="daphnia_magna_acute_toxicity_ec50_in_mg_l"
                                value={
                                    data.daphnia_magna_acute_toxicity_ec50_in_mg_l
                                }
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="and" />

                        <FormLabelTemplate
                            text="Algae acute toxicity"
                            name="fix_algae_acute_toxicity_ec50_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_algae_acute_toxicity_ec50_in_mg_l,
                            )}
                        >
                            <CommonInput
                                name="algae_acute_toxicity_ec50_in_mg_l"
                                value={data.algae_acute_toxicity_ec50_in_mg_l}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text=") / 10" />
                    </div>

                    <div className="flex mb-1">
                        <FormLabelTemplate
                            text="SEV subchronic"
                            name="fix_standard_ecotoxicity_value_sev_subchronic"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_standard_ecotoxicity_value_sev_subchronic,
                            )}
                        >
                            <CommonInput
                                name="standard_ecotoxicity_value_sev_subchronic"
                                value={
                                    data.standard_ecotoxicity_value_sev_subchronic
                                }
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="= lowest of (" />

                        <FormLabelTemplate
                            text="Fish subchronic toxicity"
                            name="fix_fish_subchronic_toxicity_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_fish_subchronic_toxicity_in_mg_l,
                            )}
                        >
                            <CommonInput
                                name="fish_subchronic_toxicity_in_mg_l"
                                value={data.fish_subchronic_toxicity_in_mg_l}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="," />

                        <FormLabelTemplate
                            text="Daphnia subchronic tox"
                            name="fix_daphnia_magna_subchronic_toxicity_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_daphnia_magna_subchronic_toxicity_in_mg_l,
                            )}
                        >
                            <CommonInput
                                name="daphnia_magna_subchronic_toxicity_in_mg_l"
                                value={
                                    data.daphnia_magna_subchronic_toxicity_in_mg_l
                                }
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="and" />

                        <FormLabelTemplate
                            text="Algae subchronic toxicity"
                            name="fix_algae_subchronic_toxicity_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_algae_subchronic_toxicity_in_mg_l,
                            )}
                        >
                            <CommonInput
                                name="algae_subchronic_toxicity_in_mg_l"
                                value={data.algae_subchronic_toxicity_in_mg_l}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text=") / 5" />
                    </div>

                    <div className="flex">
                        <FormLabelTemplate
                            text="SEV chronic"
                            name="fix_standard_ecotoxicity_value_sev_chronic"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_standard_ecotoxicity_value_sev_chronic,
                            )}
                        >
                            <CommonInput
                                name="standard_ecotoxicity_value_sev_chronic"
                                value={
                                    data.standard_ecotoxicity_value_sev_chronic
                                }
                                onChange={onChange}
                                color="input-warning"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="= lowest of (" />

                        <FormLabelTemplate
                            text="Fish chronic toxicity"
                            name="fix_fish_chronic_toxicity_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_fish_chronic_toxicity_in_mg_l,
                            )}
                        >
                            <CommonInput
                                name="fish_chronic_toxicity_in_mg_l"
                                value={data.fish_chronic_toxicity_in_mg_l}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="," />

                        <FormLabelTemplate
                            text="Daphnia chronic toxicity"
                            name="fix_daphnia_magna_chronic_toxicity_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_daphnia_magna_chronic_toxicity_in_mg_l,
                            )}
                        >
                            <CommonInput
                                name="daphnia_magna_chronic_toxicity_in_mg_l"
                                value={
                                    data.daphnia_magna_chronic_toxicity_in_mg_l
                                }
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="and" />

                        <FormLabelTemplate
                            text="Algae chronic toxicity"
                            name="fix_algae_chronic_toxicity_value_in_mg_l"
                            onChange={onChange}
                            checked={Boolean(
                                data.fix_algae_chronic_toxicity_value_in_mg_l,
                            )}
                        >
                            <CommonInput
                                name="algae_chronic_toxicity_value_in_mg_l"
                                value={
                                    data.algae_chronic_toxicity_value_in_mg_l
                                }
                                onChange={onChange}
                            />
                        </FormLabelTemplate>

                        <LabelDivider text=") / 1" />
                    </div>

                    <div className="divider" />
                </ModalTemplate>
            </td>
        </tr>
    );
};
