import React, { memo, FC } from "react";
import Select, { MultiValue } from "react-select";
import classNames from "classnames";

interface IOption {
    label: string;
    value: string;
}

interface IProps {
    value: string[];
    options?: IOption[];
    onChange: (selected: string[], name: string) => void;
    className?: string;
    name: string;
    isDisabled?: boolean;
}

export const TableMultiSelect: FC<IProps> = memo(
    ({
        value,
        options = [],
        onChange,
        className = "",
        name,
        isDisabled = false,
    }) => {
        const selectedOptions = options.filter((option) =>
            value.includes(option.value),
        );

        const handleChange = (selected: MultiValue<IOption>) => {
            const selectedValues = selected.map((option) => option.value);
            onChange(selectedValues, name);
        };

        return (
            <td className={classNames("flex p-3", className)}>
                <Select
                    isMulti
                    value={selectedOptions}
                    onChange={handleChange}
                    options={options}
                    className="w-full"
                    classNamePrefix="select"
                    isDisabled={isDisabled}
                />
            </td>
        );
    },
);
