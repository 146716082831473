// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link {
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
  font-size: 17px;
  text-decoration: none;
  color: #fff;
  padding: 15px 0;
}
.nav-link:hover {
  background-color: rgba(160, 192, 239, 0.37);
}

.mobile-link {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.mobile-link:hover {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.bg-header {
  background-color: #113261;
}

.active-item {
  background-color: rgba(160, 192, 239, 0.37);
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,QAAA;EACA,eAAA;EACA,mBAAA;EACA,eAAA;EACA,qBAAA;EACA,WAAA;EACA,eAAA;AACJ;AACI;EACI,2CAAA;AACR;;AAII;EAAA,cAAA;EAAA,oBAAA;EAAA,uBAAA;EAAA,qBAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA,oBAAA;EAAA;AAAA;;AAAA;EAAA,oBAAA;EAAA;AAAA;;AAOJ;EACI,yBAAA;AAJJ;;AAOA;EACI,2CAAA;AAJJ","sourcesContent":[".nav-link {\n    display: flex;\n    justify-content: center;\n    gap: 5px;\n    flex-wrap: wrap;\n    align-items: center;\n    font-size: 17px;\n    text-decoration: none;\n    color: #fff;\n    padding: 15px 0;\n\n    &:hover {\n        background-color: rgba(160, 192, 239, 0.37);\n    }\n}\n\n.mobile-link {\n    @apply block py-2 px-4 text-sm text-gray-100 hover:text-gray-300;\n}\n\n// .mobile-menu {\n//   @apply h-full w-full absolute bottom-0 left-0 bg-gray-900 ;\n// }\n\n.bg-header {\n    background-color: #113261;\n}\n\n.active-item {\n    background-color: rgba(160, 192, 239, 0.37);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
