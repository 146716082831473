import React, {
    FC,
    KeyboardEvent,
    useState,
    useCallback,
    ChangeEvent,
} from "react";
import { TableCeil } from "components/shared/tableCeil";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
    defaultErrorToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { toast } from "react-toastify";
import { useRawIngredientStore } from "pages/rawIngredient/rawIngredientProfile/store";
import {
    useDeleteRelation,
    useUpdateRelation,
} from "pages/rawIngredient/rawIngredientProfile/queries";
import { IContaminant } from "common/types";
import { ActionButtons, EditableInput } from "components/shared";

interface IProps {
    contaminant: IContaminant;
}

export const ContaminantRow: FC<IProps> = ({ contaminant }) => {
    const { id = "" } = useParams();
    const queryClient = useQueryClient();
    const { isOwner } = useRawIngredientStore();
    const [isEditing, setIsEditing] = useState(false);

    const navigate = useNavigate();

    const navigateToContaminantPage = () => {
        navigate(`${routerKeys.contaminants}/${contaminant.id}`);
    };

    const { mutate: updateRelation } = useUpdateRelation();
    const { mutate: deleteRelation } = useDeleteRelation();

    const formik = useFormik({
        initialValues: {
            ppm: contaminant.rawIngredientsContaminants?.ppm || "",
        },
        validationSchema: Yup.object().shape({
            ppm: Yup.number()
                .min(0, "PPM must be greater than or equal to 0")
                .required("PPM is required"),
        }),
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: ({ ppm }) => {
            updateRelation(
                {
                    id,
                    ingredientId: `${contaminant.id}`,
                    relationType: "contaminants",
                    body: { value: ppm },
                },
                {
                    onSuccess() {
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.rawIngredient, id],
                        });
                    },
                    onError() {
                        toast.error(toastTexts.error, defaultErrorToastOptions);
                    },
                },
            );
        },
    });

    const handleRemoveIngredient = () => {
        deleteRelation(
            {
                id,
                ingredientId: `${contaminant.id}`,
                relationType: "contaminants",
            },
            {
                onSuccess() {
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.rawIngredient, id],
                    });
                },
                onError() {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            formik.handleSubmit();

            if (formik.isValid) {
                setIsEditing(false);
            }
        }
    };

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            const formattedValue = value.replace(",", ".");
            formik.setFieldValue("ppm", formattedValue);
        },
        [formik],
    );

    return (
        <>
            <tr className="relative">
                <TableCeil
                    text={contaminant.name}
                    onClick={navigateToContaminantPage}
                    className="cursor-pointer"
                />
                <TableCeil className="w-1/6">
                    <EditableInput
                        name="ppm"
                        value={formik.values.ppm}
                        isEditing={isEditing}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        className="text-center"
                        inputClassName="text-center"
                        displayClassName="font-semibold text-gray-700"
                        autoFocus={isEditing}
                    />
                    {formik.errors.ppm && formik.touched.ppm && (
                        <div className="mt-1 w-full text-[#dc2626] text-sm">
                            <span>{formik.errors.ppm}</span>
                        </div>
                    )}
                </TableCeil>
                {(isOwner || true) && (
                    <TableCeil>
                        <ActionButtons
                            onSubmit={formik.handleSubmit}
                            onRemove={handleRemoveIngredient}
                            isEditing={isEditing}
                            setIsEditing={
                                formik.isValid ? setIsEditing : undefined
                            }
                        />
                    </TableCeil>
                )}
            </tr>
        </>
    );
};
