import { create } from "zustand";

export interface IState {
    key: (string | number)[];
    isOwner: boolean;
}

export interface IAction {
    setKey: (key: (string | number)[]) => void;
    setIsOwner: (key: boolean) => void;
}

export const useSubIngredientStore = create<IState & IAction>((set) => ({
    key: [],
    isOwner: false,
    setKey: (key) => set(() => ({ key })),
    setIsOwner: (isOwner) => set(() => ({ isOwner })),
}));
