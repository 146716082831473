import {
    type MoleculeData,
    type OperaMappedData,
} from "pages/subIngredient/subIngredientProfile/libs";

const parseDataValue = (value: string | null): string => {
    if (value === null) return "0";

    const parsedValue =
        typeof value === "number" ? value : parseFloat(String(value));

    if (isNaN(parsedValue)) {
        return "0";
    }

    const decimalPart = String(parsedValue).split(".")[1];
    if (decimalPart && decimalPart.length > 5) {
        return parsedValue.toFixed(5);
    }

    return String(parsedValue);
};

export const mapDataFromOpera = (
    dataValues: MoleculeData,
): OperaMappedData => ({
    operaLogDbdf: parseDataValue(dataValues["OPERA LogD BDF"]),
    molecularWeight: parseDataValue(dataValues["MW (g/mol)"]),
    operaWsBDF: parseDataValue(dataValues["OPERA Ws (M) BDF"]),
    operaProteinBinding: parseDataValue(
        dataValues["OPERA Protein binding (%) BDF"],
    ),
    vapourPressure: parseDataValue(
        dataValues["OPERA Vapour pressure (mmHg) EwC"],
    ),
    fionBDF: parseDataValue(dataValues["fion BDF"]),
    logKow: parseDataValue(
        dataValues["ChemAxon logKow"] || dataValues["OPERA LogP BDF"],
    ),
    proteinBinding: parseDataValue(
        dataValues["OChem Protein binding (%)"] ||
            dataValues["OPERA Protein binding (%) BDF"],
    ),
    logDpH7: parseDataValue(
        dataValues["ChemAxon Log D@pH7"] || dataValues["OPERA LogD BDF"],
    ),
    wspH7Ml: parseDataValue(
        dataValues["ChemAxon Ws@pH7 (M)"] || dataValues["OPERA Ws (M) BDF"],
    ),
    vpMultipleRegression: parseDataValue(
        dataValues["TIMES Vapour pressure (Pa)"] || "0",
    ),
    meltingPointDEGCOperaPred: parseDataValue(
        dataValues["OPERA Melting point (oC) EwC"],
    ),
    surfaceTension: parseDataValue(
        dataValues["ChemBCPP Surface tension (dyn/cm)"],
    ),
});
