import { Title } from "components/shared/title";
import React from "react";
import { CompositionTable, TestsTable } from "./components";
import { AddRelatedIngredients } from "../../";
import { useRawIngredientStore } from "pages/rawIngredient/rawIngredientProfile/store";

export const CompositionTab = () => {
    const { isOwner } = useRawIngredientStore();

    return (
        <div>
            <div className="md:flex lg:flex md:mx-5 lg:mx-10">
                <div className="w-1/2 md:w-1/2 lg:w-1/2 md:mr-5">
                    <Title text="Composition" className="mt-2" />
                    {(isOwner || true) && <AddRelatedIngredients type="INCI" />}
                    <CompositionTable />
                </div>
                <div className="w-1/2 md:w-1/2 lg:w-1/2 md:ml-5">
                    <Title text="Testing Data" className="mt-2" />
                    {(isOwner || true) && <div className="h-[48px] my-4" />}
                    <TestsTable />
                </div>
            </div>
        </div>
    );
};
