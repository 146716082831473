import React, { ChangeEvent, FC } from "react";
import {
    BioaccumulationSubRankRow,
    PNECValueRow,
    PersistenceSubRankRow,
    ToxicitySubRankRow,
} from "../forms";
import {
    TableTitleCeil,
    TableRadioButtonsCeil,
} from "pages/subIngredient/subIngredientProfile/components";
import { IToxicology } from "common/types";

interface IProps {
    toxicology: IToxicology;
    handleUpdate: (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
    isOwner?: boolean;
}

export const LeftTable: FC<IProps> = ({
    toxicology,
    handleUpdate,
    isOwner = true,
}) => {
    return (
        <div className="md:w-1/2 lg:w-1/2 md:mr-5">
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg mb-2">
                <tbody>
                    <PersistenceSubRankRow
                        data={toxicology}
                        onChange={handleUpdate}
                    />
                    <BioaccumulationSubRankRow
                        data={toxicology}
                        onChange={handleUpdate}
                    />
                    <ToxicitySubRankRow
                        data={toxicology}
                        onChange={handleUpdate}
                    />
                    <PNECValueRow data={toxicology} onChange={handleUpdate} />
                    <tr>
                        <TableTitleCeil text="Mobility" />
                        <TableRadioButtonsCeil
                            name="pmt"
                            onChange={handleUpdate}
                            checkedValue={toxicology?.pmt}
                            isDisabled={!isOwner}
                        />
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
