import axios from "axios";
import { routerKeys, storageKeys } from "common/constants";

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/${process.env.REACT_APP_SERVER_API}`,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(storageKeys.accessToken);

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            const { status, data } = error.response;

            if (
                status === 401 ||
                (status === 400 && data.error === "invalid_grant")
            ) {
                localStorage.removeItem(storageKeys.accessToken);
                // localStorage.removeItem(storageKeys.refreshToken);

                window.location.href = routerKeys.authLogin;
            } else if (status === 403) {
                console.error("Access forbidden:", data);
            }
        }

        return Promise.reject(error);
    },
);

export default instance;
