import React from "react";
import { TableHeadRow } from "..";
import { TableCeil } from "components/shared/tableCeil";
import { TableHeaderCeil } from "components/shared/tableHeaderCeil";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { ContaminantRow } from "./ContaminantRow";

export const ContaminantsTab = () => {
    const { contaminants } = useFormulaProfileState();

    return (
        <div>
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg">
                <thead className="bg-indigo-100">
                    <TableHeadRow>
                        <TableHeaderCeil text="Name" />
                        <TableHeaderCeil className="text-center" text="ppm" />
                        {Boolean(contaminants?.length) && (
                            <TableHeaderCeil text="" className="w-2" />
                        )}
                    </TableHeadRow>
                </thead>
                <tbody>
                    {contaminants?.length ? (
                        <>
                            {contaminants
                                ?.sort((a, b) => a.name.localeCompare(b.name))
                                ?.map((contaminant) => (
                                    <ContaminantRow
                                        key={contaminant.id}
                                        contaminant={contaminant}
                                    />
                                ))}
                        </>
                    ) : (
                        <tr>
                            <TableCeil text="No Contaminants found" />
                            <TableCeil />
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
