import React, { ReactNode } from "react";

interface IProps {
    children: ReactNode;
    text: string;
}

export const RowTemplate: React.FC<IProps> = ({ children, text }) => (
    <tr className="flex sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
        <th className="w-1/3 p-3 md:p-3 lg:p-3 text-left text-sm md:text-base lg:text-base">
            {text}:
        </th>
        <td className="w-full flex p-3 md:p-3 lg:p-3 mx-2 gap-3">{children}</td>
    </tr>
);
