import React, { FC, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useKeycloak } from "@react-keycloak/web";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { InputWrapper } from "components/shared/inputWrapper";
import { useCreateFormula } from "../../queries";
import { Button } from "components/shared/button";
import { IError } from "common/types";
import { IFormula } from "pages/formulas/formula-profile/types";
import { buildLink } from "common/utils";

interface IProps {
    handleClose: () => void;
}

const CreateSchema = Yup.object().shape({
    description: Yup.string().required("Name is required"),
});

export const CreateFormulaForm: FC<IProps> = memo(({ handleClose }) => {
    const { mutate } = useCreateFormula();
    const queryClient = useQueryClient();
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            description: "",
        },
        validationSchema: CreateSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            mutate(
                {
                    body: {
                        ...values,
                        created_by: keycloak?.tokenParsed?.email,
                    },
                },
                {
                    onSuccess: (response: IFormula) => {
                        toast.update(toastId, {
                            ...defaultSuccessToastUpdate,
                            render: "Formula has been created",
                        });
                        queryClient.invalidateQueries({
                            queryKey: [queryKeys.formulas],
                        });
                        formik.resetForm();
                        handleClose();

                        const link = buildLink(
                            routerKeys.formulas,
                            response.id,
                        );

                        navigate(link);
                    },
                    onError: (err: IError) => {
                        const message = err?.response?.data?.message || "";

                        if (message.includes("description")) {
                            formik.setFieldError("description", message);
                        }

                        toast.update(toastId, defaultErrorToastUpdate);
                    },
                },
            );
        },
    });

    const { values, errors, touched, handleChange } = formik;

    return (
        <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-4">
                <InputWrapper
                    isError={Boolean(errors.description && touched.description)}
                    error={errors.description}
                    label="Name"
                    helpText="Enter a unique name for the formula."
                    example="Example: Lipstick: Bold Red Colour Rich Cream 007"
                >
                    <textarea
                        className="textarea textarea-bordered w-full flex-grow"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <div className="flex items-center justify-end gap-2 mt-3">
                    <Button text="Clear" onClick={formik.resetForm} />
                    <Button text="Create" type="submit" />
                </div>
            </div>
        </form>
    );
});
