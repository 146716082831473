import {
    QueryClient,
    QueryClientProvider,
    QueryObserverOptions,
} from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { ReactNode } from "react";

interface IProps {
    children: ReactNode;
}

export const QueryProvider: React.FC<IProps> = ({ children }) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                keepPreviousData: true,
                refetchOnMount: true,
                refetchOnWindowFocus: false,
                refetchOnReconnect: true,
                staleTime: 5 * 60 * 1000,
                cacheTime: 10 * 60 * 1000,
            } as QueryObserverOptions,
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            {children}
            {/* {process.env.NODE_ENV === "development" && <ReactQueryDevtools />} */}
        </QueryClientProvider>
    );
};
