import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState, useCallback } from "react";

export const useOwner = () => {
    const { keycloak } = useKeycloak();
    const [email, setEmail] = useState<string | undefined>();

    useEffect(() => {
        if (keycloak) {
            keycloak
                .loadUserProfile()
                .then((profile) => {
                    if (profile?.email) {
                        setEmail(profile.email);
                    }
                })
                .catch((error) => {
                    console.error("Failed to load user profile:", error);
                });
        }
    }, [keycloak]);

    const isOwner = useCallback(
        (ownerEmail: string) => email === ownerEmail,
        [email],
    );

    return { email, isOwner };
};
