import React, {
    FC,
    useEffect,
    useState,
    memo,
    useCallback,
    ReactNode,
} from "react";
import { SearchBlock } from "pages/dashboard/components";

interface IProps {
    title?: string;
    titleIcon?: ReactNode;
    subtitle?: string;
    children: ReactNode;
    withSearch?: boolean;
    searchValue?: string;
    searchPlaceholder?: string;
    onChange?: (value: string) => void;
    callback?: () => void;
    createFn?: () => void;
    additionalChild?: ReactNode;
    additionalActions?: ReactNode;
    buttonBlock?: ReactNode;
}

export const PageTemplate: FC<IProps> = memo(
    ({
        title,
        subtitle,
        children,
        withSearch = true,
        searchValue = "",
        searchPlaceholder,
        onChange = () => {},
        buttonBlock = null,
        titleIcon = null,
    }) => {
        const [value, setValue] = useState(searchValue);

        useEffect(() => {
            setValue(searchValue);
        }, [searchValue]);

        useEffect(() => {
            const t = setTimeout(() => {
                onChange(value);
            }, 400);

            return () => {
                clearTimeout(t);
            };
        }, [value]);

        const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                e.preventDefault();
                setValue(e.target.value);
            },
            [],
        );

        return (
            <div className="flex flex-col flex-grow">
                <div className="grid lg:grid-cols-2 gap-4 mx-10 my-8 min-h-[110px]">
                    <div>
                        {title && (
                            <h2 className="flex text-2xl font-semibold items-center">
                                <span className="mr-2">{title}</span>
                                {titleIcon}
                            </h2>
                        )}
                        {subtitle && (
                            <p className="text-[#4d596a] text-sm md:text-base lg:text-base">
                                {subtitle}
                            </p>
                        )}
                    </div>
                </div>
                {withSearch && (
                    <div className="flex flex-col md:flex-row gap-8 mx-10 my-3">
                        <div className="flex-grow">
                            <SearchBlock
                                placeholder={searchPlaceholder}
                                value={value}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex items-center flex-shrink-0">
                            {buttonBlock}
                        </div>
                    </div>
                )}
                {children}
            </div>
        );
    },
);
