export type PotencyName =
    | "NC (Non sensitizer)"
    | "Negative"
    | "NS"
    | "Weak"
    | "1B (Other Sensitizer)"
    | "Moderate"
    | "Others or 1B"
    | "Positive"
    | "Strong"
    | "Extreme"
    | "Strong or 1A"
    | "1A (Strong)";

export type PotencyLevel =
    | "NS"
    | "Weak"
    | "Moderate"
    | "Sensitiser"
    | "Strong"
    | null;

export const potencyGenerator = (name: PotencyName): PotencyLevel => {
    switch (name) {
        case "NC (Non sensitizer)":
        case "Negative":
        case "NS":
            return "NS";
        case "Weak":
            return "Weak";
        case "1B (Other Sensitizer)":
        case "Moderate":
        case "Others or 1B":
            return "Moderate";
        case "Positive":
            return "Sensitiser";
        case "Strong":
        case "Extreme":
        case "Strong or 1A":
        case "1A (Strong)":
            return "Strong";
        default:
            return null;
    }
};
