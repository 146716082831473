import classNames from "classnames";
import React from "react";

interface IProps {
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    value: string;
    name: string;
    color?: string;
}

export const YesNoSelect: React.FC<IProps> = ({
    onChange,
    name,
    value,
    color = "select-secondary",
}) => (
    <select
        className={classNames(
            "select w-full select-sm max-w-xxxs join-item rounded-t-none",
            color,
        )}
        name={name}
        onChange={onChange}
        value={value || ""}
    >
        <option value=""></option>
        <option value="no">No</option>
        <option value="yes">Yes</option>
    </select>
);
