import React, { ChangeEvent, FC, memo, useState } from "react";
import {
    FilterInputBox,
    Details,
    CheckboxGroup,
    DropdownWrapper,
} from "components/shared";
import { countriesList } from "common/data/countriesList";
import { type IFilter } from "common/types";
import { useHandleUpdateFilters } from "common/hooks";
import { useManufactureOptions, useTradeNameOptions } from "../queries";

interface IBoxes {
    containGluten: boolean;
    animalDerivedIngredients: boolean;
    animalTestingIngredients: boolean;
    cfiAcceptable: boolean;
    countryOfOrigin: boolean;
    tradename: boolean;
    manufacturer: boolean;
}

interface IProps {
    onUpdateFilters: (
        key: string,
        { value, label }: IFilter[keyof IFilter],
    ) => void;
    filters: IFilter;
}

export const Filters: FC<IProps> = memo(({ onUpdateFilters, filters }) => {
    const [isOpen, setIsOpen] = useState<IBoxes>({
        animalDerivedIngredients: false,
        animalTestingIngredients: false,
        containGluten: false,
        cfiAcceptable: false,
        countryOfOrigin: false,
        tradename: false,
        manufacturer: false,
    });

    const { data: manufactures } = useManufactureOptions();

    const { data: tradeNames } = useTradeNameOptions();

    const handleToggleVisibility = (name: string) => {
        setIsOpen((curr) => ({
            ...curr,
            [name]: !curr[name as keyof IBoxes],
        }));
    };

    const handleUpdateFilters = useHandleUpdateFilters({
        onUpdateFilters,
        filters,
    });

    const handleUpdateFilterInput = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        filterValue?: string,
    ) => {
        const { name, value } = e.target;
        onUpdateFilters(name, {
            value: value,
            label: name || "",
            filterValue: filterValue || "",
        });
    };

    return (
        <form>
            <div className="w-full bg-white max-w-lg p-4 my-2 rounded-md">
                <FilterInputBox
                    name="ricode"
                    value={filters?.ricode?.value}
                    placeholder="Raw Ingredient Code"
                    onChange={(e) =>
                        handleUpdateFilterInput(e, "Raw Ingredient Code")
                    }
                />

                <Details
                    isOpen={isOpen.containGluten}
                    text="Gluten"
                    onOpen={() => handleToggleVisibility("containGluten")}
                >
                    <CheckboxGroup
                        value="yes"
                        label="Yes"
                        onChange={handleUpdateFilters({
                            key: "gluten",
                            label: "Gluten",
                        })}
                        isChecked={filters?.gluten?.value === "yes"}
                    />
                    <CheckboxGroup
                        value="no"
                        label="No"
                        onChange={handleUpdateFilters({
                            key: "gluten",
                            label: "Gluten",
                        })}
                        isChecked={filters?.gluten?.value === "no"}
                    />
                </Details>

                <Details
                    isOpen={isOpen.animalDerivedIngredients}
                    text="Animal Derived"
                    onOpen={() =>
                        handleToggleVisibility("animalDerivedIngredients")
                    }
                >
                    <CheckboxGroup
                        value="yes"
                        label="Yes"
                        onChange={handleUpdateFilters({
                            key: "animal_derived",
                            label: "Animal Derived",
                        })}
                        isChecked={filters?.animal_derived?.value === "yes"}
                    />
                    <CheckboxGroup
                        value="no"
                        label="No"
                        onChange={handleUpdateFilters({
                            key: "animal_derived",
                            label: "Animal Derived",
                        })}
                        isChecked={filters?.animal_derived?.value === "no"}
                    />
                    <CheckboxGroup
                        value="datamissing"
                        label="Data not available"
                        onChange={handleUpdateFilters({
                            key: "animal_derived",
                            label: "Animal Derived",
                        })}
                        isChecked={
                            filters?.animal_derived?.value === "datamissing"
                        }
                    />
                </Details>

                <Details
                    isOpen={isOpen.animalTestingIngredients}
                    text="Animal Testing"
                    onOpen={() =>
                        handleToggleVisibility("animalTestingIngredients")
                    }
                >
                    <CheckboxGroup
                        value="yes"
                        label="Yes"
                        onChange={handleUpdateFilters({
                            key: "animal_testing",
                            label: "Animal Testing",
                        })}
                        isChecked={filters?.animal_testing?.value === "yes"}
                    />
                    <CheckboxGroup
                        value="no"
                        label="No"
                        onChange={handleUpdateFilters({
                            key: "animal_testing",
                            label: "Animal Testing",
                        })}
                        isChecked={filters?.animal_testing?.value === "no"}
                    />
                    <CheckboxGroup
                        value="datamissing"
                        label="Data not available"
                        onChange={handleUpdateFilters({
                            key: "animal_testing",
                            label: "Animal Testing",
                        })}
                        isChecked={
                            filters?.animal_testing?.value === "datamissing"
                        }
                    />
                </Details>

                <Details
                    isOpen={isOpen.cfiAcceptable}
                    text="CFI Acceptable"
                    onOpen={() => handleToggleVisibility("cfiAcceptable")}
                >
                    <CheckboxGroup
                        value="yes"
                        label="Yes"
                        onChange={handleUpdateFilters({
                            key: "cfi_acceptable",
                            label: "CFI Acceptable",
                        })}
                        isChecked={filters?.cfi_acceptable?.value === "yes"}
                    />
                    <CheckboxGroup
                        value="no"
                        label="No"
                        onChange={handleUpdateFilters({
                            key: "cfi_acceptable",
                            label: "CFI Acceptable",
                        })}
                        isChecked={filters?.cfi_acceptable?.value === "no"}
                    />
                    <CheckboxGroup
                        value="datamissing"
                        label="Data not available"
                        onChange={handleUpdateFilters({
                            key: "cfi_acceptable",
                            label: "CFI Acceptable",
                        })}
                        isChecked={
                            filters?.cfi_acceptable?.value === "datamissing"
                        }
                    />
                </Details>
                <Details
                    isOpen={isOpen.countryOfOrigin}
                    text="Country of Origin"
                    onOpen={() => handleToggleVisibility("countryOfOrigin")}
                >
                    <DropdownWrapper
                        name="country_of_origin"
                        options={countriesList}
                        value={filters?.country_of_origin?.value}
                        onChange={handleUpdateFilters({
                            key: "country_of_origin",
                            label: "Country of Origin",
                        })}
                    />
                </Details>
                <Details
                    isOpen={isOpen.manufacturer}
                    text="Manufacturer"
                    onOpen={() => handleToggleVisibility("manufacturer")}
                >
                    <DropdownWrapper
                        name="manufacturer"
                        options={manufactures}
                        value={filters?.manufacturer?.value}
                        onChange={handleUpdateFilters({
                            key: "manufacturer",
                            label: "Manufacturer",
                        })}
                    />
                </Details>
                <Details
                    isOpen={isOpen.tradename}
                    text="Tradename"
                    onOpen={() => handleToggleVisibility("tradename")}
                >
                    <DropdownWrapper
                        name="tradename"
                        options={tradeNames}
                        value={filters?.tradename?.value}
                        onChange={handleUpdateFilters({
                            key: "tradename",
                            label: "Tradename",
                        })}
                    />
                </Details>
            </div>
        </form>
    );
});
