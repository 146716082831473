import { AxiosError } from "axios";

interface ErrorMessages {
    [field: string]: string;
}

/**
 * Extracts a user-friendly error message from an AxiosError.
 * @param {AxiosError} error - The AxiosError object.
 * @returns {string | ErrorMessages} - The object containing field names and error messages to display to the user, or a string message.
 */
export const getErrorMessage = (error: AxiosError): string | ErrorMessages => {
    if (error.response) {
        const { data, status, statusText } = error.response;

        const errorMessages: ErrorMessages =
            data && typeof data === "object"
                ? Object.entries(data as Record<string, unknown>).reduce(
                      (acc: ErrorMessages, [name, errors]) => {
                          acc[name] = Array.isArray(errors)
                              ? errors.join(", ")
                              : String(errors);
                          return acc;
                      },
                      {},
                  )
                : {};

        if (Boolean(Object.keys(errorMessages)?.length)) {
            const message = errorMessages?.message || "";
            return message?.replace(/^.*?:\s*/, "");
        }

        if (statusText) {
            return `Error: ${statusText}`;
        }

        return `Error: Something went wrong (status code: ${status})`;
    } else if (error.request) {
        return "Error: No response received from server";
    }

    return `Error: ${error.message}`;
};
