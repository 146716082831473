import classNames from "classnames";
import React, { memo } from "react";

interface IProps {
    value?: string;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    className?: string;
    name: string;
    isDisabled?: boolean;
    placeholder?: string;
    id?: string;
}

export const TableTextareaCeil: React.FC<IProps> = memo(
    ({
        value = "",
        onChange,
        className = "",
        name,
        isDisabled = false,
        placeholder = "",
        id,
    }) => (
        <div className={className}>
            <textarea
                id={id}
                className={classNames(
                    "textarea textarea-bordered w-full",
                    className,
                )}
                name={name}
                value={value}
                onChange={onChange}
                disabled={isDisabled}
                placeholder={placeholder}
            />
        </div>
    ),
);
