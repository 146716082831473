import { useQueryClient } from "@tanstack/react-query";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    queryKeys,
    toastTexts,
} from "common/constants";
import { IReport } from "common/types";
import { ModalTemplate } from "components/shared/modalTemplate";
import {
    useAddSubIngredientReport,
    useGetSubIngredient,
    useGetSubIngredientReports,
    useRemoveSubIngredientReport,
} from "pages/subIngredient/subIngredientProfile/queries";
import React, { useMemo, useRef } from "react";
import { toast } from "react-toastify";
import { ReportRow } from "./ReportRow";
import { subIngredientService } from "services";
import { handleDownloadFile } from "common/utils";
import { Button } from "components/shared/button";
import { LoadingToast } from "components/shared/loadingToast";

export const ReportButton = () => {
    const modalRef = useRef<HTMLDialogElement | null>(null);

    const openModal = () => {
        if (modalRef.current) {
            modalRef.current.showModal();
        }
    };

    const subIngredient = useGetSubIngredient();
    const queryClient = useQueryClient();

    const key = useMemo(
        () => [queryKeys.subIngredientReports, `${subIngredient?.sicode}`],
        [subIngredient],
    );

    const { data: reports, refetch } = useGetSubIngredientReports({
        sicode: `${subIngredient?.sicode}`,
    });

    const { mutate: uploadReport, isPending: isPendingAddReport } =
        useAddSubIngredientReport();

    const { mutate: removeReport, isPending: isPendingRemoveReport } =
        useRemoveSubIngredientReport();

    const handleDownload = (file: IReport) => async () => {
        const toastId = toast.loading(toastTexts.loading, defaultToastOptions);

        subIngredientService
            .getReport({
                sicode: `${subIngredient?.sicode}`,
                filename: file.filename,
            })
            .then((response) => {
                handleDownloadFile(response, file.filename);
                toast.dismiss(toastId);
            })
            .catch(() => {
                toast.update(toastId, {
                    render: toastTexts.error,
                    position: "bottom-left",
                    type: "error",
                    isLoading: false,
                });
            });
    };

    const closeModal = () => {
        if (modalRef.current) {
            modalRef.current.close();
        }
    };

    const handleAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append("file", file);

            uploadReport(
                {
                    sicode: `${subIngredient?.sicode}`,
                    body: formData,
                    filename: file.name,
                },
                {
                    onSuccess: () => {
                        refetch();
                    },
                    onError: () => {
                        toast.error(toastTexts.error, defaultErrorToastOptions);
                    },
                },
            );
        }
    };

    const deleteFile = (file: IReport) => () => {
        removeReport(
            { sicode: `${subIngredient?.sicode}`, filename: file.filename },
            {
                onSuccess: () => {
                    queryClient.setQueryData<IReport[]>(key, (prevData) =>
                        prevData?.filter(
                            (prevFile) =>
                                prevFile.filename !== file.filename &&
                                prevFile.modified !== file.modified,
                        ),
                    );
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    };

    return (
        <>
            {(isPendingAddReport || isPendingRemoveReport) && <LoadingToast />}
            <div className="flex w-full">
                <Button onClick={openModal} text="Reports files" />
            </div>
            <ModalTemplate
                text={`INCI files for ${subIngredient?.name}`}
                modalRef={modalRef}
                onClose={closeModal}
                className="max-w-xl"
            >
                <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg my-3">
                    <tbody>
                        {reports?.length ? (
                            <>
                                {reports.map((file, idx) => (
                                    <ReportRow
                                        key={`file-${idx}`}
                                        file={file}
                                        deleteFile={deleteFile(file)}
                                        handleDownload={handleDownload(file)}
                                    />
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td className="w-3/4 text-xs md:text-base lg:text-base p-3">
                                    no file found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="w-full flex-col">
                    <label className="label mb-0">
                        <span className="label-text-alt">
                            Upload a file (doc, docx, pdf, txt):
                        </span>
                    </label>
                    <input
                        className="w-full file-input file-input-bordered"
                        type="file"
                        onChange={handleAddFile}
                        accept=".doc, .docx, .pdf, .txt"
                        value=""
                    />
                </div>
            </ModalTemplate>
        </>
    );
};
