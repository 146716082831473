import { FC } from "react";
import { IFormula } from "../types";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
    toastTexts,
    queryKeys,
    defaultErrorToastOptions,
} from "common/constants";
import { useUpdateFormula } from "../queries";
import { useQueryClient } from "@tanstack/react-query";
import { IError } from "common/types";
import React from "react";
import { InputWrapper } from "components/shared/inputWrapper";
import { Button } from "components/shared/button";

interface IProps {
    formula: IFormula;
    handleClose: () => void;
}

const CreateSchema = Yup.object().shape({
    ficode: Yup.string().required("Ficode is required"),
    description: Yup.string().required("Name is required"),
});

export const EditFormulaForm: FC<IProps> = ({ formula, handleClose }) => {
    const { mutate } = useUpdateFormula();
    const queryClient = useQueryClient();

    const formik = useFormik({
        initialValues: {
            ficode: formula.ficode,
            description: formula.description || "",
        },
        validationSchema: CreateSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (body) => {
            mutate(
                { id: formula.id.toString(), body },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries({
                            queryKey: [queryKeys.formulas],
                        });
                        queryClient.invalidateQueries({
                            queryKey: [queryKeys.formula, formula.id],
                        });
                        formik.resetForm();
                        handleClose();
                    },
                    onError: (err: IError) => {
                        const messge = err?.response?.data?.message || "";

                        if (messge.toLocaleLowerCase().includes("ficode")) {
                            formik.setFieldError(
                                "ficode",
                                "Ficode must be unique",
                            );
                        }

                        if (
                            messge.toLocaleLowerCase().includes("description")
                        ) {
                            formik.setFieldError(
                                "description",
                                "Description must be unique",
                            );
                        }

                        toast.error(toastTexts.error, defaultErrorToastOptions);
                    },
                },
            );
        },
    });

    const { values, errors, touched, handleChange } = formik;

    return (
        <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-4">
                <InputWrapper
                    isError={Boolean(errors.ficode && touched.ficode)}
                    error={errors.ficode}
                    label="Ficode"
                >
                    <input
                        type="text"
                        name="ficode"
                        className="w-full input input-bordered"
                        value={values.ficode}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <InputWrapper
                    isError={Boolean(errors.description && touched.description)}
                    error={errors.description}
                    label="Name"
                >
                    <textarea
                        className="textarea textarea-bordered w-full flex-grow"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <div className="flex items-center justify-end gap-2 mt-3">
                    <Button text="Clear" onClick={formik.resetForm} />
                    <Button text="Update" type="submit" />
                </div>
            </div>
        </form>
    );
};
