import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import {
    IContaminantsResponse,
    ICreateContaminantBody,
} from "pages/contaminant/contaminants/types";
import instanse from "./axios/instance";
import { IStatisticResponse } from "common/types/statisticResponse";
import { IContaminant } from "common/types";

class ContaminantsService extends BaseService {
    constructor(
        public baseUrl = backendKeys.contaminants,
        private fetchingService = instanse,
    ) {
        super(baseUrl);
    }

    async getContaminant({ id }: { id: string }) {
        return this.handleRequest<ICreateContaminantBody>(
            this.fetchingService.get(this.getFullUrl(`/${id}`)),
        );
    }

    async getContaminants({
        queryParams,
    }: {
        queryParams: string;
    }): Promise<IContaminantsResponse> {
        const limit = queryParams.includes("limit") ? "" : "&limit=2";

        return this.handleRequest<IContaminantsResponse>(
            this.fetchingService.get(
                this.getFullUrl(`?${queryParams}${limit}`),
            ),
        );
    }

    async getAll() {
        return this.handleRequest<IContaminant[]>(
            this.fetchingService.get(this.getFullUrl("/all")),
        );
    }

    async getContaminantsStatistic(): Promise<IStatisticResponse> {
        return this.handleRequest<IStatisticResponse>(
            this.fetchingService.get(this.getFullUrl("/statistic")),
        );
    }

    async createContaminant({
        body,
    }: {
        body: Partial<ICreateContaminantBody>;
    }) {
        return this.handleRequest<IContaminant>(
            this.fetchingService.post(this.getFullUrl("/create"), body),
        );
    }

    async removeContaminant({ id }: { id: string }) {
        return this.handleRequest(
            this.fetchingService.delete(this.getFullUrl(`/${id}`)),
        );
    }

    async updateContaminant({
        id,
        body,
    }: {
        id: number;
        body: Pick<IContaminant, "description" | "name">;
    }) {
        return this.handleRequest<IContaminant>(
            this.fetchingService.put(this.getFullUrl(`/${id}`), body),
        );
    }
}

export const contaminantsService = new ContaminantsService();
