import { ToastOptions, ToastPosition, UpdateOptions } from "react-toastify";
import { toastTexts } from "./toastTexts";

export const defaultToastOptions: ToastOptions<unknown> = {
    position: "bottom-right" as ToastPosition,
    hideProgressBar: true,
    autoClose: 3000,
};

export const defaultErrorToastOptions: ToastOptions<unknown> = {
    ...defaultToastOptions,
    position: "bottom-left" as ToastPosition,
};

export const defaultErrorToastUpdate: UpdateOptions<unknown> = {
    render: toastTexts.error,
    type: "error",
    isLoading: false,
    position: "bottom-left",
    autoClose: 3000,
};

export const defaultSuccessToastUpdate: UpdateOptions<unknown> = {
    type: "success",
    isLoading: false,
    autoClose: 3000,
};
