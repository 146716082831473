import { useEffect, useState } from "react";
import { useProductTypeProperties, useProductTypes } from "../queries";
import { useFormulaProfileState } from "../store";
import { type IMosIngredientData } from "../components/tabs/mosTab/types";
import { type IFormula } from "../types";
import { MosConstants } from "../enums";

export const useProductTypesByClass = (
    fdaClass: string,
    disableAdd: boolean = false,
) => {
    const { mutate: fetchProductTypes, data: productTypesData = [] } =
        useProductTypes();

    useEffect(() => {
        fetchProductTypes({ category: fdaClass || "fda", disableAdd });
    }, [fdaClass, fetchProductTypes]);

    return productTypesData;
};

export const useMosIngredientData = (formula: IFormula | undefined) => {
    const { isOwner } = useFormulaProfileState();
    const [mosIngredientData, setMosIngredientData] =
        useState<IMosIngredientData>({
            regulatory_body: {
                value: formula?.fda_product_classification || "fda",
                disabled: !isOwner,
            },
            product_type: {
                value: formula?.product_type || "",
                disabled: !isOwner,
            },
            grams_applied_per_day: { value: "", disabled: false },
            body_weight: {
                value: formula?.body_weight ?? MosConstants.DEFAULT_BODY_WEIGHT,
                disabled: !isOwner,
            },
            skin_retention_factor: { value: "", disabled: false },
        });

    const { data: productTypeProperties } = useProductTypeProperties();

    useEffect(() => {
        if (mosIngredientData.product_type.value) {
            setMosIngredientData((curr) => {
                const category = curr?.regulatory_body?.value || "";
                const productType = curr?.product_type?.value || "";

                return {
                    ...curr,
                    grams_applied_per_day: {
                        ...curr.grams_applied_per_day,
                        value:
                            productTypeProperties?.[category]?.[productType]
                                ?.grams_applied_per_day || "",
                    },
                    skin_retention_factor: {
                        ...curr.skin_retention_factor,
                        value:
                            productTypeProperties?.[category]?.[productType]
                                ?.skin_retention_factor || "",
                    },
                };
            });
        }
    }, [
        mosIngredientData.product_type.value,
        mosIngredientData.regulatory_body.value,
        productTypeProperties,
    ]);

    return { mosIngredientData, setMosIngredientData };
};
