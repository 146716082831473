import React, { FC } from "react";
import saveIcon from "assets/images/icons/save_20.svg";

interface IProps {
    onClick?: () => void;
    disabled?: boolean;
}

export const SaveButton: FC<IProps> = ({ onClick, disabled }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            className="gray-color gap-2 text-lg flex items-center transition-transform duration-300 ease-in-out hover:scale-110 bg-transparent border-none cursor-pointer"
        >
            <span>Save</span>
            <img className="w-6 h-6" src={saveIcon} alt="Save icon" />
        </button>
    );
};
