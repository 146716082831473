export const envClass = [
    {
        text: "Botanical/Natural",
        value: "botanical/natural",
    },
    {
        text: "Inorganic",
        value: "inorganic",
    },
    {
        text: "Organic",
        value: "organic",
    },
    {
        text: "Polymer",
        value: "polymer",
    },
];
