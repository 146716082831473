import classNames from "classnames";
import React, { memo } from "react";

interface IProps {
    isSuccess?: boolean;
    text: string;
    paragraphText: string;
}

export const TableRow: React.FC<IProps> = memo(
    ({ isSuccess = true, text, paragraphText }) => (
        <tr>
            <th className="text-sm text-primary-main-black">{text}</th>
            <td>
                <p
                    className={classNames(
                        "float-right py-1 text-right items-center leading-none rounded-full inline-flex text-sm px-4 my-2 mx-3",
                        {
                            "text-green-400 bg-green-100": isSuccess,
                            "bg-red-100 text-red-400": !isSuccess,
                        },
                    )}
                >
                    {paragraphText}
                </p>
            </td>
        </tr>
    ),
);
