import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import { formulaService, formulasService, fdaService } from "services";
import { useFormulaProfileState } from "../store";
import { IFormula, UpdateProductTypePropertiesRequest } from "../types";
import { IFDAClass, IProductTypes, IUpdateMosRequest } from "common/types";
import { IUpdateFormula } from "../types/updateFormula";
import { productTypes } from "common/data";
import { IngredientRelation } from "pages/rawIngredient/types";

export const useUploadFormula = ({ id }: { id: string }) => {
    const key = [queryKeys.formula, id];
    const { setKey } = useFormulaProfileState();

    return useQuery({
        queryKey: key,
        queryFn: () =>
            formulaService.getFormula({ id }).then((res) => {
                setKey(key);
                return res;
            }),
    });
};

export const useFormulaStatusOptions = () =>
    useQuery({
        queryKey: [queryKeys.formulaStatusOptions],
        queryFn: () => formulaService.getFormulaStatusOptions(),
    });

export const useUploadFormulaSimilar = ({ id }: { id: string }) => {
    const key = [queryKeys.similar, id];

    return useQuery({
        queryKey: key,
        queryFn: () => formulasService.getFormulaSimilar({ id }),
    });
};

export const useUpdateFormula = () => {
    return useMutation({
        mutationFn: ({ id, body }: { id: string; body: IUpdateFormula }) =>
            formulaService.updateFormula({ id, body }),
    });
};

export const useRemoveFormula = () => {
    return useMutation({
        mutationFn: ({ id }: { id: string }) =>
            formulaService.removeFormula({ id }),
    });
};

export const useUpdateMos = () =>
    useMutation({
        mutationFn: ({
            id,
            body,
        }: {
            id: string | number;
            body: IUpdateMosRequest;
        }) => formulaService.updateMos({ id, body }),
    });

export const useUpdateProductTypeProperties = () =>
    useMutation({
        mutationFn: (payload: UpdateProductTypePropertiesRequest) =>
            fdaService.updateProductTypeProperties(payload),
    });

export const useGetFormula = () => {
    const { key } = useFormulaProfileState();

    return useQueryClient().getQueryData<IFormula>(key);
};

export const useAddFormulaReport = () =>
    useMutation({
        mutationFn: ({
            ficode,
            filename,
            body,
        }: {
            ficode: string;
            filename: string;
            body: FormData;
        }) => formulaService.addFormulaReport({ ficode, filename, body }),
    });

export const useRemoveFormulaReport = () =>
    useMutation({
        mutationFn: ({
            ficode,
            filename,
        }: {
            ficode: string;
            filename: string;
        }) => formulaService.removeFormulaReport({ ficode, filename }),
    });

export const useGetFormulaReports = ({ ficode }: { ficode: string }) => {
    const key = [queryKeys.formulaReports, ficode];

    return useQuery({
        queryKey: key,
        queryFn: () => formulaService.getFormulaReports({ ficode }),
    });
};

export const useGenerateFormulaReport = () => {
    return useMutation({
        mutationFn: ({ id, type }: { id: string; type: string }) =>
            formulaService.generateReport({ id, type }),
    });
};

export const useUpdateRelation = () =>
    useMutation({
        mutationFn: (payload: IngredientRelation) =>
            formulaService.updateRelation(payload),
    });

export const useDeleteRelation = () => {
    return useMutation({
        mutationFn: ({
            id,
            ingredientId,
            relationType,
        }: {
            id: string;
            ingredientId: string;
            relationType: string;
        }) =>
            formulaService.deleteRelation({
                id,
                ingredientId,
                relationType,
            }),
    });
};

export const useGetProductTypes = ({ fdaClassId }: { fdaClassId: string }) => {
    const key = [queryKeys.productTypes, fdaClassId];

    return useQuery({
        queryKey: key,
        queryFn: () => fdaService.getProductTypes({ fdaClassId }),
    });
};

export const useGetAllProductTypes = () =>
    useQuery({
        queryKey: [queryKeys.productTypeOptions],
        queryFn: () => fdaService.getAllProductTypes(),
        select: (data) =>
            [
                ...productTypes,
                ...data.map(({ id, name }) => ({
                    id,
                    value: name.toLowerCase().replaceAll(" ", "_"),
                    label: name,
                })),
            ].sort((a, b) => a.label.localeCompare(b.label)),
    });

export const useProductTypes = () =>
    useMutation({
        mutationFn: (payload: { category: string; disableAdd: boolean }) =>
            fdaService.getProductTypesByClass(payload),
    });

export const useCreateProductType = () =>
    useMutation({
        mutationFn: (
            payload: Pick<
                IProductTypes,
                | "name"
                | "regulatoryBody"
                | "skin_retention_factor"
                | "grams_applied_per_day"
                | "created_by"
            >,
        ) => fdaService.createProductType(payload),
    });

export const useGetFdaClasses = () =>
    useQuery({
        queryKey: [queryKeys.fdaClasses],
        queryFn: () => fdaService.getAll(),
    });

export const useCreateFdaClass = () =>
    useMutation({
        mutationFn: (
            payload: Pick<IFDAClass, "code" | "name" | "created_by">,
        ) => fdaService.create(payload),
    });

export const useCategoryBodies = () =>
    useQuery({
        queryKey: [queryKeys.categoryBodies],
        queryFn: () => fdaService.getCategoryBodies(),
    });

export const useProductTypeProperties = () =>
    useQuery({
        queryKey: [queryKeys.productTypeProperties],
        queryFn: () => fdaService.getProductTypeProperties(),
    });
