import React, { FC } from "react";
import { RowTemplate } from ".";
import { countriesList } from "common/data/countriesList";
import { IData } from "../TradeNameTab";
import { YesNoCheckboxGroup } from "components/shared/yesNoCheckboxGroup";

interface IProps {
    tradename: IData;
    handleUpdateData: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
}

export const TradeNameTable: FC<IProps> = ({ tradename, handleUpdateData }) => {
    return (
        <>
            <RowTemplate text="Animal Testing">
                <YesNoCheckboxGroup
                    onChange={handleUpdateData}
                    value={tradename.animal_testing.value}
                    name="animal_testing"
                    disabled={false}
                />
            </RowTemplate>
            <RowTemplate text="CFI Acceptable">
                <YesNoCheckboxGroup
                    onChange={handleUpdateData}
                    value={tradename.cfi_acceptable.value}
                    name="cfi_acceptable"
                    disabled={false}
                />
            </RowTemplate>
            <RowTemplate text="Country of origin">
                <select
                    className="select select-bordered max-w-xs"
                    name="country_of_origin"
                    onChange={handleUpdateData}
                    value={tradename.country_of_origin.value}
                    // disabled={!isOwner}
                >
                    <option value=""></option>
                    {countriesList.map(({ label, value }) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ))}
                </select>
            </RowTemplate>
        </>
    );
};
