export const backendKeys = {
    root: "/",
    formulas: "formulas",
    formula: "formula",
    rawIngredients: "rawingredients",
    subIngredients: "subingredients",
    contaminants: "contaminants",
    vendors: "vendors",
    fdaClasses: "fda_classes",
    keycloak: "keycloak",
    users: "users",
    safetySummary: "safetySummary",
    getLocation: "https://ipapi.co/json/",

    api: {
        formulas: {
            save: "/formulas/save",
            saved: "/formulas/saved",
        },
        rawIngredients: {
            fileUpload: "/file-upload",
        },
    },
} as const;
