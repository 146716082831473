import React, { FC } from "react";
import classNames from "classnames";

interface IProps {
    children: React.ReactNode;
    label: string;
    className?: string;
}

export const InputWrapper: FC<IProps> = ({
    children,
    label,
    className = "",
}) => (
    <div className="flex w-full justify-around">
        <div className="w-[30%] p-3">
            <label className="pt-3">{label}</label>
        </div>
        <div className={classNames("w-[60%]", className)}>{children}</div>
    </div>
);
