export const convertSkinDoctorCP = (key: string): number | null => {
    let value: number | null = null;

    switch (key) {
        case "Non-sensitiser":
            value = 1;
            break;
        case "Weak to moderate sensitiser":
            value = 2;
            break;
        case "Strong to extreme sensitiser":
            value = 3;
            break;
        default:
            break;
    }

    return value;
};

export const convertSkinDoctorCPToTextValue = (
    key: number | null | undefined,
): string => {
    let value = "NA";
    const preparedValue = key?.toString();

    switch (preparedValue) {
        case "1":
            value = "Non-sensitiser";
            break;
        case "2":
            value = "Weak to moderate sensitiser";
            break;
        case "3":
            value = "Strong to extreme sensitiser";
            break;
        default:
            break;
    }

    return value;
};
