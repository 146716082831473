import { useEffect, useState, useMemo } from "react";
import { useProductTypeProperties, useProductTypes } from "../queries";
import { useFormulaProfileState } from "../store";
import { type IMosIngredientData } from "../components/tabs/mosTab/types";
import { type IFormula } from "../types";
import { MosConstants } from "../enums";

export const useProductTypesByClass = (
    fdaClass: string,
    disableAdd: boolean = false,
) => {
    const { mutate: fetchProductTypes, data: productTypesData = [] } =
        useProductTypes();

    useEffect(() => {
        if (fdaClass) {
            fetchProductTypes({ category: fdaClass, disableAdd });
        }
    }, [fdaClass, disableAdd, fetchProductTypes]);

    return productTypesData;
};

export const useMosIngredientData = (formula: IFormula | undefined) => {
    const { isOwner } = useFormulaProfileState();
    const { data: productTypePropertiesByCategory } =
        useProductTypeProperties();

    const initialData = useMemo<IMosIngredientData>(() => {
        const regulatoryBody = formula?.fda_product_classification || "fda";
        const productType = formula?.product_type || "";

        return {
            regulatory_body: {
                value: regulatoryBody,
                disabled: !isOwner,
            },
            product_type: {
                value: productType,
                disabled: !isOwner,
            },
            grams_applied_per_day: { value: "", disabled: false },
            body_weight: {
                value: formula?.body_weight ?? MosConstants.DEFAULT_BODY_WEIGHT,
                disabled: !isOwner,
            },
            skin_retention_factor: { value: "", disabled: false },
        };
    }, [formula, isOwner]);

    const [mosIngredientData, setMosIngredientData] =
        useState<IMosIngredientData>(initialData);

    useEffect(() => {
        const { regulatory_body, product_type } = mosIngredientData;
        const category = regulatory_body.value;
        const productType = product_type.value;

        if (productType) {
            const updatedData = {
                grams_applied_per_day:
                    productTypePropertiesByCategory?.[category]?.[productType]
                        ?.grams_applied_per_day || "",
                skin_retention_factor:
                    productTypePropertiesByCategory?.[category]?.[productType]
                        ?.skin_retention_factor || "",
            };

            setMosIngredientData((curr) => {
                if (
                    curr.grams_applied_per_day.value !==
                        updatedData.grams_applied_per_day ||
                    curr.skin_retention_factor.value !==
                        updatedData.skin_retention_factor
                ) {
                    return {
                        ...curr,
                        grams_applied_per_day: {
                            ...curr.grams_applied_per_day,
                            value: updatedData.grams_applied_per_day,
                        },
                        skin_retention_factor: {
                            ...curr.skin_retention_factor,
                            value: updatedData.skin_retention_factor,
                        },
                    };
                }
                return curr;
            });
        }
    }, [
        mosIngredientData.product_type.value,
        mosIngredientData.regulatory_body.value,
        productTypePropertiesByCategory,
    ]);

    return { mosIngredientData, setMosIngredientData };
};
