import { AppContent } from "components/app-content";
import React from "react";
import { QueryProvider } from "libraries/queryProvider";
import "react-toastify/dist/ReactToastify.css";
import { KeycloakProvider } from "libraries/keycloakProvider";
import { RouterProvider } from "libraries/routerProvider";

export const App = () => {
    return (
        <QueryProvider>
            <KeycloakProvider>
                <RouterProvider>
                    <AppContent />
                </RouterProvider>
            </KeycloakProvider>
        </QueryProvider>
    );
};
