import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import { formulaService } from "services";
import { useFormulaCompareQuery } from "../store";
import { IFormulaCompareResponse } from "../types";

export const useUpdateFormulaCompare = ({
    id,
    searchParams,
}: {
    id: string;
    searchParams: string;
}) => {
    const queryKey = [queryKeys.formulaCompare, id, searchParams];
    const { setKey } = useFormulaCompareQuery();

    return useQuery({
        queryKey,
        queryFn: () =>
            formulaService.getFormulaCompare(id, searchParams).then((res) => {
                setKey(queryKey);
                return res;
            }),
    });
};

export const useGetFormulaCompare = () => {
    const { key } = useFormulaCompareQuery();
    const queryClient = useQueryClient();

    return queryClient.getQueryData<IFormulaCompareResponse>(key);
};
