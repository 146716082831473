import React, { FC } from "react";
import { EditButton, InfoInputField, SaveButton } from "..";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IUser } from "common/types";
import { useUpdateUserProfile } from "../../libs";
import { toast } from "react-toastify";
import {
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    toastTexts,
} from "common/constants";

interface IProps {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    company?: string;
    editMode: boolean;
    onEditClick?: () => void;
    onInputChange?: (name: string, newValue: string) => void;
    onSubmitData?: (data: IUser) => void;
    refetch?: () => void;
}

const required = "Field is required";

const validationSchema = Yup.object({
    firstName: Yup.string().required(required),
    lastName: Yup.string().required(required),
    email: Yup.string().email("Invalid email address").required(required),
    phone: Yup.string(),
    company: Yup.string(),
});

export const PersonalInfo: FC<IProps> = ({
    firstName,
    lastName,
    email,
    phone = "",
    company = "",
    editMode,
    onEditClick,
    onSubmitData,
    refetch,
}) => {
    const { mutate: updateUserProfile } = useUpdateUserProfile();

    const { handleSubmit, handleChange, values, errors, touched } = useFormik({
        initialValues: {
            firstName,
            lastName,
            email,
            phone,
            company,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            if (onSubmitData) {
                onSubmitData(values as IUser);
            }
            updateUserProfile(values as IUser, {
                onSuccess: () => {
                    toast.update(toastId, {
                        ...defaultSuccessToastUpdate,
                        render: toastTexts.success,
                    });

                    if (refetch) {
                        refetch();
                    }
                },
                onError: () => {
                    toast.update(toastId, defaultErrorToastUpdate);
                },
            });

            if (onEditClick) {
                onEditClick();
            }
        },
    });

    return (
        <div className="w-full mt-4 md:mt-6">
            <h6 className="text-lg md:text-xl font-semibold text-gray-700 mb-4 pl-4">
                Personal Information
            </h6>
            <form onSubmit={handleSubmit}>
                <div className="bg-profile p-4 md:p-6 shadow-md">
                    <div className="flex flex-col gap-4 md:gap-6 lg:flex-row lg:gap-8 items-start">
                        <div className="flex-1 flex flex-col gap-4">
                            <div className="flex flex-col lg:flex-row md:gap-4 lg:max-w-3xl">
                                <InfoInputField
                                    name="firstName"
                                    label="First Name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    editMode={editMode}
                                    isError={Boolean(
                                        touched.firstName && errors.firstName,
                                    )}
                                    errorMessage={errors.firstName}
                                />
                                <InfoInputField
                                    name="lastName"
                                    label="Last Name"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    editMode={editMode}
                                    isError={Boolean(
                                        touched.lastName && errors.lastName,
                                    )}
                                    errorMessage={errors.lastName}
                                />
                            </div>

                            <div className="flex flex-col lg:flex-row md:gap-4 lg:max-w-3xl">
                                <InfoInputField
                                    name="email"
                                    label="Email Address"
                                    value={values.email}
                                    onChange={handleChange}
                                    editMode={false}
                                    isError={Boolean(
                                        touched.email && errors.email,
                                    )}
                                    errorMessage={errors.email}
                                />
                                <InfoInputField
                                    name="phone"
                                    label="Phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    editMode={editMode}
                                    isError={Boolean(
                                        touched.phone && errors.phone,
                                    )}
                                    errorMessage={errors.phone}
                                />
                            </div>

                            <div className="flex flex-col lg:max-w-3xl">
                                <InfoInputField
                                    name="company"
                                    label="Organisation"
                                    value={values.company}
                                    onChange={handleChange}
                                    editMode={editMode}
                                    isError={Boolean(
                                        touched.company && errors.company,
                                    )}
                                    errorMessage={errors.company}
                                />
                            </div>
                        </div>

                        {onEditClick && (
                            <div className="flex justify-center md:justify-end mt-4 md:mt-0">
                                {editMode ? (
                                    <SaveButton onClick={handleSubmit} />
                                ) : (
                                    <EditButton onClick={onEditClick} />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};
