import React, { FC } from "react";
import classNames from "classnames";

interface IProps {
    title: string;
    text?: string;
    text2?: string;
    className?: string;
}

export const SectionHeader: FC<IProps> = ({
    title,
    text,
    text2,
    className,
}) => (
    <header className={classNames("flex flex-col text-base mt-4", className)}>
        <h6 className="uppercase primary-color">{title}</h6>
        {text && <span className="block text-sm text-gray-500">{text}</span>}
        {text2 && <span className="block text-sm text-gray-500">{text2}</span>}
    </header>
);
