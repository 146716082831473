import React, { ReactNode } from "react";

interface IProps {
    children: ReactNode;
    text: string;
    name?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
}

export const FormLabelTemplate: React.FC<IProps> = ({
    children,
    text,
    name,
    onChange,
    checked = false,
}) => (
    <label className="join join-vertical max-w-xxxs ml-2">
        <span className="label-text join-item bg-gray-200 px-2 py-0.5">
            <span className="first-letter:uppercase">{text}</span>
            {name && onChange && (
                <span className="text-sm ml-2 px-0">
                    <input
                        type="checkbox"
                        className="checkbox checkbox-xs mr-1 mt-0.5 rounded-t-none"
                        data-type="boolean"
                        value="true"
                        name={name || ""}
                        onChange={onChange}
                        checked={checked}
                    />
                    fix
                </span>
            )}
        </span>
        {children}
    </label>
);
