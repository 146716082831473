import React from "react";

interface IInputProps {
    value: string;
    name: string;
    onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
    isDisabled?: boolean;
}

export const Input: React.FC<IInputProps> = ({
    value,
    name,
    onChange,
    isDisabled = false,
}) => (
    <input
        type="text"
        className="w-full input input-bordered"
        name={name}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
    />
);
