import React, { useCallback } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Button } from "components/shared/button";
import { routerKeys } from "common/constants";
import { useNavigate } from "react-router-dom";
import { useLogout } from "common/hooks";

export const Header = () => {
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const { handleLogout } = useLogout();

    const handleVisitDashboard = useCallback(() => {
        navigate(routerKeys.dashboard);
    }, []);

    return (
        <div className="w-full flex items-center justify-between p-4">
            <Button
                text="Visit dashboard"
                className="btn-primary"
                onClick={handleVisitDashboard}
            />

            <div className="flex items-center">
                <h6 className="mr-4 text-primary-brand-main">
                    {keycloak?.tokenParsed?.name}
                </h6>
                <Button text="Logout" onClick={handleLogout} />
            </div>
        </div>
    );
};
