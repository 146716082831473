import React, { useState, ChangeEvent, FC, useCallback, memo } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { SectionHeader } from "../SectionHeader";
import { InputBlock } from "../InputBlock";
import { InputLabel } from "../InputLabel";
import { RequiredDescription } from "../prediction/components/RequiredDescription";
import { convertMarkerValueToShow } from "../../libs";
import { type MoleculeDataModel } from "pages/subIngredient/subIngredientProfile/libs";
import { type FormikErrors } from "formik";

interface IProps {
    values: MoleculeDataModel;
    errors: FormikErrors<MoleculeDataModel>;
    onChange: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
}

const inputClassName =
    "w-full bg-gray-100 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200";

export const VoitingTab: FC<IProps> = memo(({ values, errors, onChange }) => {
    const [isOpenCD54Marker, setIsOpenCD54Marker] = useState(false);
    const [isOpenCD86Marker, setIsOpenCD86Marker] = useState(false);

    const toggleIsOpenCD54Marker = useCallback(
        () => setIsOpenCD54Marker((prev) => !prev),
        [],
    );
    const toggleIsOpenCD86Marker = useCallback(
        () => setIsOpenCD86Marker((prev) => !prev),
        [],
    );

    const handleSelectChange = useCallback(
        (event: SelectChangeEvent<string>) => {
            const syntheticEvent = {
                target: {
                    name: event.target.name,
                    value: event.target.value,
                },
            } as ChangeEvent<HTMLInputElement | HTMLSelectElement>;
            onChange(syntheticEvent);
        },
        [onChange],
    );

    return (
        <div className="flex flex-col gap-[20px]">
            <section className="flex flex-col gap-[40px]">
                <SectionHeader
                    title="Enter in vitro values"
                    text="Input data for assays associated with three key events in the AOP for Skin sensitisation."
                    text2="Recommended to improve prediction estimate."
                />

                <p className="primary-color text-base">
                    <strong>Note:</strong> Provide parameters of at least 2 of
                    the 3 assays: DPRA, KeratinoSens, or h-CLAT
                </p>

                <div className="flex flex-col flex-nowrap gap-2 mb-10">
                    <RequiredDescription />

                    <div className="flex flex-nowrap gap-5 mb-10">
                        <InputBlock
                            title="Covalent binding to skin proteins"
                            subtitle="DPRA"
                        >
                            <InputLabel
                                text="DPRACys (% depleted)"
                                tooltipText="Percent of cysteine peptide depletion in the DPRA assay."
                                errorText={errors?.dpraAssayDPRACys}
                                isRequired
                            >
                                <input
                                    type="text"
                                    id="dpraAssayDPRACys"
                                    className={inputClassName}
                                    name="dpraAssayDPRACys"
                                    onChange={onChange}
                                    value={values.dpraAssayDPRACys}
                                />
                            </InputLabel>
                            <InputLabel
                                text="DPRALys (% depleted)"
                                tooltipText="Percent of lysine peptide depletion in the DPRA assay."
                                errorText={errors?.dpraAssayDPRALys}
                            >
                                <input
                                    type="text"
                                    id="dpraAssayDPRALys"
                                    className={inputClassName}
                                    name="dpraAssayDPRALys"
                                    onChange={onChange}
                                    value={values?.dpraAssayDPRALys}
                                />
                            </InputLabel>
                        </InputBlock>

                        <InputBlock
                            title="Keratinocyte activation"
                            subtitle="KeratinoSens™"
                        >
                            <InputLabel
                                text="EC1.5 (µM)"
                                tooltipText="Concentration yielding 1.5-fold induction in Nrf2-dependent luciferase activity in the KeratinoSens™ assay."
                                errorText={
                                    errors?.keratinoSenseAssayJaworskaKEC15
                                }
                                isRequired
                            >
                                <input
                                    type="text"
                                    id="keratinoSenseAssayJaworskaKEC15"
                                    className={inputClassName}
                                    name="keratinoSenseAssayJaworskaKEC15"
                                    onChange={onChange}
                                    value={
                                        values?.keratinoSenseAssayJaworskaKEC15
                                    }
                                />
                            </InputLabel>
                        </InputBlock>

                        <InputBlock
                            title="Dendritic cell activation"
                            subtitle="h-CLAT"
                        >
                            <InputLabel
                                text="CD54"
                                tooltipText="Activation marker CD54 in the h-CLAT."
                                errorText={errors?.hClatAssayCD54Marker}
                                isRequired
                            >
                                <Select
                                    open={isOpenCD54Marker}
                                    onClick={toggleIsOpenCD54Marker}
                                    fullWidth
                                    className="custom-select"
                                    name="hClatAssayCD54Marker"
                                    displayEmpty
                                    value={convertMarkerValueToShow(
                                        values?.hClatAssayCD54Marker,
                                    )}
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value="">-</MenuItem>
                                    <MenuItem value="positive">
                                        positive
                                    </MenuItem>
                                    <MenuItem value="negative">
                                        negative
                                    </MenuItem>
                                </Select>
                            </InputLabel>
                            <InputLabel
                                text="CD86"
                                tooltipText="Activation marker CD86 in the h-CLAT."
                                errorText={errors?.hClatAssayCD86Marker}
                                isRequired
                            >
                                <Select
                                    open={isOpenCD86Marker}
                                    onClick={toggleIsOpenCD86Marker}
                                    fullWidth
                                    className="custom-select"
                                    name="hClatAssayCD86Marker"
                                    displayEmpty
                                    value={convertMarkerValueToShow(
                                        values?.hClatAssayCD86Marker,
                                    )}
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value="">-</MenuItem>
                                    <MenuItem value="positive">
                                        positive
                                    </MenuItem>
                                    <MenuItem value="negative">
                                        negative
                                    </MenuItem>
                                </Select>
                            </InputLabel>
                        </InputBlock>
                    </div>
                </div>
            </section>
        </div>
    );
});
