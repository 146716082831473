import React, { useCallback, useState } from "react";
import { PageTemplate } from "components/page-template";
import {
    PersonalInfo,
    ProfileCard,
    ProfileMenu,
    SavedItems,
} from "./components";
import { profileConfig, useGetLocation, useGetUserProfile } from "./libs";
import { ToastContainer } from "react-toastify";
import { ArchiveAdd } from "assets/icons";

export const Profile = () => {
    const [activeItem, setActiveItem] = useState("My Profile");
    const [isEdit, setIsEdit] = useState(false);

    const { data: userInfo, refetch: refetchUser } = useGetUserProfile();
    const { data: location } = useGetLocation();

    const handleMenuClick = useCallback((active: string) => {
        setActiveItem(active);
    }, []);

    const handleOnEditClick = useCallback(() => {
        setIsEdit((curr) => !curr);
    }, [isEdit]);

    const {
        email = "",
        firstName = "",
        lastName = "",
        attributes = {},
    } = userInfo || {};

    const { phone = [], organisation = [] } = attributes;

    const subtitle =
        activeItem === "My Profile"
            ? profileConfig.userInfo.subtitle
            : profileConfig.savedItems.subtitle;

    const title =
        activeItem === "My Profile"
            ? profileConfig.userInfo.title
            : profileConfig.savedItems.title;

    const titleIcon = activeItem === "My Profile" ? null : <ArchiveAdd />;

    return (
        <PageTemplate
            title={title}
            titleIcon={titleIcon}
            subtitle={subtitle}
            withSearch={false}
        >
            <div className="flex gap-8 mx-12 my-8">
                <ProfileMenu
                    activeItem={activeItem}
                    onItemClick={handleMenuClick}
                />
                <div className="w-full px-6">
                    {activeItem === "My Profile" ? (
                        <>
                            <ProfileCard
                                name={`${firstName} ${lastName}`}
                                position={""}
                                location={location}
                            />
                            <PersonalInfo
                                firstName={firstName}
                                lastName={lastName}
                                email={email}
                                phone={phone[0]}
                                company={organisation[0]}
                                editMode={isEdit}
                                onEditClick={handleOnEditClick}
                                refetch={refetchUser}
                            />
                        </>
                    ) : (
                        <SavedItems />
                    )}
                </div>
            </div>
            <ToastContainer />
        </PageTemplate>
    );
};
