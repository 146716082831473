import { useKeycloak } from "@react-keycloak/web";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import { IFormulaSaved, IUser } from "common/types";
import { IFormulaItem } from "pages/formulas/formulas/types";
import { formulaService, userService } from "services";

export const useGetUserProfile = () => {
    const queryKey = [queryKeys.userProfile];
    const { keycloak } = useKeycloak();

    return useQuery<IUser>({
        queryKey,
        queryFn: async () => {
            if (!keycloak.authenticated) {
                throw new Error("User is not authenticated");
            }
            try {
                return keycloak.loadUserProfile();
            } catch (error) {
                throw error;
            }
        },
        enabled: keycloak.authenticated,
    });
};

export const useGetLocation = () => {
    const queryKey = [queryKeys.userLocation];

    return useQuery<Pick<IUser, "country_name" | "city">>({
        queryKey,
        queryFn: () => userService.getUserLocation(),
    });
};

export const useUpdateUserProfile = () =>
    useMutation({
        mutationFn: (payload: Partial<IUser>) =>
            userService.updateUser(payload),
    });

export const useSaveFormulaMutation = () =>
    useMutation({
        mutationFn: (body: { userId: string; formulaId: number }) =>
            userService.saveFormula(body),
    });

export const useGetSavedFormulas = () => {
    return useQuery<IFormulaSaved[]>({
        queryKey: [queryKeys.savedFormulas],
        queryFn: () => userService.getSavedFormulas(),
    });
};

export const useFullSavedFormulas = () => {
    const queryKey = [queryKeys.fullSavedFormulas];

    return useQuery<IFormulaItem[]>({
        queryKey,
        queryFn: () => formulaService.getFullSavedFormulas(),
    });
};

export const useRemoveSavedFormulas = () => {
    return useMutation({
        mutationFn: (id: number) => {
            return userService.removeSavedFormula(id);
        },
    });
};
