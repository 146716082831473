import React from "react";
import { Tab } from "../types";
import classNames from "classnames";

interface IProps {
    selectedTab: Tab;
    onChange: (key: Tab) => void;
    tabs: Tab[];
}

export const TabsHeader: React.FC<IProps> = ({
    selectedTab,
    tabs,
    onChange,
}) => (
    <div className="tabs tabs-lifted tabs-md">
        {tabs.map((tab) => (
            <a
                key={tab}
                onClick={() => onChange(tab)}
                className={classNames("tab", {
                    "tab-active": selectedTab === tab,
                })}
            >
                {tab}
            </a>
        ))}
    </div>
);
