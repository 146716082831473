import React, { FC, useEffect, useMemo, useState, memo } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { MultiValue } from "react-select";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { InputWrapper } from "components/shared/inputWrapper";
import {
    useCreateSubIngredient,
    useUpdateSubIngredientInRawIngredient,
    useFindSimilarItems,
    useGetAllSubIngredients,
    useSubIngredientByID,
} from "../queries";
import {
    IError,
    IOption,
    ISubIngredient,
    ISubIngredientError,
} from "common/types";
import { SearchableSelect } from "components/shared/SearchableSelect";
import { buildLink, getErrorMessage } from "common/utils";
import { AxiosError } from "axios";
import { FormActions } from "components/shared";

interface IProps {
    handleClose: () => void;
    formulas_id?: string;
    raw_ingredients_id?: string;
    maxValue?: number;
    isLink?: boolean;
}

const CreateSchema = Yup.object().shape({
    sicode: Yup.string(),
    name: Yup.string().required("Name is required"),
});

export const CreateINCIForm: FC<IProps> = memo(
    ({
        handleClose,
        formulas_id,
        raw_ingredients_id,
        maxValue,
        isLink = false,
    }) => {
        const [selectedSubIngredient, setSelectedSubIngredient] =
            useState<IOption | null>(null);
        const isAddingMode = useMemo(
            () => Boolean(formulas_id || raw_ingredients_id),
            [formulas_id, raw_ingredients_id],
        );
        const [similarSubId, setSimilarSubId] = useState<string | null>(null);

        const { data: currSubIngredient } = useSubIngredientByID(
            similarSubId ?? "",
        );

        useEffect(() => {
            if (similarSubId && currSubIngredient) {
                setSelectedSubIngredient({
                    value: String(currSubIngredient?.id),
                    label: `${currSubIngredient?.sicode}, ${currSubIngredient?.name}`,
                });
            }
        }, [currSubIngredient, similarSubId]);

        const navigate = useNavigate();

        const handleUpdateRelatedList = (newValue: IOption) => {
            setSelectedSubIngredient(newValue);
        };

        const queryClient = useQueryClient();

        const Schema = useMemo(() => {
            if (raw_ingredients_id && maxValue !== undefined) {
                return CreateSchema.concat(
                    Yup.object().shape({
                        weight_percent: Yup.number()
                            .max(
                                maxValue,
                                `Weight percent must be less than or equal to ${maxValue}`,
                            )
                            .min(
                                0,
                                "Weight percent must be greater than or equal to 0",
                            )
                            .required("Weight percent is required"),
                    }),
                );
            } else {
                return CreateSchema;
            }
        }, [maxValue]);

        const { mutate: createSubIngredient } = useCreateSubIngredient();
        const { mutate: updateSubIngredient } =
            useUpdateSubIngredientInRawIngredient();

        const formik = useFormik({
            initialValues: {
                sicode: "",
                name: "",
                ...(raw_ingredients_id ? { weight_percent: "" } : {}),
            },
            validationSchema: Schema,
            validateOnChange: true,
            validateOnMount: true,
            enableReinitialize: true,
            onSubmit: (values) => {
                const toastId = toast.loading(
                    toastTexts.loading,
                    defaultToastOptions,
                );

                const handleSuccess = (
                    response: ISubIngredient | ISubIngredientError,
                ) => {
                    const errorMessageSubIngredientExist = (
                        response as ISubIngredientError
                    )?.error;

                    if (errorMessageSubIngredientExist) {
                        toast.dismiss(toastId);
                        formik.setFieldError(
                            "name",
                            errorMessageSubIngredientExist,
                        );
                        return;
                    }

                    const message = selectedSubIngredient
                        ? "Success! INCI has been updated"
                        : "Success! INCI has been created";
                    toast.update(toastId, {
                        ...defaultSuccessToastUpdate,
                        render: message,
                    });

                    if (!selectedSubIngredient) {
                        const link = buildLink(
                            routerKeys.subIngredient,
                            (response as ISubIngredient).id,
                        );
                        navigate(link);
                    }

                    queryClient.invalidateQueries({
                        queryKey: [queryKeys.subIngredients],
                    });
                    queryClient.invalidateQueries({
                        queryKey: [queryKeys.formula],
                    });
                    queryClient.removeQueries({
                        queryKey: [queryKeys.subIngredients, "all"],
                    });

                    setSelectedSubIngredient(null);
                    formik.resetForm();
                    handleClose();
                };

                const handleError = (err: IError) => {
                    toast.dismiss(toastId);
                    const responseError = err?.response?.data?.message || "";

                    if (responseError.includes("sicode")) {
                        formik.setFieldError("sicode", responseError);
                    }

                    const message = getErrorMessage(err as AxiosError);

                    formik.setFieldError(
                        "name",
                        typeof message === "string"
                            ? message
                            : "An unexpected error occurred",
                    );
                };

                if (selectedSubIngredient) {
                    updateSubIngredient(
                        { ...values, formulas_id, raw_ingredients_id },
                        {
                            onSuccess: handleSuccess,
                            onError: handleError,
                        },
                    );
                } else {
                    createSubIngredient(
                        { ...values, formulas_id, raw_ingredients_id },
                        {
                            onSuccess: handleSuccess,
                            onError: handleError,
                        },
                    );
                }
            },
        });

        const { data, isLoading } = isAddingMode
            ? useGetAllSubIngredients()
            : { data: null, isLoading: false };

        const { data: similar } = useFindSimilarItems(formik.values.name);

        const options = useMemo(() => {
            if (data) {
                return data.map((item) => {
                    return {
                        value: String(item?.id),
                        label: `${item?.sicode}, ${item?.name}`,
                    };
                }) as unknown as MultiValue<IOption>;
            } else {
                return [];
            }
        }, [data]);

        useEffect(() => {
            if (selectedSubIngredient) {
                const index = selectedSubIngredient.label.indexOf(",");

                formik.setFieldValue(
                    "sicode",
                    selectedSubIngredient.label.slice(0, index),
                );
                formik.setFieldValue(
                    "name",
                    selectedSubIngredient.label.slice(index + 1).trim(),
                );
            }
        }, [selectedSubIngredient]);

        const { values, errors, touched, handleChange, resetForm } = formik;

        const handleResetForm = () => {
            resetForm();
            setSelectedSubIngredient(null);
        };

        if (maxValue !== undefined && maxValue <= 0 && raw_ingredients_id) {
            return (
                <div className="w-full">
                    <h6 className="text-xl text-gray-500 text-center">
                        In the form, there already exists an equal distribution
                        of INCIs totaling 100%.
                    </h6>
                </div>
            );
        }

        return (
            <>
                <form className="w-full" onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col gap-4">
                        {isAddingMode && Boolean(options.length) && (
                            <div className="px-1">
                                <SearchableSelect
                                    isLoading={isLoading}
                                    options={options}
                                    values={selectedSubIngredient}
                                    onChange={handleUpdateRelatedList}
                                />
                            </div>
                        )}
                        {raw_ingredients_id && (
                            <InputWrapper
                                isError={Boolean(
                                    errors.weight_percent &&
                                        touched.weight_percent,
                                )}
                                error={errors.weight_percent}
                                label="Weight percent"
                            >
                                <input
                                    type="number"
                                    name="weight_percent"
                                    className="w-full input input-bordered"
                                    value={values.weight_percent}
                                    onChange={handleChange}
                                />
                            </InputWrapper>
                        )}
                        <InputWrapper
                            isError={Boolean(errors.name && touched.name)}
                            error={errors.name}
                            label="Name"
                        >
                            <textarea
                                className="textarea textarea-bordered w-full flex-grow"
                                name="name"
                                value={values.name}
                                onChange={(e) => {
                                    if (selectedSubIngredient) {
                                        setSelectedSubIngredient(null);
                                    }

                                    handleChange(e);
                                }}
                            />
                        </InputWrapper>

                        <FormActions
                            baseLink={routerKeys.subIngredient}
                            links={similar}
                            handleResetForm={handleResetForm}
                            onClick={isLink ? undefined : setSimilarSubId}
                            submitButtonText={
                                selectedSubIngredient && !isLink
                                    ? "Update"
                                    : "Create"
                            }
                        />
                    </div>
                </form>
            </>
        );
    },
);
