import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { routerKeys } from "common/constants/routerKeys";
import { BurgerIcon, CloseIcon } from "assets/icons";
import { userService } from "services";
import { DesktopMenu, MobileMenu } from "./components";
import "./styles.scss";

export const Navbar = () => {
    const [isVisibleMenu, setIsVisibleMenu] = useState(false);
    const { keycloak } = useKeycloak();
    const isAuthenticated = Boolean(keycloak.authenticated);

    useEffect(() => {
        const assignGroup = async () => {
            try {
                const { id, email } = (await keycloak.loadUserProfile()) || {};
                if (!id || !email) return;
                await userService.addGroupIfEmpty({ id, email });
            } catch (error) {
                console.error(error);
            }
        };

        if (keycloak.authenticated) {
            assignGroup();
        }
    }, [keycloak.authenticated]);

    return (
        <nav className="bg-header">
            <div className="mx-auto px-4">
                <div className="flex justify-between items-center">
                    <div className="flex space-x-4">
                        <NavLink
                            to={routerKeys.formulas}
                            className="flex item-center py-3 px-3 text-gray-100 hover:text-primary-main-white"
                        >
                            <span className="font-bold text-25px">
                                SmartSafety
                            </span>
                        </NavLink>
                    </div>

                    {isAuthenticated && (
                        <>
                            <DesktopMenu />
                            <div className="md:hidden flex item-center">
                                <button
                                    className="mobile-menu-button"
                                    onClick={() =>
                                        setIsVisibleMenu(!isVisibleMenu)
                                    }
                                >
                                    {isVisibleMenu ? (
                                        <CloseIcon />
                                    ) : (
                                        <BurgerIcon />
                                    )}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <MobileMenu
                isVisible={isVisibleMenu}
                isAuthenticated={isAuthenticated}
            />
        </nav>
    );
};
