import { MinusIcon, PlusIcon } from "assets/icons";
import classNames from "classnames";
import React from "react";

interface IProps {
    isOpen: boolean;
    text: string;
    children: React.ReactNode;
    onOpen: () => void;
    detailsClassName?: string;
    summaryClassName?: string;
}

export const Details: React.FC<IProps> = React.memo(
    ({
        isOpen,
        text,
        children,
        onOpen,
        detailsClassName = "",
        summaryClassName = "",
    }) => {
        return (
            <details
                className={classNames(
                    "flex flex-row w-full cursor-pointer mb-3",
                    detailsClassName,
                )}
            >
                <summary
                    className={classNames(
                        "w-full bg-white text-dark flex py-3",
                        summaryClassName,
                    )}
                    onClick={onOpen}
                >
                    <p>{isOpen ? <MinusIcon /> : <PlusIcon />}</p>
                    <b className="pl-1">{text}</b>
                </summary>
                {isOpen && children}
            </details>
        );
    },
);
