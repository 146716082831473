import React, { FC, useMemo } from "react";
import { type ReportData } from "../../types";
import classNames from "classnames";

interface IProps {
    reportData: ReportData;
}

export const ReportTemplate: FC<IProps> = ({ reportData }) => {
    const calcSummaryVisible = useMemo(
        () =>
            [
                reportData.totalWeight,
                reportData.highestExposureFactor,
                reportData.mos,
            ].some(Boolean),
        [reportData],
    );

    const calcMetricsVisible = useMemo(
        () =>
            [
                reportData.calculationMetrics.productType,
                reportData.calculationMetrics.gramsAppliedPerDay,
                reportData.calculationMetrics.skinRetentionFactor,
            ].some(Boolean),
        [
            reportData.calculationMetrics.productType,
            reportData.calculationMetrics.gramsAppliedPerDay,
            reportData.calculationMetrics.skinRetentionFactor,
        ],
    );
    return (
        <div
            id="report-id"
            className="text-sm mx-auto my-8 w-full max-w-3xl absolute left-[-9999px]"
        >
            <h1 className="text-xl primary-color font-extrabold mb-4">
                Formula MOS Report
            </h1>
            <div className="max-w-[90%] text-sm p-4 bg-blue-50 shadow-sm border-l-4 border-blue-500 mb-6">
                <p className="mb-2">
                    <span className="font-bold text-gray-700">FI Code:</span>
                    <span className="ml-2 text-gray-800">
                        {reportData.productId}
                    </span>
                </p>
                <p className="mb-2">
                    <span className="font-bold text-gray-700">
                        Formula Name:
                    </span>
                    <span className="ml-2 text-gray-800">
                        {reportData.productName}
                    </span>
                </p>
                <p className="mb-2">
                    <span className="font-bold text-gray-700">
                        Generated by:
                    </span>
                    <span className="ml-2 text-gray-800">
                        {reportData.author}
                    </span>
                </p>
                <p className="mb-2">
                    <span className="font-bold text-gray-700">Date:</span>
                    <span className="ml-2 text-gray-800">
                        {reportData.date}
                    </span>
                </p>
            </div>

            <hr className="max-w-[90%] my-4 border-gray-200" />

            {calcSummaryVisible && (
                <>
                    <h2 className="text-lg font-semibold primary-color my-3 pl-3 border-l-4 border-[#113261]">
                        MOS Calculation Summary
                    </h2>
                    <div className="mb-6 text-gray-700">
                        {reportData.totalWeight && (
                            <p>
                                <span className="font-bold">Total Weight:</span>{" "}
                                {reportData.totalWeight}
                            </p>
                        )}
                        {reportData.highestExposureFactor && (
                            <p>
                                <span className="font-bold">
                                    Highest Exposure Factor:
                                </span>{" "}
                                {reportData.highestExposureFactor}
                            </p>
                        )}
                        {reportData.mos && (
                            <p>
                                <span className="font-bold">MOS:</span>{" "}
                                {reportData.mos}
                            </p>
                        )}
                    </div>
                </>
            )}

            {calcMetricsVisible && (
                <>
                    <h2 className="text-lg font-semibold primary-color my-3 pl-3 border-l-4 border-[#113261]">
                        Calculation Metrics
                    </h2>
                    <div className="mb-6 text-gray-700">
                        {reportData.calculationMetrics.productType && (
                            <p>
                                <span className="font-bold">Product Type:</span>
                                {reportData.calculationMetrics.productType}
                            </p>
                        )}
                        {reportData.calculationMetrics.bodyWeight && (
                            <p>
                                <span className="font-bold">Body Weight:</span>
                                {reportData.calculationMetrics.bodyWeight}
                            </p>
                        )}
                        {reportData.calculationMetrics.gramsAppliedPerDay && (
                            <p>
                                <span className="font-bold">
                                    Grams Applied Per Day:
                                </span>
                                {
                                    reportData.calculationMetrics
                                        .gramsAppliedPerDay
                                }
                            </p>
                        )}
                        {reportData.calculationMetrics.skinRetentionFactor && (
                            <p>
                                <span className="font-bold">
                                    Skin Retention Factor:
                                </span>
                                {
                                    reportData.calculationMetrics
                                        .skinRetentionFactor
                                }
                            </p>
                        )}
                    </div>
                </>
            )}

            {Boolean(reportData.ingredients.length) && (
                <>
                    <h2 className="text-lg font-semibold primary-color my-3 pl-3 border-l-4 border-[#113261]">
                        Ingredients and Exposure Analysis
                    </h2>

                    <table className="w-full text-xxs text-center border border-gray-300 mb-4">
                        <thead>
                            <tr className="bg-gray-50">
                                <th className="border p-1 text-gray-800 max-w-[10%]">
                                    SI Code
                                </th>
                                <th className="border p-1 text-gray-800 !max-w-[15%]">
                                    Name
                                </th>
                                <th className="border p-1 text-gray-800 max-w-[10%]">
                                    Total Weight (%)
                                </th>
                                <th className="border p-1 text-gray-800 max-w-[10%]">
                                    Skin Ret.
                                </th>
                                <th className="border p-1 text-gray-800 max-w-[10%]">
                                    Derm. Pen.
                                </th>
                                <th className="border p-1 text-gray-800 max-w-[10%]">
                                    Daily Exp.
                                </th>
                                <th className="border p-1 text-gray-800 max-w-[10%]">
                                    Syst. Exp.
                                </th>
                                <th className="border p-1 text-gray-800 max-w-[10%]">
                                    NOAEL
                                </th>
                                <th className="border p-1 text-gray-800 max-w-[10%]">
                                    MOS
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportData.ingredients.map((ingredient, index) => (
                                <tr
                                    key={index}
                                    className={classNames(
                                        "w-full",
                                        index % 2 === 0
                                            ? "bg-white"
                                            : "bg-gray-50",
                                    )}
                                >
                                    <td className="border p-1 max-w-[10%]">
                                        {ingredient.sicode}
                                    </td>
                                    <td className="border p-1 !max-w-[15%] break-words overflow-hidden text-ellipsis whitespace-normal">
                                        {ingredient.name}
                                    </td>
                                    <td className="border p-1 max-w-[10%]">
                                        {ingredient.total_weight}
                                    </td>
                                    <td className="border p-1 max-w-[10%]">
                                        {ingredient.skin_retention_factor}
                                    </td>
                                    <td className="border p-1 max-w-[10%]">
                                        {ingredient.dermal_penetration}
                                    </td>
                                    <td className="border p-1 max-w-[10%]">
                                        {ingredient.daily_exposure}
                                    </td>
                                    <td className="border p-1 max-w-[10%]">
                                        {ingredient.systematic_daily_exposure}
                                    </td>
                                    <td className="border p-1 max-w-[10%]">
                                        {ingredient.noael}
                                    </td>
                                    <td className="border p-1 max-w-[10%]">
                                        {ingredient.mos}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};
