import React from "react";

interface ITableRowProps {
    children: React.ReactNode;
}

export const TableRow: React.FC<ITableRowProps> = ({ children }) => (
    <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
        {children}
    </tr>
);
