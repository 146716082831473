import React from "react";
import { Button } from "components/shared/button";
import { Title } from "components/shared/title";
import { useEffect, useState } from "react";
import {
    RowTemplate,
    ManufacturesTable,
    TradeNameTable,
    CreateTradename,
} from "./components";
import { TabTable } from "../../TabTable";
import {
    useGetRawIngredient,
    useUpdateRawIngredient,
} from "pages/rawIngredient/rawIngredientProfile/queries";
import { toast } from "react-toastify";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    storageKeys,
    toastTexts,
} from "common/constants";
import { useQueryClient } from "@tanstack/react-query";
import { useRawIngredientStore } from "pages/rawIngredient/rawIngredientProfile/store";
import { YesNoCheckboxGroup } from "components/shared/yesNoCheckboxGroup";
import { ModalTemplate } from "components/shared/modalTemplate";
import { useModal } from "common/hooks";

export interface IData {
    animal_testing: { value: string };
    cfi_acceptable: { value: string };
    country_of_origin: { value: string };
    vendors_id: { value: string | number };
}

export const TradeNameTab = () => {
    const [activeTradename, setActiveTradename] = useState<string>(
        localStorage.getItem(storageKeys.tradeName) || "",
    );
    const [animalDerived, setAnimalDerived] = useState<string>("");
    const [gluten, setGluten] = useState<string>("");
    const { key } = useRawIngredientStore();
    const queryClient = useQueryClient();
    const { isOwner } = useRawIngredientStore();

    const {
        modalRef: createTradenameRef,
        openModal,
        closeModal,
    } = useModal({});

    const [tradename, setTradename] = useState<IData>({
        animal_testing: { value: "" },
        cfi_acceptable: { value: "" },
        country_of_origin: { value: "" },
        vendors_id: { value: "" },
    });

    const rawIngredient = useGetRawIngredient();

    useEffect(() => {
        if (rawIngredient && !activeTradename) {
            setAnimalDerived(rawIngredient.animal_derived);
            setActiveTradename(
                rawIngredient?.tradename?.[0]?.id.toString() || "",
            );

            const isContainGluten = rawIngredient.subIngredients?.some(
                (subIngredient) =>
                    subIngredient.gluten?.toLowerCase() === "yes",
            );

            setGluten(isContainGluten ? "yes" : "no");
        }
    }, [rawIngredient]);

    useEffect(() => {
        localStorage.setItem(storageKeys.tradeName, activeTradename.toString());

        return () => {
            localStorage.removeItem(storageKeys.tradeName);
        };
    }, [activeTradename]);

    useEffect(() => {
        const currentTradename = rawIngredient?.tradename.find(
            (it) => it.id === +activeTradename,
        );

        setTradename((curr) => ({
            ...curr,
            animal_testing: {
                ...curr.animal_testing,
                value: currentTradename?.animal_testing || "",
            },
            cfi_acceptable: {
                ...curr.cfi_acceptable,
                value: currentTradename?.cfi_acceptable || "",
            },
            country_of_origin: {
                ...curr.cfi_acceptable,
                value: currentTradename?.country_of_origin || "",
            },
            vendors_id: {
                ...curr.vendors_id,
                value: currentTradename?.vendors_id || "",
            },
        }));
    }, [activeTradename]);

    const handleUpdateActiveTradename = (
        e: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        setActiveTradename(e.target.value);
    };

    const handleUpdateAnimalDerived = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setAnimalDerived(e.target.value);
    };

    const handleUpdateData = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = e.target;

        setTradename((curr) => ({
            ...curr,
            [name]: { ...curr[name as keyof IData], value },
        }));
    };

    const { mutate, isPending } = useUpdateRawIngredient();

    const handleUpdateRawIngredient = () => {
        const {
            animal_testing,
            cfi_acceptable,
            country_of_origin,
            vendors_id,
        } = tradename;

        mutate(
            {
                id: `${rawIngredient?.id}` || "",
                body: {
                    animal_derived: animalDerived,
                    vendors_id: vendors_id.value,
                    tradename: {
                        animal_testing: animal_testing.value,
                        cfi_acceptable: cfi_acceptable.value,
                        country_of_origin:
                            country_of_origin.value === "datamissing"
                                ? ""
                                : country_of_origin.value,
                        vendors_id: vendors_id.value,
                    },
                },
            },
            {
                onSuccess: (data) => {
                    toast.success(
                        `${toastTexts.success} Raw Ingredient has been updated.`,
                        defaultToastOptions,
                    );

                    queryClient.setQueryData(key, data);
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    };

    return (
        <div>
            <ModalTemplate
                text="Add Trade Name"
                onClose={closeModal}
                modalRef={createTradenameRef}
                className="max-w-[600px]"
            >
                <CreateTradename
                    handleClose={closeModal}
                    tradenames={rawIngredient?.tradename}
                />
            </ModalTemplate>
            <div className="md:mx-5 lg:mx-10">
                <form className="w-full">
                    <div className="flex">
                        <div className="w-1/2 md:w-1/2 lg:w-1/2 md:mr-5">
                            <Title text="Trade-name(s)" className="mt-2" />
                            <div className="flex gap-2 mb-2 w-full">
                                {Boolean(rawIngredient?.tradename?.length) && (
                                    <select
                                        className="select select-bordered flex-grow m-0 mb-2"
                                        onChange={handleUpdateActiveTradename}
                                        value={activeTradename}
                                        // disabled={!isOwner}
                                    >
                                        {rawIngredient?.tradename?.map(
                                            (tradename) => (
                                                <option
                                                    key={tradename.id}
                                                    value={tradename.id.toString()}
                                                >
                                                    {tradename.tradename} (
                                                    {
                                                        tradename.vendor
                                                            ?.description
                                                    }
                                                    )
                                                </option>
                                            ),
                                        )}
                                    </select>
                                )}
                                <Button
                                    onClick={openModal}
                                    text="Add Trade Name"
                                />
                            </div>
                        </div>
                        <div className="w-1/2 md:w-1/2 lg:w-1/2 md:ml-5" />
                    </div>
                    <div className="flex mb-4">
                        <div className="w-1/2 md:w-1/2 lg:w-1/2 md:mr-5">
                            <TabTable>
                                <thead className="">
                                    <RowTemplate text="Animal Derived">
                                        <YesNoCheckboxGroup
                                            onChange={handleUpdateAnimalDerived}
                                            value={animalDerived}
                                            name="animal_derived"
                                            disabled={false}
                                        />
                                    </RowTemplate>
                                    {Boolean(activeTradename) && (
                                        <>
                                            <TradeNameTable
                                                handleUpdateData={
                                                    handleUpdateData
                                                }
                                                tradename={tradename}
                                            />
                                        </>
                                    )}
                                    <RowTemplate text="Gluten">
                                        {gluten}
                                    </RowTemplate>
                                </thead>
                            </TabTable>
                        </div>
                        <div className="w-1/2 md:w-1/2 lg:w-1/2 md:ml-5">
                            <ManufacturesTable />
                        </div>
                    </div>
                    {(isOwner || true) && (
                        <Button
                            isLoading={isPending}
                            text="Save Trade Name Information"
                            onClick={handleUpdateRawIngredient}
                        />
                    )}
                </form>
            </div>
        </div>
    );
};
