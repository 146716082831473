export const parseIngredients = (
    data: Record<string, string>[],
    headerRowKeys: Record<string, string>,
) => {
    const startIdx = data.findIndex((row) =>
        Object.values(row).includes("Raw Ingredient Code"),
    );
    const keys = {
        ricode: headerRowKeys["Raw Ingredient Code"],
        supplier: headerRowKeys["Supplier"],
        tradename: headerRowKeys["Trade Name"],
        rawDescription: headerRowKeys["Raw Ingredient"],
        rawWeight: headerRowKeys["% Raw Ingredient"],
        sicode: headerRowKeys["INCI Code"],
        subDescription: headerRowKeys["INCI Name"],
        casNumber: headerRowKeys["CAS#"],
        function: headerRowKeys["Function"],
        subWeight: headerRowKeys["% Sub-Ingredient"],
        contaminant: headerRowKeys["Contaminants"],
        subAmount: headerRowKeys["% in Final Formulation (by weight)"],
    };
    const rawIngredients = [];

    for (const item of data.slice(startIdx + 1)) {
        const contaminants = parseContaminants(item[keys.contaminant]);

        const sabWeight = isNaN(+item[keys.subWeight])
            ? item[keys.subWeight]?.split(" ")[0] || "0"
            : item[keys.subWeight];
        if (item[keys.ricode]) {
            const rawWeight = isNaN(+item[keys.rawWeight])
                ? item[keys.rawWeight]?.split(" ")[0] || "0"
                : item[keys.rawWeight];
            const rawIngredient = {
                ricode: "",
                description: item[keys.rawDescription],
                weight_percent: rawWeight,
                vendor: item[keys.supplier],
                tradename: item[keys.tradename],
                isOwner: false,
                isExist: false,
                contaminants,
                subIngredients: [
                    {
                        name: item[keys.subDescription],
                        weight_percent: sabWeight,
                        cas_number: item[keys.casNumber],
                        function: item[keys.function],
                    },
                ],
            };
            rawIngredients.push(rawIngredient);
        } else {
            const lastRawIngredient = rawIngredients[rawIngredients.length - 1];
            lastRawIngredient.contaminants = contaminants;
            if (item[keys.subDescription] || item[keys.subWeight]) {
                lastRawIngredient.subIngredients.push({
                    name: item[keys.subDescription],
                    weight_percent: sabWeight,
                    cas_number: item[keys.casNumber],
                    function: item[keys.function],
                });
            }
        }
    }

    return {
        description: data?.[0]?.[0]?.replace("Product Name: ", "") || "",
        items: rawIngredients,
    };
};

function parseContaminants(text: string) {
    const result = [];
    const contaminantRegex =
        /([a-zA-Z0-9\-\s]+)([<>=]+)([\d\.]+)\s?(ppm|mg\/kg|%)/gi;
    let match;

    while ((match = contaminantRegex.exec(text)) !== null) {
        const [_, contaminant, operator, value, unit] = match;

        const parsedItem = {
            name: contaminant.trim(),
            description: `${operator}${value} ${unit}`,
            ppm: value,
        };

        result.push(parsedItem);
    }

    return result;
}
