export const pageContent = {} as const;

export const formulaContent = {
    title: "Formula",
    subTitle:
        "Welcome to the SmartSafety Formulas section! To get started, you can either search for a formula using the search engine or apply specific filters to find what you need.",
    placeholder: "Search for a formula",
} as const;

export const rawIngredientContent = {
    title: "Raw Ingredient",
    subTitle:
        "Welcome to the SmartSafety Raw Ingredients section! To get started, you can either search for a raw ingredient using the search engine or apply specific filters to find what you need.",
    placeholder: "Search for a raw ingredient",
} as const;

export const subIngredientContent = {
    title: "INCI",
    subTitle:
        "Welcome to the SmartSafety INCIs section! To get started, you can either search for a INCI using the search engine or apply specific filters to find what you need.",
    placeholder: "Search for an INCI",
} as const;

export const contaminantsContent = {
    title: "Contaminant",
    subTitle:
        "Welcome to the SmartSafety Contaminants section! To get started, you can either search for a contaminant using the search engine or apply specific filters to find what you need.",
    placeholder: "Search for a contaminant",
} as const;
