import React, { FC } from "react";
import { SubIngredientTab } from "../../libs/types";
import { BasicInfoTab } from "./basicInfoTab";
import { ReportSummaryTab } from "./reportSummaryTab";
import { EnvironmentalSummaryTab } from "./environmentalSummaryTab";
import { SkinSafetyTab } from "./skinSafetyTab";
import { SafetySummaryTab } from "./safetySummaryTab";
import { TabHeader } from "../../libs";

interface IProps {
    selectedTab: SubIngredientTab;
}

const ContentMap = {
    [TabHeader.BASIC_INFO]: <BasicInfoTab />,
    [TabHeader.SAFETY_SUMMARY]: <SafetySummaryTab />,
    [TabHeader.REPORT_SUMMARY]: <ReportSummaryTab />,
    [TabHeader.ENVIRONMENTAL_SUMMARY]: <EnvironmentalSummaryTab />,
    [TabHeader.SKIN_SAFETY]: <SkinSafetyTab />,
} as const;

export const TabContent: FC<IProps> = ({ selectedTab }) =>
    ContentMap[selectedTab] || null;
