import React, {
    FC,
    useState,
    useCallback,
    ChangeEvent,
    KeyboardEvent,
} from "react";
import { ActionButtons, EditableInput, TableCeil } from "components/shared";
import { IFormulaContaminant } from "pages/formulas/formula-profile/types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
    defaultErrorToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import {
    useDeleteRelation,
    useUpdateRelation,
} from "pages/formulas/formula-profile/queries";
import { toast } from "react-toastify";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";

interface IProps {
    contaminant: IFormulaContaminant;
}

export const ContaminantRow: FC<IProps> = ({ contaminant }) => {
    const [isEditing, setIsEditing] = useState(false);
    const { id = "" } = useParams();
    const queryClient = useQueryClient();
    const { isOwner } = useFormulaProfileState();
    const navigate = useNavigate();

    const navigateToContaminantPage = useCallback(() => {
        navigate(`${routerKeys.contaminants}/${contaminant.id}`);
    }, [navigate, contaminant.id]);

    const { mutate: updateRelation } = useUpdateRelation();
    const { mutate: deleteRelation } = useDeleteRelation();

    const formik = useFormik({
        initialValues: {
            ppm: contaminant.formulasContaminants.ppm,
        },
        validationSchema: Yup.object().shape({
            ppm: Yup.number()
                .min(0, "PPM must be greater than or equal to 0")
                .required("PPM is required"),
        }),
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: ({ ppm }) => {
            updateRelation(
                {
                    id,
                    ingredientId: `${contaminant.id}`,
                    relationType: "contaminants",
                    body: { value: ppm },
                },
                {
                    onSuccess() {
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.formula, id],
                        });
                    },
                    onError() {
                        toast.error(toastTexts.error, defaultErrorToastOptions);
                    },
                },
            );
        },
    });

    const handleRemoveIngredient = useCallback(() => {
        deleteRelation(
            {
                id,
                ingredientId: `${contaminant.id}`,
                relationType: "contaminants",
            },
            {
                onSuccess() {
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.formula, id],
                    });
                },
                onError() {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [deleteRelation, id, queryClient, contaminant.id]);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            const formattedValue = value.replace(",", ".");
            formik.setFieldValue("ppm", formattedValue);
        },
        [formik],
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                event.preventDefault();
                formik.handleSubmit();
                if (formik.isValid) {
                    setIsEditing(false);
                }
            }
        },
        [formik],
    );

    const handleSubmit = useCallback(() => {
        formik.handleSubmit();
        if (formik.isValid) {
            setIsEditing(false);
        }
    }, [formik]);

    return (
        <tr key={contaminant.id} className="relative">
            <TableCeil
                text={contaminant.name}
                className="cursor-pointer"
                onClick={navigateToContaminantPage}
            />
            <TableCeil className="flex items-center justify-center pt-3">
                <EditableInput
                    name="ppm"
                    value={formik.values.ppm}
                    isEditing={isEditing}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className="text-center"
                    inputClassName="text-center"
                    displayClassName="font-semibold text-gray-700"
                    autoFocus={isEditing}
                />
                {formik.errors.ppm && formik.touched.ppm && (
                    <div className="p-0.5 w-full text-[#dc2626]">
                        {formik.errors.ppm}
                    </div>
                )}
            </TableCeil>
            {(isOwner || true) && (
                <TableCeil>
                    <ActionButtons
                        onSubmit={handleSubmit}
                        onRemove={handleRemoveIngredient}
                        isEditing={isEditing}
                        setIsEditing={formik.isValid ? setIsEditing : undefined}
                    />
                </TableCeil>
            )}
        </tr>
    );
};
