import React, { FC, memo } from "react";
import { ModalTemplate } from "components/shared/modalTemplate";
import { useGetContaminant } from "../queries";
import { EditIcon } from "assets/icons";
import { useContaminantStore } from "../store";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { UpdateContaminantForm } from "pages/contaminant/contaminants";
import { ICreateContaminantBody } from "pages/contaminant/contaminants/types";
import { useModal } from "common/hooks";

interface IProps {
    refetch: (
        options?: RefetchOptions | undefined,
    ) => Promise<QueryObserverResult<ICreateContaminantBody, Error>>;
}

export const Header: FC<IProps> = memo(({ refetch }) => {
    const contaminant = useGetContaminant();
    const { isOwner } = useContaminantStore();

    const {
        modalRef: updateContaminantRef,
        openModal,
        closeModal,
    } = useModal({
        onClose: () => {
            refetch();
        },
    });

    return (
        <>
            <ModalTemplate
                text="Edit Contaminant"
                onClose={closeModal}
                modalRef={updateContaminantRef}
                className="max-w-[600px]"
            >
                {contaminant && (
                    <UpdateContaminantForm
                        handleClose={closeModal}
                        contaminant={contaminant}
                    />
                )}
            </ModalTemplate>
            <div className="md:flex lg:flex items-center p-3 mt-10 mx-10 mb-1 justify-between shadow-sm rounded-lg bg-[#EDE9FE] dark:bg-gray-800">
                <div>
                    <span className="text-xs md:text-sm lg:text-base text-gray-800 dark:text-gray-200">
                        {contaminant?.name}
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base text-gray-800 dark:text-gray-200">
                        <b>{contaminant?.description}</b>
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base text-gray-800 dark:text-gray-200">
                        {(isOwner || true) && (
                            <button className="ml-2" onClick={openModal}>
                                <EditIcon />
                            </button>
                        )}
                    </span>
                </div>
            </div>
        </>
    );
});
