import React, { ChangeEvent, FC, memo, useMemo } from "react";
import { TableTitleCeil } from "../../../TableTitleCeil";
import { TableInputCeil } from "../../../TableInputCeil";
import { useSubIngredientStore } from "pages/subIngredient/subIngredientProfile/store";
import { CheckboxGroup, DropdownWrapper } from "components/shared";
import { IOption } from "common/types";
import { TableRadioButtonsCeil } from "../../../TableRadiobuttonsCeil";
import classNames from "classnames";
import { TableTextareaCeil } from "../../../TableTextareaCeil";
import { TableMultiSelect } from "../../../TableMultiSelect";
import { Tooltip } from "react-tooltip";
import { getLastModifier } from "common/utils";

interface IProps {
    className?: string;
    header: string;
    headerClassName?: string;
    content?: string | null;
    comment?: string;
    contentClassName?: string;
    onChange?: (
        e:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | ChangeEvent<HTMLSelectElement>,
    ) => void;
    onChangeMultiSelect?: (selected: string[], name: string) => void;
    onChangeCIRReviewed?: (e: ChangeEvent<HTMLInputElement>) => void;
    value?: string | null;
    values?: string[] | null;
    name?: string;
    isOwner?: boolean;
    options?: IOption[];
    defaultOption?: boolean;
    defaultLabel?: string;
    defaultValue?: string;
    isCIRReviewed?: boolean;
    type?:
        | "input"
        | "dropdown"
        | "text"
        | "radio"
        | "cir_reviewed"
        | "multi_select";
    tooltipMessage?: string | null;
    lastModifiedBy?: Record<string, Record<string, string | null>>;
}

const RenderInput: FC<Omit<IProps, "header">> = memo(
    ({
        content = "",
        comment = "",
        contentClassName,
        className = "",
        onChange = () => {},
        onChangeCIRReviewed = () => {},
        onChangeMultiSelect = () => {},
        value,
        values,
        name,
        isOwner = false,
        type,
        options,
        defaultOption = false,
        defaultValue = "",
        defaultLabel,
        isCIRReviewed = false,
    }) => {
        switch (type) {
            case "input":
                return (
                    <TableInputCeil
                        className={className}
                        onChange={onChange}
                        value={value || ""}
                        name={name || ""}
                        isDisabled={!isOwner}
                    />
                );
            case "dropdown":
                return (
                    <div className={classNames("flex p-3", className)}>
                        <DropdownWrapper
                            className="w-full"
                            name={name || ""}
                            value={value || ""}
                            onChange={onChange}
                            disableDefaultOption={!defaultOption}
                            options={options}
                            defaultLabel={defaultLabel}
                            defaultValue={defaultValue}
                            disabled={!isOwner}
                        />
                    </div>
                );
            case "multi_select":
                return (
                    <TableMultiSelect
                        className={classNames("w-full", className)}
                        name={name || ""}
                        value={values || [""]}
                        onChange={onChangeMultiSelect}
                        options={options}
                        isDisabled={!isOwner}
                    />
                );

            case "text":
                return content ? (
                    <TableTitleCeil
                        text={content}
                        className={classNames(contentClassName, className)}
                    />
                ) : null;
            case "radio":
                return (
                    <div className={className}>
                        <TableRadioButtonsCeil
                            className="pb-0"
                            name={name || ""}
                            onChange={onChange}
                            checkedValue={value || ""}
                            isDisabled={!isOwner}
                        />
                        <TableTextareaCeil
                            className="flex p-3"
                            onChange={onChange}
                            value={comment || ""}
                            name={`${name || ""}_comment`}
                            placeholder="Add a comment"
                            isDisabled={!isOwner}
                        />
                    </div>
                );
            case "cir_reviewed":
                return (
                    <div className={className}>
                        <div className="flex p-3">
                            <CheckboxGroup
                                value="Yes"
                                label="Select if CIR has been reviewed. This is required to insert a citation."
                                onChange={onChangeCIRReviewed}
                                isChecked={isCIRReviewed}
                                isDisabled={!isOwner}
                            />
                        </div>
                        {isCIRReviewed && (
                            <TableTextareaCeil
                                className="flex p-3"
                                onChange={onChange}
                                value={content || ""}
                                name="cir_citation"
                                placeholder="Add CIR Citation"
                                isDisabled={!isOwner}
                            />
                        )}
                    </div>
                );
            default:
                return null;
        }
    },
);

export const TableRow: FC<IProps> = memo(
    ({
        header,
        headerClassName,
        content,
        contentClassName,
        onChange = () => {},
        value,
        values,
        name,
        comment,
        options,
        defaultOption,
        defaultLabel,
        defaultValue,
        isCIRReviewed = false,
        onChangeCIRReviewed,
        onChangeMultiSelect,
        type = "input",
        lastModifiedBy,
    }) => {
        const { isOwner } = useSubIngredientStore();

        const tooltipMessage = useMemo(() => {
            if (name && lastModifiedBy?.[name]) {
                return `Field changed by ${getLastModifier(
                    lastModifiedBy,
                    name,
                )}`;
            }

            return null;
        }, [lastModifiedBy, name]);

        if (type === "text" && !content) return null;

        return (
            <tr>
                <td className="flex group">
                    <TableTitleCeil
                        id={`modified-by-${name}`}
                        tooltipMessage={tooltipMessage}
                        text={header}
                        className={classNames(headerClassName, "w-1/3")}
                    />
                    <RenderInput
                        className="!w-2/3"
                        type={type}
                        content={content}
                        comment={comment}
                        contentClassName={contentClassName}
                        onChange={onChange}
                        value={value || ""}
                        values={values || []}
                        name={name || ""}
                        isOwner={isOwner}
                        options={options}
                        defaultOption={defaultOption}
                        defaultLabel={defaultLabel}
                        defaultValue={defaultValue}
                        isCIRReviewed={isCIRReviewed}
                        onChangeCIRReviewed={onChangeCIRReviewed}
                        onChangeMultiSelect={onChangeMultiSelect}
                    />

                    {tooltipMessage && (
                        <div className="absolute -top-2 right-0 hidden group-hover:block">
                            <Tooltip
                                anchorSelect={`#modified-by-${name}`}
                                variant="info"
                                place="top"
                                className="z-10 text-sm !bg-gray-700 !rounded-md p-2 !shadow-lg"
                            >
                                {tooltipMessage}
                            </Tooltip>
                        </div>
                    )}
                </td>
            </tr>
        );
    },
);
