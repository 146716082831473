import { testsConfig } from "common/data";

export const prepareTestData = (testsData) => {
    const finalArray = [];

    testsData.forEach((item) => {
        if (item.test) {
            const row = {
                test_code: item.test.test_code,
                attributes: [],
                results: [],
            };

            const testsKeys = ["attributes", "results"];

            testsKeys.forEach((element) => {
                const configItem = testsConfig[item.test.test_code];

                if (configItem && configItem[element] && item[element]) {
                    for (let ca = 0; ca < item[element]?.length; ca++) {
                        if (configItem[element][item[element][ca].name]) {
                            row[element].push({
                                name: item[element][ca].name,
                                value: item[element][ca][element].value,
                            });
                        }
                    }
                }
            });

            finalArray.push(row);
        }
    });

    return finalArray;
};
