import React, { FC } from "react";
import { Button } from "../button";
import { FilterStatusList } from "../filter-status-list";
import arrowDownIcon from "assets/images/icons/arrow-down.svg";
import { IFilter } from "common/types";

interface IProps {
    isFilterVisible: boolean;
    onFilterVisible: () => void;
    onRemoveFilter: (key: string) => void;
    filters: IFilter;
}

export const FilterBlock: FC<IProps> = ({
    isFilterVisible,
    onFilterVisible,
    onRemoveFilter,
    filters,
}) => {
    return (
        <div className="flex items-center gap-5 mb-4">
            <div className="">
                <Button
                    className="btn-filter bg-indigo-100 sm:shadow-lg"
                    onClick={onFilterVisible}
                >
                    Filters
                    {isFilterVisible ? (
                        <img
                            className="-rotate-90 w-4 h-4"
                            src={arrowDownIcon}
                            alt=""
                        />
                    ) : (
                        <img className="rotate-90" src={arrowDownIcon} alt="" />
                    )}
                </Button>
            </div>
            <FilterStatusList
                filters={filters}
                onRemoveFilter={onRemoveFilter}
            />
        </div>
    );
};
