import React, { FC } from "react";
import { Link } from "react-router-dom";
import arrowDown from "assets/images/icons/arrow-down.svg";

interface INavLinkProps {
    to: string;
    text: string;
}
export const NavLink: FC<INavLinkProps> = ({ to, text }) => (
    <Link
        to={to}
        className="flex items-center gap-2 p-2 text-gray-700 hover:text-primary hover:bg-gray-100 rounded transition-colors duration-200"
    >
        <img src={arrowDown} alt="Arrow Down Icon" className="h-4" />
        <span className="text-base">{text}</span>
    </Link>
);
