import React from "react";

interface IProps {
    children: React.ReactNode;
}

export const TableHeadRow: React.FC<IProps> = ({ children }) => (
    <tr className="sm:table-row rounded-l-lg sm:rounded-none border-b border-primary-main-dgray">
        {children}
    </tr>
);
