import React, { FC } from "react";
import closeIcon from "assets/images/icons/icons8-close.svg";
import { IFilter } from "common/types";

interface IProps {
    filters: IFilter;
    onRemoveFilter: (key: string) => void;
}

export const FilterStatusList: FC<IProps> = ({ filters, onRemoveFilter }) => (
    <div className="flex flex-shrink-0">
        {Object.entries(filters)
            .filter(([_, { value }]) => value)
            .map(([key, { label, filterValue: value }]) => (
                <span
                    key={key}
                    className="flex items-center gap-2 ml-2 px-3 py-1 min-w-10 rounded-full text-sm bg-green-200 relative"
                >
                    <span className="text-green-500 flex items-center justify-center">
                        {label === value ? label : `${label}: ${value}`}
                    </span>
                    <button
                        onClick={() => onRemoveFilter(key)}
                        className="w-3 h-3 flex items-center justify-center"
                    >
                        <img src={closeIcon} alt="Close" />
                    </button>
                </span>
            ))}
    </div>
);
