import React, { FC } from "react";

interface SubIngredientIconProps {
    size?: number;
    color?: string;
}

export const SubIngredientIcon: FC<SubIngredientIconProps> = ({
    size = 17,
    color = "white",
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.3425 7.39462L14.6483 10.3555C14.0533 12.9125 12.8775 13.9467 10.6675 13.7342C10.3133 13.7059 9.9308 13.6421 9.51996 13.543L8.32996 13.2596C5.37621 12.5584 4.46246 11.0992 5.15663 8.13837L5.8508 5.17045C5.99246 4.56837 6.16246 4.0442 6.37496 3.61212C7.20371 1.89795 8.6133 1.43754 10.9791 1.99712L12.162 2.27337C15.13 2.96754 16.0366 4.43379 15.3425 7.39462Z"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.6675 13.7344C10.2283 14.0319 9.67583 14.2798 9.00291 14.4994L7.88375 14.8677C5.07166 15.7744 3.59125 15.0165 2.6775 12.2044L1.77083 9.40647C0.864164 6.59439 1.615 5.10689 4.42708 4.20022L5.54625 3.83189C5.83666 3.7398 6.11291 3.66189 6.375 3.6123C6.1625 4.04439 5.9925 4.56855 5.85083 5.17064L5.15666 8.13855C4.4625 11.0994 5.37625 12.5586 8.33 13.2598L9.52 13.5431C9.93083 13.6423 10.3133 13.7061 10.6675 13.7344Z"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.95337 6.04199L12.3888 6.91324"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.25928 8.7832L10.3134 9.30737"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
