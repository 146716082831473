import React, { FC, useMemo } from "react";
import { ConfidenceInner } from "../ConfidenceInner";

interface IProps {
    confidence: number;
    withoutMaxWidth?: boolean;
}

const getConfidenceAttributes = (confidence: number) => {
    if (confidence < 1) {
        return [
            "prediction-confidence negative",
            "negative",
            String(confidence),
        ];
    } else if (confidence <= 3) {
        return ["prediction-confidence weak", "weak", String(confidence)];
    } else if (confidence < 30) {
        return [
            "prediction-confidence substantial",
            "substantial",
            String(confidence),
        ];
    } else {
        return ["prediction-confidence strong", "strong", String(confidence)];
    }
};

export const ConfidenceComponent: FC<IProps> = ({
    confidence,
    withoutMaxWidth = false,
}) => {
    const [confidenceClass, confidenceText, confidenceValue] = useMemo(
        () => getConfidenceAttributes(confidence),
        [confidence],
    );

    return (
        <ConfidenceInner
            confidenceClass={confidenceClass}
            confidenceText={confidenceText}
            confidenceValue={confidenceValue}
            withoutMaxWidth={withoutMaxWidth}
        />
    );
};
