import React, { FC } from "react";
import {
    useFetchRawIngredientTradenames,
    useDeleteTradename,
} from "pages/rawIngredient/rawIngredientProfile/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useRawIngredientStore } from "pages/rawIngredient/rawIngredientProfile/store";
import { useQueryClient } from "@tanstack/react-query";
import {
    defaultToastOptions,
    defaultErrorToastOptions,
    toastTexts,
} from "common/constants";
import { toast } from "react-toastify";

interface IProps {
    id: string;
}

export const TradeNameList: FC<IProps> = ({ id }) => {
    const {
        data: tradenames,
        isLoading,
        isError,
        refetch: refetchTradenames,
    } = useFetchRawIngredientTradenames(id);
    const { mutate: deleteTradename } = useDeleteTradename();

    const { key } = useRawIngredientStore();
    const queryClient = useQueryClient();

    const handleRemoveTradename = (tradenameId: number) => {
        deleteTradename(tradenameId, {
            onSuccess: () => {
                toast.success(toastTexts.remove, {
                    ...defaultToastOptions,
                });
                refetchTradenames();
                queryClient.refetchQueries({
                    queryKey: key,
                });
            },
            onError: (error) => {
                toast.error(toastTexts.error, defaultErrorToastOptions);
                console.error("Error deleting trade name:", error);
            },
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center">
                <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    className="text-gray-500"
                />
            </div>
        );
    }

    if (isError) {
        return <div className="text-red-500">Error loading trade names</div>;
    }

    return (
        <div className="my-4">
            <div className="divider mb-3" />
            <h3 className="text-lg font-bold mb-4">Existing Trade Names</h3>
            <ul className="flex flex-col gap-2">
                {tradenames?.map(({ id, tradename, vendor }) => (
                    <li
                        key={id}
                        className="flex items-center justify-between bg-gray-100 px-4 py-2 rounded-md"
                    >
                        <span className="text-gray-800">
                            {tradename} ({vendor?.description})
                        </span>
                        <button
                            type="button"
                            className="ml-4 text-red-500 hover:text-red-700"
                            onClick={() => handleRemoveTradename(id)}
                        >
                            <FontAwesomeIcon size="sm" icon={faClose} />
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};
