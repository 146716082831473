import * as yup from "yup";

const percentageValidation = yup
    .number()
    .typeError("Value must be a number")
    .test(
        "non-negative",
        "Value must be zero or positive",
        (value) => !value || value === 0 || value > 0,
    )
    .max(100, "Value must be less or equal 100")
    .test(
        "max-decimals",
        "Value must have no more than 5 digits after the decimal point",
        (value) =>
            value === undefined || /^\d+(\.\d{1,5})?$/.test(String(value)),
    );

const decimalValidationPositive = (maxDigits: number) =>
    yup
        .number()
        .typeError("Value must be a number")
        .test(
            "non-negative",
            "Value must be zero or positive",
            (value) => !value || value === 0 || value > 0,
        )
        .test(
            "max-decimals",
            `Value must have no more than ${maxDigits} digits after the decimal point`,
            (value) => {
                if (!value) return true;
                const regex = new RegExp(`^\\d+(\\.\\d{1,${maxDigits}})?$`);
                return regex.test(String(value));
            },
        );

const decimalValidation = yup
    .number()
    .typeError("Value must be a number")
    .test(
        "max-decimals",
        "Value must have no more than 5 digits after the decimal point",
        (value) => !value || /^-?\d+(\.\d{1,5})?$/.test(String(value)),
    );

export const moleculeValidationSchema = yup.object({
    dpraAssayDPRACys: percentageValidation,
    dpraAssayDPRALys: percentageValidation,
    keratinoSenseAssayJaworskaKEC15: decimalValidationPositive(5),
    hClatAssayCD54Marker: yup.string(),
    hClatAssayCD86Marker: yup.string(),
    vpMultipleRegression: decimalValidationPositive(5),
    kmaxMultipleRegression: decimalValidationPositive(5),
    keratinoSenseAssayJaworskaIC50: decimalValidationPositive(5),
    keratinoSenseAssayJaworskaKEC3: decimalValidationPositive(5),
    unclearImax: decimalValidationPositive(5),
    hClatAssayEC150: decimalValidationPositive(5),
    hClatAssayEC200: decimalValidationPositive(5),
    hClatAssayCV75: decimalValidationPositive(5),
    llna: decimalValidationPositive(5),
    timesSSNeuralNetwork: yup.string(),
    toxtree: yup.string(),
    derekOecdIts: yup.string(),
    sensis: yup.string(),
    timesSSBayesianNetwork: yup.string(),
    derekBDFb: yup.string(),
    kdpraBDF: yup.string(),
    meltingPointDEGCOperaPred: decimalValidation,
    surfaceTension: decimalValidation,
    logKow: decimalValidation,
    proteinBinding: decimalValidationPositive(1),
    operaProteinBinding: decimalValidationPositive(1),
    logDpH7: yup.number().typeError("Value must be a number"),
    wspH7Ml: yup.number().typeError("Value must be a number"),
    operaLogDbdf: decimalValidation,
    operaWsBDF: yup.number().typeError("Value must be a number"),
    kdpraBDFlogKmax: decimalValidationPositive(2),
});
