import { create } from "zustand";
import {
    IFormulaContaminant,
    IFormulaRawIngredient,
    IFormulaeExtraInfo,
    IPreparedSubIngredient,
    YesNoMaybe,
} from "../types";

export interface IState {
    key: (string | number)[];
    rawIngredients: IFormulaRawIngredient[];
    contaminants: IFormulaContaminant[];
    subIngredients: IPreparedSubIngredient[];
    rawIngredientsTotalWeight: string;
    subIngredientsTotalWeight: string;
    totalEnvironmentalScore: string;
    formulaeExtraInfo: IFormulaeExtraInfo;
    isOwner: boolean;
}

const defaultValues: Omit<IState, "key"> = {
    formulaeExtraInfo: {
        animalDerived: "Not available",
        gluten: "Not available",
        crueltyFree: "Not available",
        vegan: "Not available",
    },
    rawIngredients: [],
    contaminants: [],
    subIngredients: [],
    rawIngredientsTotalWeight: "0",
    subIngredientsTotalWeight: "0",
    totalEnvironmentalScore: "0",
    isOwner: false,
};

export interface IAction {
    clearState: () => void;
    setKey: (key: (string | number)[]) => void;
    setRawIngredients: (ingredients: IFormulaRawIngredient[]) => void;
    setContaminants: (ingredients: IFormulaContaminant[]) => void;
    setSubIngredients: (ingredients: IPreparedSubIngredient[]) => void;
    clearSubIngredients: () => void;
    setSubIngredientsTotalWeight: (value: string) => void;
    setRawIngredientsTotalWeight: (value: string) => void;
    setTotalEnvironmentalScore: (value: string) => void;
    setFormulaExtraInfo: (
        key: keyof IFormulaeExtraInfo,
        value: YesNoMaybe,
    ) => void;
    setIsOwner: (value: boolean) => void;
}

export const useFormulaProfileState = create<IState & IAction>((set) => ({
    key: [],
    ...defaultValues,
    clearState: () =>
        set((data) => ({
            ...defaultValues,
            isOwner: data.isOwner,
        })),
    setKey: (key) => set(() => ({ key })),
    setFormulaExtraInfo: (key, value) =>
        set((state) => ({
            formulaeExtraInfo: { ...state.formulaeExtraInfo, [key]: value },
        })),
    setRawIngredients: (rawIngredients) =>
        set((data) => ({
            rawIngredients: [...data.rawIngredients, ...rawIngredients],
        })),
    setContaminants: (contaminants) =>
        set(() => ({
            contaminants,
        })),
    setSubIngredients: (subIngredients) =>
        set((data) => ({
            subIngredients: [...data.subIngredients, ...subIngredients],
        })),
    clearSubIngredients: () =>
        set(() => ({
            subIngredients: [],
        })),
    setSubIngredientsTotalWeight: (value) =>
        set(() => ({
            subIngredientsTotalWeight: value,
        })),
    setRawIngredientsTotalWeight: (value) =>
        set(() => ({
            rawIngredientsTotalWeight: value,
        })),
    setTotalEnvironmentalScore: (value) =>
        set(() => ({
            totalEnvironmentalScore: value,
        })),
    setIsOwner: (value) =>
        set(() => ({
            isOwner: value,
        })),
}));
