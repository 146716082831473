export const mockReportData = {
    productId: "00001-01",
    productName: "Reena's Par Excellence 2-in-1 Shampoo and Conditioner",
    author: "Author Name",
    date: new Date().toLocaleDateString(),
    totalWeight: "98.40%",
    highestExposureFactor:
        "AQUA, CITRIC ACID, SODIUM PPG-16/PEG-2 Lauryl ETHER SULFATE",
    mos: "10.46",
    calculationMetrics: {
        productType: "Shampoo",
        bodyWeight: "60kg",
        gramsAppliedPerDay: "10g",
        skinRetentionFactor: "0.8",
    },
    ingredients: [
        {
            siCode: "000-0001",
            name: "AQUA",
            totalWeight: "81.36%",
            skinRetention: "0.01",
            dermalPenetration: "2",
            dailyExposure: "1.702",
            systemicExposure: "0.0284",
            noael: "10",
            mos: "352.52",
        },
        {
            siCode: "000-0003",
            name: "CITRIC ACID",
            totalWeight: "0.04%",
            skinRetention: "0.01",
            dermalPenetration: "4",
            dailyExposure: "0.0018",
            systemicExposure: "0",
            noael: "3",
            mos: "102431.03",
        },
    ],
};
