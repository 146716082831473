import React, { ReactNode } from "react";
import classNames from "classnames";
import { FormulasTable } from "./components";
import { Pagination } from "components/shared/pagination";
import { TableEntity } from "pages/dashboard/libs/types";

import { TableStructure } from "common/data";

interface Props {
    isFilterVisible: boolean;
    entities?: TableEntity[];
    entityType?: keyof typeof TableStructure;
    totalCount?: number;
    page: number;
    pageSize: number;
    setPage: (page: number) => void;
    children: ReactNode;
}

export const SectionContent: React.FC<Props> = ({
    isFilterVisible,
    totalCount,
    entities,
    entityType,
    page,
    setPage,
    pageSize = 5,
    children,
}) => (
    <div
        className={classNames("flex", {
            "flex-row": isFilterVisible,
            "flex-col": !isFilterVisible,
        })}
    >
        {isFilterVisible && (
            <div className="w-full md:w-1/4 lg:w-1/4 py-2 pr-2 mr-4">
                {children}
            </div>
        )}
        <div
            className={classNames("py-4", {
                "w-full md:w-3/4 lg:w-3/4": isFilterVisible,
                "w-full": !isFilterVisible,
            })}
        >
            <FormulasTable
                entity={TableStructure}
                entityType={entityType}
                entities={entities}
            />
            <div className="flex items-center gap-10 mt-16">
                <Pagination
                    pageSize={pageSize}
                    totalCount={totalCount || 0}
                    currentPage={page}
                    setCurrentPage={setPage}
                />

                <div className="w-1/3 inline-flex">{`Results: ${totalCount}`}</div>
            </div>
        </div>
    </div>
);
