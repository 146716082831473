export type MoleculeDataModel = {
    dpraAssayDPRACys: string;
    dpraAssayDPRALys: string;
    keratinoSenseAssayJaworskaKEC15: string;
    keratinoSenseAssayJaworskaIC50: string;
    keratinoSenseAssayJaworskaKEC3: string;
    hClatAssayCD54Marker: string;
    hClatAssayCD86Marker: string;
    vpMultipleRegression: string;
    kmaxMultipleRegression: string;
    unclearImax: string;
    hClatAssayEC150: string;
    hClatAssayEC200: string;
    hClatAssayCV75: string;
    timesSSNeuralNetwork: string;
    toxtree: string;
    derekOecdIts: string;
    meltingPointDEGCOperaPred: string;
    surfaceTension: string;
    sensis: string;
    timesSSBayesianNetwork: string;
    llna: string;
    logKow: string;
    proteinBinding: string;
    logDpH7: string;
    wspH7Ml: string;
    operaProteinBinding: string;
    operaLogDbdf: string;
    operaWsBDF: string;
    kdpraBDFlogKmax: string;
    kdpraBDF: string;
    derekBDFb: string;
};

export const initialMoleculeDataModel = {
    dpraAssayDPRACys: "",
    dpraAssayDPRALys: "",
    keratinoSenseAssayJaworskaKEC15: "",
    keratinoSenseAssayJaworskaIC50: "",
    keratinoSenseAssayJaworskaKEC3: "",
    hClatAssayCD54Marker: "",
    hClatAssayCD86Marker: "",
    vpMultipleRegression: "",
    kmaxMultipleRegression: "",
    unclearImax: "",
    hClatAssayEC150: "",
    hClatAssayEC200: "",
    hClatAssayCV75: "",
    timesSSNeuralNetwork: "",
    toxtree: "",
    derekOecdIts: "",
    meltingPointDEGCOperaPred: "",
    surfaceTension: "",
    sensis: "",
    timesSSBayesianNetwork: "",
    llna: "",
    logKow: "",
    proteinBinding: "",
    logDpH7: "",
    wspH7Ml: "",
    operaProteinBinding: "",
    operaLogDbdf: "",
    operaWsBDF: "",
    kdpraBDFlogKmax: "",
    kdpraBDF: "",
    derekBDFb: "",
};
