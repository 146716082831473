import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { MultiValue } from "react-select";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { InputWrapper } from "components/shared/inputWrapper";
import { Button } from "components/shared/button";
import { useCreateContaminant, useGetAllContaminants } from "../queries";
import { IContaminant, IError, IOption } from "common/types";
import { SearchableSelect } from "components/shared/SearchableSelect";
import { buildLink } from "common/utils";

interface IProps {
    handleClose: () => void;
    formulas_id?: string;
    raw_ingredients_id?: string;
}

const CreateSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
});

const ppmSchema = Yup.object().shape({
    ppm: Yup.number().required("PPM is required"),
});

export const CreateContaminantForm: FC<IProps> = memo(
    ({ handleClose, formulas_id, raw_ingredients_id }) => {
        const { mutate } = useCreateContaminant();
        const queryClient = useQueryClient();
        const { keycloak } = useKeycloak();
        const navigate = useNavigate();

        const isAddingMode = useMemo(
            () => Boolean(formulas_id || raw_ingredients_id),
            [formulas_id, raw_ingredients_id],
        );

        const [selectedContaminant, setSelectedContaminant] =
            useState<IOption | null>(null);

        const handleUpdateRelatedList = (newValue: IOption) => {
            setSelectedContaminant(newValue);
        };

        const Schema = useMemo(() => {
            if (isAddingMode) {
                return CreateSchema.concat(ppmSchema);
            } else {
                return CreateSchema;
            }
        }, []);

        const formik = useFormik({
            initialValues: {
                name: "",
                description: "",
                ...(isAddingMode ? { ppm: "" } : {}),
            },
            validationSchema: Schema,
            validateOnChange: true,
            validateOnMount: true,
            enableReinitialize: true,
            onSubmit: (values) => {
                const toastId = toast.loading(
                    toastTexts.loading,
                    defaultToastOptions,
                );

                mutate(
                    {
                        body: {
                            ...values,
                            formulas_id,
                            raw_ingredients_id,
                            created_by: keycloak?.tokenParsed?.email,
                        },
                    },
                    {
                        onSuccess: (response: IContaminant) => {
                            toast.update(toastId, {
                                ...defaultSuccessToastUpdate,
                                render: selectedContaminant
                                    ? "Updated!"
                                    : "Success! Contaminant has been created",
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.contaminants],
                            });

                            queryClient.removeQueries({
                                queryKey: [queryKeys.contaminants, "all"],
                            });

                            formik.resetForm();
                            setSelectedContaminant(null);
                            handleClose();

                            if (!selectedContaminant) {
                                const link = buildLink(
                                    routerKeys.contaminants,
                                    response.id,
                                );
                                navigate(link);
                            }
                        },
                        onError: (err: IError) => {
                            const message = err?.response?.data?.message || "";

                            if (message.includes("description")) {
                                formik.setFieldError("description", message);
                            }
                            toast.update(toastId, defaultErrorToastUpdate);
                        },
                    },
                );
            },
        });

        const { data, isLoading } = isAddingMode
            ? useGetAllContaminants()
            : { data: null, isLoading: false };

        const options = useMemo(() => {
            if (data) {
                return data.map((item) => {
                    return {
                        value: String(item?.id),
                        label: `${item?.name}, ${item?.description}`,
                    };
                }) as unknown as MultiValue<IOption>;
            } else {
                return [];
            }
        }, [data]);

        useEffect(() => {
            if (selectedContaminant) {
                const index = selectedContaminant.label.indexOf(",");

                formik.setFieldValue(
                    "name",
                    selectedContaminant.label.slice(0, index),
                );
                formik.setFieldValue(
                    "description",
                    selectedContaminant.label.slice(index + 1).trim() || "",
                );
            }
        }, [selectedContaminant]);

        const { values, errors, touched, handleChange, resetForm } = formik;

        const handleResetForm = () => {
            resetForm();
            setSelectedContaminant(null);
        };

        return (
            <form className="w-full" onSubmit={formik.handleSubmit}>
                <div className="flex flex-col gap-4">
                    {isAddingMode && Boolean(options.length) && (
                        <div className="px-1">
                            <SearchableSelect
                                isLoading={isLoading}
                                options={options}
                                values={selectedContaminant}
                                onChange={handleUpdateRelatedList}
                            />
                            <small className="text-gray-500">
                                Select an existing contaminant and set ppm or
                                create a new one by providing name, ppm, and
                                description.
                            </small>
                        </div>
                    )}
                    <InputWrapper
                        isError={Boolean(errors.name && touched.name)}
                        error={errors.name}
                        label="Name"
                    >
                        <input
                            type="text"
                            name="name"
                            className="w-full input input-bordered"
                            value={values.name}
                            onChange={(e) => {
                                if (selectedContaminant) {
                                    setSelectedContaminant(null);
                                }

                                handleChange(e);
                            }}
                        />
                    </InputWrapper>
                    {isAddingMode && (
                        <InputWrapper
                            isError={Boolean(errors.ppm && touched.ppm)}
                            error={errors.ppm}
                            label="PPM"
                        >
                            <input
                                type="number"
                                name="ppm"
                                className="w-full input input-bordered"
                                value={values.ppm}
                                onChange={handleChange}
                            />
                        </InputWrapper>
                    )}
                    <InputWrapper
                        isError={Boolean(
                            errors.description && touched.description,
                        )}
                        error={errors.description}
                        label="Description"
                    >
                        <textarea
                            className="textarea textarea-bordered w-full flex-grow"
                            name="description"
                            value={values.description}
                            onChange={(e) => {
                                if (selectedContaminant) {
                                    setSelectedContaminant(null);
                                }

                                handleChange(e);
                            }}
                        />
                    </InputWrapper>
                    <div className="flex items-center justify-end gap-2 mt-3">
                        <Button text="Clear" onClick={handleResetForm} />
                        <Button
                            text={selectedContaminant ? "Add" : "Create"}
                            type="submit"
                        />
                    </div>
                </div>
            </form>
        );
    },
);
