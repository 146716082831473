type Data =
    | Record<string, unknown>
    | Array<unknown>
    | string
    | number
    | boolean
    | File
    | null;

type AppendFormData = (
    formData: FormData,
    data: Data,
    parentKey?: string,
) => void;

export const jsonToFormData = (
    data: Data,
    parentKey: string = "",
): FormData => {
    const formData = new FormData();

    const appendFormData: AppendFormData = (formData, data, parentKey = "") => {
        if (data && typeof data === "object" && !(data instanceof File)) {
            if (Array.isArray(data)) {
                data.forEach((item, index) => {
                    appendFormData(
                        formData,
                        item as Data,
                        `${parentKey}[${index}]`,
                    );
                });
            } else {
                Object.keys(data).forEach((key) => {
                    const value = (data as Record<string, unknown>)[key];
                    const formKey = parentKey ? `${parentKey}.${key}` : key;

                    if (Array.isArray(value)) {
                        value.forEach((item, index) => {
                            appendFormData(
                                formData,
                                item as Data,
                                `${formKey}[${index}]`,
                            );
                        });
                    } else if (value && typeof value === "object") {
                        appendFormData(formData, value as Data, formKey);
                    } else {
                        formData.append(
                            formKey,
                            value !== null && value !== undefined
                                ? String(value)
                                : "",
                        );
                    }
                });
            }
        } else if (parentKey) {
            if (data instanceof File) {
                formData.append(parentKey, data);
            } else {
                formData.append(
                    parentKey,
                    data !== null && data !== undefined ? String(data) : "",
                );
            }
        }
    };

    appendFormData(formData, data, parentKey);

    return formData;
};
