import React, {
    FC,
    useState,
    ChangeEvent,
    useCallback,
    memo,
    useMemo,
} from "react";
import { SectionHeader } from "../SectionHeader";
import { InputBlock } from "../InputBlock";
import { InputLabel } from "../InputLabel";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { type MoleculeDataModel } from "pages/subIngredient/subIngredientProfile/libs";
import { RequiredDescription } from "../prediction/components/RequiredDescription";
import { FormikErrors } from "formik";

interface IProps {
    values: MoleculeDataModel;
    errors: FormikErrors<MoleculeDataModel>;
    onChange: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
}

const inputClassName =
    "w-full bg-gray-100 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200";

export const Tg497: FC<IProps> = memo(({ values, errors, onChange }) => {
    const [isOpenSelect, setIsOpenSelect] = useState(false);

    const toggleIsOpenSelect = useCallback(
        () => setIsOpenSelect((prev) => !prev),
        [],
    );

    const handleSelectChange = useCallback(
        (event: SelectChangeEvent<string>) => {
            const syntheticEvent = {
                target: {
                    name: event.target.name,
                    value: event.target.value,
                },
            } as ChangeEvent<HTMLInputElement | HTMLSelectElement>;
            onChange(syntheticEvent);
        },
        [onChange],
    );

    const isRequired = useMemo(
        () =>
            [
                values.dpraAssayDPRACys,
                values.dpraAssayDPRALys,
                values.derekOecdIts,
            ].every((it) => !it),
        [values.dpraAssayDPRACys, values.dpraAssayDPRALys, values.derekOecdIts],
    );

    return (
        <div className="flex flex-col gap-[20px]">
            <section className="flex flex-col gap-[40px]">
                <SectionHeader
                    title="Enter experimental values"
                    text="Input data for assays associated with three key events in the AOP for Skin sensitisation."
                    text2="Recommended to improve prediction estimate."
                />
                <div className="flex flex-col flex-nowrap gap-2 mb-10">
                    <RequiredDescription />
                    <div className="flex flex-wrap gap-5">
                        <InputBlock
                            title="Covalent binding to skin proteins"
                            subtitle="DPRA"
                        >
                            <InputLabel
                                text="DPRACys (% depleted)"
                                tooltipText="Percent of cysteine peptide depletion in the DPRA assay."
                                errorText={errors.dpraAssayDPRACys}
                                isRequired={isRequired}
                            >
                                <input
                                    type="text"
                                    name="dpraAssayDPRACys"
                                    onChange={onChange}
                                    value={values.dpraAssayDPRACys}
                                    className={inputClassName}
                                />
                            </InputLabel>
                            <InputLabel
                                text="DPRALys (% depleted)"
                                tooltipText="Percent of lysine peptide depletion in the DPRA assay."
                                errorText={errors.dpraAssayDPRALys}
                                isRequired={isRequired}
                            >
                                <input
                                    type="text"
                                    name="dpraAssayDPRALys"
                                    onChange={onChange}
                                    value={values.dpraAssayDPRALys}
                                    className={inputClassName}
                                />
                            </InputLabel>
                        </InputBlock>
                        <InputBlock
                            title="Dendritic cell activation"
                            subtitle="h-CLAT"
                        >
                            <InputLabel
                                text="EC150 (ug/ml)"
                                tooltipText="Concentration yielding 150 % induction of the cell surface activation marker CD86 in the h-CLAT assay."
                                errorText={errors.hClatAssayEC150}
                                isRequired
                            >
                                <input
                                    type="text"
                                    name="hClatAssayEC150"
                                    onChange={onChange}
                                    value={values.hClatAssayEC150}
                                    className={inputClassName}
                                />
                            </InputLabel>
                            <InputLabel
                                text="EC200 (ug/ml)"
                                tooltipText="Concentration yielding 200 % induction of the cell surface activation marker CD54 in the h-CLAT assay."
                                errorText={errors.hClatAssayEC200}
                                isRequired
                            >
                                <input
                                    type="text"
                                    name="hClatAssayEC200"
                                    onChange={onChange}
                                    value={values.hClatAssayEC200}
                                    className={inputClassName}
                                />
                            </InputLabel>
                        </InputBlock>
                        <InputBlock
                            title="In silico"
                            subtitle="Derek / OECD QSAR TB"
                        >
                            <InputLabel
                                text="Sensitiser potency"
                                tooltipText="ECETOC skin sensitisation class for the compound as predicted by the Derek Nexus software."
                                errorText={errors.derekOecdIts}
                                isRequired={isRequired}
                            >
                                <Select
                                    className="custom-select bg-white"
                                    name="derekOecdIts"
                                    open={isOpenSelect}
                                    onClick={toggleIsOpenSelect}
                                    value={values.derekOecdIts || ""}
                                    onChange={handleSelectChange}
                                    displayEmpty
                                    fullWidth
                                >
                                    <MenuItem value="">
                                        No data / Out of domain
                                    </MenuItem>
                                    <MenuItem value="1">Sensitiser</MenuItem>
                                    <MenuItem value="0">
                                        Non-sensitiser
                                    </MenuItem>
                                </Select>
                            </InputLabel>
                        </InputBlock>
                    </div>
                </div>
            </section>
        </div>
    );
});
