import React from "react";

interface IProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    name: string;
    onClick: () => void;
    isDisabled?: boolean;
    isDisabledInput?: boolean;
}

export const InputGroup: React.FC<IProps> = ({
    onChange,
    onClick,
    name,
    value,
    isDisabled = false,
    isDisabledInput = false,
}) => (
    <div className="join">
        <input
            type="text"
            className="input input-bordered join-item"
            name={name || ""}
            value={value || ""}
            onChange={onChange}
            disabled={isDisabledInput}
        />
        <button
            className="btn modal-button btn-square btn-edit join-item"
            style={{ display: "block" }}
            onClick={onClick}
            disabled={isDisabled}
        />
    </div>
);
