import { useMemo } from "react";
import { buildLinkWithQuery } from "common/utils";
import { useLocation } from "react-router-dom";

type Props = {
    baseUrl: string;
    id?: number;
};

export const useLinkWithQuery = ({ baseUrl, id }: Props): string => {
    const { search } = useLocation();
    const queryParams: Record<string, string> = {};

    const currentQueryParams = new URLSearchParams(search);
    currentQueryParams.forEach((value, key) => (queryParams[key] = value));

    const link = useMemo(
        () =>
            buildLinkWithQuery(
                baseUrl.endsWith("/") ? baseUrl : `${baseUrl}/`,
                id,
                queryParams,
            ),
        [baseUrl, id, queryParams],
    );

    return link;
};
