import { backendKeys } from "common/constants";
import instance from "./axios/instance";

// MOCK
import { IFormulasResponse } from "pages/formulas/formulas/types";
import BaseService from "./baseService";
import { IFormula, ISimilar } from "pages/formulas/formula-profile/types";
import { IQuery, IStatisticResponse } from "common/types";
import { getSortingParams } from "common/utils";

class FormulasService extends BaseService {
    constructor(
        public baseUrl = backendKeys.formulas,
        private fetchingService = instance,
    ) {
        super(baseUrl);
    }

    async getFormulas({ queryParams, sorting }: IQuery) {
        const limit = queryParams.includes("limit") ? "" : "&limit=2";
        const sortingParams = getSortingParams(sorting || []);

        return this.handleRequest<IFormulasResponse>(
            this.fetchingService.get(
                this.getFullUrl(`?${queryParams}${limit}${sortingParams}`),
            ),
        );
    }

    async getAllFormulas() {
        return this.handleRequest<IFormula[]>(
            this.fetchingService.get(this.getFullUrl("/all")),
        );
    }

    async getFormulasStatistic() {
        return this.handleRequest<IStatisticResponse>(
            this.fetchingService.get(this.getFullUrl("/statistic")),
        );
    }

    async getProductTypes() {
        return this.handleRequest<{ product_type: string }[]>(
            this.fetchingService.get(this.getFullUrl("/filter/product_type")),
        );
    }

    async getFormulaSimilar({ id }: { id: string }) {
        return this.handleRequest<ISimilar[]>(
            this.fetchingService.get(this.getFullUrl(`/${id}/similar`)),
        );
    }
}

export const formulasService = new FormulasService();
