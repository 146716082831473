export const convertDpraFromPercentDepletedToRemaining = (
    maybeValue: string | null | undefined,
): number | null => {
    if (
        maybeValue === null ||
        maybeValue === undefined ||
        isNaN(Number(maybeValue))
    ) {
        return null;
    } else {
        return 100.0 - Number(maybeValue);
    }
};
