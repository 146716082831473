import {
    IParsedRawIngredient,
    IRawIngredientsResponse,
} from "pages/rawIngredient/types";
import { create } from "zustand";
import { IParsedFormula } from "../types";

export interface IState {
    key: (string | number)[];
}

export interface IAction {
    setKey: (key: (string | number)[]) => void;
}

export const useFormulasQuery = create<IState & IAction>((set) => ({
    key: [],
    setKey: (key) => set(() => ({ key })),
}));

interface FormulaStore {
    formula: IParsedFormula | null;
    setFormula: (formula: IParsedFormula | null) => void;
    updateRawIngredient: (
        ricode: number,
        updatedIngredient: Partial<IParsedRawIngredient>,
    ) => void;
    changeRawIngredient: (
        newIngredient: IRawIngredientsResponse,
        owner: string,
    ) => void;
    addRawIngredient: (ingredient: IParsedRawIngredient) => void;
    removeRawIngredient: (ricode: number) => void;
}

export const mapperRawIngredient = (
    newIngredient: IRawIngredientsResponse,
    owner: string,
) => {
    const { ricode, description, contaminants, subIngredients, created_by } =
        newIngredient?.rows?.[0] || {};

    return {
        ricode,
        description,
        contaminants:
            contaminants?.map(({ name, description }) => ({
                name,
                description: description || "",
                ppm: "",
            })) || [],
        subIngredients:
            subIngredients?.map(({ name, rawIngredientsSubIngredients }) => ({
                name,
                weight_percent:
                    rawIngredientsSubIngredients?.weight_percent || "",
            })) || [],
        weight_percent: "",
        isOwner: created_by === owner || !Boolean(created_by),
        isExist: true,
    };
};

export const useFormulaStore = create<FormulaStore>((set) => ({
    formula: null,

    setFormula: (formula) => set(() => ({ formula })),
    updateRawIngredient: (index, updatedIngredient) =>
        set((state) => {
            if (!state.formula) return state;

            const updatedIngredients = state.formula.rawIngredients.map(
                (ingredient, idx) =>
                    idx === index
                        ? { ...ingredient, ...updatedIngredient }
                        : ingredient,
            );

            return {
                formula: {
                    ...state.formula,
                    rawIngredients: updatedIngredients,
                },
            };
        }),
    changeRawIngredient: (newIngredient, owner) =>
        set((state) => {
            if (!state.formula) return state;

            const rawIngredient = mapperRawIngredient(newIngredient, owner);

            const updatedIngredients = state.formula.rawIngredients.map(
                (ingredient) =>
                    ingredient.description?.toLowerCase() ===
                    newIngredient?.rows?.[0]?.description.toLowerCase()
                        ? {
                              ...rawIngredient,
                              weight_percent: ingredient.weight_percent,
                          }
                        : ingredient,
            );

            return {
                formula: {
                    ...state.formula,
                    rawIngredients: updatedIngredients,
                },
            };
        }),
    addRawIngredient: (ingredient) =>
        set((state) => {
            if (!state.formula) return state;

            return {
                formula: {
                    ...state.formula,
                    rawIngredients: [
                        ...state.formula.rawIngredients,
                        ingredient,
                    ],
                },
            };
        }),
    removeRawIngredient: (ricode) =>
        set((state) => {
            if (!state.formula) return state;

            const filteredIngredients = state.formula.rawIngredients.filter(
                (_, index) => index !== ricode,
            );

            return {
                formula: {
                    ...state.formula,
                    rawIngredients: filteredIngredients,
                },
            };
        }),
}));
