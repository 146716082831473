import classNames from "classnames";
import React from "react";

interface IProps {
    className?: string;
}

export const MinusIcon: React.FC<IProps> = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={classNames("w-6 h-6", className)}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
    </svg>
);
