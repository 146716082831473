import React, { FC } from "react";
import { IOption } from "common/types";
import { SearchableSelect } from "components/shared/SearchableSelect";
import { Button } from "components/shared/button";
import { MultiValue } from "react-select";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
    isLoading: boolean;
    options: MultiValue<IOption>;
    values: IOption | null;
    onChange: (newValue: IOption) => void;
    handleCreate: () => void;
    onClose: () => void;
    error: string | null;
}

export const AddNewItemTemplate: FC<IProps> = ({
    isLoading,
    options,
    values,
    onChange,
    handleCreate,
    onClose,
    error,
}) => (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-md shadow-lg">
        <div className="flex justify-between items-end pb-4 gap-4">
            <div className="w-2/3">
                <h6 className="text-sm font-medium text-gray-700">
                    Choose from list
                </h6>
                <SearchableSelect
                    isLoading={isLoading}
                    options={options}
                    values={values}
                    onChange={onChange}
                />
            </div>
            <div className="flex items-center gap-2 w-1/3">
                <Button
                    onClick={handleCreate}
                    className="px-4 py-2 bg-green-500 text-white rounded-md"
                >
                    <FontAwesomeIcon icon={faPlus} size="lg" />
                </Button>
                <Button
                    onClick={onClose}
                    className="px-4 py-2 bg-red-500 text-white rounded-md"
                >
                    <FontAwesomeIcon icon={faMinus} size="lg" />
                </Button>
            </div>
        </div>
        {error && <span className="text-sm text-red-500">{error}</span>}
    </div>
);
