export const sensitizationClassDecoder = (
    number: number,
):
    | "NonSensitizer"
    | "WeakSensitizer"
    | "ModerateSensitizer"
    | "StrongSensitizer" => {
    switch (number) {
        case 1:
            return "NonSensitizer";
        case 2:
            return "WeakSensitizer";
        case 3:
            return "ModerateSensitizer";
        case 4:
            return "StrongSensitizer";
        default:
            throw new Error(
                "Expected sensitizer class number to be within 1-4",
            );
    }
};
