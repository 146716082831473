import { IToxicology } from "common/types";

export const updateToxicologyState = (
    currentToxicology: IToxicology,
    newToxicology: IToxicology,
): IToxicology => {
    const updatedToxicology: IToxicology = { ...currentToxicology };

    (Object.keys(currentToxicology) as Array<keyof IToxicology>).forEach(
        (key) => {
            if (key.startsWith("fix_")) {
                (updatedToxicology[key] as boolean | null | string) =
                    currentToxicology[key] === null
                        ? newToxicology[key]
                        : currentToxicology[key];

                const originalKey = key.slice(4) as keyof IToxicology;

                (updatedToxicology[originalKey] as boolean | null | string) =
                    !Boolean(currentToxicology[key])
                        ? newToxicology[originalKey]
                        : currentToxicology[originalKey];
            } else {
                const fixKey = `fix_${key}` as keyof IToxicology;

                if (currentToxicology[fixKey] !== null) {
                    (updatedToxicology[key] as string | boolean | null) =
                        currentToxicology[fixKey]
                            ? currentToxicology[key]
                            : newToxicology[key];
                } else {
                    (updatedToxicology[key] as string | boolean | null) =
                        newToxicology[key];
                }
            }
        },
    );

    return {
        ...updatedToxicology,
        overall_environmental_rank: newToxicology?.overall_environmental_rank,
    };
};
