import classNames from "classnames";
import React, { ReactNode, memo } from "react";

type ButtonType = "button" | "submit";

interface IProps {
    text?: string;
    onClick?: () => void;
    isLoading?: boolean;
    className?: string;
    classNameContainer?: string;
    type?: ButtonType;
    children?: ReactNode;
    disabled?: boolean;
    isSplitText?: boolean;
    isOwner?: boolean;
}

const splitText = (text: string) => {
    const words = text.split(" ");
    if (words.length >= 2) {
        return (
            <span className="text-center">
                <span className="block">{words[0]}</span>
                <span className="block">
                    {words[1] + (words?.[2] ? ` ${words[2]}` : "")}
                </span>
            </span>
        );
    }
    return text;
};

export const Button: React.FC<IProps> = memo(
    ({
        text = "",
        onClick,
        isLoading = false,
        className = "",
        classNameContainer = "",
        type = "button",
        children = null,
        disabled = false,
        isSplitText = false,
        isOwner = true,
    }) => {
        if (!isOwner) {
            return null;
        }

        return (
            <div className={classNameContainer}>
                <button
                    className={classNames(
                        "btn relative flex justify-center items-center",
                        className,
                        {
                            "opacity-50 cursor-not-allowed":
                                isLoading || disabled,
                        },
                    )}
                    onClick={!isLoading && !disabled ? onClick : undefined}
                    type={type}
                    disabled={isLoading || disabled}
                >
                    {isLoading && (
                        <span
                            className={classNames(
                                "absolute inset-0 flex justify-center items-center",
                                "bg-opacity-50 bg-white rounded",
                                "z-10",
                            )}
                        >
                            <span className="loading loading-spinner text-primary" />
                        </span>
                    )}
                    <span
                        className={classNames(
                            "flex justify-center items-center",
                            {
                                invisible: isLoading,
                            },
                        )}
                    >
                        {text && (isSplitText ? splitText(text) : text)}
                        {children}
                    </span>
                </button>
            </div>
        );
    },
);
