import { IToxicology } from "common/types";
import React, { useRef } from "react";
import {
    InputGroup,
    FormLabelTemplate,
    CommonInput,
    LabelDivider,
    InputTableCeil,
    YesNoSelect,
} from "..";
import { ModalTemplate } from "components/shared/modalTemplate";
// import { useSubIngredientStore } from "pages/subIngredient/subIngredientProfile/store";

interface IProps {
    data: IToxicology;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
}

export const BioaccumulationSubRankRow: React.FC<IProps> = ({
    data,
    onChange,
}) => {
    const bioaccumulationModalRef = useRef<HTMLDialogElement>(null);
    // const { isOwner } = useSubIngredientStore();

    const openModal = () => {
        if (bioaccumulationModalRef.current) {
            bioaccumulationModalRef.current.showModal();
        }
    };

    const closeModal = () => {
        if (bioaccumulationModalRef.current) {
            bioaccumulationModalRef.current.close();
        }
    };

    return (
        <tr>
            <InputTableCeil
                text="Persistence sub rank value"
                name="fix_bioacc_srv"
                checked={Boolean(data.fix_bioacc_srv)}
                onChange={onChange}
                // isDisabled={!isOwner}
            />
            <td className="flex p-3">
                <InputGroup
                    onChange={onChange}
                    value={data.bioacc_srv}
                    name="bioacc_srv"
                    onClick={openModal}
                    // isDisabled={!isOwner}
                />
                <ModalTemplate
                    text="Bioaccumulation sub rank value"
                    onClose={closeModal}
                    modalRef={bioaccumulationModalRef}
                >
                    <div className="flex">
                        <FormLabelTemplate
                            text="Bioaccumulation sub rank"
                            name="fix_bioacc_srv"
                            onChange={onChange}
                            checked={Boolean(data.fix_bioacc_srv)}
                        >
                            <CommonInput
                                name="bioacc_srv"
                                value={data.bioacc_srv}
                                onChange={onChange}
                                color="input-info"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="depends on" />

                        <FormLabelTemplate
                            text="molecular weight"
                            name="fix_mw"
                            onChange={onChange}
                            checked={Boolean(data.fix_mw)}
                        >
                            <CommonInput
                                name="mw"
                                value={data.mw}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>
                        <LabelDivider text="," />

                        <FormLabelTemplate
                            text="readily biodegradable"
                            name="fix_ready_bio_pred"
                            onChange={onChange}
                            checked={Boolean(data.fix_ready_bio_pred)}
                        >
                            <YesNoSelect
                                name="ready_bio_pred"
                                value={data.ready_bio_pred}
                                onChange={onChange}
                                color="select-primary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="and" />

                        <FormLabelTemplate
                            text="bioconcentration factor"
                            name="fix_bcf"
                            onChange={onChange}
                            checked={Boolean(data.fix_bcf)}
                        >
                            <CommonInput
                                name="bcf"
                                value={data.bcf}
                                onChange={onChange}
                            />
                        </FormLabelTemplate>
                    </div>

                    <div className="divider" />

                    <div className="flex">
                        <FormLabelTemplate
                            text="readily biodegradable"
                            name="fix_ready_bio_pred"
                            onChange={onChange}
                            checked={Boolean(data.fix_ready_bio_pred)}
                        >
                            <YesNoSelect
                                name="ready_bio_pred"
                                value={data.ready_bio_pred}
                                onChange={onChange}
                                color="input-primary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="if" />

                        <FormLabelTemplate
                            text="biowin 3"
                            name="fix_biowin3"
                            onChange={onChange}
                            checked={Boolean(data.fix_biowin3)}
                        >
                            <CommonInput
                                name="biowin3"
                                value={data.biowin3}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="> 2.75 and" />

                        <FormLabelTemplate
                            text="biowin 5"
                            name="fix_biowin5"
                            onChange={onChange}
                            checked={Boolean(data.fix_biowin5)}
                        >
                            <CommonInput
                                name="biowin5"
                                value={data.biowin5}
                                onChange={onChange}
                                color="input-secondary"
                            />
                        </FormLabelTemplate>

                        <LabelDivider text="> 0.5" />
                    </div>

                    <div className="divider" />
                </ModalTemplate>
            </td>
        </tr>
    );
};
