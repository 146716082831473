import React, { FC, memo } from "react";
import { useGetFormula } from "../queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { ModalTemplate } from "components/shared/modalTemplate";
import { EditFormulaForm } from "./EditFormulaForm";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { IFormula } from "../types";
import { useFormulaProfileState } from "../store";
import { useModal } from "common/hooks";

interface IProps {
    refetch: (
        options?: RefetchOptions | undefined,
    ) => Promise<QueryObserverResult<IFormula, Error>>;
}

export const Header: FC<IProps> = memo(({ refetch }) => {
    const formula = useGetFormula();
    const { isOwner } = useFormulaProfileState();

    const {
        modalRef: createFormulaRef,
        openModal,
        closeModal,
    } = useModal({
        onClose: () => {
            refetch();
        },
    });

    return (
        <>
            <ModalTemplate
                text="Edit formula"
                onClose={closeModal}
                modalRef={createFormulaRef}
                className="max-w-[600px]"
            >
                {formula && (
                    <EditFormulaForm
                        formula={formula}
                        handleClose={closeModal}
                    />
                )}
            </ModalTemplate>
            <div className="md:flex lg:flex items-center p-3 mt-10 mx-10 mb-1 justify-between shadow-sm rounded-lg bg-[#EDE9FE] dark:bg-gray-800">
                <div>
                    <span className="text-xs md:text-sm lg:text-base text-gray-800 dark:text-gray-200">
                        {formula?.ficode}
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base text-gray-800 dark:text-gray-200">
                        <b>{formula?.description}</b>
                    </span>
                </div>
                <div>
                    {(isOwner || true) && (
                        <button
                            onClick={openModal}
                            className="text-gray-800 dark:text-gray-200"
                        >
                            <FontAwesomeIcon icon={faPencil} />
                        </button>
                    )}
                </div>
            </div>
        </>
    );
});
