import React, { FC } from "react";
import { Link } from "react-router-dom";

interface IProps {
    title: string;
    link: string;
    count?: number;
    increaseCount?: number;
    description: string;
    // underReviewCount: number;
}

export const InfoCard: FC<IProps> = ({
    title,
    link,
    count,
    increaseCount,
    description,
}) => (
    <Link
        to={link}
        className="bg-primary-main-white rounded-xl overflow-hidden m-2 cursor-pointer shadow-sm transition-transform transform hover:scale-105 bg-hover-color"
    >
        <div className="p-6">
            <div className="flex justify-between items-center mb-4">
                <p className="text-sm font-bold">{title}</p>
                <p className="font-bold text-sm text-[#1e4dcc] hover:underline">
                    View All
                </p>
            </div>
            <div className="flex items-center mb-4">
                {count !== undefined && count > 0 && (
                    <p className="text-3xl font-bold">{count}</p>
                )}
                {increaseCount !== undefined && increaseCount > 0 && (
                    <p className="bg-green-200 text-green-800 rounded-full inline-flex items-center text-sm px-2 py-1 ml-3">
                        {`+${increaseCount} Today`}
                    </p>
                )}
            </div>
            <p className="text-xs">{description}</p>
        </div>
    </Link>
);
