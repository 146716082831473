import React, { useCallback, useState } from "react";
import { PageTemplate } from "components/page-template";
import {
    HelpDeskItems,
    ProfileMenu,
    SavedItems,
    UserProfile,
} from "./components";
import {
    ProfileConfig,
    ProfileItems,
    useGetLocation,
    useGetUserProfile,
} from "./libs";
import { ToastContainer } from "react-toastify";
import { ArchiveAdd } from "assets/icons";

interface IProps {
    activeItem: ProfileItems;
}

const ActiveTab: React.FC<IProps> = ({ activeItem }) => {
    const { data: userInfo, refetch: refetchUser } = useGetUserProfile();
    const { data: location } = useGetLocation();

    switch (activeItem) {
        case ProfileItems.MY_PROFILE:
            return (
                <UserProfile
                    userInfo={userInfo}
                    location={location}
                    refetchUser={refetchUser}
                />
            );
        case ProfileItems.SAVED_ITEMS:
            return <SavedItems />;
        default:
            return <HelpDeskItems />;
    }
};

export const Profile = () => {
    const [activeItem, setActiveItem] = useState(ProfileItems.MY_PROFILE);

    const handleMenuClick = useCallback((active: ProfileItems) => {
        setActiveItem(active);
    }, []);

    const subtitle = ProfileConfig[activeItem].subtitle;
    const title = ProfileConfig[activeItem].title;
    const titleIcon =
        activeItem === ProfileItems.SAVED_ITEMS ? <ArchiveAdd /> : null;

    return (
        <PageTemplate
            title={title}
            titleIcon={titleIcon}
            subtitle={subtitle}
            withSearch={false}
        >
            <div className="flex gap-8 mx-12 my-8">
                <ProfileMenu
                    activeItem={activeItem}
                    onItemClick={handleMenuClick}
                />
                <div className="w-full px-6">
                    <ActiveTab activeItem={activeItem} />
                </div>
            </div>
            <ToastContainer />
        </PageTemplate>
    );
};
