import React, { FC, useCallback, useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable, IconLink, SaveButton } from "components/shared";
import classNames from "classnames";
import { IFormulaItem } from "pages/formulas/formulas/types";
import {
    useFullSavedFormulas,
    useGetSavedFormulas,
    useRemoveSavedFormulas,
} from "pages/profile/libs";
import {
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    routerKeys,
    toastTexts,
} from "common/constants";
import { toast } from "react-toastify";
import { formatLabel } from "common/utils";

interface IProps {
    className?: string;
}

export const SavedItems: FC<IProps> = ({ className = "" }) => {
    const { data: savedFormulas = [], refetch } = useFullSavedFormulas();
    const { mutate: removeSavedFormulasMutation } = useRemoveSavedFormulas();
    const { refetch: refetchSavedFormulas } = useGetSavedFormulas();

    const handleRemove = useCallback((id: number) => {
        const toastId = toast.loading(toastTexts.loading, defaultToastOptions);
        removeSavedFormulasMutation(id, {
            onSuccess: () => {
                toast.update(toastId, {
                    ...defaultSuccessToastUpdate,
                    render: toastTexts.success,
                });

                if (refetch) {
                    refetch();
                }

                if (refetchSavedFormulas) {
                    refetchSavedFormulas();
                }
            },
            onError: () => {
                toast.update(toastId, defaultErrorToastUpdate);
            },
        });
    }, []);

    const columns = useMemo<ColumnDef<IFormulaItem>[]>(
        () => [
            {
                accessorKey: "ficode",
                header: () => <span>FI Code</span>,
            },
            {
                accessorKey: "description",
                header: () => <span>Description</span>,
            },
            {
                accessorKey: "status",
                header: () => <span>Status</span>,
                cell: ({ row }) => (
                    <span>{formatLabel(row.getValue("status"))}</span>
                ),
            },
            {
                id: "unsaved",
                accessorKey: "unsaved",
                header: () => <span>Unsaved</span>,
                cell: ({ row }) => (
                    <SaveButton
                        className="flex items-center px-2 hover:cursor-pointer"
                        id={row.original.id}
                        onAction={handleRemove}
                        items={savedFormulas}
                        attribute="id"
                    />
                ),
            },
            {
                id: "edit_formula",
                accessorKey: "edit_formula",
                header: () => <span>Edit</span>,
                cell: ({ row }) => (
                    <IconLink to={routerKeys.formulas} id={row.original.id} />
                ),
            },
        ],
        [savedFormulas, savedFormulas.length, handleRemove],
    );
    return (
        <div
            className={classNames(
                "bg-profile flex flex-col w-full min-h-[230px] rounded-tl-2xl rounded-tr-2xl px-10 py-4 shadow-md",
                className,
            )}
        >
            <h1 className="gray-color text-xl font-medium mb-2">
                Saved Formulas
            </h1>
            <DataTable
                className="pb-4"
                data={savedFormulas}
                columns={columns}
            />
        </div>
    );
};
