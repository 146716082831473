import React, { FC, ReactNode } from "react";
import classNames from "classnames";

interface IProps {
    title: string;
    subtitle?: string;
    children: ReactNode;
}

export const InputBlock: FC<IProps> = ({ title, subtitle, children }) => (
    <div
        className={classNames(
            "flex flex-col gap-5 justify-between h-[540px] min-h-[540px] w-1/3 max-w-[330px] px-1 p-6 bg-gray-100 shadow-md rounded-lg border border-gray-300",
        )}
    >
        <div>
            <h6 className="text-lg font-semibold text-gray-800 text-center">
                {title}
            </h6>
            <div className="mx-auto border-t border-gray-300 w-[80%] my-2"></div>
            {subtitle && (
                <h6 className="text-md text-gray-700 text-center">
                    {subtitle}
                </h6>
            )}
        </div>

        <div className="mt-2 flex-grow">{children}</div>
    </div>
);
