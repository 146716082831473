import React from "react";

interface IProps {
    text: string;
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    isDisabled?: boolean;
}

export const InputTableCeil: React.FC<IProps> = ({
    text,
    checked,
    onChange,
    name,
    isDisabled = false,
}) => (
    <td className="text-xs md:text-base lg:text-base px-3">
        <b>{text}</b>
        <label className="text-sm ml-3 px-0">
            <input
                type="checkbox"
                className="checkbox checkbox-xs mr-1 rounded-t-none"
                value="true"
                data-type="boolean"
                onChange={onChange}
                name={name || ""}
                checked={checked}
                disabled={isDisabled}
            />
            fix
        </label>
    </td>
);
