import React, { FC } from "react";
import editIcon from "assets/images/icons/pencil.svg";

interface IProps {
    onClick?: () => void;
}

export const EditButton: FC<IProps> = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            className="gray-color gap-2 text-lg flex items-center transition-transform duration-300 ease-in-out hover:scale-110 bg-transparent border-none cursor-pointer"
        >
            <span>Edit</span>
            <img className="w-4 h-4" src={editIcon} alt="Edit icon" />
        </button>
    );
};
