import React from "react";
import { FIContaminantsTable } from "./FIContaminantsTable";
import { RIContaminantsTable } from "./RIContaminantsTable";
import { useUploadContaminant } from "../queries";
import { useParams } from "react-router-dom";

interface IProps {
    tab: string;
}

export const TabContent: React.FC<IProps> = ({ tab }) => {
    const { id = "" } = useParams();
    const { data } = useUploadContaminant(id);

    switch (tab) {
        case "Formulas":
            return <FIContaminantsTable items={data?.formulas} />;
        case "Raw Ingredients":
        case "Concentrations":
            return <RIContaminantsTable items={data?.rawIngredients} />;
        default:
            return <RIContaminantsTable />;
    }
};
