export const FormulaStatus = {
    OBSOLETE: "Obsolete",
    FUNCTIONAL: "Functional",
    NOT_FOUND: "Not found",
    FOUND: "Found",
    Incomplete: "Incomplete",
    Complete: "Complete",
    Active: "Active",
    Inactive: "Inactive",
} as const;
