import {
    type IFormula,
    type IPreparedSubIngredient,
} from "pages/formulas/formula-profile/types";
import { type ReportData } from "../types";
import { type IUser } from "common/types";
import { getFullName, modifyDate } from "common/utils";

type InputType = {
    data: IPreparedSubIngredient[];
    formula?: IFormula;
    userInfo?: IUser;
};

export const mapReport = ({
    data,
    formula,
    userInfo,
}: InputType): ReportData => {
    const { firstName = "", lastName = "" } = userInfo || {};
    let totalWeight = 0;
    let highestMosValue = 0;
    let highestExposureFactor = "";

    const mappedData =
        data?.map(
            ({
                sicode,
                name,
                rawSub,
                skin_retention_factor,
                dermal_penetration,
                daily_exposure,
                systematic_daily_exposure,
                noael,
                MOS,
            }) => {
                totalWeight += Number(rawSub?.raw_weight_percent) || 0;

                const mosValue = Number(MOS || 0);
                if (mosValue > highestMosValue) {
                    highestMosValue = mosValue;
                }

                const exposureValue = Number(daily_exposure || 0);
                if (exposureValue > Number(highestExposureFactor)) {
                    highestExposureFactor = name;
                }

                return {
                    sicode,
                    name,
                    total_weight: rawSub.raw_weight_percent,
                    skin_retention_factor,
                    dermal_penetration,
                    daily_exposure,
                    systematic_daily_exposure,
                    noael,
                    mos: MOS,
                };
            },
        ) || [];

    return {
        productId: formula?.ficode || "",
        productName: formula?.description || "",
        author: getFullName(firstName, lastName),
        date: modifyDate(new Date().toISOString()),
        totalWeight: totalWeight.toString(),
        highestExposureFactor: highestExposureFactor,
        mos: highestMosValue.toString(),
        calculationMetrics: {
            productType: formula?.product_type
                ? formula.product_type.charAt(0).toUpperCase() +
                  formula.product_type.slice(1)
                : "",
            bodyWeight: formula?.body_weight ? `${formula?.body_weight}kg` : "",
            gramsAppliedPerDay: formula?.grams_applied_per_day || "",
            skinRetentionFactor: formula?.skin_retention_factor || "",
        },
        ingredients: mappedData,
    };
};
