import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    useParams,
    useNavigate,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import { useUploadContaminant, useRemoveContaminant } from "./queries";
import { useContaminantStore } from "./store";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    toastTexts,
    routerKeys,
    queryKeys,
} from "common/constants";
import { Header, TabContent } from "./components";
import { TabsBox } from "components/tabsBox";
import { ToastContainer, toast } from "react-toastify";
import { LoadingToast } from "components/shared/loadingToast";
import { useKeycloak } from "@react-keycloak/web";

const contaminantsTab = ["Concentrations"];

export const ContaminantProfilePage = () => {
    const { id = "" } = useParams();
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();

    const { search } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams(search);
    const [selectedTab, setSelectedTab] = useState<string>(
        searchParams.get("tab") || contaminantsTab[0],
    );

    const { setIsOwner, isOwner, setKey } = useContaminantStore();
    const {
        data: contaminant,
        isLoading,
        isError,
        refetch,
    } = useUploadContaminant(id);

    useEffect(() => {
        if (id) {
            const queryKey = [queryKeys.contaminants, id];
            setKey(queryKey);
        }
    }, [id]);

    const isUserOwner = useMemo(() => {
        return keycloak.tokenParsed?.email === contaminant?.created_by;
    }, [keycloak.tokenParsed?.email, contaminant?.created_by]);

    useEffect(() => {
        //setIsOwner(isUserOwner);
        setIsOwner(true);

        return () => {
            setIsOwner(false);
        };
    }, [isUserOwner, setIsOwner]);

    const { mutate: removeContaminantMutate } = useRemoveContaminant();

    const removeContaminant = useCallback(() => {
        removeContaminantMutate(
            { id },
            {
                onSuccess: () => {
                    navigate(routerKeys.contaminants);
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [id]);

    const handleSelectTab = useCallback(
        (tab: string) => {
            setSelectedTab(tab);
            searchParams.set("tab", tab);
            setSearchParams(searchParams);
        },
        [searchParams, setSearchParams],
    );

    if (isLoading) {
        return <LoadingToast />;
    }

    if (!contaminant || isError) {
        if (isError) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        } else {
            toast.info(toastTexts.dataLack, defaultToastOptions);
        }

        return <ToastContainer />;
    }

    return (
        <>
            <Header refetch={refetch} />
            <TabsBox
                tabs={contaminantsTab}
                selectedTab={selectedTab}
                onSelectTab={handleSelectTab}
                onRemove={isOwner ? removeContaminant : undefined}
            />
            <TabContent tab={selectedTab} />
            <ToastContainer />
        </>
    );
};
