import React, { useMemo } from "react";
import { TableCeil, TableHeaderCeil } from "components/shared";
import { TableHeadRow } from "..";
import { RawIngredientRow } from "./RawIngredientRow";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";

export const RawIngredients = () => {
    const { rawIngredients, rawIngredientsTotalWeight } =
        useFormulaProfileState();

    const hasRawIngredients = useMemo(
        () => Boolean(rawIngredients?.length),
        [rawIngredients],
    );

    const sortedRawIngredients = useMemo(() => {
        return rawIngredients?.sort((a, b) => {
            if (!a.description) return 1;
            if (!b.description) return -1;
            return a.description.localeCompare(b.description);
        });
    }, [rawIngredients]);

    return (
        <div>
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg">
                <thead className="bg-indigo-100">
                    <TableHeadRow>
                        <TableHeaderCeil text="RI Code" />
                        <TableHeaderCeil text="Description" />
                        <TableHeaderCeil
                            className="hidden md:table-cell"
                            text="INCI Ingredients"
                        />
                        <TableHeaderCeil
                            className="hidden sm:table-cell w-auto whitespace-nowrap"
                            text="Weight %"
                        />
                        <TableHeaderCeil
                            className="hidden sm:table-cell w-auto whitespace-nowrap"
                            text="Amount %"
                        />
                        <TableHeaderCeil className="sm:hidden" text="%" />
                        {hasRawIngredients && (
                            <TableHeaderCeil text="" className="w-2" />
                        )}
                    </TableHeadRow>
                </thead>
                <tbody>
                    {hasRawIngredients ? (
                        <>
                            {sortedRawIngredients.map((rawIngredient) => (
                                <RawIngredientRow
                                    key={rawIngredient.id}
                                    rawIngredient={rawIngredient}
                                    rawIngredientsTotalWeight={
                                        rawIngredientsTotalWeight
                                    }
                                />
                            ))}
                            <tr>
                                <TableCeil />
                                <TableCeil className="hidden sm:table-cell" />
                                <TableCeil className="hidden sm:table-cell" />
                                <TableCeil
                                    className="flex justify-end font-bold whitespace-nowrap"
                                    text="Total amount:"
                                />
                                <TableCeil
                                    className="text-center"
                                    text={rawIngredientsTotalWeight}
                                />
                                <TableCeil />
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <TableCeil text="No Raw Ingredients found" />
                            <TableCeil />
                            <TableCeil />
                            <TableCeil />
                            <TableCeil />
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
