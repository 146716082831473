import React, { useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { defaultToastOptions, routerKeys, toastTexts } from "common/constants";
import { Button } from "components/shared";
import { Id, toast } from "react-toastify";

export const LoginPage = () => {
    const { keycloak, initialized } = useKeycloak();
    const navigate = useNavigate();

    const isAuthenticated = useMemo(
        () => Boolean(keycloak.authenticated),
        [keycloak.authenticated],
    );

    useEffect(() => {
        if (isAuthenticated) {
            navigate(routerKeys.formulas);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        let toastId: Id | null = null;

        if (!initialized) {
            toastId = toast.loading(toastTexts.loading, defaultToastOptions);
        }

        return () => {
            if (toastId) {
                toast.dismiss(toastId);
            }
        };
    }, [initialized]);

    const handleLogin = useCallback(() => {
        keycloak.login();
    }, []);

    return (
        <div className="flex flex-grow items-center justify-center">
            <div className="flex flex-col justify-center w-full h-[400px] md:w-1/3 rounded-lg bg-white shadow-lg p-6">
                <h2 className="text-3xl text-center text-black mb-5">
                    SmartSafety
                </h2>
                <h5 className="text-center text-base mb-5 font-black">
                    Welcome!
                </h5>
                <div className="px-12 pb-3">
                    <Button
                        className="w-full btn-upload text-center"
                        onClick={handleLogin}
                        text="Log In"
                    />
                </div>
            </div>
        </div>
    );
};
