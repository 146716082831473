import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import instance from "./axios/instance";

type UserProfile = {
    id: string;
    email: string;
};

class KeycloakService extends BaseService {
    constructor(
        public baseUrl = backendKeys.keycloak,
        private fetchingService = instance,
    ) {
        super(baseUrl);
    }

    async assignGroup(payload: UserProfile) {
        this.handleRequest(
            this.fetchingService.post(
                this.getFullUrl("/assign-group"),
                payload,
            ),
        );
    }
}
export const keycloakService = new KeycloakService();
